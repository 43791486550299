import { intervalToDuration } from "date-fns";
import { formatDiffStrict } from "../../lib/functions";

type DurationValueProps = {
  seconds?: number;
};

export const formatDurationSeconds = (seconds: number) => {
  // const duration = format(0, seconds * 1000);
  const duration = intervalToDuration({
    //@ts-ignore
    start: 0,
    //@ts-ignore
    end: seconds * 1000,
  });

  return formatDiffStrict(duration);
};

export default function DurationValue({ seconds }: DurationValueProps) {
  if (!seconds) {
    return <>-</>;
  }

  return <>{formatDurationSeconds(seconds)}</>;
}
