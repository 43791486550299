import BaseApi from "./_baseApi";
import { doRequest } from "./_helpers";
import { Id, ProjectExport } from "@/types/apiTypes";

export default class ShareProjectExport extends BaseApi {
  findExport(
    exportId: Id,
    securityCode: String,
  ): Promise<ProjectExport> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/share/project-export/${exportId}`,
      data: { code: securityCode },
    });
  }

  download(exportId: Id, filedId: Id, securityCode: string): Promise<{ url: string }> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/share/project-export/${exportId}/download/${filedId}`,
      data: { code: securityCode },
    });
  }
}
