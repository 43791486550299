import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { Carpool, Id, Planning, Shift } from "../types/apiTypes";
import { SearchParams } from "../types/appTypes";

export default class AdminCarpool extends BaseApi {
  findOne(id: Id): Promise<Carpool> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/carpools/${id}`,
    });
  }

  findManyForPeriod(
    periodId: Id,
    searchParams: SearchParams = {},
  ): Promise<Carpool[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/periods/${periodId}/carpools${query}`,
    });
  }
}
