import Block from "@/components/common/Block";
import React, { createElement, memo, useMemo, useState } from "react";
import { FaCodeMerge } from "react-icons/fa6";
import {
  Heading,
  IconButton,
  Pane,
  Paragraph,
  Popover,
  Position,
} from "evergreen-ui";
import { AreaActivityType, Timeslot } from "@/types/apiTypes";
import { ShiftAugmented } from "@/pages/planner/_components/PlannerRoundsItem";
import { useApi } from "@/context/AxiosContext";
import { useTranslation } from "react-i18next";
import {
  TimeslotIconMap,
  TimeslotTypeToIconMap,
} from "@/components/config/iconMaps";

enum TimeslotType {
  MORNING = "morning",
  EVENING = "evening",
  NIGHT = "night",
  MIDNIGHT = "midnight",
}

const compatibleTimeslotTypes = {
  [TimeslotType.MORNING]: [TimeslotType.MORNING],
  [TimeslotType.EVENING]: [TimeslotType.NIGHT, TimeslotType.EVENING],
  [TimeslotType.NIGHT]: [TimeslotType.NIGHT, TimeslotType.EVENING],
  [TimeslotType.MIDNIGHT]: [TimeslotType.MIDNIGHT],
};

function useSetParentShift() {
  const { apiInstance } = useApi();
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function setParentShift(
    parentShiftId: number,
    areaActivityTypeId: number,
    timeslotUid: string,
  ) {
    setIsSubmitting(true);

    const result = await apiInstance?.adminShifts.addChildShift(
      parentShiftId,
      areaActivityTypeId,
      timeslotUid,
    );

    setIsSubmitting(false);

    return result;
  }

  return {
    setParentShift,
    isSubmitting,
  };
}

type ParentShiftWithTimeslotUid = {
  id?: number;
  name?: string | undefined;
  areaActivityTypeId: number;
  timeslotType: string;
  timeslotUid: string;
  icon: string;
};

const CombinePopover = memo(function CombinePopover({
  allShiftsInArea,
  areaActivityType,
  isPlannableWithinPeriod,
  shift,
  refresh,
}: {
  allShiftsInArea: ShiftAugmented[];
  areaActivityType: AreaActivityType;
  isPlannableWithinPeriod: boolean;
  shift: ShiftAugmented | null;
  refresh: () => void;
}) {
  const { setParentShift, isSubmitting } = useSetParentShift();
  const { t } = useTranslation();

  const [isShown, setIsShown] = useState(false);

  function onClick(e) {
    setIsShown(!isShown);
  }

  function onCloseComplete() {
    setIsShown(false);
  }

  async function onClickParentShift(parentShift: ParentShiftWithTimeslotUid) {
    await setParentShift(
      parentShift.id!,
      areaActivityType.id,
      parentShift.timeslotUid,
    );
    refresh();
  }

  const parentShiftCandidates: ParentShiftWithTimeslotUid[] = useMemo(() => {
    const timeslots = Object.values(
      areaActivityType.activityType.type.timeslots,
    );

    return timeslots
      .map((timeslotType: Timeslot) => {
        const matchingShift = allShiftsInArea.find((shiftInArea) => {
          if (!shiftInArea) {
            return false;
          }

          const hasCompatibleTimeslotType = compatibleTimeslotTypes[
            timeslotType.type
          ].includes(shiftInArea.timeslotType);

          return (
            shiftInArea.id !== shift?.id &&
            hasCompatibleTimeslotType &&
            areaActivityType.activityType.startAt <= shiftInArea.startAt
          );
        });

        if (!matchingShift) {
          return null;
        }

        return {
          id: matchingShift?.id,
          name: matchingShift?.name,
          areaActivityTypeId: areaActivityType.id,
          timeslotType: timeslotType.type,
          timeslotUid: timeslotType.id,
          icon: TimeslotTypeToIconMap[timeslotType.type],
        };
      })
      .filter((shift) => shift) as ParentShiftWithTimeslotUid[];
  }, [allShiftsInArea, shift]);

  return (
    <Block>
      <Popover
        bringFocusInside
        isShown={isShown}
        onClose={onCloseComplete}
        position={Position.TOP}
        shouldCloseOnEscapePress={true}
        shouldCloseOnExternalClick={true}
        content={
          <Pane className="px-4 py-4" width={240} minHeight={200}>
            <Heading className="pb-2" size={400}>
              Combineer met een dienst uit de lijst:
            </Heading>
            <Pane className="flex flex-col gap-2 overflow-y-auto" height={200}>
              {parentShiftCandidates.map((candidateShift) => (
                <Pane
                  onClick={() => onClickParentShift(candidateShift)}
                  className="cursor-pointer self-start rounded border-b-2 border-l-2 border-r-2 border-t p-2 py-2 hover:border-blue-400 hover:bg-blue-100"
                  key={candidateShift.id}
                >
                  {/* @ts-ignore */}
                  <Paragraph>
                    <Pane className="flex items-center gap-1">
                      {createElement(candidateShift.icon, {
                        size: 20,
                      })}{" "}
                      {t("common." + candidateShift.timeslotType)} -{" "}
                    </Pane>
                    {candidateShift.name}
                  </Paragraph>
                </Pane>
              ))}
            </Pane>
          </Pane>
        }
      >
        {parentShiftCandidates.length > 0 ? (
          <a>
            <IconButton
              onClick={onClick}
              className="!cursor-pointer"
              icon={
                !isSubmitting && (
                  <FaCodeMerge
                    color={isPlannableWithinPeriod ? "green" : "gray"}
                  />
                )
              }
              intent="success"
              disabled={!isPlannableWithinPeriod}
              isLoading={isSubmitting}
            />
          </a>
        ) : (
          // Keep empty element - Popover needs a child
          <a></a>
        )}
      </Popover>
    </Block>
  );
});

export default CombinePopover;
