import { BookIcon, Pane } from "evergreen-ui";
import { Outlet } from "react-router-dom";
import TopSubNav from "../common/TopSubNav";
import { ADMIN_BOOKKEEPING_EXPORT_PAGE } from "src/RouteMap";

export default function BookkeepingLayout() {
  // const menuItems = [
  //   {
  //     label: "Export",
  //     pathname: ADMIN_BOOKKEEPING_EXPORT_PAGE,
  //     icon: <BookIcon />,
  //   },
  // ];

  return (
    <Pane className="h-full">
      {/* <TopSubNav menuItems={menuItems} /> */}

      <Pane className="h-full">
        <Outlet />
      </Pane>
    </Pane>
  );
}
