import { useApi } from "src/context/AxiosContext";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDoRequest } from "src/lib/request-hooks";
import { WorkerShift } from "@/types/apiTypes";
import { Dialog, Pane, Text, TextInputField, toaster } from "evergreen-ui";

function useUpdateStartEndStatuses({ workerShift }) {
  const { apiInstance } = useApi();

  const {
    handle,
    setIsLoading: setIsSubmitting,
    isLoading: isSubmitting,
    validationErrors,
    errorMessage: submitErrorMessage,
  } = useDoRequest();

  const doSubmit = async (startStatus, stopStatus) => {
    const data = [
      startStatus,
      stopStatus,
    ];


    const request = apiInstance!.adminWorkerShifts.fixStatusUpdates(
      data,
    )

    await handle(request);

    toaster.success("Posted status updates");
  };

  return {
    submitContext: {
      doSubmit,
      setIsSubmitting,
      isSubmitting,
      submitErrorMessage,
      validationErrors,
    },
    doSubmit,
  };
}

export default function FixReportDataDialog({
  workerShift,
  isShown,
  close,
  afterSubmit,
}: {
  workerShift: WorkerShift;
  isShown: boolean;
  close: () => void;
  afterSubmit: () => void;
}) {

  const [startStatusValues, setStartStatusValues] = useState(
    workerShift.statusUpdates.find((update) => update.status === "started")
    || {
      id: uuidv4(),
      status: "started",
      answers: {},
      createdAt: workerShift.shift.startAt,
      shiftId: workerShift.id,
      workerId: workerShift.workerId,
      formId: "shift_status_started_v1",
    }
  )

  const [stopStatusValues, setStopStatusValues] = useState(
    workerShift.statusUpdates.find((update) => update.status === "stopped")
    || {
      id: uuidv4(),
      status: "stopped",
      answers: null,
      createdAt: workerShift.shift.endAt,
      shiftId: workerShift.id,
      workerId: workerShift.workerId,
      formId: null,
    }
  )

  const { submitContext, doSubmit } = useUpdateStartEndStatuses(
    {
      workerShift: workerShift,
    },
  );

  async function onConfirm() {
    await doSubmit(startStatusValues, stopStatusValues);
    afterSubmit();
  }

  return (
    <Dialog
      confirmLabel="Fix shift"
      intent="danger"
      isShown={isShown}
      onCloseComplete={close}
      onConfirm={onConfirm}
      shouldCloseOnOverlayClick={false}
      title="Fix shift start/ending times"
    >
      <Pane>
        <Pane className="mt-4">
          <Text className="my-4">Start status</Text>
          <TextInputField
            required
            name="startTime"
            label="Start time"
            isInvalid={!!submitContext.validationErrors?.startTime}
            validationMessage={submitContext.validationErrors?.startTime?.join(
              ", ",
            )}
            value={startStatusValues.createdAt}
            onChange={(e) => setStartStatusValues((current) => ({...current, createdAt: e.target.value}))}
            marginBottom={0}
            inputHeight={40}
            className="!text-base"
          />
          <Text className="my-4">Weather</Text>

          <TextInputField
            required
            name="start_shift__precipitation"
            label="Precipitation (Dry, Drizzle, Rain, Heavy rain, Hail, Snow)"
            isInvalid={!!submitContext.validationErrors?.start_shift__precipitation}
            validationMessage={submitContext.validationErrors?.start_shift__precipitation?.join(
              ", ",
            )}
            value={startStatusValues.answers.start_shift__precipitation}
            onChange={
                (e) => setStartStatusValues((current) => ({
                ...current, 
                answers: {...startStatusValues.answers, start_shift__precipitation: e.target.value},
              }))
            }
            marginBottom={0}
            inputHeight={40}
            className="!text-base"
          />
          <TextInputField
            required
            name="start_shift__temperature"
            label="Temp (number in celcius)"
            isInvalid={!!submitContext.validationErrors?.start_shift__temperature}
            validationMessage={submitContext.validationErrors?.start_shift__temperature?.join(
              ", ",
            )}
            value={startStatusValues.answers.start_shift__temperature}
            onChange={
                (e) => setStartStatusValues((current) => ({
                ...current, 
                answers: {...startStatusValues.answers, start_shift__temperature: e.target.value},
              }))
            }
            marginBottom={0}
            inputHeight={40}
            className="!text-base"
          />
          <TextInputField
            required
            name="start_shift__cloudiness"
            label="Cloudiness (0, 25, 50, 75, 100)"
            isInvalid={!!submitContext.validationErrors?.start_shift__cloudiness}
            validationMessage={submitContext.validationErrors?.start_shift__cloudiness?.join(
              ", ",
            )}
            value={startStatusValues.answers.start_shift__cloudiness}
            onChange={
                (e) => setStartStatusValues((current) => ({
                ...current, 
                answers: {...startStatusValues.answers, start_shift__cloudiness: e.target.value},
              }))
            }
            marginBottom={0}
            inputHeight={40}
            className="!text-base"
          />
          <TextInputField
            required
            name="start_shift__wind"
            label="Wind (0 - 12)"
            isInvalid={!!submitContext.validationErrors?.start_shift__wind}
            validationMessage={submitContext.validationErrors?.start_shift__wind?.join(
              ", ",
            )}
            value={startStatusValues.answers.start_shift__wind}
            onChange={
                (e) => setStartStatusValues((current) => ({
                ...current, 
                answers: {...startStatusValues.answers, start_shift__wind: e.target.value},
              }))
            }
            marginBottom={0}
            inputHeight={40}
            className="!text-base"
          />
          <TextInputField
            required
            name="start_shift__wind_direction"
            label="Wind direction (N, NW, W, SW, S, SE, E, NE)"
            isInvalid={!!submitContext.validationErrors?.start_shift__wind_direction}
            validationMessage={submitContext.validationErrors?.start_shift__wind_direction?.join(
              ", ",
            )}
            value={startStatusValues.answers.start_shift__wind_direction}
            onChange={
                (e) => setStartStatusValues((current) => ({
                ...current, 
                answers: {...startStatusValues.answers, start_shift__wind_direction: e.target.value},
              }))
            }
            marginBottom={0}
            inputHeight={40}
            className="!text-base"
          />
        </Pane>
        <Pane className="mt-4">
          <Text className="my-4">End</Text>

          <TextInputField
            required
            name="endTime"
            label="End time"
            isInvalid={!!submitContext.validationErrors?.endTime}
            validationMessage={submitContext.validationErrors?.endTime?.join(
              ", ",
            )}
            value={stopStatusValues.createdAt}
            onChange={(e) => setStopStatusValues((current) => ({...current, createdAt: e.target.value}))}
            marginBottom={0}
            inputHeight={40}
            className="!text-base"
          />
        </Pane>
      </Pane>
    </Dialog>
  );
}
