import { useApi } from "@/context/AxiosContext";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Area } from "@/types/apiTypes";
import { useLoadResource } from "@/lib/request-hooks";
import { FilterMapType } from "@/types/appTypes";
import { useDoFilter } from "@/hooks/useDoFilter";
import { useWindowSearchParams } from "@/hooks/useWindowSearchParams";

export default function useAreas() {
  const { apiInstance } = useApi();
  const searchParams = useWindowSearchParams();
  const { projectId } = useParams();

  const [areas, setAreas] = useState<Area[]>([]);

  const find = () =>
    apiInstance!.adminAreas.findManyForProject(projectId!, searchParams);

  const setData = (areas: Area[]) => {
    setAreas(areas);
  };

  const loadResourceLogic = useLoadResource(find, setData, true);

  const [initialized, setInitialized] = useState<boolean>(false);
  const [addressName, setAddressName] = useState<string | null>(null);

  const filterMap: FilterMapType = {
    addressName: {
      setter: setAddressName,
      topic: "areaName",
      value: addressName,
    },
  };

  const filterActions = useDoFilter({
    searchParams,
    setInitialized,
    initialized,
    filterMap,
    loadResourceLogic,
    find,
    setData,
    setMeta: () => {},
  });

  const {
    fetchData,
    setDefaultFilters,
    setFilter,
    doSortBy,
    handleInput,
    doFilter,
  } = filterActions;

  return {
    ...loadResourceLogic,
    areas,
    setAreas,
    doFilter,
    doSortBy,
    fetchData,
    filterMap,
    handleInput,
    setData,
    setDefaultFilters,
    setFilter,
  };
}
