import { Page, Document, PDFViewer, View, Styles } from "@react-pdf/renderer";
import { MediaItems, Report, ReportComponent } from "@/types/apiTypes";
import { pdfGeneralStyles } from "./styles/pdfReportStyles";
import { PdfPreviewTextComponent } from "./PdfPreviewTextComponent";
import { PdfPreviewTableComponent } from "./PdfPreviewTableComponent";
import { ReportPdfHeader } from "./PdfHeader";
import ReportPdfFooter from "./PdfFooter";
import { PdfPreviewIntroductionComponent } from "./PdfPreviewIntroductionComponent";
import { getFlattenedComponents } from "../utility";
import { PdfPreviewPageNumberComponent } from "./PdfPreviewPageNumberComponent";
import { PdfPreviewImage } from "./PdfPreviewImage";
import { PdfPreviewMapImage } from "./PdfPreviewMapImage";
import { useState } from "react";
import { PdfPreviewBibliographyComponent } from "./PdfPreviewBibliographyComponent";
import { PdfPreviewTableOfContentsComponent } from "./PdfPreviewTableOfContentsComponent";
import { PdfPreviewMultipleImages } from "./PdfPreviewMultipleImages";

export const PdfPreviewDocument = ({
  report,
  reportTemplateValues,
}: {
  report: Report;
  reportTemplateValues: { [key: string]: string };
}) => {
  const { components, title, date } = report;
  const [totalPages, setTotalPages] = useState("");
  useState(reportTemplateValues);
  const [introductionComponent] = components;

  return (
    <PDFViewer showToolbar={true} className="h-full w-full">
      <Document>
        {introductionComponent.type === "introduction" && (
          <PdfPreviewIntroductionComponent
            component={introductionComponent}
            date={date}
            mediaItems={report.mediaItems}
          />
        )}
        <Page size="A4" dpi={100} style={pdfGeneralStyles.generalPage}>
          <ReportPdfHeader reportTitle={title} />
          <PDFComponents
            components={components}
            reportTemplateValues={reportTemplateValues}
            totalPages={totalPages}
            report={report}
          />
          <PdfPreviewPageNumberComponent updateTotalPages={setTotalPages} />
          <ReportPdfFooter />
        </Page>
      </Document>
    </PDFViewer>
  );
};

const PDFComponents = ({
  components,
  report,
  reportTemplateValues,
  totalPages,
}: {
  components: ReportComponent[];
  report: Report;
  reportTemplateValues: {
    [key: string]: string;
  };
  totalPages: string;
}) => {
  const flattenedComponents = getFlattenedComponents(components);

  return (
    <>
      {flattenedComponents.map((component, index) => {
        if (component.type === "pageBreak") {
          return <View break key={index}></View>;
        }
        return (
          <View style={pdfGeneralStyles.content} key={index}>
            <PdfPreviewRenderer
              component={component}
              flattenedComponents={flattenedComponents}
              reportTemplateValues={{ ...reportTemplateValues, totalPages }}
              mediaItems={report.mediaItems}
            />
          </View>
        );
      })}
    </>
  );
};
const PdfPreviewRenderer = ({
  component,
  flattenedComponents,
  reportTemplateValues,
  mediaItems,
}: {
  component: ReportComponent;
  flattenedComponents: ReportComponent[];
  reportTemplateValues: { [key: string]: string };
  mediaItems: MediaItems;
}) => {
  switch (component.type) {
    case "textEditor":
      return (
        <PdfPreviewTextComponent
          component={component}
          reportTemplateValues={reportTemplateValues}
        />
      );
    case "table":
      return <PdfPreviewTableComponent component={component} />;
    case "multipleImages":
      return <PdfPreviewMultipleImages medias={component.images} />;
    case "image":
      return (
        <PdfPreviewImage
          imageComponent={component}
          styles={pdfGeneralStyles.mapImage as Styles}
          mediaItems={mediaItems}
          showDescription={true}
        />
      );
    case "mapImage":
      return (
        <PdfPreviewMapImage
          mapImageComponent={component}
          mediaItems={mediaItems}
        />
      );
    case "bibliography":
      return (
        <PdfPreviewBibliographyComponent
          bibliographyComponent={component}
          flattenedComponents={flattenedComponents}
        />
      );

    case "tableOfContents":
      return (
        <PdfPreviewTableOfContentsComponent
          tableOfContentsComponent={component}
        />
      );
    default:
      return null;
  }
};
