import Block from "@/components/common/Block";
import { MdWork } from "react-icons/md";
import { Heading, TextInputField } from "evergreen-ui";
import { shortenString } from "@/lib/functions";
import React from "react";
import MultiSelectInputField from "@/components/formfields/MultiSelectInputField";
import { LabeledOption } from "@/types/appTypes";

type ClientActivityMultiSelectProps = {
  clientActivityOptions: LabeledOption[];
  selected: LabeledOption[];
  setSelected: Function;
  onUpdatePreciseLocation: (item, location) => void;
};

export default function ClientActivityMultiSelect({
  clientActivityOptions,
  selected,
  setSelected,
  onUpdatePreciseLocation,
}: ClientActivityMultiSelectProps) {
  const setPreciseLocationForItem = (item, location) => {
    onUpdatePreciseLocation(item, location);
  };

  const renderItem = () => {
    return ({ item, index, onDeselect }) => {
      return (
        <Block
          key={item.activity.side + item.activity.name + index}
          className="flex items-center border bg-white px-2 py-2"
        >
          <Block className={"flex grow"}>
            <Block className="px-1">
              <MdWork color={"#7c7c7c"} size={15} />
            </Block>
            <Block className="flex-nowrap border-l px-1">
              <Heading title={item.activity.name} size={300}>
                {shortenString(item.activity.name, 20)}
              </Heading>
            </Block>
          </Block>
          <Block className="flex grow items-center">
            <TextInputField
              required
              className="w-full !text-sm"
              width="100%"
              name="Precieze locatie"
              label="precieze locatie"
              placeholder="onder,boven,links,rechts"
              value={item.activity.preciseLocationInfo}
              onChange={(e) => setPreciseLocationForItem(item, e.target.value)}
              marginBottom={0}
              inputHeight={30}
            />
          </Block>
        </Block>
      );
    };
  };

  return (
    <MultiSelectInputField
      emptySelectText={"Geen activiteiten"}
      options={clientActivityOptions}
      selected={selected}
      setSelected={setSelected}
      renderItem={renderItem()}
    />
  );
}
