import { useState } from "react";

export default function useSafeLeave({
  onConfirm,
  isDirty,
}: {
  onConfirm: () => void;
  isDirty: boolean;
}) {
  const [safeLeaveDialogShown, setSafeLeaveDialogShown] =
    useState<boolean>(false);

  function doLeave() {
    onConfirm();
  }

  function onLeave() {
    if (isDirty) {
      setSafeLeaveDialogShown(true);
    } else {
      doLeave();
    }
  }

  function closeSafeLeaveDialog() {
    setSafeLeaveDialogShown(false);
  }

  return {
    safeLeaveDialogShown,
    setSafeLeaveDialogShown,
    closeSafeLeaveDialog,
    doLeave,
    onLeave,
  };
}
