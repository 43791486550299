import { useWindowSearchParams } from "src/hooks/useWindowSearchParams";
import { useEffect, useMemo, useState } from "react";
import { Planning } from "../../../types/apiTypes";
import { isEmpty } from "lodash";
import { __r, PLANNER_PERIOD_PLANNING_PAGE } from "../../../RouteMap";
import { useLoadResource } from "../../../lib/request-hooks";
import { useDoFilter } from "../../../hooks/useDoFilter";
import { createSearchParams, useLocation, useParams } from "react-router-dom";
import { useApi } from "../../../context/AxiosContext";
import { FilterMapType } from "../../../types/appTypes";

export default function usePlanningWithFilters() {
  const searchParams = useWindowSearchParams();
  const location = useLocation();

  const { periodId } = useParams();

  const [planning, setPlanning] = useState<Planning[] | []>([]);
  const { apiInstance } = useApi();

  const setData = (planning) => {
    const filteredPlanning = planning.filter(
      (v) => !isEmpty(v.areaActivityTypes),
    );

    setPlanning(filteredPlanning);
  };

  const [latLng, setLatLng] = useState<string | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [activityType, setActivityType] = useState<string | null>(null);
  const [workerId, setWorkerId] = useState<number | null>(null);
  const [shiftStatus, setShiftStatus] = useState<string | null>(null);
  const [projectId, setProjectId] = useState<number | null>(null);
  const [shiftRequestType, setShiftRequestType] = useState<string | null>(null);
  const [projectType, setProjectType] = useState<string | null>(null);
  const [addressName, setAddressName] = useState<string | null>(null);
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  const [showDemo, setShowDemo] = useState<string | null>(null);

  const find = () => {
    return apiInstance!.adminPlanning.findManyForPeriod(
      periodId!,
      searchParams,
    );
  };

  const shouldFetch = useMemo(() => {
    return (
      !isEmpty(periodId) &&
      location.pathname === __r(PLANNER_PERIOD_PLANNING_PAGE, { periodId })
    );
  }, [periodId, location]);

  const filterMap: FilterMapType = {
    address: {
      setter: setLatLng,
      topic: "latLng",
      value: latLng,
    },
    fromDate: {
      setter: setFromDate,
      topic: "fromDate",
      value: fromDate,
    },
    toDate: {
      setter: setToDate,
      topic: "toDate",
      value: toDate,
    },
    addressName: {
      setter: setAddressName,
      topic: "addressName",
      value: addressName,
    },
    activityType: {
      setter: setActivityType,
      topic: "activityType",
      value: activityType,
    },
    workerId: {
      setter: setWorkerId,
      topic: "workerId",
      value: workerId,
      label: "Veldwerker",
    },
    shiftStatus: {
      setter: setShiftStatus,
      topic: "shiftStatus",
      value: shiftStatus,
      label: "Dienst status",
    },
    projectId: {
      setter: setProjectId,
      topic: "projectId",
      value: projectId,
    },
    projectType: {
      setter: setProjectType,
      topic: "projectType",
      value: projectType,
    },
    shiftRequestType: {
      setter: setShiftRequestType,
      topic: "shiftRequestType",
      value: shiftRequestType,
    },
    showDemo: {
      setter: setShowDemo,
      topic: "showDemo",
      value: showDemo,
    },
    // reportOverdue: {
    //     setter: setReportOverdue,
    //     topic: 'reportOverdue',
    //     label: "Rapportage achterstallig",
    // }
  };

  const loadResourceLogic = useLoadResource(find, setData, shouldFetch);

  const {
    fetchData,
    setDefaultFilters,
    setFilter,
    doSortBy,
    handleInput,
    doFilter,
  } = useDoFilter({
    searchParams,
    setInitialized,
    initialized,
    filterMap,
    loadResourceLogic,
    find,
    setData,
    setMeta: () => {},
  });

  useEffect(() => {
    if (!initialized) {
      setDefaultFilters();
    }
  }, []);

  return {
    ...loadResourceLogic,
    doFilter,
    doSortBy,
    fetchData,
    filterMap,
    handleInput,
    planning,
    setData,
    setDefaultFilters,
    setFilter,
    shouldFetch,
  };
}
