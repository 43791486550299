import { useParams } from "react-router-dom";
import { useApi } from "@/context/AxiosContext";
import { useDoRequest } from "@/lib/request-hooks";
import { Area, AreaActivityType, Id } from "@/types/apiTypes";
import { toaster } from "evergreen-ui";
import ActivityTypesSelect from "@/components/activity-types/ActivityTypesSelect";

export default function useAreaForm() {
  const { projectId } = useParams();

  // only retrieve data when we have an areaId.
  const { apiInstance } = useApi();

  const {
    handle,
    setIsLoading: setIsSubmitting,
    isLoading: isSubmitting,
    validationErrors,
    errorMessage: submitErrorMessage,
  } = useDoRequest();

  const remove = (areaId: Id) => apiInstance!.adminAreas.remove(areaId!);

  const doCreate = async (
    area: Partial<Area>,
    activityTypes: AreaActivityType,
  ) => {
    const data = {
      ...area,
      activityTypes,
    };

    const request = apiInstance!.adminAreas.createForProject(projectId!, data);
    const result = await handle(request);

    toaster.success("Saved!", {
      id: "success",
    });

    return result;
  };

  const doUpdate = (area: Partial<Area>) => {
    const data = {
      ...area,
    };

    const request = apiInstance!.adminAreas.update(area.id!, data);

    toaster.success("Saved!", {
      id: "success",
    });

    return handle(request);
  };

  const uploadFile = (objectId: Id, formData: FormData) => {
    const request = apiInstance!.adminAreas.uploadMedia(objectId, formData);

    toaster.success("Saved!", {
      id: "success",
    });

    return handle(request);
  };

  const removeFile = async (objectId: Id, mediaId: Id) => {
    const request = apiInstance!.adminAreas.removeMedia(objectId, mediaId);

    const result = await handle(request);

    toaster.success("Removed!");

    return result;
  };

  function doRemove(areaId: Id) {
    if (window.confirm("Weet je zeker dat je dit gebied wilt verwijderen?"))
      return remove(areaId);
  }

  function uploadFn(formData) {
    return apiInstance!.media.uploadToAreaTmp(formData);
  }

  return {
    uploadFn,
    doUpdate,
    doRemove,
    uploadFile,
    removeFile,
    doCreate,
    submitContext: {
      setIsSubmitting,
      isSubmitting,
      submitErrorMessage,
      validationErrors,
    },
  };
}
