import { useCallback, useEffect, useState } from "react";
import { Feature } from "geojson";
import mapboxgl from "mapbox-gl";
import { areaFactors } from "@turf/turf";
import { getBoundingBox } from "@/lib/functions";
import { Simulate } from "react-dom/test-utils";
import resize = Simulate.resize;

export const AREAS_SOURCE = "project-areas";

function addSourceAndLayersToMap(map: mapboxgl.Map, initialData: Feature[]) {
  map.addSource(AREAS_SOURCE, {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: initialData,
    },
  });

  map.addLayer({
    id: "project-areas",
    type: "fill",
    source: AREAS_SOURCE,
    paint: {
      "fill-color": "#008DD5",
      "fill-opacity": 0.4,
    },
    filter: ["==", "$type", "Polygon"],
  });
}

function useMapEvents({ mapRef, onClick, areas }) {
  const [hoveredFeatureId, setHoveredFeatureId] = useState(null);

  const onClickArea = (e) => {
    const selectedArea = mapRef.current.queryRenderedFeatures(e.point, {
      layers: [AREAS_SOURCE],
    });

    if (selectedArea.length === 0) {
      return;
    }

    onClick(selectedArea[0], areas, e);
  };

  const onHoverObject = useCallback(
    (e) => {
      const features = e.features;
      const feature = features[0];
      const objectId = feature.properties.area_id;

      if (hoveredFeatureId) {
        mapRef.current!.setFeatureState(
          { source: AREAS_SOURCE, id: hoveredFeatureId },
          { hover: false },
        );
      }

      if (objectId) {
        mapRef.current!.setFeatureState(
          { source: AREAS_SOURCE, id: objectId },
          { hover: true },
        );
      }

      setHoveredFeatureId(objectId);
    },
    [mapRef.current, hoveredFeatureId],
  );

  const onMouseLeave = useCallback(() => {
    if (hoveredFeatureId) {
      mapRef.current!.setFeatureState(
        { source: AREAS_SOURCE, id: hoveredFeatureId },
        { hover: false },
      );
    }

    setHoveredFeatureId(null);
  }, [mapRef.current, hoveredFeatureId]);

  return {
    onClickArea,
    onHoverObject,
    onMouseLeave,
  };
}

export function useShowAreasOnMap({
  shouldFitBoundsOnLoad = false,
  mapRef,
  areasFeatures,
  onClick,
  areas,
  onLoaded,
}) {
  const { onClickArea, onHoverObject, onMouseLeave } = useMapEvents({
    mapRef,
    onClick,
    areas,
  });

  const [mapIsLoaded, setMapIsLoaded] = useState(false);

  useEffect(() => {
    if (!mapRef.current || mapIsLoaded) {
      return;
    }

    const map = mapRef.current;

    const onLoad = () => {
      addSourceAndLayersToMap(map, areasFeatures);
      setMapIsLoaded(true);
      onLoaded && onLoaded();
    };

    const onIdle = () => {
      map.resize();
    };

    map.once("idle", onIdle);
    map.on("load", onLoad);

    return () => {
      map.off("load", onLoad);
    };
  }, [mapRef.current, areasFeatures]);

  useEffect(() => {
    const map = mapRef.current;

    if (!map) {
      return;
    }

    if (!mapIsLoaded) {
      return;
    }

    const source = map.getSource(AREAS_SOURCE) as
      | mapboxgl.GeoJSONSource
      | undefined;

    if (source) {
      source.setData(areasFeatures);
    }
  }, [mapRef.current, areasFeatures, mapIsLoaded]);

  useEffect(() => {
    const areaMapRef = mapRef.current;

    if (!areaMapRef) {
      return;
    }

    if (shouldFitBoundsOnLoad && areasFeatures?.features?.length > 0) {
      const polygonCoordinates = areasFeatures.features[0].geometry;

      //@ts-ignore
      const points = polygonCoordinates.coordinates[0].map((points) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: points,
          },
        };
      });

      const boundingBox = getBoundingBox(points);

      const bounds = [
        [boundingBox.xMin, boundingBox.yMin],
        [boundingBox.xMax, boundingBox.yMax],
      ];

      areaMapRef.fitBounds(bounds);
    }
  }, [areasFeatures, shouldFitBoundsOnLoad]);

  useEffect(() => {
    const map = mapRef.current;

    if (!map) {
      return;
    }

    if (!mapIsLoaded) {
      return;
    }

    map.on("click", AREAS_SOURCE, onClickArea);
    map.on("mousemove", AREAS_SOURCE, onHoverObject);
    map.on("mouseleave", AREAS_SOURCE, onMouseLeave);

    return () => {
      map.off("click", AREAS_SOURCE, onClick);
      map.off("mousemove", AREAS_SOURCE, onHoverObject);
      map.off("mouseleave", AREAS_SOURCE, onMouseLeave);
    };
  }, [mapRef, mapIsLoaded]);
}
