import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { ActivityType, Id } from "@/types/apiTypes";

export default class Forms extends BaseApi {
  findOne(id: Id): Promise<ActivityType> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/forms/${id}`,
    });
  }

  findMany() {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/forms`,
    });
  }
}
