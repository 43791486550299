import { ClientActivitiesOnObject } from "@/types/apiTypes";
import Block from "@/components/common/Block";
import { Heading, Paragraph, Tooltip } from "evergreen-ui";
import { MdWork } from "react-icons/md";
import { shortenString } from "@/lib/functions";
import React from "react";

export default function ObjectClientActivitiesStatic({
  clientActivities,
}: {
  clientActivities: ClientActivitiesOnObject[];
}) {
  return (
    <Block className="flex gap-2">
      {clientActivities.map((activity) => (
        <Tooltip key={activity.id} content={activity.side} position={"top"}>
          <Block className="border hover:border-gray-400 hover:bg-gray-100">
            <Block className="flex cursor-pointer flex-nowrap items-center border">
              <Block className="px-1">
                <MdWork color={"#7c7c7c"} size={15} />
              </Block>
              <Block className="flex gap-1 border-l px-1">
                <Heading title={activity.name} size={200}>
                  {shortenString(activity.name, 20)}
                </Heading>
              </Block>
              <Block>
                <Paragraph size={300}>
                  {activity.preciseLocationInfo?.join(",")}
                </Paragraph>
              </Block>
            </Block>
          </Block>
        </Tooltip>
      ))}
    </Block>
  );
}
