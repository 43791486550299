import { AxiosError } from "axios";
import { ApiAxiosErrorResponse, ValidationErrorsType } from "../types/apiTypes";

export default class ApiError extends Error {
  public date: Date;
  public data: AxiosError | null;
  public messages: string[] | null = null;
  public validationErrors: ValidationErrorsType | null = null;
  public code: number | null = null;

  constructor(error: AxiosError | null = null, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    this.name = "ApiError";

    this.date = new Date();
    this.data = error;

    if (error?.response) {
      const { data } = error.response as ApiAxiosErrorResponse;

      this.code = error.response.status;

      if (data?.errors) {
        this.validationErrors = data.errors;
        // const [field, message] = Object.entries(data.errors)[0] as string[];
        // this.messages = [`${field} ${message}`];
      } else if (data.message) {
        this.messages = [data.message];
      }
    } else {
      this.messages = ["Api Unreachable"];
    }
  }
}
