import {
  Checkbox,
  Heading,
  Pane,
} from "evergreen-ui";
import { useState } from "react";

export default function ShowDemoFilter({ filterMap, handleInput }) {
  const [showDemo, setShowDemo] = useState(filterMap.showDemo.value === "1")

  function setStatusFilter() {
    setShowDemo((current) => {
      handleInput(filterMap.showDemo, showDemo === true ? null : "1")
      return !current
    })
  }

  return (
    <Pane className="flex flex-col gap-1">
      <Pane className="flex h-4 justify-between">
        <Heading size={200}>
          Demo
        </Heading>
      </Pane>
      <Pane maxWidth={200} height={40}>
        <Checkbox
          onChange={() => setStatusFilter()}
          checked={showDemo}
        />
      </Pane>
    </Pane>
  );
}
