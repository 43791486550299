import { useCallback, useEffect } from "react";
import { createSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import { FilterMapType } from "../types/appTypes";

export interface useDoFilterProps {
  searchParams: URLSearchParams;
  setInitialized: Function;
  initialized: boolean;
  filterMap: FilterMapType;
  loadResourceLogic: any;
  setMeta: Function;
  setData: Function;
  find: Function;
}

export const useDoFilter = function ({
  searchParams,
  setInitialized,
  initialized,
  filterMap,
  loadResourceLogic,
  setMeta,
  setData,
  find,
}: useDoFilterProps) {
  const { handle, setIsLoading } = loadResourceLogic;

  const fetchData = async () => {
    const setDataCb = async (data) => {
      return new Promise((resolve) => {
        setData(data?.data || data);
        setMeta(data?.metadata);
        resolve(data);
      });
    };

    setIsLoading(true);
    await handle(find(), setDataCb);
    setIsLoading(false);
  };

  const setDefaultFilters = useCallback(() => {
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.forEach((value, key) => {
      searchParams.set(key, value);
    });

    for (const [key, entry] of Object.entries(filterMap)) {
      if (queryParams.get(entry.topic)) {
        let value;

        if (entry.transform) {
          value = entry.transform(queryParams.get(entry.topic));
        } else {
          value = queryParams.get(entry.topic);
        }

        if (entry.setter) {
          entry.setter(value);
        }

        queryParams.set(entry.topic, value);
        searchParams.set(entry.topic, value);
      }

      if (entry.default && !queryParams.get(entry.topic)) {
        if (entry.setter) {
          entry.setter(entry.default);
        }

        let value;

        if (entry.transform) {
          value = entry.transform(entry.default);
        } else {
          value = entry.default.toString();
        }

        queryParams.set(entry.topic, value);
        searchParams.set(entry.topic, value);
      }
    }

    const url =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?${createSearchParams(queryParams)}`;
    window.history.pushState({ path: url }, "", url);

    setInitialized(true);
  }, []);

  const setFilter = (filterEntry, value) => {
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.forEach((value, key) => {
      searchParams.set(key, value);
    });

    if (!filterEntry) {
      return;
    }

    if (filterEntry.transform) {
      value = filterEntry.transform(value);
    }

    if (value) {
      searchParams.set(filterEntry.topic, value.toString());
    } else {
      searchParams.delete(filterEntry.topic);
    }

    filterEntry.setter(value);

    const url =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?${createSearchParams(searchParams)}`;
    window.history.pushState({ path: url }, "", url);
  };

  const doSortBy = async (value) => {
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.forEach((value, key) => {
      searchParams.set(key, value);
    });

    if (value) {
      queryParams.set("sortBy", value);
      searchParams.set("sortBy", value);
    } else {
      queryParams.delete("sortBy");
      searchParams.delete("sortBy");
    }

    const url =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?${createSearchParams(queryParams)}`;
    window.history.pushState({ path: url }, "", url);

    await fetchData();
  };

  const doFilter = async (filterEntry, value) => {
    setFilter(filterEntry, value);

    return fetchData();
  };

  const debouncedFetchData = useCallback(debounce(fetchData, 300), [
    initialized,
    find,
  ]);

  const debouncedFilter = useCallback(
    function (filterEntry, value) {
      setFilter(filterEntry, value);
      debouncedFetchData();
    },
    [initialized, find],
  );

  const handleInput = (
    filterEntry,
    value: string | number | null | boolean | readonly string[] | undefined,
  ) => {
    debouncedFilter(filterEntry, value);
  };

  return {
    setDefaultFilters,
    fetchData,
    setFilter,
    doSortBy,
    doFilter,
    debouncedFetchData,
    debouncedFilter,
    handleInput,
  };
};
