import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { Id } from "../types/apiTypes";
import { PostData, SearchParams } from "src/types/appTypes";

export default class WorkerAvailability extends BaseApi {
  findOne(id: Id): Promise<WorkerAvailability> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/worker/availability/${id}`,
    });
  }

  syncAvailability(data: PostData): Promise<WorkerAvailability[]> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/worker/availability`,
      data: data,
    });
  }

  findMany(searchParams: SearchParams = {}): Promise<WorkerAvailability[]> {
    const query = !isEmpty(searchParams)
      ? `?${createSearchParams(searchParams)}`
      : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/worker/availability${query}`,
    });
  }

  update(id: Id, data: PostData): Promise<WorkerAvailability> {
    return doRequest(this._axiosInstance!, {
      method: "put",
      url: `/worker/availability/${id}`,
      data: data,
    });
  }

  remove(id: Id): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: "delete",
      url: `/worker/availability/${id}`,
    });
  }
}
