import { useState } from "react";

export default function useSafeSubmit({
  onConfirm,
  doSubmit,
  isDirty = true,
}: {
  onConfirm: () => void;
  doSubmit: () => Promise<any>;
  isDirty?: boolean;
}) {
  const [safeSubmitDialogShown, setSafeSubmitDialogShown] =
    useState<boolean>(false);

  async function doSafeSubmit() {
    await doSubmit();
    onConfirm();
    setSafeSubmitDialogShown(false);
  }

  function onSafeSubmit() {
    if (isDirty) {
      setSafeSubmitDialogShown(true);
    } else {
      onConfirm();
    }
  }

  function closeSafeDialog() {
    setSafeSubmitDialogShown(false);
  }

  return {
    safeSubmitDialogShown,
    setSafeSubmitDialogShown,
    closeSafeDialog,
    doSafeSubmit,
    onSafeSubmit,
  };
}
