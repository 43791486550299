import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { Id, WorkerShift } from "../types/apiTypes";
import { PostData, SearchParams } from "../types/appTypes";

export default class WorkerShifts extends BaseApi {
  findOne(id: Id): Promise<WorkerShift> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/worker/shifts/${id}`,
    });
  }

  requestChange(id: Id, data: PostData): Promise<WorkerShift> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/worker/shifts/${id}/request-change`,
      data: data,
    });
  }

  statusUpdate(id: Id, data: PostData): Promise<WorkerShift> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/shifts/status-updates`,
      data: data,
    });
  }

  findManyHistory(searchParams: SearchParams = {}): Promise<WorkerShift[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/worker/shift-history${query}`,
    });
  }

  findMany(searchParams: SearchParams = {}): Promise<WorkerShift[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/worker/shifts${query}`,
    });
  }

  findPayments() {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/worker/payments`,
    });
  }
}
