import {
  Alert,
  Card,
  ChevronLeftIcon,
  EyeOpenIcon,
  Heading,
  InfoSignIcon,
  majorScale,
  Pane,
  Paragraph,
  Text,
  TextareaField,
  TextInputField,
  TickIcon,
  Tooltip,
} from "evergreen-ui";
import { useTranslation } from "react-i18next";
import useTimeslotResources from "../../../hooks/useTimeslotResources";
import useSafeSubmit from "../../../hooks/useSafeSubmit";
import useSafeLeave from "../../../hooks/useSafeLeave";
import AreYouSureDialog from "../../../components/common/AreYouSureDialog";
import PendingChangesDialog from "../../../components/common/PendingChangesDialog";
import { DateFormat } from "../../../components/common/DateFormat";
import Actions from "../../../components/common/Actions";
import { getWeek, isMonday, parseISO } from "date-fns";
import Divider from "../../../components/common/Divider";
import AllTimeslotsAvailabilitySwitcher from "./AllTimeslotsAvailabilitySwitcher";
import AvailabilitySwitcher from "./AvailabilitySwitcher";
import { TableLoadingSpinner } from "../../../components/table/StandardTable";
import { countBy, groupBy, isEmpty } from "lodash";
import { transformToApiWorkerAvailability } from "../../../hooks/usePeriodForm";
import { useMemo, useState } from "react";
import { classNames } from "../../../lib/functions";
import { SunTimesDialog } from "./SunTimesDialog";
import { useNavigate, useNavigation } from "react-router-dom";

type CountByKeyType = {
  [key: string]: number;
};

const minDaysPerWeek = 2;

function Counter({ size, count, min }) {
  const { t } = useTranslation();
  const timeslotCounterClasses = classNames(
    count >= min ? "!text-green-600" : "!text-red-600",
    "font-bold !text-sm",
  );

  return (
    <Tooltip
      content={t("worker_availability_page.enough_shifts_per_week_tooltip", {
        minDaysPerWeek,
      })}
    >
      <Pane className="justify-content flex cursor-pointer items-center gap-1">
        <Pane className="flex min-w-[2rem] flex-col">
          <Heading size={size} className={timeslotCounterClasses}>
            {count} / {min}
          </Heading>
        </Pane>
        <InfoSignIcon color="muted" />
      </Pane>
    </Tooltip>
  );
}

function TimeslotHeaderEntry({ timeslot }) {
  const { t } = useTranslation();

  return (
    <Tooltip content={t(timeslot.info)}>
      <Pane className="flex cursor-pointer items-center justify-center gap-1">
        <Heading className="!text-xs sm:!text-base" size={400}>
          {t(timeslot.label)}
        </Heading>
        <InfoSignIcon color="muted" />
      </Pane>
    </Tooltip>
  );
}

function useTimeslotCounters({ availability, timeslots, weekInterval }) {
  const groupedByTimeslotType = useMemo(() => {
    return countBy(
      transformToApiWorkerAvailability(availability.current),
      "timeslotUid",
    );
  }, [availability.current]) as CountByKeyType;

  const groupedPerWeek = useMemo(() => {
    const result = {};

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [dateKey, avEntries] of Object.entries(availability.current)) {
      if (isEmpty(avEntries)) {
        continue;
      }

      const key = getWeek(parseISO(dateKey), { weekStartsOn: 1 });

      if (result[key]) {
        result[key] += 1;
      } else {
        result[key] = 1;
      }
    }

    return result;
  }, [availability.current, timeslots]) as CountByKeyType;

  const timeslotByUid = useMemo(() => {
    return groupBy(timeslots, "id");
  }, [timeslots]);

  const enoughShiftsPerWeek = useMemo(() => {
    if (isEmpty(timeslotByUid)) {
      return true;
    }

    const amountPerWeek = weekInterval.reduce((accumulator, value, index) => {
      const key = getWeek(weekInterval[index], { weekStartsOn: 1 });

      const countThisWeek = groupedPerWeek[key] ?? 0;
      const count =
        countThisWeek >= minDaysPerWeek ? minDaysPerWeek : countThisWeek;

      return accumulator + count;
    }, 0);

    if (amountPerWeek < minDaysPerWeek * weekInterval.length) {
      return false;
    }

    return true;
  }, [timeslotByUid, groupedByTimeslotType]);

  return {
    groupedByTimeslotType,
    enoughShiftsPerWeek,
    groupedPerWeek,
  };
}

function WeekSeparator({ period, timeslots, date, groupedPerWeek }) {
  const { t } = useTranslation();

  const weekNumber = useMemo(() => getWeek(date, { weekStartsOn: 1 }), [date]);

  const countThisWeek = useMemo(() => {
    return groupedPerWeek[getWeek(date, { weekStartsOn: 1 })] ?? 0;
  }, [timeslots, date, groupedPerWeek]);

  return (
    <>
      <td colSpan={timeslots.length + 3} className="pt-5">
        <Heading className="!text-lg" size={200}>
          Week {weekNumber}
        </Heading>
      </td>
      <tr>
        <td>
          <Pane className="flex justify-center">
            <Divider title={t("worker_availability_page.action")} />
          </Pane>
        </td>
        <td>
          <Pane className="flex justify-center">
            <Divider
              title={t("worker_availability_page.week_nr", { weekNumber })}
            />
          </Pane>
        </td>
        {timeslots.map((timeslot) => (
          <td key={date + timeslot.id}>
            <Pane className="flex flex-col items-center justify-center">
              <Divider title={t(timeslot.label)} />
            </Pane>
          </td>
        ))}
        <td>
          <Pane className="flex items-center">
            <Counter size={600} count={countThisWeek} min={minDaysPerWeek} />
          </Pane>
        </td>
      </tr>
    </>
  );
}

export default function AvailabilityTable(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    availability,
    doSubmit,
    isLocked,
    fromDate,
    interval,
    weekInterval,
    isLoading,
    refreshCount,
    refreshView,
    submitContext,
    toDate,
    useForm,
    backPath,
    period,
    ignoreIsLocked,
    notes,
    setNotes,
  } = props;

  const { formState, onChange } = useForm();
  const { timeslots, isLoading: staticIsLoading } = useTimeslotResources();
  const [sunTimesDialogIsOpen, setSunTimesDialogIsOpen] =
    useState<boolean>(false);

  function onConfirmSafeSubmit() {
    navigate(backPath);
  }

  const { safeSubmitDialogShown, closeSafeDialog, doSafeSubmit, onSafeSubmit } =
    useSafeSubmit({
      onConfirm: onConfirmSafeSubmit,
      doSubmit,
      isDirty: formState.isDirty,
    });

  const { closeSafeLeaveDialog, doLeave, onLeave, safeLeaveDialogShown } =
    useSafeLeave({
      onConfirm: onConfirmSafeSubmit,
      isDirty: formState.isDirty,
    });

  const { enoughShiftsPerWeek, groupedPerWeek } = useTimeslotCounters({
    availability,
    timeslots,
    weekInterval,
  });

  const saveDisabled = useMemo(() => {
    // if (!enoughShiftsPerWeek) {
    //     return true;
    // }

    return !formState.isDirty;
  }, [enoughShiftsPerWeek, formState.isDirty]);

  return (
    <Pane>
      <SunTimesDialog
        isOpen={sunTimesDialogIsOpen}
        close={() => setSunTimesDialogIsOpen(false)}
        fromDate={fromDate}
        toDate={toDate}
      />

      <AreYouSureDialog
        doAction={doSafeSubmit}
        text={t("worker_availability_page.dialog_are_you_sure.text").toString()}
        intent="success"
        danger={
          !enoughShiftsPerWeek
            ? t("worker_availability_page.info_shifts_per_week", {
                minDaysPerWeek,
              }).toString()
            : null
        }
        isShown={safeSubmitDialogShown}
        onCloseComplete={closeSafeDialog}
        isConfirmLoading={!!submitContext.isLoading}
      >
        <Pane className="py-2">
          <TextareaField
            hint={
              "Mochten er bijzonderheden zijn over deze period, laat dat dan weten middels deze notities."
            }
            inputHeight={40}
            name="periodNotes"
            label="Notities bij je beschikbaarheid"
            placeholder="..."
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </Pane>
      </AreYouSureDialog>

      <PendingChangesDialog
        doAction={doLeave}
        isShown={safeLeaveDialogShown}
        onCloseComplete={closeSafeLeaveDialog}
        isConfirmLoading={!!submitContext.isLoading}
      />

      {submitContext.errorMessage && (
        <Alert
          intent="danger"
          title={submitContext.errorMessage}
          marginBottom={32}
        />
      )}

      <Pane
        className="flex items-center gap-2 rounded-md"
        padding={20}
        marginY={20}
        elevation={1}
      >
        <InfoSignIcon
          className="self-start"
          color="muted"
          size={majorScale(3)}
        />
        <Pane className="flex flex-col gap-2">
          <Paragraph color="muted" className="!text-xs">
            {t("worker_availability_page.explanation")}
          </Paragraph>
          <Paragraph color="muted" className="!text-xs">
            {t("worker_availability_page.explanation_extra")}
          </Paragraph>
        </Pane>
      </Pane>

      {!enoughShiftsPerWeek && (
        <Pane marginY={20}>
          <Alert intent="warning">
            <Paragraph size={300} className="!text-xs">
              {t("worker_availability_page.info_shifts_per_week", {
                minDaysPerWeek,
              })}
            </Paragraph>
          </Alert>
        </Pane>
      )}

      <table className="availability-table">
        <col className="width:5%" />

        {/*the top header with save buttons header*/}
        <thead className="shadow-top-md bg-white bg-white px-4 shadow-md sm:sticky sm:top-0 sm:z-10">
          <tr key="header" className="bg-white">
            <th className="bg-white" colSpan={timeslots?.length + 3}>
              <Pane className="flex flex-wrap p-4 md:flex-row">
                <Pane className="flex grow flex-wrap items-center gap-2 pt-2">
                  {fromDate && toDate && (
                    <>
                      <Heading size={600}>{t("common.period")}:</Heading>
                      <DateFormat
                        className="!text-base"
                        formatStr="eeeeee - PP"
                        date={fromDate}
                      />{" "}
                      <Text>/</Text>{" "}
                      <DateFormat
                        className="!text-base"
                        formatStr="eeeeee - PP"
                        date={toDate}
                      />
                    </>
                  )}
                  <Pane marginLeft="auto" className="block lg:hidden">
                    <Actions.Button
                      textOverflow={"ellipsis"}
                      onClick={() => setSunTimesDialogIsOpen(true)}
                      iconBefore={EyeOpenIcon}
                      height={majorScale(5)}
                    >
                      {t("worker_availability_page.sun_times")}
                    </Actions.Button>
                  </Pane>
                </Pane>
                <Pane className="shrink">
                  <Actions marginTop={10} marginLeft="auto" marginBottom={10}>
                    <Actions.Button
                      iconBefore={ChevronLeftIcon}
                      onClick={onLeave}
                      height={majorScale(5)}
                    >
                      {t("common.go_back")}
                    </Actions.Button>
                    {!isLocked && (
                      <Actions.Button
                        textOverflow={"ellipsis"}
                        onClick={onSafeSubmit}
                        iconBefore={TickIcon}
                        disabled={
                          ignoreIsLocked === true ? false : saveDisabled
                        }
                        appearance="primary"
                        intent="success"
                        height={majorScale(5)}
                      >
                        {t("worker_availability_page.save_availability")}
                      </Actions.Button>
                    )}
                  </Actions>
                </Pane>
              </Pane>
            </th>
          </tr>

          {/* the table header with the timeslots */}
          <tr className="py-5">
            <th key="action" className="!w-[2%] py-10">
              <Heading className="!text-xs sm:!text-base">
                {t("worker_availability_page.action")}
              </Heading>
            </th>

            <th key="date" className="py-10">
              <Heading className="!text-xs sm:!text-base">
                {t("common.date")}{" "}
              </Heading>
            </th>

            {timeslots.map((timeslot) => (
              <th key={t(timeslot.id)}>
                <TimeslotHeaderEntry timeslot={timeslot} />
              </th>
            ))}
            <th className="hidden lg:table-cell">
              <Actions.Button
                textOverflow={"ellipsis"}
                onClick={() => setSunTimesDialogIsOpen(true)}
                size={"small"}
                iconBefore={EyeOpenIcon}
                height={majorScale(5)}
              >
                {t("worker_availability_page.sun_times")}
              </Actions.Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {interval && !isMonday(interval[0]) && (
            <WeekSeparator
              period={period}
              key={refreshCount}
              groupedPerWeek={groupedPerWeek}
              timeslots={timeslots}
              date={interval[0]}
            />
          )}

          {(interval ?? []).map((date) => (
            <>
              {isMonday(date) && (
                <WeekSeparator
                  period={period}
                  key={refreshCount}
                  groupedPerWeek={groupedPerWeek}
                  timeslots={timeslots}
                  date={date}
                />
              )}
              <tr
                className="availability-rows min-h-[5rem] py-10"
                key={"row-" + date.toDateString()}
              >
                <AllTimeslotsAvailabilitySwitcher
                  key={refreshCount}
                  date={date}
                  timeslots={timeslots}
                  availability={availability}
                  refreshView={refreshView}
                  isLocked={isLocked}
                  onChange={onChange}
                />

                {timeslots.map((timeslot) => (
                  <AvailabilitySwitcher
                    key={date.toDateString() + timeslot.id + refreshCount}
                    refreshView={refreshView}
                    refreshCount={refreshCount}
                    date={date}
                    timeslot={timeslot}
                    availability={availability}
                    isLocked={isLocked}
                    onChange={onChange}
                  />
                ))}
              </tr>
            </>
          ))}
        </tbody>
      </table>

      <Pane marginTop={20}>
        {isLoading === true && !interval && (
          <TableLoadingSpinner
            title={"Beschikbaarheidstable aan het laden..."}
          />
        )}
      </Pane>
    </Pane>
  );
}
