import React from "react";
import { EmptyState, Heading, SearchIcon } from "evergreen-ui";

import { ClientActivity, ClientObject, Id } from "@/types/apiTypes";
import Block from "@/components/common/Block";
import ObjectEditMap from "@/pages/projects/objects/ObjectMap.component";
import ObjectListItem from "@/pages/projects/objects/ObjectListItem.component";
import Divider from "@/components/common/Divider";
import { useTranslation } from "react-i18next";
import Page from "@/components/common/Page";
import CsvImportButton from "@/components/common/CsvImportButton";
import ObjectCsvImportList from "@/pages/projects/objects/ObjectCsvImportList";
import useObjectViewState from "@/pages/projects/objects/useObjectEditViewState.hook";
import { isEmpty } from "lodash";
import useObjectForm from "@/pages/projects/objects/useObjectForm.hook";
import ObjectFuzzyFilter from "@/components/shared/filters/ObjectFuzzyFilter";

export default function ObjectEditPage() {
  const { t } = useTranslation();

  const {
    objectMapRef,
    objects,
    setObjects,
    activeObject,
    setActiveObject,
    setNewObject,
    updateList,
    afterSave,
    afterDelete,
    onClickEdit,
    clientActivities,
    rowsToImport,
    setRowsToImport,
    doImportCsvRow,
    objectApi,
    onClickImportRow,
    onClickLocate,
    tmpGeometries,
    selectedGeometry,
    filterMap,
    handleInput,
  } = useObjectViewState();

  return (
    <Page paddingBottom={0}>
      <Block className="flex w-full gap-2">
        <Block className="flex-grow">
          <ObjectEditMap
            ref={objectMapRef}
            selectedGeometry={selectedGeometry}
            tmpGeometries={tmpGeometries}
            objects={objects}
            setObjects={setObjects}
            setNewObject={setNewObject}
            onClickObject={setActiveObject}
            activeObject={activeObject}
          />
        </Block>
        <Block className="min-w-[20rem] max-w-[25rem] flex-shrink">
          <Block className="flex items-center justify-between gap-2 py-4">
            <Heading size={600}>{t("object_page.objects")}</Heading>
            <Divider />
            {isEmpty(rowsToImport) && (
              <CsvImportButton
                title={t("object_page.import")}
                doImportRow={doImportCsvRow}
                setRowsToImport={setRowsToImport}
              />
            )}
          </Block>
          <Block className="py-2">
            <ObjectFuzzyFilter
              filterMap={filterMap}
              handleInput={handleInput}
            />
          </Block>
          <Block>
            {!isEmpty(rowsToImport) && (
              <ObjectCsvImportList
                rowsToImport={rowsToImport}
                doImportRow={doImportCsvRow}
                setRowsToImport={setRowsToImport}
                onClickImportRow={onClickImportRow}
              />
            )}
          </Block>

          {isEmpty(rowsToImport) && (
            <ObjectList
              activeObject={activeObject}
              activeObjectId={activeObject?.id}
              afterDelete={afterDelete}
              afterSave={afterSave}
              clientActivities={clientActivities}
              objectApi={objectApi}
              objects={objects}
              onClickEdit={onClickEdit}
              onClickLocate={onClickLocate}
              updateList={updateList}
            />
          )}
        </Block>
      </Block>
    </Page>
  );
}

type ObjectListProps = {
  objects: ClientObject[];
  activeObjectId: Id | undefined;
  objectApi: ReturnType<typeof useObjectForm>;
  afterSave: (object: ClientObject) => void;
  afterDelete: (object: ClientObject) => void;
  onClickEdit: (object: ClientObject) => void;
  clientActivities: ClientActivity[];
  updateList: (object: ClientObject) => void;
  activeObject: ClientObject | null;
  onClickLocate: (object: ClientObject) => void;
};

function ObjectList(props: ObjectListProps) {
  const { objects, ...rest } = props;

  return (
    <Block className="mt-2 max-h-[calc(100vh-20rem)] overflow-auto border-t pt-2 pr-5 pb-20 shadow">
      {objects.map((object) => (
        <ObjectListItem
          key={object.id}
          object={object}
          isEditMode={props.activeObject?.id === object.id}
          {...rest}
        />
      ))}

      {objects.length === 0 ? (
        <Block className="flex-col">
          <EmptyState
            background="light"
            title="Geen objecten gevonden"
            orientation="horizontal"
            icon={<SearchIcon size={20} color="#C1C4D6" />}
            iconBgColor="#EDEFF5"
            description="Zoek adressen op via zoek balk en voeg zo nieuwe objecten toe."
          />
        </Block>
      ) : (
        <Block className="h-20"></Block>
      )}
    </Block>
  );
}
