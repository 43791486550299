import { AreaActivityType } from "@/types/apiTypes";
import {
  Checkbox,
  Pane,
  Paragraph,
  Spinner,
  TextInputField,
} from "evergreen-ui";
import { useActivityTypes } from "@/pages/projects/activity-types/ActivityTypesPage";
import { isEmpty } from "lodash";
import { memo, useEffect, useMemo, useState } from "react";
import Block from "@/components/common/Block";
import { classNames } from "@/lib/functions";

export type ActivityTypesSelectProps = {
  projectId: string;
  selected: Partial<AreaActivityType>[];
  setSelected: Function;
  enableAll: boolean;
  editMode: boolean;
};

const ActivityTypesSelect = memo(function ActivityTypesSelect(
  props: ActivityTypesSelectProps,
) {
  const { selected, setSelected, enableAll, editMode } = props;

  const { activityTypes, isLoading } = useActivityTypes();

  const areaActivityTypes = useMemo(() => {
    return activityTypes.map((v) => {
      return {
        ...v,
      };
    });
  }, [activityTypes, selected]);

  function setChecked(isChecked, item) {
    if (isChecked) {
      const newArr = selected.filter((v) => v.id !== item.id);
      setSelected(newArr);
    } else {
      setSelected([...selected, item]);
    }
  }

  function setNWorkers(item, value) {
    if (value === "") {
      value = 0;
    }

    const newSelected = selected.map((v) => {
      if (v.id === item.id) {
        return {
          ...v,
          nWorkers: parseInt(value),
        };
      }

      return v;
    });

    setSelected(newSelected);
  }

  function selectedItem(item) {
    return selected.find((v) => v.id === item.id);
  }

  useEffect(() => {
    if (enableAll && isLoading === false && !isEmpty(areaActivityTypes)) {
      setSelected([...areaActivityTypes]);
    }
  }, [isLoading, enableAll]);

  return (
    <Pane>
      {isLoading === true && (
        <Pane className="flex justify-end">
          <Spinner size={20} />
        </Pane>
      )}
      <Pane className="flex flex-col gap-3">
        {!isEmpty(areaActivityTypes) &&
          areaActivityTypes.map((item, index) => (
            <AreaActivityTypeItem
              key={index}
              areaActivityType={item}
              setChecked={setChecked}
              selectedItem={selectedItem(item)}
              // isChecked={isChecked(item)}
              setNWorkers={setNWorkers}
              editMode={editMode}
            />
          ))}
      </Pane>
    </Pane>
  );
});

function AreaActivityTypeItem({
  areaActivityType,
  setChecked,
  selectedItem,
  setNWorkers,
  editMode,
}) {
  const [value, setValue] = useState(selectedItem?.nWorkers?.toString() || "1");

  return (
    <Pane
      className={classNames("bg-white", editMode && "cursor-pointer")}
      onClick={() => setChecked(!!selectedItem, areaActivityType)}
      key={areaActivityType.id}
    >
      <Pane className="flex h-[64px] w-full items-center border pl-3">
        <Pane className="flex w-full items-center justify-between gap-2 px-2">
          <Pane className="flex grow items-center gap-2">
            <span className="text-sm font-bold text-gray-700">
              {areaActivityType.name}
            </span>
          </Pane>
          <Block className="flex shrink flex-nowrap items-center gap-2">
            <Paragraph>|</Paragraph>
            <TextInputField
              className="text-center"
              label={""}
              onClick={(e) => e.stopPropagation()}
              disabled={!selectedItem || !editMode}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                setNWorkers(selectedItem, e.target.value);
              }}
              marginBottom={0}
              inputHeight={40}
              maxWidth={50}
            />
          </Block>
        </Pane>
        {editMode && (
          <Checkbox
            className="flex-2 mx-3"
            marginLeft="auto"
            checked={!!selectedItem}
            onClick={(e) => e.preventDefault()}
          />
        )}
      </Pane>
    </Pane>
  );
}

export default ActivityTypesSelect;
