import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { CsvDataUrl, PaymentSpecification, PaymentSpecificationGroupedByUser } from "../types/apiTypes";
import { PostData, SearchParams } from "../types/appTypes";

export default class AdminPayments extends BaseApi {
  public create(data: PostData): Promise<PaymentSpecification> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/payment-specifications`,
      data: data,
    });
  }

  public exportToCsv(paymentSpecId: number): Promise<CsvDataUrl> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/payment-specifications/${paymentSpecId}/export`,
    });
  }

  public findMany(
    searchParams: SearchParams = {},
  ): Promise<PaymentSpecification[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/payment-specifications${query}`,
    });
  }

  public findOne(
    paymentId: string,
  ): Promise<PaymentSpecificationGroupedByUser[]> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/payment-specifications/${paymentId}`,
    });
  }
}
