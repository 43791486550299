import {
  ArchiveIcon,
  Pane,
  PaperclipIcon,
  SettingsIcon,
  UserIcon,
} from "evergreen-ui";
import { Outlet, useParams } from "react-router-dom";
import {
  __r,
  WORKER_SHIFTS_ARCHIVE_PAGE,
  WORKER_SHIFTS_CHANGES_PAGE,
  WORKER_SHIFTS_DETAIL_PAGE,
  WORKER_SHIFTS_GO_TO_APP_PAGE,
  WORKER_SHIFTS_HISTORY_PAGE,
  WORKER_SHIFTS_PLANNED_PAGE,
  WORKER_SHIFTS_REPORT_PAGE,
} from "../../RouteMap";
import TopSubNav from "../common/TopSubNav";
import { BsBinoculars } from "react-icons/bs";

export default function WorkerShiftsLayout() {
  const { shiftId } = useParams();

  const menuItems = [
    {
      label: "Bekijken je diensten in de app",
      pathname: WORKER_SHIFTS_GO_TO_APP_PAGE,
      icon: <BsBinoculars />,
    },
    // We willen dat de workers alles in de app doen
    // {
    //   label: "Geplande diensten",
    //   pathname: WORKER_SHIFTS_PLANNED_PAGE,
    //   icon: <BsBinoculars />,
    // },
    // {
    //   label: "Gelopen diensten",
    //   pathname: WORKER_SHIFTS_HISTORY_PAGE,
    //   icon: <ArchiveIcon />,
    // },
  ];

  return (
    <Pane className="h-full">
      <TopSubNav menuItems={menuItems} />

      <Pane className="h-full">
        <Outlet />
      </Pane>
    </Pane>
  );
}
