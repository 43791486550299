import { ChevronLeftIcon, IconButton, Pane } from "evergreen-ui";
import { classNames } from "../../lib/functions";

export default function SidebarLeft({ children, isOpen, setIsOpen, ...rest }) {
  const isOpenClasses =
    "drop-shadow-2xl flex z-20 flex-col min-h-screen flex-grow max-w-[17rem] min-w-[17rem] fixed sm:static top-0 left-0 z-19 bg-white w-full";
  const isClosedClasses = "fixed w-full";

  const classes = classNames(isOpen ? isOpenClasses : isClosedClasses);

  return (
    <Pane {...rest} className={classes}>
      <Pane className="flex justify-end" onClick={() => setIsOpen(false)}>
        <IconButton
          appearance="minimal"
          disabled={false}
          icon={ChevronLeftIcon}
          onClick={() => {
            setIsOpen(false);
          }}
          type="button"
        />
      </Pane>
      {isOpen && children}
    </Pane>
  );
}
