import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { createContext, useCallback, useContext } from "react";
import { debounce } from "lodash";

import { FilterContextType, FilterMapType } from "../types/appTypes";

const FilterContext = createContext({
  filterMap: {},
  handleInput: () => {},
} as FilterContextType);

export function useFilter() {
  return useContext(FilterContext);
}

type FilterLogicProps = {
  children: JSX.Element;
  filterMap: FilterMapType;
};

export default function UseFilterLogic({
  children,
  filterMap,
}: FilterLogicProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const doFilter = (filterEntry, value) => {
    if (value) {
      searchParams.set(filterEntry.topic, value);
    } else {
      searchParams.delete(filterEntry.topic);
    }

    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(searchParams)}`,
    });
  };

  const debouncedFilter = useCallback(debounce(doFilter, 300), []);

  const handleInput = (filterEntry, value) => {
    filterEntry.setter(value);

    debouncedFilter(filterEntry, value);
  };

  return (
    <FilterContext.Provider value={{ filterMap, handleInput }}>
      {children}
    </FilterContext.Provider>
  );
}
