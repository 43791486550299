import { createContext, useContext } from "react";

export type PlannerDialogContextProps = {
  state?: any;
  dispatch?: Function;
  helpers?: {
    setAttachedWorkers: Function;
    setCarpoolsAvailable: Function;
    setFilterLogic: any;
    setSelectedDriverId: (driverId: number | null) => void;
    setSelectedCarpoolId: (carpoolId: number | null) => void;
    doAssignWorker: (WorkerApiType, data?: any) => void;
    doUnAssignWorker: (WorkerApiType) => void;
    updateWorker: (WorkerApiType, data: any) => void;
    toggleIsDriving: (WorkerApiType) => void;
    addWorkerToCarpool: (
      WorkerApiType,
      driverId?: number | null,
      carpoolId?: number | null,
    ) => void;
    removeWorkerFromCarpool: (
      WorkerApiType,
      driverId?: number | null,
      carpoolId?: number | null,
    ) => void;
    // setAttachedWorkers,
    // setCarpoolsAvailable,
    // setFilterLogic,
    // setSelectedDriverId,
    // doAssignWorker,
    // doUnAssignWorker,
    // updateWorker,
    // setPopupShownId,
  };
};

export const PlannerDialogContext = createContext<PlannerDialogContextProps>(
  {},
);

export function usePlannerDialog() {
  return useContext(PlannerDialogContext);
}
