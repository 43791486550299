import {
  Avatar,
  Button,
  EmptyState,
  majorScale,
  Pane,
  RefreshIcon,
  SearchIcon,
  Table,
  Text,
} from "evergreen-ui";
import StandardTable from "../../components/table/StandardTable";

import { User } from "../../types/apiTypes";
import { useApi } from "../../context/AxiosContext";
import { useDoRequest, useLoadResource } from "../../lib/request-hooks";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DateValue from "../../components/common/DateValue";
import PageWithSubSidebarLeft from "src/components/common/PageWithSubSidebarLeft";
import UsersFilter from "./UsersFilter";
import PageTitle from "../../components/common/PageTitle";
import { useWindowSearchParams } from "../../hooks/useWindowSearchParams";
import { useAuth } from "../../context/AuthContext";
import Actions from "src/components/common/Actions";

const useUsers = function () {
  const searchParams = useWindowSearchParams();
  const [users, setUsers] = useState<User[]>([]);
  const { apiInstance } = useApi();

  const request = () => apiInstance!.adminUsers.findMany(searchParams);

  return {
    ...useLoadResource(request, setUsers, true),
    users,
  };
};

export function useLoginAs() {
  const { apiInstance } = useApi();
  const { setJwt, setUser } = useAuth();

  async function doLoginAs(userId) {
    const { jwt, user } = await apiInstance!.adminUsers.loginAs(userId);

    setJwt!(jwt, false);
    setUser!(user);
  }

  return {
    doLoginAs,
  };
}

function useExportUsersLogic() {
  const searchParams = useWindowSearchParams();
  const { apiInstance } = useApi();

  const { handle, isLoading } = useDoRequest();

  async function doExport() {
    const request = apiInstance!.adminUsers.exportUsersCsv(searchParams);

    return await handle(request);
  }

  async function doExportCb() {
    const { url } = await doExport();
    const a = document.createElement("a");

    a.target = "_blank";
    a.style.display = "none";
    a.href = url;

    // the filename you want
    a.download = "export.csv";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  return {
    doExport: doExportCb,
    isLoading,
  };
}

export default function UsersPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { users, meta, isLoading, refresh } = useUsers();

  const navTo = (user) => {
    navigate(`/admin/users/${user.id}`);
  };

  const { doLoginAs } = useLoginAs();

  const { doExport, isLoading: isExporting } = useExportUsersLogic();

  return (
    <PageWithSubSidebarLeft
      pageTitleContent={
        <PageTitle
          actions={
            <Actions>
              <Actions.Button
                textOverflow={"ellipsis"}
                appearance="primary"
                intent="success"
                isLoading={isExporting}
                onClick={doExport}
                height={majorScale(4)}
              >
                Exporteer
              </Actions.Button>
            </Actions>
          }
        >
          {t("users_page.title")}
        </PageTitle>
      }
      title={"Filters"}
      filterContent={<UsersFilter />}
      closedContent={<Button iconBefore={SearchIcon}>Zoek</Button>}
    >
      <Pane className="w-full">
        <StandardTable
          refresh={refresh}
          meta={meta}
          isLoading={isLoading}
          data={users}
          emptyState={<EmptyUsersState />}
        >
          <Table.Head>
            <Table.HeaderCell minWidth={120}>Name</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Last active At</Table.HeaderCell>
            <Table.HeaderCell>Login as</Table.HeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {users.map((user) => (
              <Table.Row
                key={user.id}
                isSelectable
                onSelect={() => {
                  navTo(user);
                }}
              >
                <Table.Cell minWidth={120}>
                  <Pane className="flex items-center gap-2">
                    <Avatar
                      className="hover:opacity-90"
                      src={user?.profilePictureThumbnail ?? ""}
                      name={""}
                      size={20}
                    />
                    <Text className="text-xs" size={majorScale(9)}>
                      {user.firstName} {user.lastName}
                    </Text>
                  </Pane>
                </Table.Cell>
                <Table.TextCell>{user.role}</Table.TextCell>
                <Table.TextCell>{user.email}</Table.TextCell>
                <Table.TextCell>
                  <DateValue date={user.lastActiveAt} />
                </Table.TextCell>
                <Table.TextCell>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      doLoginAs(user.id);
                    }}
                  >
                    Login als
                  </Button>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </StandardTable>
      </Pane>
    </PageWithSubSidebarLeft>
  );
}

function EmptyUsersState() {
  return (
    <EmptyState
      background="light"
      title="No users found"
      orientation="horizontal"
      icon={<SearchIcon color="#C1C4D6" />}
      iconBgColor="#EDEFF5"
      description="Users appear when there are users"
      anchorCta={
        <EmptyState.LinkButton href="https://segment.com/docs/" target="_blank">
          Learn how to ...
        </EmptyState.LinkButton>
      }
    />
  );
}
