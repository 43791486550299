import BaseApi from "./_baseApi";
import { doRequest } from "./_helpers";
import { createSearchParams } from "react-router-dom";
import { PostData, SearchParams } from "@/types/appTypes";
import { ClientActivity, Id } from "@/types/apiTypes";

export default class AdminClientActivity extends BaseApi {
  findOne(id: Id): Promise<ClientActivity> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/client-activities/${id}`,
    });
  }

  update(id: Id, data: PostData): Promise<ClientActivity> {
    return doRequest(this._axiosInstance!, {
      method: "put",
      url: `/admin/client-activities/${id}`,
      data: data,
    });
  }

  findMany(
    projectId: Id,
    searchParams: SearchParams = {},
  ): Promise<ClientActivity[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/projects/${projectId}/client-activities${query}`,
    });
  }

  createForProject(projectId: Id, data: PostData): Promise<ClientActivity> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/projects/${projectId}/client-activities`,
      data: data,
    });
  }

  remove(id: Id): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: "delete",
      url: `/admin/client-activities/${id}`,
    });
  }
}
