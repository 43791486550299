import {
  Text,
  Dialog,
  Pane,
  FormField,
  Select,
  TextInputField,
} from "evergreen-ui";
import { DateFormat } from "../../../components/common/DateFormat";
import { eachDayOfInterval } from "date-fns";
import SunCalc from "suncalc";
import { useTranslation } from "react-i18next";
import { useState } from "react";

// SunCalc.getTimes(/*Date*/ date, /*Number*/ latitude, /*Number*/ longitude, /*Number (default=0)*/ height)

type LocationOption = {
  label: string;
  value: string;
  lat: number;
  lng: number;
};

const locationOptions = [
  {
    label: "Amsterdam",
    value: "amsterdam",
    lat: 52.3727598,
    lng: 4.8936041,
  },
  {
    label: "Arnhem",
    value: "arnhem",
    lat: 51.984257,
    lng: 5.9108573,
  },
  {
    label: "Assen",
    value: "assen",
    lat: 52.9952273,
    lng: 6.560498,
  },
  {
    label: "Den Haag",
    value: "denhaag",
    lat: 52.0799838,
    lng: 4.3113461,
  },
  {
    label: "Groningen",
    value: "groningen",
    lat: 53.2190652,
    lng: 6.5680077,
  },
  {
    label: "Leeuwarden",
    value: "leeuwarden",
    lat: 53.2005936,
    lng: 5.7918548,
  },
  {
    label: "Lelystad",
    value: "lelystad",
    lat: 52.5150949,
    lng: 5.4768915,
  },
  {
    label: "Haarlem",
    value: "haarlem",
    lat: 52.3837058,
    lng: 4.6435597,
  },
  {
    label: "Maastricht",
    value: "maastricht",
    lat: 50.8579855,
    lng: 5.6969882,
  },
  {
    label: "Middelburg",
    value: "middelburg",
    lat: 52.0799838,
    lng: 4.3113461,
  },
  {
    label: "s-Hertogenbosch",
    value: "shertogenbosch",
    lat: 51.6889387,
    lng: 5.303116,
  },
  {
    label: "Utrecht",
    value: "utrecht",
    lat: 52.0809856,
    lng: 5.127684,
  },
  {
    label: "Zwolle",
    value: "zwolle",
    lat: 52.5089759,
    lng: 6.0943765,
  },
  {
    label: "-",
    value: "-",
    lat: 52.5089759,
    lng: 6.0943765,
  },
] as LocationOption[];

const lngTest = new RegExp(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/);
const latTest = new RegExp(
  /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
);

export function SunTimesDialog({ isOpen, close, fromDate, toDate }) {
  const { t } = useTranslation();

  const [location, setLocation] = useState<string>(locationOptions[0].value);

  const [lat, setLat] = useState<number>(locationOptions[0].lat);
  const [lng, setLng] = useState<number>(locationOptions[0].lng);

  const [validationErrors, setValidationErrors] = useState({
    lat: null,
    lng: null,
  });

  if (!isOpen) {
    return <></>;
  }

  const interval = eachDayOfInterval({
    start: fromDate,
    end: toDate,
  });

  const getTime = (date) => {
    if (!lngTest.test(lng.toString())) {
    }
    if (!latTest.test(lat.toString())) {
    }

    const sunTimes = SunCalc.getTimes(date, lat, lng);

    // if (!sunTimes) {
    //     const sunTimes = SunCalc.getTimes(date, locationOptions[0].lat, locationOptions[0].lng);
    //     setLocation(locationOptions[0].value)
    //
    //     return sunTimes;
    // }

    return sunTimes;
  };

  function setLngCb(lng) {
    setLng(lng);
    setLocation("-");

    if (!lngTest.test(lng)) {
      setValidationErrors({
        ...validationErrors,
        // @ts-ignore
        lng: "Invalid longitude",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        // @ts-ignore
        lng: null,
      });
    }
  }

  function setLatCb(lat) {
    setLat(lat);
    setLocation("-");

    if (!latTest.test(lat)) {
      setValidationErrors({
        ...validationErrors,
        // @ts-ignore
        lat: "Invalid latitude",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        // @ts-ignore
        lat: null,
      });
    }
  }

  function setLocationCb(location) {
    const loc = locationOptions.find((v) => v.value === location);

    setLat(loc!.lat);
    setLng(loc!.lng);

    setValidationErrors({
      ...validationErrors,
      // @ts-ignore
      lng: null,
      lat: null,
    });

    setLocation(location);
  }

  return (
    <Dialog
      width={800}
      isShown={isOpen}
      onCloseComplete={close}
      title={t("sun_times_dialog.title")}
      confirmLabel="Ok"
      hasFooter={false}
    >
      <Pane className="w-full pb-8">
        <Pane className="flex gap-2" marginBottom={20}>
          <FormField label="Locatie">
            <Select
              value={location}
              onChange={(e) => setLocationCb(e.target.value)}
              width={240}
              height={40}
            >
              {locationOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormField>

          <Pane className="flex gap-2">
            <TextInputField
              name="lat"
              label="latitude"
              placeholder="..."
              isInvalid={!!validationErrors?.lat}
              validationMessage={validationErrors?.lat}
              value={lat}
              onChange={(e) => setLatCb(e.target.value)}
              marginBottom={0}
              inputHeight={40}
            />
            <TextInputField
              name="lng"
              label="Longitude"
              placeholder="..."
              isInvalid={!!validationErrors?.lng}
              validationMessage={validationErrors?.lng}
              value={lng}
              onChange={(e) => setLngCb(e.target.value)}
              marginBottom={0}
              inputHeight={40}
            />
          </Pane>
        </Pane>

        <table className="data-table">
          <thead>
            <tr>
              <th>{t("common.date")}</th>
              <th>{t("common.sunrise")}</th>
              <th>{t("common.sunset")}</th>
            </tr>
          </thead>
          <tbody>
            {(interval ?? []).map((date) => (
              <tr key={"row-" + date.toDateString()}>
                <td>
                  <Pane className="flex justify-center">
                    <DateFormat
                      className="!font-mono !text-xs"
                      date={date}
                      formatStr="eeeeee d MMM yy"
                    />
                  </Pane>
                </td>
                <td>
                  <Pane className="flex justify-center">
                    <DateFormat
                      className="!font-mono !text-xs"
                      date={getTime(date).sunrise}
                      formatStr="p"
                    />
                  </Pane>
                </td>
                <td>
                  <Pane className="flex justify-center">
                    <DateFormat
                      className="!font-mono !text-xs"
                      date={getTime(date).sunsetStart}
                      formatStr="p"
                    />
                  </Pane>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Pane>
    </Dialog>
  );
}
