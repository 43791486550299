/* eslint-disable no-dupe-keys */
import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { SearchParams } from "../types/appTypes";
import {
  Id,
  IntroductionComponent,
  AdditionalDataObservationTypes,
  Report,
  ReportData,
  ReportComponentIds,
} from "../types/apiTypes";

export default class AdminAProjectReports extends BaseApi {
  public findMany(
    projectId: string,
    searchParams: SearchParams = {},
  ): Promise<Report[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/projects/${projectId}/reports${query}`,
    });
  }

  findOne(id: Id): Promise<Report> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/reports/${id}`,
    });
  }

  createReport(
    projectId: Id,
    observationTypes: AdditionalDataObservationTypes,
    introductionComponent: IntroductionComponent,
  ): Promise<Report> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/projects/${projectId}/reports`,
      data: {
        observationTypes,
        introductionComponent,
      },
    });
  }

  updateReport(
    reportId: Id,
    data: Partial<Report>,
    updatedReportComponentType?: ReportComponentIds,
  ): Promise<Report> {
    return doRequest(this._axiosInstance!, {
      method: "put",
      url: `/admin/reports/${reportId}`,
      data: { report: data, updatedReportComponentType },
    });
  }

  deleteReport(reportId: Id): Promise<Report> {
    return doRequest(this._axiosInstance!, {
      method: "delete",
      url: `/admin/reports/${reportId}`,
    });
  }

  getProjectDataNeededForReport(projectId: string): Promise<ReportData> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/projects/${projectId}/reportdata`,
    });
  }

  getProjectMediaUrls(
    reportId: string,
    mediaUuids: string[],
  ): Promise<Report[]> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/reports/${reportId}/media-urls`,
      data: {
        mediaUuids,
      },
    });
  }

  uploadMedia(reportId: Id, file: File): Promise<number> {
    const formData = new FormData();
    formData.append("file", file);
    return doRequest(this._axiosInstance!, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "post",
      url: `/admin/reports/${reportId}/uploadmedia`,
      data: formData,
    });
  }
}
