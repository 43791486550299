import { useNavigate, useParams } from "react-router-dom";
import { parseInt } from "lodash";

import {
  EmptyState,
  Heading,
  Popover,
  Position,
  SearchIcon,
  Spinner,
} from "evergreen-ui";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import AreaListItem, {
  AreaListItemProps,
} from "@/pages/projects/areas/AreaListItem.component";
import AreaObjectsEditMap from "@/pages/projects/areas/AreaMap.component";
import Block from "src/components/common/Block";
import useAreaForm from "@/pages/projects/areas/useAreaForm.hook";
import useAreas from "@/hooks/useAreas";
import useObjectsForProject from "@/hooks/useObjectsForProject";
import { Area, ClientObject } from "src/types/apiTypes";
import { useApp } from "@/context/AppContext";
import { getBoundingBox } from "@/lib/functions";

import AddressFuzzyFilter from "@/components/shared/filters/AdressFuzzyFilter";
import { __r, ADMIN_AREA_ADD_PAGE, ADMIN_AREA_EDIT_PAGE } from "@/RouteMap";
import ObjectList from "@/components/objects/ObjectList.component";
import { useActivityTypes } from "@/pages/projects/activity-types/ActivityTypesPage";

function useObjectsUnAssigned() {
  const { projectId } = useParams();
  const searchParams = new URLSearchParams();

  searchParams.set("unassigned", "true");

  return useObjectsForProject({ searchParams, projectId: projectId! });
}

export default function AreaAddPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { areaId, projectId } = useParams();

  const [activeArea, setActiveArea] = useState<Area | null>(null);
  const [activeObject, setActiveObject] = useState<ClientObject | null>(null);
  const { activityTypes, isLoading } = useActivityTypes();

  const areaForm = useAreaForm();
  const { setLayoutProps } = useApp();

  useEffect(() => {
    setLayoutProps({
      overflowY: "auto",
    });
  }, []);

  const { objects } = useObjectsForProject({ projectId: projectId! });

  const {
    isLoading: isLoadingObjectsUnAssigned,
    objects: objectsUnAssigned,
    refresh: refreshObjectsUnAssigned,
  } = useObjectsUnAssigned();

  const { areas, refresh, filterMap, handleInput } = useAreas();

  function doRefresh() {
    refresh();
    refreshObjectsUnAssigned();
  }

  const updateList = (area: Area) => {
    doRefresh();
  };

  async function onNewArea(area: Partial<Area>) {
    const newArea = await areaForm.doCreate(area, activityTypes);

    doRefresh();
    setActiveArea(newArea);

    navigate(
      __r(ADMIN_AREA_EDIT_PAGE, {
        projectId: projectId,
        areaId: newArea.id,
      }),
    );

    return newArea;
  }

  function afterSave(area: Area) {
    navigate(
      __r(ADMIN_AREA_ADD_PAGE, {
        projectId: projectId,
      }),
    );

    updateList(area);
  }

  function afterDelete(area: Area) {
    doRefresh();
    setActiveArea(null);
  }

  useEffect(() => {
    if (!areaId || !areas.length) {
      setActiveArea(null);

      return;
    }

    if (!areaMapRef.current) {
      return;
    }

    const area = areas.find((area) => area.id === parseInt(areaId));

    if (area) {
      setActiveArea(area);

      const polygonCoordinates = area.geometry.features[0].geometry;

      //@ts-ignore
      const points = polygonCoordinates.coordinates[0].map((points) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: points,
          },
        };
      });

      const boundingBox = getBoundingBox(points);

      const bounds = [
        [boundingBox.xMin, boundingBox.yMin],
        [boundingBox.xMax, boundingBox.yMax],
      ];

      // @ts-ignore
      areaMapRef.current.fitBounds(bounds);
    }
  }, [areas, areaId]);

  function onClickEdit(area: Area) {
    if (!areaMapRef.current) {
      return;
    }

    navigate(
      __r(ADMIN_AREA_EDIT_PAGE, {
        projectId: projectId,
        areaId: area.id,
      }),
    );
  }

  async function updateArea(area: Partial<Area>) {
    const updatedArea = await areaForm.doUpdate(area);

    doRefresh();

    return updatedArea;
  }

  async function onRemoveArea(area: Area) {
    await areaForm.doRemove(area.id);
    doRefresh();

    setActiveArea(null);
  }

  const areaMapRef = useRef(null);

  function onClickObject(clickedObject: ClientObject) {
    if (!areaMapRef.current) {
      return;
    }

    const object = objects.find((o) => o.id === clickedObject.id);

    if (!object) {
      return;
    }

    setActiveObject(object);

    // @ts-ignore
    const coordinates = object.geometry?.geometry.geometries[0]?.coordinates;

    // @ts-ignore
    areaMapRef.current.flyTo(coordinates);
  }

  return (
    <Block>
      <Block className="flex w-full gap-2">
        <Block className="flex-1">
          <AreaObjectsEditMap
            ref={areaMapRef}
            activeArea={activeArea}
            activeObject={activeObject}
            areas={areas}
            objects={objects}
            onRemoveArea={onRemoveArea}
            setActiveArea={onClickEdit}
            setNewArea={onNewArea}
            updateArea={updateArea}
          />
        </Block>
        <Block className="w-[25vw] flex-shrink">
          <Block className="flex items-center justify-between gap-2 py-4">
            <Heading size={600}>{t("area_page.areas")}</Heading>
            <AssignedRatioObjects
              onClickObject={onClickObject}
              objectsUnAssigned={objectsUnAssigned}
              isLoadingObjectsUnAssigned={isLoadingObjectsUnAssigned}
              objects={objects}
            />
          </Block>
          <Block className="py-2">
            <AddressFuzzyFilter
              filterMap={filterMap}
              handleInput={handleInput}
            />
          </Block>
          <AreaList
            key={activeArea?.id}
            onClickObject={onClickObject}
            areas={areas}
            activeArea={activeArea}
            onClickEdit={onClickEdit}
            updateList={updateList}
            afterDelete={afterDelete}
            afterSave={afterSave}
            areaForm={areaForm}
          />
        </Block>
      </Block>
    </Block>
  );
}

function AssignedRatioObjects({
  objectsUnAssigned,
  isLoadingObjectsUnAssigned,
  objects,
  onClickObject,
}) {
  const { t } = useTranslation();

  return (
    <Popover
      position={Position.BOTTOM}
      content={
        <Block className="flex flex-col gap-2 py-2 px-3 pt-4 pb-8">
          <ObjectList
            listClasses="flex-col"
            itemClasses="py-2"
            onClickObject={onClickObject}
            objects={objectsUnAssigned}
          />
        </Block>
      }
    >
      <a className="relative">
        <Block className="flex items-center gap-2 border-b py-2">
          <Heading size={200}>{t("area_page.unassigned_objects")}</Heading>
          <Block className="flex items-center gap-2">
            <Heading size={400}>
              {isLoadingObjectsUnAssigned ? (
                <Spinner />
              ) : (
                objectsUnAssigned.length
              )}
            </Heading>
            <Heading size={100}>/</Heading>
            <Heading size={400}>{objects.length}</Heading>
          </Block>
        </Block>
      </a>
    </Popover>
  );
}

interface AreaListProps
  extends Omit<AreaListItemProps, "area" | "isEditMode" | "setEditMode"> {
  areas: Area[];
}

export function AreaList(props: AreaListProps) {
  const { areas, ...rest } = props;

  return (
    <Block className="max-h-[calc(100vh-20rem)] overflow-auto border-t shadow">
      {areas.map((area: Area) => (
        <AreaListItem
          key={area.id}
          area={area}
          isEditMode={props.activeArea?.id === area.id}
          {...rest}
        />
      ))}

      {areas.length === 0 ? (
        <Block className="flex-col">
          <EmptyState
            background="light"
            title="Geen gebieden gevonden"
            orientation="horizontal"
            icon={<SearchIcon size={20} color="#C1C4D6" />}
            iconBgColor="#EDEFF5"
            description="Gebruik de Polygon tool om een gebied aan te maken."
          />
        </Block>
      ) : (
        <Block className="h-20"></Block>
      )}
    </Block>
  );
}
