import { Card, EmptyState, Heading, Pane, SearchIcon } from "evergreen-ui";
import PageTitle from "src/components/common/PageTitle";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useLoadResource } from "src/lib/request-hooks";
import { Period } from "src/types/apiTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TableLoadingSpinner } from "src/components/table/StandardTable";
import { useApi } from "src/context/AxiosContext";
import Page from "src/components/common/Page";
import { isEmpty } from "lodash";
import {
  __r,
  ADMIN_USERS_EDIT_PAGE,
  ADMIN_WORKER_AVAILABILITY_PAGE,
} from "src/RouteMap";
import AvailabilityPeriodCard from "../../../components/shared/AvailabilityPeriodCard";

const usePeriods = function () {
  const { workerId } = useParams();
  const { apiInstance } = useApi();
  const [searchParams] = useSearchParams();

  const [periods, setPeriods] = useState<Period[]>([]);

  searchParams.set("workerId", workerId!);

  const find = () => apiInstance!.adminPeriods.findMany(searchParams);

  const setData = (periods) => {
    setPeriods(periods);
  };

  return {
    ...useLoadResource(find, setData, true),
    periods,
  };
};

export default function AdminWorkerPeriodsPage() {
  const { t } = useTranslation();
  const { userId } = useParams();
  const navigate = useNavigate();
  const { workerId } = useParams();

  const location = useLocation();
  const { backPath } = location?.state ?? {};
  const bPath = backPath ?? __r(ADMIN_USERS_EDIT_PAGE, { userId, workerId });

  const { periods, isLoading } = usePeriods();

  const navTo = (period: Period) => {
    navigate(
      __r(ADMIN_WORKER_AVAILABILITY_PAGE, { workerId, periodId: period.id }),
    );
  };

  return (
    <Page>
      <PageTitle backPath={bPath}>{t("worker_requests_page.title")}</PageTitle>

      <Pane className="flex flex-col gap-4 pt-10">
        {isLoading === true && (
          <TableLoadingSpinner title={"Periodes ophalen"} />
        )}

        <Heading size={100} className="!font-bold">
          {t("worker_requests_page.availability_requests")}
        </Heading>

        {periods.map((period, index) => (
          <AvailabilityPeriodCard
            key={period.id}
            navTo={navTo}
            period={period}
          />
        ))}
        {isLoading === false && isEmpty(periods) && (
          <EmptyWorkerRequestsState />
        )}
      </Pane>
    </Page>
  );
}

function EmptyWorkerRequestsState() {
  const { t } = useTranslation();

  return (
    <Card elevation={1}>
      <EmptyState
        background="light"
        title="Er zijn nu nog geen periodes waarvoor je beschikbaarheid kan invullen"
        icon={<SearchIcon color="#C1C4D6" />}
        orientation="horizontal"
        iconBgColor="#EDEFF5"
        description="Kom snel terug om te kijken of er nieuwe periodes zijn waarvoor je beschikbaarheid kan invullen."
      />
    </Card>
  );
}
