import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { RegisterData } from "../types/apiDataTypes";
import { User } from "../types/apiTypes";
import { PostData } from "../types/appTypes";

export default class Users extends BaseApi {
  uploadProfilePicture(formData: FormData) {
    return doRequest(this._axiosInstance!, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "post",
      url: `/me/profile-picture`,
      data: formData,
    });
  }

  register(data: RegisterData): Promise<User> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/users/register`,
      withCredentials: true,
      data: data,
    });
  }

  findMe(): Promise<User> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/me`,
    });
  }

  updateMe(data: PostData): Promise<User> {
    return doRequest(this._axiosInstance!, {
      method: "put",
      url: `/me`,
      data: data,
    });
  }
}
