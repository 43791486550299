import {
  forwardRef,
  memo,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useApi } from "src/context/AxiosContext";
import { Address, Area, Timeslot, WorkerApiType } from "src/types/apiTypes";
import { useLoadResource } from "src/lib/request-hooks";
import { FormField, Pane, Switch, TextInputField } from "evergreen-ui";
import { useTranslation } from "react-i18next";
import { formatISO, parseISO } from "date-fns";
import { useWindowSearchParams } from "src/hooks/useWindowSearchParams";
import { DisplayAddressSimple } from "src/components/shared/filters/AddressFilter";
import { useDoFilter } from "src/hooks/useDoFilter";
import WorkerFilter from "../../../../components/shared/filters/WorkerFilter";
import { FilterMapType } from "src/types/appTypes";
import CarpoolFilter from "../../../../components/shared/filters/CarpoolFilter";
import { usePlannerDialog } from "../logic/PlannerDialogContext";

export function useWorkerFiltersForPlanner({
  defaultTimeslotUid,
  area,
  shiftDate,
}) {
  const queryParams = new URLSearchParams(window.location.search);
  const { periodId, areaActivityTypeId } = useParams();

  // const [searchParams, setSearchParams] = useSearchParams();
  const searchParams = useWindowSearchParams();
  const [initialized, setInitialized] = useState<boolean>(false);

  const availableOnDateValue = useMemo(() => {
    if (queryParams.get("availableOnDate")) {
      return queryParams.get("availableOnDate");
    }

    if (shiftDate) {
      return formatISO(shiftDate);
    } else {
      return null;
    }
  }, [shiftDate]);

  const [firstName, setFirstName] = useState<string>(
    queryParams.get("firstName") ?? "",
  );
  const [lastName, setLastName] = useState<string>(
    queryParams.get("lastName") ?? "",
  );
  const [email, setEmail] = useState<string>(queryParams.get("email") ?? "");
  const [hasTransport, setHasTransport] = useState<boolean>(
    queryParams.get("hasTransport") === "true",
  );
  const [hasDrivingLicense, setHasDrivingLicense] = useState<boolean>(
    queryParams.get("hasDrivingLicense") === "true",
  );
  const [periodIdFilter, setPeriodIdFilter] = useState<string>(
    queryParams.get("periodId") ?? "",
  );
  const [availableOnDate, setAvailableOnDate] = useState<string | null>(
    availableOnDateValue,
  );
  const [availableWorkers, setAvailableWorkers] = useState<
    WorkerApiType[] | []
  >([]);
  const [distanceKm, setDistanceKm] = useState<string | null>(
    queryParams.get("distanceKm") ?? "",
  );
  const [timeslotUid, setTimeslotUid] = useState<string | null>(
    queryParams.get("timeslotUid") || defaultTimeslotUid,
  );
  const [distanceToWorkerId, setDistanceToWorkerId] = useState<string | null>(
    queryParams.get("distanceToWorkerId"),
  );
  const [areaActivityTypeIdQuery, setAreaActivityTypeIdQuery] = useState<
    string | null
  >(queryParams.get("areaActivityType") ?? "");
  const [carpoolId, setCarpoolId] = useState<string | null>(
    queryParams.get("carpoolId") ?? "",
  );

  const filterMap: FilterMapType = {
    areaId: {
      topic: "areaId",
      default: area.id,
    },
    email: {
      setter: setEmail,
      topic: "email",
    },
    firstName: {
      setter: setFirstName,
      topic: "firstName",
    },
    lastName: {
      setter: setLastName,
      topic: "lastName",
    },
    hasDrivingLicense: {
      setter: setHasDrivingLicense,
      topic: "hasDrivingLicense",
    },
    timeslotUid: {
      setter: setTimeslotUid,
      topic: "timeslotUid",
      transform: (value) => (value ? value : "ignore"),
      default: timeslotUid,
    },
    hasTransport: {
      setter: setHasTransport,
      topic: "hasTransport",
    },
    periodId: {
      setter: setPeriodIdFilter,
      topic: "periodId",
      default: periodId,
    },
    availableOnDate: {
      setter: (value) => setAvailableOnDate(value),
      topic: "availableOnDate",
      transform: (value) => (value ? value : "ignore"),
      default: availableOnDate,
    },
    distanceKm: {
      setter: setDistanceKm,
      topic: "distanceKm",
    },
    areaActivityId: {
      setter: setAreaActivityTypeIdQuery,
      topic: "areaActivityTypeId",
      default: areaActivityTypeId,
    },
    workerId: {
      setter: setDistanceToWorkerId,
      topic: "distanceToWorkerId",
      label: "Zoek op afstand tot werker",
    },
    carpoolId: {
      setter: setCarpoolId,
      topic: "carpoolId",
      default: carpoolId,
      label: "Zoek beschikbare carpools",
    },
  };

  // always populate distance
  searchParams.set("populate", "distance");

  const { apiInstance } = useApi();

  const find = () => {
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.forEach((value, key) => {
      searchParams.set(key, value);
    });

    return apiInstance!.adminWorkers.findMany(searchParams);
  };

  const loadResourceLogic = useLoadResource(find, setData, initialized);

  const {
    isLoading,
    errorMessage,
    successMessage,
    meta,
    refreshCount,
    setRefreshCount,
    refresh,
    setMeta,
    setDataCb,
  } = loadResourceLogic;

  const {
    fetchData,
    setDefaultFilters,
    setFilter,
    doSortBy,
    handleInput,
    doFilter,
  } = useDoFilter({
    searchParams,
    setInitialized,
    initialized,
    filterMap,
    loadResourceLogic,
    find,
    setData: setDataCb,
    setMeta,
  });

  const { helpers } = usePlannerDialog();

  useEffect(() => {
    if (initialized) {
      helpers!.setFilterLogic({
        availableWorkers,
        doSortBy,
        fetchData,
        isLoading,
        meta,
        refresh,
        setFilter,
        selectedDriverId: distanceToWorkerId,
        selectedCarpoolId: carpoolId,
      });
    }
  }, [
    meta,
    isLoading,
    availableWorkers,
    initialized,
    distanceToWorkerId,
    carpoolId,
  ]);

  useEffect(() => {
    if (!initialized) {
      setDefaultFilters();
    }
  }, [periodId, availableOnDate]);

  function setData(availableWorkers: WorkerApiType[]) {
    setAvailableWorkers(availableWorkers);
  }

  return {
    isLoading,
    errorMessage,
    successMessage,
    meta,
    refreshCount,
    setRefreshCount,
    refresh,
    availableWorkers,
    handleInput,
    filterMap,
    email,
    firstName,
    lastName,
    hasTransport,
    hasDrivingLicense,
    distanceKm,
    periodIdFilter,
    availableOnDate,
    doFilter,
    fetchData,
    doSortBy,
    timeslotUid,
    setTimeslotUid,
    searchParams: queryParams,
    setFilter,
  };
}

type WorkerFilterProps = {
  shiftDate: Date;
  timeslot: Timeslot;
  address?: Address;
  area: Area;
};

export type RefWorkerFilter = {
  setTimeslotUid: (value: string) => void;
  setAvailableOnDate: (value: Date) => void;
};

const WorkerFiltersForPlanner = memo(
  forwardRef(function WorkerFilterForPlanner(
    { shiftDate, timeslot, address, area }: WorkerFilterProps,
    ref: Ref<RefWorkerFilter>,
  ) {
    const { t } = useTranslation();

    const { periodId } = useParams();

    useImperativeHandle(ref, () => ({
      setTimeslotUid(value: string) {
        handleInput(filterMap.timeslotUid, value);
      },
      setAvailableOnDate(value: Date) {
        handleInput(filterMap.availableOnDate, formatISO(value));
      },
    }));

    const {
      handleInput,
      filterMap,
      email,
      firstName,
      lastName,
      hasDrivingLicense,
      hasTransport,
      distanceKm,
      availableOnDate,
      setFilter,
      fetchData,
    } = useWorkerFiltersForPlanner({
      defaultTimeslotUid: timeslot.id,
      area,
      shiftDate,
    });

    // default shift date
    function setAvailableOnDate(isChecked) {
      if (isChecked) {
        setFilter(filterMap.availableOnDate, formatISO(shiftDate));
        setFilter(filterMap.timeslotUid, timeslot.id);
      } else {
        setFilter(filterMap.availableOnDate, null);
        setFilter(filterMap.timeslotUid, null);
      }

      return fetchData();
    }

    return (
      <Pane className="flex flex-col gap-3">
        <Pane className="flex justify-between gap-1">
          <Pane className="flex shrink flex-col gap-3">
            <WorkerFilter filterMap={filterMap} handleInput={handleInput} />

            <CarpoolFilter
              key={availableOnDate + timeslot.id}
              filterMap={filterMap}
              handleInput={handleInput}
              periodId={periodId!}
              availableOnDate={availableOnDate}
              timeslotUid={timeslot.id}
            />

            <TextInputField
              marginBottom={0}
              inputHeight={40}
              maxWidth={200}
              name="distanceKm"
              label={t("worker_filter.distance_to_location")}
              type="number"
              placeholder="e.g,. 5"
              value={distanceKm ?? ""}
              onChange={(e) =>
                handleInput(filterMap.distanceKm, e.target.value)
              }
            />

            {address && <DisplayAddressSimple address={address} />}
          </Pane>
        </Pane>

        <Pane className="flex grow justify-evenly gap-2 py-4">
          <FormField
            label={t("worker_filter.available")}
            hint={t("worker_filter.available_hint")}
          >
            <Switch
              checked={!availableOnDate || availableOnDate !== "ignore"}
              onChange={(e) => setAvailableOnDate(e.target.checked)}
            />
          </FormField>

          <FormField label={t("worker_filter.has_driving_license")}>
            <Switch
              checked={hasDrivingLicense}
              onChange={(e) =>
                handleInput(filterMap.hasDrivingLicense, e.target.checked)
              }
            />
          </FormField>

          <FormField label={t("worker_filter.has_transport")}>
            <Switch
              checked={hasTransport}
              onChange={(e) =>
                handleInput(filterMap.hasTransport, e.target.checked)
              }
            />
          </FormField>
        </Pane>

        <Pane className="flex flex-col justify-between gap-3">
          <TextInputField
            name="email"
            label="Email"
            placeholder="abc@ab.nl"
            width="100%"
            className={"w-full grow"}
            value={email}
            onChange={(e) => handleInput(filterMap.email, e.target.value)}
            marginBottom={0}
            inputHeight={40}
          />

          <TextInputField
            name="firstName"
            label={t("user.first_name")}
            width="100%"
            className={"w-full grow"}
            placeholder="..."
            value={firstName}
            onChange={(e) => handleInput(filterMap.firstName, e.target.value)}
            marginBottom={0}
            inputHeight={40}
          />

          <TextInputField
            name="lastName"
            label={t("user.last_name")}
            width="100%"
            className={"w-full"}
            placeholder="..."
            value={lastName}
            onChange={(e) => handleInput(filterMap.lastName, e.target.value)}
            marginBottom={0}
            inputHeight={40}
          />
        </Pane>

        {/*<FilterWorkerTable*/}
        {/*    availableWorkers={availableWorkers}*/}
        {/*    refresh={refresh}*/}
        {/*    workersIsLoading={isLoading}*/}
        {/*    meta={meta}*/}
        {/*    doSortBy={doSortBy}*/}
        {/*/>*/}
      </Pane>
    );
  }),
);

export default WorkerFiltersForPlanner;
