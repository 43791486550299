import {
  Checkbox,
  Heading,
  majorScale,
  Pane,
  Paragraph,
  Text,
  TextareaField,
} from "evergreen-ui";
import {
  Area,
  Form,
  ObservationFeatureCollection,
  ObservationProperties,
} from "@/types/apiTypes";
import React, { useMemo, useState } from "react";
import Actions from "@/components/common/Actions";
import DateValue from "@/components/common/DateValue";
import useObservation from "@/pages/submitted-worker-shifts/hooks/useObservation";
import { isEmpty } from "lodash";
import { AttachedMediaFileList } from "@/pages/worker-shifts/_components/WorkerShiftDetails";
import { InfoBox } from "@/pages/projects/activity-types/ActivityTypeEditPage";
import { BsBinocularsFill } from "react-icons/bs";
import GaiaMap from "@/components/shared/gaiaMap/GaiaMap";
import { getGeoJsonBoundingBox } from "@/lib/functions";
import GaiaMapSource from "@/components/shared/gaiaMap/GaiaMapSource";
import { GaiaMapSourceType } from "@/components/shared/gaiaMap/GaiaMapSources";

type ObservationItemProps = {
  area: Area;
  count: number;
  form?: Form;
  observation: ObservationFeatureCollection["features"][0];
  observationApi: ReturnType<typeof useObservation>;
  totalCount: number;
};

function useObservationFormState({ observation, observationApi }) {
  const [relevant, setRelevant] = useState<boolean>(
    observation.properties.relevant,
  );
  const [status, setStatus] = useState<"complete" | "incomplete" | "rejected">(
    observation.status?.type,
  );

  const [notes, setNotes] = useState<string>(observation.properties.notes);

  const [adminNotes, setAdminNotes] = useState<string>(
    observation.properties.adminNotes,
  );

  const [answers, setAnswers] = useState<Record<string, string>>(
    observation?.properties.answers || {},
  );

  const [isDirty, setIsDirty] = useState(false);

  function updateAnswerValue(questionId: string, value: string) {
    setAnswers((prevAnswers) => {
      return {
        ...prevAnswers,
        [questionId]: value,
      };
    });

    setIsDirty(true);
  }

  function updateNotes(value: string) {
    setNotes(value);
    setIsDirty(true);
  }

  const [data, setData] = useState<ObservationFeatureCollection["features"][0]>(
    observation.properties,
  );

  function doSubmit(newData = {}) {
    const data = {
      ...observation.properties,
      relevant,
      status: {
        type: status,
      },
      adminNotes,
      notes,
      answers,
      ...newData,
    };

    return observationApi.update(observation.properties.id, data);
  }

  return {
    adminNotes,
    answers,
    data,
    doSubmit,
    isDirty,
    relevant,
    setAdminNotes,
    setAnswers,
    setData,
    setRelevant,
    setStatus,
    status,
    updateAnswerValue,
    updateNotes,
    notes,
  };
}

export default function ObservationItem({
  observation,
  form,
  observationApi,
  area,
  count,
  totalCount,
}: ObservationItemProps) {
  const {
    setRelevant,
    isDirty,
    setStatus,
    adminNotes,
    setAdminNotes,
    doSubmit,
    updateAnswerValue,
    answers,
    updateNotes,
    notes,
  } = useObservationFormState({ observation, observationApi });

  const questionsMap = useMemo(() => {
    const questionsMap = new Map();

    if (!form) {
      return questionsMap;
    }

    form.questions.forEach((question) => {
      questionsMap.set(question.id, question);
    });

    return questionsMap;
  }, [form?.questions]);

  function onRelevantChange(checkedValue: boolean) {
    setRelevant(checkedValue);
    doSubmit({ relevant: checkedValue });
  }

  function onClickStatus(status) {
    setStatus(status);

    doSubmit({
      status: {
        type: status,
      },
    });
  }

  return (
    <Pane className="my-2 border p-4">
      <Pane className="flex justify-between"></Pane>
      <Pane className="flex gap-4">
        <Pane className="flex grow flex-col gap-2 border-r-2 py-2 pr-4">
          <Pane>
            <Heading>
              <span className="flex items-center gap-1">
                <BsBinocularsFill color="#5f5f5f" />
                {`${count} / ${totalCount}`}
              </span>
            </Heading>
            <Pane className="pt-4">
              <Heading size={400}>Gemaakt op:</Heading>
              <Paragraph size={400}>
                <DateValue
                  formatStr={"eeeeee d MMM pp"}
                  date={observation.properties.createdAt}
                />{" "}
              </Paragraph>
            </Pane>
          </Pane>
          <Pane>
            <Heading size={400}>Gemaakt door:</Heading>
            <Heading size={300}>
              {observation.properties.createdBy.firstName}{" "}
              {observation.properties.createdBy.lastName}
            </Heading>
          </Pane>
          <Pane className="py-2">
            <Heading size={300}>Object:</Heading>
            <Pane className="max-h-[400px] min-w-[300px] bg-gray-100">
              <GaiaMap initialBounds={getGeoJsonBoundingBox(observation)}>
                <GaiaMapSource
                  sourceType={GaiaMapSourceType.Observations}
                  data={observation}
                  shouldFitBounds={false}
                />
              </GaiaMap>
              {/* <ObservationMap
                objects={[]}
                className={"h-[400px] min-w-[300px]"}
                area={area}
                fitBoundsToObservations={true}
                // @ts-ignore
                createdLocations={[
                  observation.properties.createdLocation,
                ].filter((v) => v)}
                observations={{
                  type: "FeatureCollection",
                  features: [observation],
                  //   {
                  //     ...observation,
                  //     ...{
                  //       ...observation.properties.createdLocation,
                  //       properties: {
                  //         color: "blue",
                  //       },
                  //     },
                  //   },
                  // ],
                }}
              /> */}
            </Pane>
          </Pane>

          <Pane className="py-2">
            <ObservationMediaList observation={observation.properties} />
          </Pane>

          <Pane className="py-2">
            <Heading size={400}>Parent observation</Heading>
            <Paragraph size={300}>
              {observation.properties.parentObservationId ?? "-"}
            </Paragraph>
          </Pane>
        </Pane>

        <Pane className="min-w-[15rem] max-w-[20rem] grow py-2">
          <Pane className="flex gap-2 py-2">
            <Actions className="cursor-pointer items-center !justify-start py-2">
              <Actions.Button
                onClick={() => onClickStatus("incomplete")}
                className={
                  observation.properties.status.type === "incomplete"
                    ? "!bg-orange-500 !text-white"
                    : "!border-orange-500 !text-orange-500"
                }
                height={majorScale(3)}
              >
                Incomplete
              </Actions.Button>
              <Actions.Button
                onClick={() => onClickStatus("pendingReview")}
                appearance={
                  observation.properties.status.type === "pendingReview"
                    ? "primary"
                    : "default"
                }
                className={
                  observation.properties.status.type === "pendingReview"
                    ? "!bg-blue-500 !text-white"
                    : "!border-blue-500 !text-blue-500"
                }
                intent="warning"
                height={majorScale(3)}
              >
                Pending review
              </Actions.Button>
              <Actions.Button
                onClick={() => onClickStatus("changesRequested")}
                className={
                  observation.properties.status.type === "changesRequested"
                    ? "!bg-yellow-700 !text-white"
                    : "!border-yellow-700 !text-yellow-700"
                }
                intent="warning"
                height={majorScale(3)}
              >
                Changes requested
              </Actions.Button>
              <Actions.Button
                onClick={() => onClickStatus("rejected")}
                appearance={
                  observation.properties.status.type === "rejected"
                    ? "primary"
                    : "default"
                }
                intent="danger"
                height={majorScale(3)}
              >
                Rejected
              </Actions.Button>
              <Actions.Button
                onClick={() => onClickStatus("approved")}
                intent="success"
                appearance={
                  observation.properties.status.type === "approved"
                    ? "primary"
                    : "default"
                }
                height={majorScale(3)}
              >
                Approved
              </Actions.Button>
            </Actions>
            <Pane
              onClick={() => onRelevantChange(!observation.properties.relevant)}
              className="flex items-center gap-1 self-start"
            >
              <Checkbox checked={observation.properties.relevant} />
              <Heading size={300}>Relevant</Heading>
            </Pane>
          </Pane>

          <Pane className="py-2">
            <TextareaField
              label={"Boordeeling"}
              onChange={(e) => setAdminNotes(e.target.value)}
              value={adminNotes}
              marginBottom={0}
              inputHeight={40}
            />
          </Pane>
          <Pane>
            <Pane className="py-2">
              {!form && (
                <InfoBox
                  description={`This observation is not part of a form. FormId referenced: ${observation.properties.formId}`}
                />
              )}

              <Heading size={500}>Antwoorden:</Heading>
              {Object.entries(answers).map(([questionId, value]) => (
                <Pane key={questionId} className="flex flex-col gap-1 py-2">
                  <Heading fontWeight="bold" size={300}>
                    {questionsMap.get(questionId)?.title || questionId}
                  </Heading>
                  {questionsMap.get(questionId)?.type.type === "text" ? (
                    <TextareaField
                      onChange={(e) =>
                        updateAnswerValue(questionId, e.target.value)
                      }
                      value={value}
                      marginBottom={0}
                      inputHeight={40}
                    />
                  ) : (
                    <Heading size={200}>{value}</Heading>
                  )}
                </Pane>
              ))}
            </Pane>
            <Pane className="py-2">
              <Heading size={500}>Notities / Opmerkingen:</Heading>
              <TextareaField
                onChange={(e) => updateNotes(e.target.value)}
                value={notes}
                marginBottom={0}
                inputHeight={40}
              />
            </Pane>

            <Actions marginTop={10} marginBottom={10}>
              <Actions.Button
                onClick={() => doSubmit()}
                appearance="primary"
                disabled={!isDirty}
                height={majorScale(5)}
              >
                Opslaan
              </Actions.Button>
            </Actions>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}

type ObservationMediaListProps = {
  observation: ObservationProperties;
};

function ObservationMediaList({ observation }: ObservationMediaListProps) {
  return (
    <>
      <Heading className="pt-2 pb-2" size={400}>
        Media
      </Heading>
      {!isEmpty(observation.media) ? (
        <AttachedMediaFileList mediaFiles={observation.media} />
      ) : (
        <Text className="text-gray-500">-</Text>
      )}
    </>
  );
}
