import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { Bookkeeping, CsvDataUrl, Id } from "../types/apiTypes";
import { SearchParams } from "../types/appTypes";

export default class AdminWorkerBookkeeping extends BaseApi {
  public findOne(id: Id): Promise<Bookkeeping> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/workers/${id}/bookkeeping`,
    });
  }

  public findMany(searchParams: SearchParams = {}): Promise<Bookkeeping[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/workers/bookkeeping${query}`,
    });
  }

  public exportToCsv(searchParams: SearchParams = {}): Promise<CsvDataUrl> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/workers/bookkeeping/csv${query}`,
    });
  }
}
