import {
  Avatar,
  Button,
  CrossIcon,
  EmptyState,
  FormField,
  Heading,
  IconButton,
  majorScale,
  Pane,
  Position,
  SearchIcon,
  Select,
  Table,
  Text,
  TextInputField,
  TickIcon,
  Tooltip,
} from "evergreen-ui";
import StandardTable from "src/components/table/StandardTable";

import { Period, User } from "src/types/apiTypes";
import { useApi } from "src/context/AxiosContext";
import { useLoadResource } from "src/lib/request-hooks";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DateValue from "src/components/common/DateValue";
import PageWithSubSidebarLeft from "src/components/common/PageWithSubSidebarLeft";
import UseFilterLogic, { useFilter } from "../../../../hooks/useFilterLogic";
import PageTitle from "../../../../components/common/PageTitle";
import {
  __r,
  ADMIN_WORKER_AVAILABILITY_PAGE,
  PLANNER_PAGE,
} from "src/RouteMap";
import { useWindowSearchParams } from "../../../../hooks/useWindowSearchParams";
import { FilterMapType } from "../../../../types/appTypes";
import { shortenString } from "../../../../lib/functions";

const statusOptions = [
  {
    label: "Filled in",
    value: "filledIn",
  },
  {
    label: "Not filled in",
    value: "notFilledIn",
  },
];

function FilterTemplate() {
  const { filterMap, handleInput } = useFilter();

  return (
    <Pane className="flex flex-col gap-3">
      <TextInputField
        name="email"
        label="Email"
        placeholder=""
        value={filterMap?.email.value ?? ""}
        onChange={(e) => handleInput(filterMap.email, e.target.value)}
        marginBottom={0}
        inputHeight={40}
      />

      <TextInputField
        name="firstName"
        label="First name"
        placeholder=""
        value={filterMap?.firstName.value ?? ""}
        onChange={(e) => handleInput(filterMap.firstName, e.target.value)}
        marginBottom={0}
        inputHeight={40}
      />

      <TextInputField
        name="lastName"
        label="Last name"
        placeholder=""
        value={filterMap?.lastName.value ?? ""}
        onChange={(e) => handleInput(filterMap.lastName, e.target.value)}
        marginBottom={0}
        inputHeight={40}
      />

      <FormField label="Status">
        <Select
          value={filterMap?.status.value ?? ""}
          onChange={(e) => handleInput(filterMap.status, e.target.value)}
          width={240}
          height={40}
        >
          <option key="-" value="">
            -
          </option>
          {statusOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </FormField>
    </Pane>
  );
}

function AvailabilityProgressFilter() {
  const { periodId } = useParams();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const filterMap: FilterMapType = {
    email: {
      setter: setEmail,
      value: email,
      topic: "email",
    },
    firstName: {
      setter: setFirstName,
      value: firstName,
      topic: "firstName",
    },
    lastName: {
      setter: setLastName,
      value: lastName,
      topic: "lastName",
    },
    status: {
      setter: setStatus,
      value: status,
      topic: "status",
    },
    periodId: {
      value: periodId,
      topic: "periodId",
    },
    role: {
      setter: setRole,
      value: "worker",
      topic: "role",
    },
  };

  return (
    <UseFilterLogic filterMap={filterMap}>
      <FilterTemplate />
    </UseFilterLogic>
  );
}

const useUsers = function () {
  const { periodId } = useParams();
  const searchParams = useWindowSearchParams();

  const [users, setUsers] = useState<User[]>([]);
  const { apiInstance } = useApi();

  searchParams.set("periodId", periodId!);
  searchParams.set("populate", "availabilityStatus");

  const request = () => apiInstance!.adminUsers.findMany(searchParams);

  function setData(users) {
    setUsers(users);
  }

  return {
    ...useLoadResource(request, setData, !!periodId),
    users,
  };
};

export const useAdminPeriod = function () {
  const { apiInstance } = useApi();
  const { periodId } = useParams();

  const [period, setPeriod] = useState<Period | null>(null);

  const find = () => apiInstance!.adminPeriods.findOne(periodId!);

  return {
    ...useLoadResource(find, setPeriod, true),
    period,
  };
};

export default function PeriodProgressPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { periodId } = useParams();

  const { users, meta, isLoading, refresh } = useUsers();

  const { period } = useAdminPeriod();

  const navTo = (user: User) => {
    navigate(
      {
        pathname: __r(ADMIN_WORKER_AVAILABILITY_PAGE, {
          workerId: user.worker!.id,
          periodId,
        }),
      },
      {
        state: {
          backPath: window.location.pathname + window.location.search,
        },
      },
    );
  };

  return (
    <PageWithSubSidebarLeft
      pageTitleContent={
        <PageTitle backPath={PLANNER_PAGE}>
          {t("availability_requests_progress_page.title")}
          <span className="ml-2">{period?.name}</span>
        </PageTitle>
      }
      filterContent={<AvailabilityProgressFilter />}
      closedContent={
        <Button iconBefore={SearchIcon}>{t("common.filter")}</Button>
      }
      title={"Filters"}
    >
      <Pane className="w-full">
        <Pane className="flex items-center gap-2 pt-2">
          {period?.startAt && period?.endAt && (
            <>
              <Heading size={600}>{t("common.period")}</Heading>
              <DateValue formatStr="eeeeee - PP" date={period.startAt} />{" "}
              <Text>/</Text>{" "}
              <DateValue formatStr="eeeeee - PP" date={period.endAt} />
            </>
          )}
        </Pane>

        <StandardTable
          refresh={refresh}
          meta={meta}
          isLoading={isLoading}
          data={users}
          emptyState={<EmptyUsersState />}
        >
          <Table.Head>
            <Table.HeaderCell minWidth={120}>Naam</Table.HeaderCell>
            <Table.HeaderCell minWidth={200}>Email</Table.HeaderCell>
            <Table.HeaderCell>Laatst actief op</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell># Dagen</Table.HeaderCell>
            <Table.HeaderCell maxWidth={300}>Notities</Table.HeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {users.map((user) => (
              <Table.Row
                key={user.id}
                isSelectable
                onSelect={() => {
                  navTo(user);
                }}
              >
                <Table.Cell minWidth={120}>
                  <Pane className="flex items-center gap-2">
                    <Avatar
                      className="hover:opacity-90"
                      src={user?.profilePictureThumbnail ?? ""}
                      name={""}
                      size={20}
                    />
                    <Text className="text-xs" size={majorScale(9)}>
                      {user.firstName} {user.lastName}
                    </Text>
                  </Pane>
                </Table.Cell>
                <Table.TextCell minWidth={200}>{user.email}</Table.TextCell>
                <Table.TextCell>
                  <DateValue date={user.lastActiveAt} />
                </Table.TextCell>
                <Table.TextCell>
                  {user.availabilityFilledIn ? (
                    <IconButton
                      intent="success"
                      icon={TickIcon}
                      type="button"
                    />
                  ) : (
                    <IconButton
                      intent="danger"
                      icon={CrossIcon}
                      type="button"
                    />
                  )}
                </Table.TextCell>
                <Table.TextCell>
                  {user.workerAvailabilityStatus?.totalDays ?? "-"}
                </Table.TextCell>
                <Table.TextCell className="whitespace-pre-wrap" maxWidth={300}>
                  <Tooltip
                    position={Position.TOP}
                    content={user.workerAvailabilityStatus?.notes}
                  >
                    <Text>{user.workerAvailabilityStatus?.notes}</Text>
                  </Tooltip>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </StandardTable>
      </Pane>
    </PageWithSubSidebarLeft>
  );
}

function EmptyUsersState() {
  return (
    <EmptyState
      background="light"
      title="No users found"
      orientation="horizontal"
      icon={<SearchIcon color="#C1C4D6" />}
      iconBgColor="#EDEFF5"
      description="Users appear when there are users"
    />
  );
}
