import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useState } from "react";
import { useLoadResource } from "../../../lib/request-hooks";

import { useApi } from "src/context/AxiosContext";
import { ClientActivity } from "src/types/apiTypes";
import { EmptyState, Pane, SearchIcon, Table } from "evergreen-ui";
import Actions from "src/components/common/Actions";
import StandardTable from "src/components/table/StandardTable";
import { __r, ADMIN_CLIENT_ACTIVITY_EDIT_PAGE } from "../../../RouteMap";

export function useClientTypes() {
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const [clientActivities, setClientActivity] = useState<ClientActivity[]>([]);

  const { apiInstance } = useApi();

  const request = () =>
    apiInstance!.adminClientActivity.findMany(projectId!, searchParams);

  function setData(data) {
    setClientActivity(data);
  }

  return {
    ...useLoadResource(request, setData),
    clientActivities,
  };
}

export default function ClientActivityTypesPage() {
  const { projectId } = useParams();

  const { clientActivities, refresh, isLoading, meta } = useClientTypes();
  const navigate = useNavigate();

  const navTo = (activity: ClientActivity) => {
    navigate(
      __r(ADMIN_CLIENT_ACTIVITY_EDIT_PAGE, {
        projectId,
        activityId: activity.id,
      }),
    );
  };

  return (
    <Pane>
      <Actions marginBottom={10}>
        <Link to={`/projects/${projectId}/client-activities/add`}>
          <Actions.Button appearance="primary">
            Nieuwe Activiteit
          </Actions.Button>
        </Link>
      </Actions>
      <StandardTable
        refresh={refresh}
        meta={meta}
        isLoading={isLoading}
        data={clientActivities}
        emptyState={<EmptyClientActivityTypesState />}
      >
        <Table.Head>
          <Table.TextHeaderCell>Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>Description</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body height="auto">
          {clientActivities.map((activity) => (
            <Table.Row
              key={activity.id}
              isSelectable
              onSelect={() => {
                navTo(activity);
              }}
            >
              <Table.TextCell className="!text-slate-800">
                {activity.name}
              </Table.TextCell>
              <Table.TextCell className="!text-slate-800">
                {activity.notes ?? "-"}
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </StandardTable>
    </Pane>
  );
}

function EmptyClientActivityTypesState() {
  return (
    <EmptyState
      background="light"
      title="Nog geen activiteiten toegevoegd"
      orientation="horizontal"
      icon={<SearchIcon color="#C1C4D6" />}
      iconBgColor="#EDEFF5"
      description="Activiteiten verschijnen als je op de knop 'Nieuwe Activiteit' klikt. Daar kun je een nieuwe activiteit toevoegen."
    />
  );
}
