import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  EmptyState,
  EyeOpenIcon,
  FilterIcon,
  Heading,
  IconButton,
  majorScale,
  Pane,
  RefreshIcon,
  SearchIcon,
  Spinner,
  Text,
  TickIcon,
} from "evergreen-ui";
import { isEmpty } from "lodash";
import { TbSum } from "react-icons/tb";
import { useEffect, useMemo, useRef, useState } from "react";
import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

import Actions from "src/components/common/Actions";
import Page from "src/components/common/Page";
import PageTitle from "src/components/common/PageTitle";
import useTimeslotResources from "src/hooks/useTimeslotResources";

import { __r, PLANNER, PLANNER_PERIOD_PLANNING_PAGE } from "src/RouteMap";
import { PlannerContext } from "src/context/PlannerContext";
import { TableLoadingSpinner } from "src/components/table/StandardTable";
import { Period } from "src/types/apiTypes";

import AreYouSureDialog from "src/components/common/AreYouSureDialog";
import AvailabilityPerDayTableSheet from "./_components/AvailabilityPerDayTableSheet";
import Collapse from "src/components/common/Collapse";
import PendingChangesDialog from "src/components/common/PendingChangesDialog";

import { DateFormat } from "src/components/common/DateFormat";
import { useApp } from "src/context/AppContext";
import { usePeriods } from "src/hooks/usePeriods";

import usePeriod from "src/hooks/usePeriod";
import useSafeLeave from "src/hooks/useSafeLeave";
import useSafeSubmit from "src/hooks/useSafeSubmit";

// private
import PlannerAggregations from "./_components/PlannerAggregations";
import PlannerFilters from "./_components/PlannerFilters";
import PlannerForPeriodTable from "./_components/PlannerForPeriodTable";

import usePendingShiftsForPeriod from "./_hooks/usePendingShiftsForPeriod";
import usePlanningWithFilters from "./_hooks/usePlanningWithFilters";
import usePublishPlanning from "./_hooks/usePublishPlanning";

export default function PlannerPage() {
  const filterRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const { setLayoutProps } = useApp();

  useEffect(() => {
    setLayoutProps({
      scrollToTop: false,
    });
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  const { periodId } = useParams();

  const [isSideSheetOpen, setIsSideSheetOpen] = useState(false);

  const planningWithFilters = usePlanningWithFilters();

  const { doPublishPlanning } = usePublishPlanning();

  const { planning, isLoading, refresh } = planningWithFilters;

  const { period, refresh: refreshPeriod } = usePeriod();

  const { pendingShifts, refresh: refreshPendingShifts } =
    usePendingShiftsForPeriod();

  const { periods } = usePeriods();

  const { timeslots, timeslotMapById } = useTimeslotResources();
  const [popupShownId, setPopupShownId] = useState<number | null>(null);

  const periodStart = useMemo(() => {
    return period ? parseISO(period.startAt) : null;
  }, [period, periodId]);

  const periodEnd = useMemo(() => {
    return period ? parseISO(period.endAt) : null;
  }, [period, periodId]);

  const prevPeriod = useMemo(() => {
    return !isEmpty(periods) && periodStart
      ? [...periods].reverse().find((p) => parseISO(p.startAt) < periodStart!)
      : null;
  }, [periods, periodStart, periodId]);

  const nextPeriod = useMemo(() => {
    return !isEmpty(periods) && periodStart
      ? periods.find((p) => parseISO(p.startAt) > periodStart!)
      : null;
  }, [periods, periodStart, periodId]);

  function navToPeriod(period: Period) {
    navigate(
      {
        pathname: __r(PLANNER_PERIOD_PLANNING_PAGE, {
          periodId: period.id,
        }),
        search: window.location.search,
      },
      {
        state: {
          refresh: true,
        },
      },
    );
  }

  useEffect(() => {
    if (location?.state?.refresh) {
      refresh();
      refreshPeriod();
      refreshPendingShifts();
    }
  }, [location?.state]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (filterRef.current && entries[0].boundingClientRect.y <= 0) {
          filterRef.current?.classList.add("shadow-bottom-lg");
          filterRef.current?.classList.add("px-2");
          filterRef.current?.classList.add("pb-2");
        } else {
          filterRef.current?.classList.remove("shadow-bottom-lg");
          filterRef.current?.classList.remove("px-2");
          filterRef.current?.classList.remove("pb-2");
        }
      },
      {
        rootMargin: "0px 0px -100% 0px",
        threshold: 0,
      },
    );

    if (filterRef.current) {
      observer.observe(filterRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [filterRef.current]);

  function onConfirmSafeSubmit() {
    refresh();
    refreshPeriod();
    refreshPendingShifts();
  }

  const { safeSubmitDialogShown, closeSafeDialog, doSafeSubmit, onSafeSubmit } =
    useSafeSubmit({
      onConfirm: onConfirmSafeSubmit,
      doSubmit: doPublishPlanning,
      isDirty: true,
    });

  const { closeSafeLeaveDialog, doLeave, onLeave, safeLeaveDialogShown } =
    useSafeLeave({
      onConfirm: () => navigate(PLANNER),
      isDirty: pendingShifts.length > 0,
    });

  return (
    <Page key={periodId} className="pb-[50rem]">
      <AreYouSureDialog
        doAction={doSafeSubmit}
        text={t("dialog_are_you_sure.text").toString()}
        intent="success"
        isShown={safeSubmitDialogShown}
        onCloseComplete={closeSafeDialog}
        isConfirmLoading={false}
      />

      <PendingChangesDialog
        doAction={doLeave}
        confirmLabel={t("planner_page.dialog_pending_changes.confirm")}
        text={t("planner_page.dialog_pending_changes.text").toString()}
        isShown={safeLeaveDialogShown}
        onCloseComplete={closeSafeLeaveDialog}
        isConfirmLoading={false}
      />
      <Pane className="py-4">
        <PageTitle
          onLeave={onLeave}
          actions={<Actions></Actions>}
          backPath={PLANNER}
        ></PageTitle>

        <Pane className="flex">
          <Pane className="flex grow flex-col gap-2 py-2">
            <Pane className="flex items-center gap-1">
              <TbSum color="muted" size={majorScale(2)} />
              <Heading size={300}>Aggregaties</Heading>
            </Pane>
            <Pane className={"h-[1px] w-32 bg-gray-200"} />
            {!isEmpty(planning) && <PlannerAggregations planning={planning} />}
          </Pane>
        </Pane>
      </Pane>

      <Collapse
        ref={filterRef}
        className="sticky top-0 z-10 bg-white py-2 transition-all duration-100"
        defaultIsOpen={true}
      >
        <Pane className="top-0 flex flex-col gap-2 py-2">
          <Pane className="flex grow items-center gap-2">{period?.name}</Pane>
          <Pane className="flex items-center gap-2 py-3">
            <Heading size={300}>Periode:</Heading>
            <DateFormat
              className="!text-xs capitalize"
              formatStr="eeeeeee - PP"
              date={periodStart}
            />{" "}
            <Text className="!text-xs">|</Text>
            <DateFormat
              className="!text-xs capitalize"
              formatStr="eeeeeee - PP"
              date={periodEnd}
            />
          </Pane>
          <Pane className="flex items-center gap-1">
            <FilterIcon color="muted" size={majorScale(2)} />
            <Heading size={300}>Filters</Heading>
          </Pane>
          <Pane className={"h-[1px] w-32 bg-gray-200"} />
          <Pane className="flex gap-2">
            {period && (
              <PlannerFilters
                key={periodId}
                period={period}
                filterLogic={planningWithFilters}
              />
            )}
          </Pane>
        </Pane>

        <Actions className="shrink">
          <Button
            onClick={() => setIsSideSheetOpen(true)}
            iconBefore={EyeOpenIcon}
          >
            Beschikbaarheid
          </Button>
          <Button
            intent="success"
            onClick={onSafeSubmit}
            disabled={pendingShifts.length === 0}
            iconBefore={TickIcon}
            appearance="primary"
          >
            Maak definitief{" "}
            {pendingShifts.length > 0 && `(${pendingShifts.length})`}
          </Button>
          <IconButton
            disabled={!prevPeriod}
            intent="info"
            isActive={isLoading === null}
            icon={ChevronLeftIcon}
            onClick={() => navToPeriod(prevPeriod!)}
            type="button"
          />
          <IconButton
            disabled={!nextPeriod}
            intent="info"
            isActive={isLoading === null}
            icon={ChevronRightIcon}
            onClick={() => navToPeriod(nextPeriod!)}
            type="button"
          />
          {refresh && !isLoading && (
            <IconButton
              appearance="minimal"
              icon={RefreshIcon}
              onClick={() => refresh()}
              type="button"
            />
          )}
          {isLoading && (
            <Pane className="flex justify-end">
              <Spinner size={30} />
            </Pane>
          )}
        </Actions>
      </Collapse>

      <Pane>
        {isSideSheetOpen && (
          <AvailabilityPerDayTableSheet
            period={period}
            setIsShown={setIsSideSheetOpen}
          />
        )}
      </Pane>

      <PlannerContext.Provider
        value={{
          timeslots,
          timeslotMapById,
          period,
          popupShownId,
          setPopupShownId,
          refresh,
        }}
      >
        {period && (
          <PlannerForPeriodTable
            period={period}
            prevPeriod={prevPeriod}
            nextPeriod={nextPeriod}
            planning={planning}
          />
        )}

        {isLoading && (
          <TableLoadingSpinner
            title={"Planning ophalen voor deze periode..."}
          />
        )}

        {isLoading === false && isEmpty(planning) && (
          <AreaActivityTypesEmptyState />
        )}
      </PlannerContext.Provider>
      <Outlet />
    </Page>
  );
}

function AreaActivityTypesEmptyState() {
  const { t } = useTranslation();

  return (
    <EmptyState
      background="light"
      title={t("planner_page.empty_area_activities_title")}
      orientation="horizontal"
      icon={<SearchIcon color="#C1C4D6" />}
      iconBgColor="#EDEFF5"
      description={t("planner_page.empty_area_activities_description")}
    />
  );
}
