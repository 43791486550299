import { Alert, Dialog, DialogProps, Pane, Paragraph } from "evergreen-ui";
import { useTranslation } from "react-i18next";
import React from "react";

interface AreYouSureDialogProps extends DialogProps {
  doAction: () => void;
  children?: React.ReactNode;
  danger?: string | null;
  warning?: string | null;
  text?: string | null;
  title?: string | null;
}

export default function AreYouSureDialog({
  children,
  doAction,
  ...rest
}: AreYouSureDialogProps) {
  const { t } = useTranslation();

  const { danger = "", warning = null, text = "", title = null } = rest;

  return (
    <Dialog
      title={title ?? t("dialog_are_you_sure.title")}
      confirmLabel={
        rest?.confirmLabel ?? t("dialog_are_you_sure.confirm").toString()
      }
      cancelLabel={
        rest?.cancelLabel ?? t("dialog_are_you_sure.cancel").toString()
      }
      onConfirm={doAction}
      {...rest}
    >
      {text ?? <Paragraph>{t("dialog_are_you_sure.title")}</Paragraph>}

      {warning && (
        <Alert className="my-2" intent="warning">
          <Paragraph size={300} className="!text-xs">
            {warning}
          </Paragraph>
        </Alert>
      )}
      {danger && (
        <Alert className="my-2" intent="danger">
          <Paragraph size={300} className="!text-xs">
            {danger}
          </Paragraph>
        </Alert>
      )}
      <Pane>{children && children}</Pane>
    </Dialog>
  );
}
// "Are you sure you want to continue?"
