import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { Id, Planning, Shift } from "../types/apiTypes";
import { SearchParams } from "../types/appTypes";

export default class AdminPlanning extends BaseApi {
  create(
    projectId: Id,
    areaActivityId: Id,
    roundIndex: number,
  ): Promise<Planning> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/projects/${projectId}/planning/${areaActivityId}/rounds/${roundIndex}`,
    });
  }

  findOne(
    projectId: Id,
    areaActivityId: Id,
    roundIndex: number,
  ): Promise<Planning> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/projects/${projectId}/planning/${areaActivityId}/rounds/${roundIndex}`,
    });
  }

  update(id: Id, data: Planning): Promise<Planning> {
    return doRequest(this._axiosInstance!, {
      method: "put",
      url: `/admin/planning/${id}`,
      data: data,
    });
  }

  publishForPeriod(periodId: Id): Promise<Planning> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/periods/${periodId}/planning/publish`,
    });
  }

  findShiftsForPeriod(
    periodId: Id,
    searchParams: SearchParams = {},
  ): Promise<Shift> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/periods/${periodId}/shifts${query}`,
    });
  }

  findManyForPeriod(
    periodId: Id,
    searchParams: SearchParams = {},
  ): Promise<Planning> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/periods/${periodId}/planning${query}`,
    });
  }

  remove(id): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: "delete",
      url: `/admin/planning/${id}`,
    });
  }
}
