import { CalendarIcon, DashboardIcon, Pane, UserIcon } from "evergreen-ui";
import { Outlet, useParams } from "react-router-dom";
import TopSubNav from "../common/TopSubNav";

import {
  __r,
  PLANNER_AVAILABILITY_REQUEST_EDIT_PAGE,
  PLANNER_AVAILABILITY_REQUEST_PLANNING_PAGE,
  PLANNER_AVAILABILITY_REQUEST_PROGRESS_PAGE,
} from "../../RouteMap";

export default function PlannerLayout() {
  const { periodId } = useParams();

  const menuItems = [
    {
      label: "Planner",
      pathname: __r(PLANNER_AVAILABILITY_REQUEST_PLANNING_PAGE, { periodId }),
      icon: <UserIcon />,
    },
    {
      label: "Invulvoortgang ",
      pathname: __r(PLANNER_AVAILABILITY_REQUEST_PROGRESS_PAGE, { periodId }),
      icon: <DashboardIcon />,
    },
    {
      label: "Settings",
      pathname: __r(PLANNER_AVAILABILITY_REQUEST_EDIT_PAGE, { periodId }),
      icon: <CalendarIcon />,
    },
  ];

  return (
    <Pane className="h-full">
      <TopSubNav menuItems={menuItems} />

      <Pane className="h-full">
        <Outlet />
      </Pane>
    </Pane>
  );
}
