import { BsBinoculars } from "react-icons/bs";
import { IssueIcon, Pane, PaperclipIcon } from "evergreen-ui";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";

import {
  __r,
  WORKER_SHIFTS_CHANGES_PAGE,
  WORKER_SHIFTS_DETAIL_PAGE,
  WORKER_SHIFTS_OBSERVATIONS_PAGE,
} from "@/RouteMap";
import { useAuth } from "@/context/AuthContext";

import TopSubNav from "../common/TopSubNav";

export default function WorkerShiftDetailsLayout() {
  const { getAdminUser } = useAuth();

  const { shiftId } = useParams();
  const { t } = useTranslation();

  const adminUser = getAdminUser();

  const menuItems = [
    {
      label: t("worker_shifts_page.details_page"),
      pathname: __r(WORKER_SHIFTS_DETAIL_PAGE, { shiftId }),
      icon: <BsBinoculars />,
    },
    // {
    //   label: t("worker_shifts_page.report_page"),
    //   pathname: __r(WORKER_SHIFTS_REPORT_PAGE, { shiftId }),
    //   icon: <PaperclipIcon />,
    // },
    {
      label: t("worker_shifts_page.changes_page"),
      pathname: __r(WORKER_SHIFTS_CHANGES_PAGE, { shiftId }),
      icon: <IssueIcon color="muted" />,
    },
  ];

  if (adminUser) {
    menuItems.push({
      label: t("worker_shifts_page.observations_page"),
      pathname: __r(WORKER_SHIFTS_OBSERVATIONS_PAGE, { shiftId }),
      icon: <PaperclipIcon />,
    });
  }

  return (
    <Pane className="h-full">
      <TopSubNav menuItems={menuItems} />

      <Pane className="h-full">
        <Outlet />
      </Pane>
    </Pane>
  );
}
