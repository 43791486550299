import { Avatar, Link, Pane, Spinner, Text, toaster } from "evergreen-ui";
import { useCallback, useRef, useState } from "react";
import { useApi } from "src/context/AxiosContext";
import Box from "ui-box";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

function useMediaUploadSingle({ setResult, uploadFn }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const uploadFile = async (file: File) => {
    setIsLoading(true);
    // Upload the image using the fetch and FormData APIs
    const formData = new FormData();

    // Assume "image" is the name of the form field the server expects
    formData.append("file", file);

    const result = await uploadFn(formData);

    setIsLoading(false);

    return result;
  };

  const setFileCb = async (file: File) => {
    setResult(await uploadFile(file));
  };

  return {
    setFileCb,
    isLoading,
  };
}

export default function ProfilePictureUploader(props) {
  const { apiInstance } = useApi();
  const { user, setUser } = useAuth();
  const { t } = useTranslation();

  const { validationErrors, title, children, ...rest } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  function setResult(user) {
    setIsRefreshing(true);

    setTimeout(() => {
      setUser!(user);
      setIsRefreshing(false);

      toaster.success("Profile picture updated!");
    }, 2000);
  }

  const uploadFn = (formData) =>
    apiInstance!.users.uploadProfilePicture(formData);
  const { setFileCb, isLoading } = useMediaUploadSingle({
    uploadFn,
    setResult,
  });

  const handleFileChange = useCallback((e) => {
    const file = e.target.files[0];

    setFileCb(file);
  }, []);

  const handleButtonClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  return (
    <Pane
      className="flex cursor-pointer flex-col items-center gap-4"
      onClick={handleButtonClick}
      {...rest}
    >
      <Box
        {...{ ref: fileInputRef }}
        is="input"
        type="file"
        name="profile-picture"
        required={true}
        accept="image/png, image/jpeg"
        multiple={false}
        onChange={handleFileChange}
        display="none"
      />

      {isLoading || isRefreshing ? (
        <>
          <Spinner size={20} />
          <Text className="cursor-pointer !text-xs hover:underline">
            {t("profile_picture_uploader.updating_profile_picture")}
          </Text>
        </>
      ) : (
        <>
          <Avatar
            className="hover:opacity-90"
            src={user!.profilePicture ?? ""}
            name={""}
            size={100}
          />

          <Link className="cursor-pointer !text-xs hover:underline">
            {t("profile_picture_uploader.upload_profile_picture")}
          </Link>
        </>
      )}

      {validationErrors?.file && (
        <Pane>
          <Text color="red">{validationErrors?.file?.join(", ")}</Text>
        </Pane>
      )}
    </Pane>
  );
}
