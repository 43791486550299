import {
  Button,
  Card,
  EmptyState,
  EyeOpenIcon,
  Link,
  majorScale,
  SearchIcon,
} from "evergreen-ui";
import PageTitle from "../../components/common/PageTitle";
import { useApi } from "@/context/AxiosContext";
import { useState } from "react";
import { useLoadResource } from "@/lib/request-hooks";
import { WorkerShift } from "@/types/apiTypes";
import Page from "../../components/common/Page";
import { __r, WORKER_SHIFTS_DETAIL_PAGE } from "@/RouteMap";
import { useNavigate } from "react-router-dom";
import useTimeslotResources from "../../hooks/useTimeslotResources";
import { addMonths } from "date-fns";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { TableLoadingSpinner } from "@/components/table/StandardTable";
import Actions from "../../components/common/Actions";
import { SunTimesDialog } from "../worker-availability/_components/SunTimesDialog";
import { WorkerShiftDetails } from "./_components/WorkerShiftDetails";
import { BsAndroid, BsApple } from "react-icons/bs";

export default function WorkerGoToAppPage() {
  return (
    <Page>
      <PageTitle>
        Bekijk je diensten in de Gaia fieldworker app
      </PageTitle>

      <Card elevation={1} padding={16} marginBottom={16}>
        <EmptyState
          background="light"
          title="Je diensten zijn te vinden in de app."
          orientation="horizontal"
          icon={<SearchIcon color="#C1C4D6" />}
          iconBgColor="#EDEFF5"
          description={
            <div>
              Gebruik de app op je Gaia tablet of gebruik onderstaande links op je telefoon.
              <div className="flex flew-row gap-4">
                <Link href="https://play.google.com/store/apps/details?id=com.gaiaEcology.fieldworker.android" target="_blank">
                  <Button size="large" iconBefore={<BsAndroid />}>
                    Android
                  </Button>
                </Link>
                
                <Link href="https://apps.apple.com/us/app/gaia-fieldworker/id6479219140" target="_blank">
                  <Button size="large" iconBefore={<BsApple />}>
                    iOS
                  </Button>
                </Link>
              </div>
            </div>
          }
        />
      </Card>
    </Page>
  );
}