import { HookOptions } from "@/types/appTypes";
import { useApi } from "@/context/AxiosContext";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { WorkerShift } from "@/types/apiTypes";
import { useLoadResource } from "@/lib/request-hooks";

export function useWorkerShift(options: HookOptions = {}) {
  const { apiInstance } = useApi();
  const { shiftId } = useParams();

  const [workerShift, setWorkerShift] = useState<WorkerShift>();
  const find = () => apiInstance!.workerShifts.findOne(shiftId!);

  function setData(data: WorkerShift) {
    setWorkerShift(data);

    if (options.setData) {
      options.setData(data);
    }
  }

  return {
    ...useLoadResource(find, setData, !!shiftId),
    workerShift,
  };
}

export function useTestPayments() {
  const { apiInstance } = useApi();

  const [payments, setPayments] = useState<WorkerShift>();
  const find = () => apiInstance!.workerShifts.findPayments();

  function setData(data) {
    setPayments(data);
  }

  return {
    ...useLoadResource(find, setData),
    payments,
  };
}
