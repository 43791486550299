import {
  Badge,
  Heading,
  InfoSignIcon,
  Pane,
  PercentageIcon,
  PersonIcon,
  Popover,
  Position,
  Text,
  TimeIcon,
  useTheme,
} from "evergreen-ui";
import { useNavigate } from "react-router-dom";
import React, { createElement, memo, useMemo, useState } from "react";
import { addDays, parseISO, subDays } from "date-fns";
import { isEmpty } from "lodash";

import DateValue from "src/components/common/DateValue";
import {
  Area,
  AreaActivityType,
  Period,
  Planning,
  Shift,
  Timeslot,
} from "src/types/apiTypes";
import { DateFormat } from "src/components/common/DateFormat";
import {
  __r,
  PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_OVERLAY,
} from "src/RouteMap";
import { classNames, roundNumber } from "src/lib/functions";
import WorkerItemWithPopover from "./WorkerItemWithPopover";
import {
  ShiftStatusIconMap,
  TimeslotIconMap,
} from "src/components/config/iconMaps";
import { usePlannerContext } from "@/context/PlannerContext";
import CombinePopover from "@/pages/planner/_components/CombinePopover";
import RemoveChild from "@/pages/planner/_components/RemoveChild";

// a shift but with a little extra fields to make it easier to work with
export interface ShiftAugmented extends Shift {
  timeslot: Timeslot;
  timeslotType: string;
  name?: string;
}

type PlannerRoundsItemProps = {
  areaActivityType: AreaActivityType;
  entry: any;
  index: number;
  period: Period;
  plan: Planning;
  shift: ShiftAugmented | null;
  shifts: ShiftAugmented[];
  startDate: Date;
  allShiftsInArea: ShiftAugmented[];
  allShiftsInAreaMap: Record<number, ShiftAugmented>;
  isChild: boolean;
};

/**
 * Split shifts into left and right side of the period end date.
 *
 * @param shifts
 * @param period
 */
function splitShifts(shifts: Shift[], period: Period): Shift[][] {
  if (!period) {
    return [[], []];
  }

  const periodEnd = parseISO(period.endAt);

  const leftShifts: Shift[] = [];
  const rightShifts: Shift[] = [];

  for (const shift of shifts) {
    const shiftDate = parseISO(shift.date);

    if (shiftDate <= periodEnd) {
      leftShifts.push(shift);
    } else {
      rightShifts.push(shift);
    }
  }

  return [leftShifts, rightShifts];
}

type useMinMaxStartDateProps = {
  shifts: Shift[];
  period: Period;
  shift: Shift | null;
  areaActivityType: AreaActivityType;
};

/**
 * @param shifts
 * @param period
 * @param shift
 * @param areaActivityType
 */
export function useMinMaxStartDate({
  shifts,
  period,
  shift,
  areaActivityType,
}: useMinMaxStartDateProps) {
  const [leftShifts, rightShifts] = useMemo(
    () => splitShifts(shifts, period),
    [shifts, period],
  );

  const [leftStartDate, rightStartDate] = useMemo(() => {
    if (!period) {
      return [new Date(), new Date()];
    }

    if (!areaActivityType) {
      return [new Date(), new Date()];
    }

    const minDaysBetweenRounds =
      areaActivityType.activityType.minDaysBetweenRounds;

    const periodStart =
      parseISO(period.startAt) < parseISO(areaActivityType.activityType.startAt)
        ? parseISO(areaActivityType.activityType.startAt)
        : parseISO(period.startAt);

    const periodEnd = parseISO(period.endAt);

    const left = shift
      ? leftShifts.filter((s) => s.id !== shift?.id)
      : leftShifts;

    const lastLeftShift = left[left.length - 1];
    const firstRightShift = rightShifts[0];

    let minStartDate = lastLeftShift
      ? addDays(parseISO(lastLeftShift.date), minDaysBetweenRounds)
      : periodStart;

    if (minStartDate < periodStart) {
      minStartDate = periodStart;
    }

    let maxStartDate = firstRightShift
      ? subDays(parseISO(firstRightShift.date), minDaysBetweenRounds)
      : periodEnd;

    if (maxStartDate > periodEnd) {
      maxStartDate = periodEnd;
    }

    return [minStartDate, maxStartDate];
  }, [leftShifts, rightShifts, areaActivityType, period, shift]);

  const minStartDate = useMemo(() => {
    if (!period) {
      return new Date();
    }

    if (leftStartDate > parseISO(period.endAt)) {
      return leftStartDate;
    }

    if (rightStartDate < parseISO(period.startAt)) {
      return rightStartDate;
    }

    return leftStartDate;
  }, [period, leftStartDate, rightStartDate]);

  const isPlannableWithinPeriod = useMemo(() => {
    if (!period) {
      return false;
    }

    if (shift) {
      return true;
    }

    if (
      leftStartDate > parseISO(period.endAt) ||
      rightStartDate < parseISO(period.startAt)
    ) {
      return false;
    }

    return true;
  }, [period, shifts, leftStartDate]);

  return {
    minStartDate,
    leftStartDate,
    rightStartDate,
    isPlannableWithinPeriod,
  };
}

export default function PlannerRoundsItem({
  index,
  shifts,
  shift,
  period,
  areaActivityType,
  allShiftsInArea,
  isChild,
  allShiftsInAreaMap,
}: PlannerRoundsItemProps) {
  const navigate = useNavigate();

  const { popupShownId, setPopupShownId, refresh } = usePlannerContext();

  const numberOfShifts = areaActivityType.activityType.numberOfShifts;
  const numberOfRounds = areaActivityType.activityType.numberOfRounds;

  const shiftIsDone = numberOfShifts >= numberOfRounds && !shift;

  const prevShift = useMemo(() => {
    if (isEmpty(shifts)) {
      return null;
    }

    // note: this works if they shifts are ordered in order of their rounds - we make sure they are.
    const myIndex = shift?.id
      ? (shifts ?? [])!.findIndex((v) => v.id === shift.id)
      : index;

    return shifts[myIndex - 1] ?? null;
  }, [shifts, shift, period?.id]);

  const { minStartDate, isPlannableWithinPeriod } = useMinMaxStartDate({
    shifts,
    period,
    shift,
    areaActivityType,
  });

  function onClick() {
    if (!isPlannableWithinPeriod) {
      return;
    }

    const shiftId = shift?.id ?? "add";

    navigate(
      {
        pathname: __r(PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_OVERLAY, {
          areaActivityTypeId: areaActivityType.id,
          shiftId: shiftId,
        }),
      },
      {
        state: {
          backPath: window.location.pathname + window.location.search,
        },
      },
    );
  }

  const borderClasses = useMemo(() => {
    if (areaActivityType.activityType.unplannedShiftCount === 0) {
      return "";
    }

    return classNames(
      areaActivityType.activityType.legRoom <= 10
        ? "!border-4 !border-yellow-300"
        : "",
      areaActivityType.activityType.legRoom <= 5
        ? "!border-4 !border-orange-300"
        : "",
      areaActivityType.activityType.legRoom <= 0
        ? "!border-4 !border-red-600"
        : "",
    );
  }, [areaActivityType.activityType.legRoom]);

  const classes = useMemo(
    () =>
      classNames(
        shift ? "hover:inner-shadow-md hover:bg-blue-50 cursor-pointer" : "",
        !shift && numberOfShifts >= numberOfRounds
          ? "bg-gray-100 cursor-not-allowed shrink hover:bg-gray-100"
          : "",
        isPlannableWithinPeriod
          ? "hover:inner-shadow-md grow hover:bg-blue-50 cursor-pointer"
          : "bg-gray-100 cursor-not-allowed shrink",
        "flex-1 plannable-item flex relative overflow-visible px-2 pb-1 items-center min-h-[5rem] items-stretch",
        borderClasses,
        isChild
          ? "border-l-4 border-blue-600 !border-t-2 plannable-item-child"
          : "",
      ),
    [shift, isPlannableWithinPeriod, borderClasses],
  );

  return (
    <Pane key={index} className={classes}>
      <Pane onClick={onClick} className="grow">
        {!shift ? (
          <Pane className="flex shrink items-center gap-1 pt-2">
            {shiftIsDone ? (
              <>
                <Text className="!text-xs">
                  Alle diensten zijn gepland. Volgende plandatum zou zijn vanaf:
                </Text>
                {minStartDate && (
                  <Text className="!text-xs">
                    <DateFormat
                      className="!text-xs !font-bold capitalize"
                      date={minStartDate}
                      formatStr="eeeeee d MMM"
                    />
                  </Text>
                )}
              </>
            ) : (
              <>
                {!isPlannableWithinPeriod && (
                  <Text className="!text-xs">
                    De volgende dienst kan pas gepland worden op:{" "}
                  </Text>
                )}
                {minStartDate && (
                  <Text className="!text-xs">
                    <DateFormat
                      className="!text-xs !font-bold capitalize"
                      date={minStartDate}
                      formatStr="eeeeee d MMM"
                    />
                  </Text>
                )}
              </>
            )}
          </Pane>
        ) : (
          <Pane className="relative flex h-full grow gap-4 overflow-hidden py-1">
            <Pane className="flex h-full flex-col items-center justify-between py-1 !text-xs hover:underline">
              <Pane>
                {ShiftStatusIconMap[shift.status] &&
                  createElement(ShiftStatusIconMap[shift.status], {
                    color: shift.isPublished ? "success" : "muted",
                  })}
              </Pane>
              <Pane>
                {createElement(TimeslotIconMap[shift.timeslot.icon], {
                  size: 24,
                })}
              </Pane>
            </Pane>

            <Pane className="flex h-full flex-col"></Pane>

            <Pane className="flex flex-col">
              <Pane className="flex flex-wrap gap-1">
                {shift.workers.map((worker) => (
                  <WorkerItemWithPopover
                    //@ts-ignore
                    color={shift.timeslot?.color}
                    shift={shift}
                    key={"workeritem-" + worker.id}
                    worker={worker}
                    setPopupShownId={setPopupShownId}
                    popupShownId={popupShownId}
                  />
                ))}
              </Pane>

              <Pane className="my-auto flex items-center gap-1">
                <TimeIcon size={12} color={"#676767"} />

                <Heading size={200} className="!text-xs capitalize">
                  <DateValue formatStr="EEEEEE d MMM pp" date={shift.startAt} />{" "}
                  -
                </Heading>
                <Heading size={200} className="!text-xs capitalize">
                  <DateValue formatStr="EEEEEE d MMM pp" date={shift.endAt} />
                </Heading>
              </Pane>
            </Pane>
          </Pane>
        )}
      </Pane>
      <Pane className="flex shrink gap-1 py-1">
        <Pane>
          {allShiftsInArea.length > 0 && !shift && isPlannableWithinPeriod && (
            <CombinePopover
              shift={shift}
              allShiftsInArea={allShiftsInArea}
              areaActivityType={areaActivityType}
              isPlannableWithinPeriod={isPlannableWithinPeriod}
              refresh={refresh}
            />
          )}
          {isChild && shift && <RemoveChild shift={shift} refresh={refresh} />}
        </Pane>
        <ProgressInfo
          shift={shift}
          areaActivityType={areaActivityType}
          allShiftInAreaMap={allShiftsInAreaMap}
          isChild={isChild}
        />
      </Pane>
    </Pane>
  );
}

const ProgressInfo = memo(function ProgressInfo({
  shift,
  areaActivityType,
  allShiftInAreaMap,
  isChild,
}: {
  areaActivityType: AreaActivityType;
  shift: ShiftAugmented | null;
  allShiftInAreaMap: Record<number, ShiftAugmented>;
  isChild: boolean;
}) {
  const { colors } = useTheme();
  const [isShown, setIsShown] = useState<boolean>(false);

  function onClick(e) {
    setIsShown(!isShown);
  }

  function onCloseComplete() {
    setIsShown(false);
  }

  const numberOfShifts = areaActivityType.activityType.numberOfShifts;
  const numberOfRounds = areaActivityType.activityType.numberOfRounds;

  const workers =
    isChild && shift
      ? [
          ...allShiftInAreaMap[shift?.originalParentShiftEntryId]?.workers,
          ...shift?.workers,
        ]
      : shift?.workers;

  return (
    <Pane>
      <Popover
        bringFocusInside
        isShown={isShown}
        position={Position.TOP}
        shouldCloseOnExternalClick={true}
        onClose={onCloseComplete}
        shouldCloseOnEscapePress={true}
        content={
          <Pane
            className="px-4"
            width={240}
            minHeight={200}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <table className="data-table">
              <thead>
                <tr>
                  <th>Topic</th>
                  <th>Waarde</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>urgentie</td>
                  <td>{roundNumber(areaActivityType.activityType.urgency)}</td>
                </tr>
                <tr>
                  <td>totaal te plannen</td>
                  <td>{areaActivityType.activityType.numberOfRounds}</td>
                </tr>
                <tr>
                  <td>gepland</td>
                  <td>{areaActivityType.activityType.numberOfShifts}</td>
                </tr>
                <tr>
                  <td>ongepland</td>
                  <td>{areaActivityType.activityType.unplannedShiftCount}</td>
                </tr>
                <tr>
                  <td>dagen ertussen</td>
                  <td>{areaActivityType.activityType.minDaysBetweenRounds}</td>
                </tr>
                <tr>
                  <td>dagen speling</td>
                  <td>{areaActivityType.activityType.legRoom}</td>
                </tr>
                <tr>
                  <td>start</td>
                  <td>
                    <DateValue
                      date={areaActivityType.activityType.startAt}
                      formatStr="d MMM"
                    />
                  </td>
                </tr>
                <tr>
                  <td>einde</td>
                  <td>
                    <DateValue
                      date={areaActivityType.activityType.endAt}
                      formatStr="d MMM"
                    />
                  </td>
                </tr>
                <tr>
                  <td>dagen tot einde</td>
                  <td>{areaActivityType.activityType.daysUntilDeadline}</td>
                </tr>
              </tbody>
            </table>
          </Pane>
        }
      >
        <a>
          <Pane
            onClick={onClick}
            className="flex justify-center border py-2 px-2"
          >
            <InfoSignIcon color={colors.gray400} />
          </Pane>
        </a>
      </Popover>

      {shift && (
        <Pane>
          <Badge
            color={
              (workers?.length ?? 0) >= areaActivityType.nWorkers
                ? "green"
                : "orange"
            }
            className="!lowercase shadow-md"
          >
            <Pane className="flex items-center gap-1">
              <PersonIcon size={10} />
              <Pane className="flex gap-1">
                <span>{workers?.length ?? 0}</span>
                <span>/</span>
                <span>{areaActivityType.nWorkers}</span>
              </Pane>
            </Pane>
          </Badge>
        </Pane>
      )}
      <Pane>
        <Badge
          color={numberOfShifts >= numberOfRounds ? "green" : "orange"}
          className="min-w-[3rem] !lowercase shadow-md"
        >
          <Pane className="flex items-center gap-1">
            <PercentageIcon size={10} />
            <Pane className="flex gap-1">
              <span>{numberOfShifts}</span>
              <span>/</span>
              <span>{numberOfRounds}</span>
            </Pane>
          </Pane>
        </Badge>
      </Pane>
    </Pane>
  );
});
