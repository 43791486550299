import Page from "src/components/common/Page";
import PageTitle from "src/components/common/PageTitle";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ChevronLeftIcon,
  Heading,
  majorScale,
  Pane,
  Paragraph,
  TrashIcon,
} from "evergreen-ui";
import { useTranslation } from "react-i18next";
import { HookOptions } from "@/types/appTypes";
import { useApi } from "@/context/AxiosContext";
import React, { useEffect, useState } from "react";
import {
  ActivityType,
  Area,
  ObservationFeatureCollection,
  WorkerShift,
} from "@/types/apiTypes";
import { useLoadResource } from "@/lib/request-hooks";
import {
  __r,
  ADMIN_ACTIVITY_TYPE_EDIT_PAGE,
  ADMIN_SUBMITTED_SHIFTS_LIST_PAGE,
  PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_OVERLAY_FULL_URL,
} from "@/RouteMap";
import Divider from "../../components/common/Divider";
import { DateFormat } from "@/components/common/DateFormat";
import Actions from "../../components/common/Actions";
import { TableLoadingSpinner } from "@/components/table/StandardTable";
import Block from "@/components/common/Block";
import { WorkerShiftSummary } from "@/components/shared/worker-shift/WorkerShiftSummary";
import {
  classNames,
  getGeoJsonBoundingBox,
  shortenString,
} from "@/lib/functions";
import { MdPerson, MdWork } from "react-icons/md";
import useObjectsForArea from "@/hooks/useObjectsForArea";
import ApproveWorkerShiftDialog from "@/pages/submitted-worker-shifts/components/ApproveWorkerShiftDialog.component";
import RejectWorkerShiftDialog from "@/pages/submitted-worker-shifts/components/RejectWorkerShiftDialog";
import { format } from "date-fns";
import * as locale from "date-fns/locale/nl";
import ObservationList from "@/pages/submitted-worker-shifts/components/ObservationList";
import useObservation from "@/pages/submitted-worker-shifts/hooks/useObservation";
import { useApp } from "@/context/AppContext";
import StatusUpdatesList from "@/pages/submitted-worker-shifts/components/StatusUpdatesList";
import useForms from "@/pages/submitted-worker-shifts/hooks/useForms";
import { FaExternalLinkAlt } from "react-icons/fa";
import GaiaMap from "@/components/shared/gaiaMap/GaiaMap";
import { GaiaMapSourceType } from "@/components/shared/gaiaMap/GaiaMapSources";
import GaiaMapSource from "@/components/shared/gaiaMap/GaiaMapSource";
import FixReportDataDialog from "./components/FixReportDataDialog";

const useWorkerShift = function (options: HookOptions = {}) {
  const { shiftId, workerId } = useParams();
  const { apiInstance } = useApi();

  const [workerShift, setWorkerShift] = useState<WorkerShift>();
  const request = () =>
    apiInstance!.adminWorkerShifts.findOneForWorker(workerId!, shiftId!);

  const setData = (workerShift: WorkerShift) => {
    setWorkerShift(workerShift);
  };

  return {
    ...useLoadResource(request, setData, true),
    workerShift,
  };
};

function useObservations() {
  const { apiInstance } = useApi();
  const { shiftId, workerId } = useParams();

  const [observations, setObservations] =
    useState<ObservationFeatureCollection | null>(null);

  const request = () =>
    apiInstance!.adminWorkerShiftObservation.findAllForShift(
      shiftId!,
      workerId!,
      {
        asGeoJson: true,
      },
    );

  const setData = (reports) => {
    setObservations(reports);
  };

  return {
    ...useLoadResource(request, setData, true),
    observations,
  };
}

function useArea({ areaId }) {
  const { apiInstance } = useApi();

  const [area, setArea] = useState<Area | null>(null);

  const request = () => apiInstance!.adminAreas.findOne(areaId!);

  const setData = (area: Area) => {
    setArea(area);
  };

  return {
    ...useLoadResource(request, setData, !!areaId),
    area,
  };
}

export default function SubmittedWorkerShiftDetailPage() {
  const { forms } = useForms();

  const location = useLocation();
  const { backPath } = location?.state ?? {};
  const { setLayoutProps } = useApp();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setLayoutProps({
      overflowY: "auto",
    });
  }, []);

  const {
    isLoading,
    workerShift,
    refresh: refreshWorkerShift,
  } = useWorkerShift();

  const {
    observations,
    refresh: refreshObservations,
    refreshCount,
  } = useObservations();

  const observationApi = useObservation({
    onUpdated: doRefresh,
  });

  const { area } = useArea({
    areaId: workerShift?.shift.area?.id,
  });

  const { objects, geoObjects } = useObjectsForArea({ areaId: area?.id });

  function doRefresh() {
    refreshObservations();
    refreshWorkerShift();
  }

  const [fixDialogIsShown, setfixDialogIsShown] = useState<boolean>(false);

  return (
    <Page>
      {isLoading && refreshCount === 0 && (
        <TableLoadingSpinner title="Dienst aan het ophalen..." />
      )}
      <PageTitle
        actions={
          <Actions>
            <Link
              to={{
                // pathname: __r(PLANNER_PERIOD_PLANNING_PAGE, {
                //   periodId: workerShift?.periodId,
                // }),
                pathname: __r(
                  PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_OVERLAY_FULL_URL,
                  {
                    periodId: workerShift?.periodId,
                    areaActivityTypeId:
                      workerShift?.shift.areaActivityTypes[0].id,
                    shiftId: workerShift?.shiftEntryId,
                  },
                ),
              }}
            >
              <Button title={"Open in planner"} type="button">
                <FaExternalLinkAlt className="mx-1" />
                Open in Planner
              </Button>
            </Link>
          </Actions>
        }
        backPath={backPath ?? ADMIN_SUBMITTED_SHIFTS_LIST_PAGE}
      >
        Report
        <Actions></Actions>
      </PageTitle>

      <Block className="flex min-h-[300px] gap-4">
        <Block className="flex-[3]">
          {workerShift && (
            <>
              <FixReportDataDialog
                isShown={!!fixDialogIsShown}
                close={() => setfixDialogIsShown(false)}
                afterSubmit={() => {
                  setfixDialogIsShown(false);
                  doRefresh();
                }}
                workerShift={workerShift}
              />
              <WorkerShiftDetail
                workerShift={workerShift}
                refresh={doRefresh}
              />
            </>
          )}

          <Block className="flex w-full flex-col gap-2">
            <Block className="flex-1">
              {workerShift && area && (
                <GaiaMap initialBounds={getGeoJsonBoundingBox(area?.geometry)}>
                  <GaiaMapSource
                    sourceType={GaiaMapSourceType.Areas}
                    data={area?.geometry || null}
                    shouldFitBounds={false}
                  />
                  <GaiaMapSource
                    sourceType={GaiaMapSourceType.Objects}
                    data={geoObjects}
                    shouldFitBounds={false}
                  />
                  <GaiaMapSource
                    sourceType={GaiaMapSourceType.Observations}
                    data={observations}
                    shouldFitBounds={false}
                  />
                </GaiaMap>
              )}
            </Block>
            <Block className="flex-shrink">
              {area && <AreaStaticListItem area={area} />}
            </Block>
          </Block>

          <Pane minHeight={300}>
            {observations && area && (
              <ObservationList
                observationApi={observationApi}
                objects={objects}
                observations={observations}
                area={area}
                forms={forms}
              />
            )}
          </Pane>
        </Block>

        <Block className="min-w-[14rem] flex-[1] px-4">
          <StatusUpdatesList
            forms={forms}
            statusUpdates={workerShift?.statusUpdates}
          />
          <Button className="mt-8" onClick={() => setfixDialogIsShown(true)}>
            Fix shift start/end status
          </Button>
        </Block>
      </Block>
    </Page>
  );
}

export function WorkerShiftDetail({ workerShift, refresh }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const location = useLocation();
  const { backPath } = location?.state ?? {};

  const [approveDialogIsShown, setApproveDialogIsShown] =
    useState<boolean>(false);

  const [rejectDialogStatus, setRejectDialogStatus] = useState<string>("");

  return (
    <Block>
      <ApproveWorkerShiftDialog
        isShown={approveDialogIsShown}
        close={() => setApproveDialogIsShown(false)}
        afterSubmit={() => {
          setApproveDialogIsShown(false);
          refresh();
        }}
        workerShift={workerShift}
      />

      <RejectWorkerShiftDialog
        isShown={!!rejectDialogStatus}
        close={() => setRejectDialogStatus("")}
        status={rejectDialogStatus}
        afterSubmit={() => {
          setRejectDialogStatus("");
          refresh();
        }}
        workerShift={workerShift}
      />

      <Actions className="py-2">
        <Actions.Button
          iconBefore={ChevronLeftIcon}
          onClick={() => navigate(backPath ?? ADMIN_SUBMITTED_SHIFTS_LIST_PAGE)}
          height={majorScale(5)}
        >
          {t("common.go_back")}
        </Actions.Button>

        <Button
          onClick={() => setRejectDialogStatus("cancelled")}
          iconBefore={<TrashIcon />}
          appearance={
            workerShift.status === "cancelled" ? "primary" : "default"
          }
          intent="danger"
          marginLeft="auto"
          height={majorScale(5)}
        >
          Annuleren
        </Button>

        <Actions.Button
          onClick={() => setRejectDialogStatus("invalid")}
          height={majorScale(5)}
          appearance={workerShift.status === "rejected" ? "primary" : "default"}
          intent="danger"
          className={
            workerShift.status === "invalid"
              ? "!bg-yellow-700 !text-white"
              : "!text-white-700 !border-yellow-700"
          }
        >
          {t("shifts.setStatus.invalid")}
        </Actions.Button>

        <Actions.Button
          onClick={() => setRejectDialogStatus("changesRequested")}
          className={
            workerShift.status === "changesRequested"
              ? "!bg-blue-700 !text-white"
              : "!border-blue-700 !text-blue-700"
          }
          height={majorScale(5)}
        >
          {t("shifts.setStatus.changesRequested")}
        </Actions.Button>

        <Actions.Button
          onClick={() => setApproveDialogIsShown(true)}
          height={majorScale(5)}
          className={
            workerShift.status === "approved"
              ? "!bg-green-600 !text-white"
              : "!border-green-600 !text-green-600"
          }
        >
          Goedkeuren
        </Actions.Button>
      </Actions>

      <WorkerShiftSummary
        showProject={true}
        showWorker={true}
        workerShift={workerShift}
      />
      <Divider title="Geplande dienstijden">
        <Pane className="flex gap-2">
          <Pane
            title={
              workerShift.startAt &&
              format(new Date(workerShift.shift.startAt), "PPPPp", {
                locale: locale.default,
              })
            }
          >
            <Heading size={300}>Starttijd:</Heading>
            {workerShift.startAt && (
              <DateFormat
                size={400}
                formatStr="dd-MM-yyyy p"
                date={new Date(workerShift.shift.startAt)}
              />
            )}
          </Pane>

          <Pane
            title={
              workerShift.endAt &&
              format(new Date(workerShift.shift.endAt), "PPPPp", {
                locale: locale.default,
              })
            }
          >
            <Heading size={300}>Eindtijd:</Heading>
            {workerShift.endAt && (
              <DateFormat
                size={400}
                formatStr="dd-MM-yyyy p"
                date={new Date(workerShift.shift.endAt)}
              />
            )}
          </Pane>
        </Pane>
      </Divider>

      <Divider title="Observatietijd">
        <Pane className="flex gap-2">
          <Pane
            title={
              workerShift.startedAt &&
              format(new Date(workerShift.startedAt), "PPPPp", {
                locale: locale.default,
              })
            }
          >
            <Heading size={300}>Starttijd:</Heading>
            {workerShift.startedAt && (
              <DateFormat
                size={400}
                formatStr="dd-MM-yyyy p"
                date={new Date(workerShift.startedAt)}
              />
            )}
          </Pane>

          <Pane
            title={
              workerShift.endedAt &&
              format(new Date(workerShift.endedAt), "PPPPp")
            }
          >
            <Heading size={300}>Eindtijd:</Heading>
            {workerShift.endedAt && (
              <DateFormat
                size={400}
                formatStr="dd-MM-yyyy p"
                date={new Date(workerShift.endedAt)}
              />
            )}
          </Pane>
        </Pane>
      </Divider>

      <Divider title="Bestanden">
        {/*{editMode === true && (*/}
        {/*  <FormField>*/}
        {/*    <MultiFileUploader*/}
        {/*      uploadFn={() => {}}*/}
        {/*      mediaFiles={[]}*/}
        {/*      setMediaFiles={(v) => {}}*/}
        {/*    />*/}
        {/*  </FormField>*/}
        {/*)}*/}

        {/*{editMode === false && (*/}
        {/*  <FormField label={"Attached media"}>*/}
        {/*    <AttachedShiftReportMediaFiles*/}
        {/*      editMode={false}*/}
        {/*      mediaFiles={[]}*/}
        {/*      setMediaFiles={(v) => {}}*/}
        {/*    />*/}
        {/*  </FormField>*/}
        {/*)}*/}
      </Divider>
    </Block>
  );
}

type AreaStaticListItemProps = {
  area: Area;
  objects?: Object[];
};

function AreaStaticListItem({ area, objects }: AreaStaticListItemProps) {
  return (
    <div
      className={classNames(
        "mb-2 min-h-[8rem] rounded border-b-2 border-l-2 border-r-2 border-t p-2 py-4",
      )}
    >
      <Block>
        <Block className="flex flex-col gap-2">
          <Block className="flex max-w-lg flex-grow justify-between gap-2 pr-2">
            <Heading size={500}>{area.name}</Heading>
          </Block>

          <Divider title={"Activities"}>
            <ActivityTypeList
              activityTypes={area.activityTypes}
              projectIdOverride={area.projectId}
            />
          </Divider>

          {/* <Divider title={"Objecten"}>
            <ObjectList
              listClasses={"flex-col max-w-[20rem]"}
              // onClickObject={onClickObject}
              objects={area.objects}
            />
          </Divider> */}

          {/* <Divider title="Details" defaultIsOpen={false}>
            <FormField label="Notities:">
              <Paragraph>{area.notes ?? "-"}</Paragraph>
            </FormField>
            <Block>
              <FormField label="Bestanden" className="max-w-[30rem]">
                {area.media.length === 0 ? (
                  <Paragraph>-</Paragraph>
                ) : (
                  <Collapse
                    title={`${area.media.length} Bestand(en)`}
                    defaultIsOpen={false}
                  >
                    <AttachedMediaFiles
                     mediaFiles={mediaFiles}
                    maxLengthFileName={40}
                    editMode={editMode}
                   onRemove={onRemoveMedia}
                    />
                  </Collapse>
                )}
              </FormField>
            </Block>
          </Divider> */}
        </Block>
      </Block>
    </div>
  );
}

function ActivityTypeList({
  activityTypes,
  projectIdOverride,
}: {
  activityTypes: ActivityType[];
  projectIdOverride?: number;
}) {
  const { projectId: projectIdParam } = useParams();

  const projectId = projectIdOverride ?? projectIdParam;

  return (
    <Block className="flex flex-wrap gap-2">
      {activityTypes.length === 0 && <Paragraph>-</Paragraph>}

      {activityTypes.map((activityType) => (
        <Link
          key={activityType.id}
          to={__r(ADMIN_ACTIVITY_TYPE_EDIT_PAGE, {
            projectId,
            activityTypeId: activityType.id,
          })}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Block className="border hover:border-gray-400 hover:bg-gray-100">
            <Block className="flex cursor-pointer items-center border">
              <Block className="px-1">
                <MdWork color={"#7c7c7c"} size={15} />
              </Block>
              <Block className="flex-nowrap border-l px-1">
                <Heading title={activityType.name} size={200}>
                  {shortenString(activityType.name, 20)}
                </Heading>
              </Block>
              <Paragraph>|</Paragraph>
              <Block className="flex flex-nowrap items-center px-1">
                <MdPerson color={"#7c7c7c"} size={15} />
                <Paragraph className="px-1">
                  {/*@ts-ignore*/}
                  {activityType.nWorkers}
                </Paragraph>
              </Block>
            </Block>
          </Block>
        </Link>
      ))}
    </Block>
  );
}
