import { TableOfContentsComponent } from "@/types/apiTypes";
import { HTMLComponent } from "./HTMLComponent";
import { pdfReportTableOfContentsStyles } from "./styles/pdfReportContentStyles";

export const PdfPreviewTableOfContentsComponent = ({
  tableOfContentsComponent,
}: {
  tableOfContentsComponent: TableOfContentsComponent;
}) => {
  return (
    <HTMLComponent
      styles={pdfReportTableOfContentsStyles}
      content={tableOfContentsComponent.content}
    />
  );
};
