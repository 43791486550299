import axios from "axios";

export default function useMapboxAddressSearch() {
  async function searchAddress(address: string) {
    const searchText = encodeURIComponent(address.trim().replace(";", ""));
    const response = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json`,
      {
        params: {
          access_token: process.env.REACT_APP_MAPBOX_API_KEY,
          session_token: "testing-session",
          autocomplete: false,
          country: "NL",
          limit: 1,
          types: "address",
        },
      },
    );

    const features = response.data.features[0];

    if (!features) {
      throw new Error("Address not found");
    }

    return {
      coordinates: features.center,
      relevance: features.relevance,
      address: {
        city: features.context.find((context) => context.id.includes("place"))
          .text,
        country: features.context.find((context) =>
          context.id.includes("country"),
        ).text,
        countryCode: features.context.find((context) =>
          context.id.includes("country"),
        ).short_code,
        formattedAddress: features.place_name,
        mapBoxId: features.properties.mapbox_id,
        lng: features.center[0],
        lat: features.center[1],
        number: features.address,
        postalCode: features.context.find((context) =>
          context.id.includes("postcode"),
        ).text,
        province: features.context.find((context) =>
          context.id.includes("region"),
        ).text,
        street: features.text,
        language: features.language,
      },
    };
  }

  return {
    searchAddress,
  };
}
