import { memo } from "react";

const MoonIconSvg = (props) => {
  const { size = 24 } = props;

  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511.999 511.999"
      xmlSpace="preserve"
      {...props}
    >
      <path
        style={{
          fill: "#0069a3",
        }}
        d="M435.177 196.528c-.467 0-.928.025-1.393.035.45-3.69.687-7.445.687-11.257 0-50.762-41.151-91.913-91.913-91.913-31.99 0-60.158 16.346-76.62 41.138-13.31-6.382-28.223-9.958-43.971-9.958-56.244 0-101.839 45.595-101.839 101.839s45.595 101.839 101.839 101.839h213.209c36.375 0 65.862-29.487 65.862-65.862s-29.486-65.861-65.861-65.861z"
      />
      <path
        style={{
          opacity: 0.1,
        }}
        d="M450.258 313.171H237.049c-56.244 0-101.839-45.595-101.839-101.839 0-24.281 8.502-46.573 22.686-64.072-23.037 18.67-37.767 47.19-37.767 79.153 0 56.244 45.595 101.839 101.839 101.839h213.209c22.037 0 41.532-10.834 53.488-27.455-10.814 7.778-24.07 12.374-38.407 12.374zM308.463 161.436c-3.976-16.87-13.065-32.797-25.662-45.954a92.39 92.39 0 0 0-16.862 19.049c-.457-.22 24.537 12.728 36.718 29.74 2.07 2.891 6.622.625 5.806-2.835z"
      />
      <path
        style={{
          fill: "#f5df5d",
        }}
        d="M377.874 388.198c-107.911.868-196.836-86.584-197.689-194.495-.624-78.792 45.231-146.938 111.785-178.674 6.792-3.238 5.084-13.383-2.413-14.041a258.55 258.55 0 0 0-24.765-.979C123.937 1.182 9.893 117.14 10.968 257.995c1.073 140.466 115.274 254.004 255.993 254.004 103.792 0 193.141-61.774 233.325-150.557 3.1-6.85-4.814-13.453-10.953-9.112-31.545 22.307-69.963 35.535-111.459 35.868z"
      />
      <path
        style={{
          opacity: 0.1,
        }}
        d="M283.74 495.221c-140.719 0-254.92-113.538-255.993-254.004-.511-66.951 24.998-128.264 67.02-174.311-51.912 47.348-84.375 115.641-83.799 191.09C12.041 398.462 126.242 512 266.961 512c74.937 0 142.34-32.205 189.152-83.523-45.486 41.46-105.974 66.744-172.373 66.744z"
      />
      <g
        style={{
          opacity: 0.05,
        }}
      >
        <path d="M76.099 341.846c-16.814 0-32.138 6.373-43.694 16.833 17.775 40.545 45.743 75.603 80.677 101.919 17.006-11.753 28.155-31.377 28.155-53.613 0-35.976-29.163-65.139-65.138-65.139z" />
        <circle cx={110.268} cy={248.802} r={20.023} />
        <path d="M384.134 388.036c-2.612.104-5.232.169-7.869.169-35.334 0-68.482-9.351-97.114-25.705a55.672 55.672 0 0 0-6.83 26.795c0 30.885 25.037 55.922 55.922 55.922s55.922-25.037 55.922-55.922c.001-.422-.021-.84-.031-1.259zM112.47 51.871A257.424 257.424 0 0 0 54.001 113.9c7.491 6.516 17.271 10.469 27.979 10.469 23.563 0 42.667-19.102 42.667-42.667-.001-11.613-4.647-22.138-12.177-29.831z" />
      </g>
    </svg>
  );
};

const MoonIcon = memo(MoonIconSvg);
export default MoonIcon;
