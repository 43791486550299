import { useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "@/context/AxiosContext";
import { useLoadResource } from "@/lib/request-hooks";
import { Report, ReportData } from "@/types/apiTypes";

export function useClientReport() {
  const { reportId, projectId } = useParams();
  const { apiInstance } = useApi();
  const [reportData, setReportData] = useState<Report>();
  const [projectData, setProjectData] = useState<ReportData>();
  // const [mediaUrls, setMediaUrls] = useState<string[]>();

  //   // const mediaURLsRequest = () =>
  //   //   apiInstance!.adminReports.getProjectMediaUrls(reportId!, [
  //   //     "420ec951-1c4b-4e35-9cc0-d1a22775d739",
  //   //     "3e8ef893-8c40-4b49-b9b8-058d42d16d94",
  //   //   ]);
  const projectDataRequest = () =>
    apiInstance!.adminReports.getProjectDataNeededForReport(projectId!);

  const reportRequest = () => apiInstance!.adminReports.findOne(reportId!);

  return {
    // ...useLoadResource(mediaURLsRequest, setMediaUrls),
    ...useLoadResource(reportRequest, setReportData),
    ...useLoadResource(projectDataRequest, setProjectData),
    reportData,
    projectData,
  };
}
