import React, { memo, useState } from "react";
import { IconButton, TrashIcon, useTheme } from "evergreen-ui";
import { ShiftAugmented } from "@/pages/planner/_components/PlannerRoundsItem";
import { useApi } from "@/context/AxiosContext";
import { Id } from "@/types/apiTypes";

function useRemoveChild() {
  const { apiInstance } = useApi();
  const [isSubmitting] = useState(false);

  async function removeChild(shiftId: Id) {
    return apiInstance?.adminShifts.removeChildShift(shiftId);
  }

  return {
    removeChild,
    isSubmitting,
  };
}

const RemoveChild = memo(function RemoveChild({
  shift,
  refresh,
}: {
  shift: ShiftAugmented;
  refresh: () => void;
}) {
  const { colors } = useTheme();
  const { removeChild, isSubmitting } = useRemoveChild();

  async function onClick() {
    await removeChild(shift.id!);
    refresh();
  }

  return (
    <IconButton
      title="Disconnect shift from its parent"
      className="!cursor-pointer"
      onClick={onClick}
      isLoading={isSubmitting}
      icon={!isSubmitting && <TrashIcon color={colors.red500} />}
      type="button"
    />
  );
});

export default RemoveChild;
