import {
  Alert,
  Button,
  Card,
  FormField,
  Heading,
  majorScale,
  TextInputField,
} from "evergreen-ui";
import Block from "../../components/common/Block";
import { useState } from "react";
import { useApi } from "../../context/AxiosContext";
import { useAuth } from "../../context/AuthContext";
import { useDoRequest } from "../../lib/request-hooks";
import Form from "../../components/common/Form";

export default function LoginPage() {
  const { handle, isLoading, errorMessage } = useDoRequest();

  const { apiInstance } = useApi();
  const { setJwt, setUser } = useAuth();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const submit = async () => {
    const request = apiInstance!.auth.login({ identifier: email, password });

    const { jwt, user } = await handle(request);

    setJwt!(jwt);
    setUser!(user);
  };

  return (
    <Block className={"mx-auto max-w-[25rem]"}>
      <Block className="my-20 max-w-sm">
        <img height="100px" src={"/logo.png"} alt="logo" />
      </Block>
      <Card elevation={1} className={"p-8"}>
        <Form onSubmit={submit}>
          <Block className={"align-items flex flex-col justify-center gap-5"}>
            <Heading>Gaia Ecologie Portal</Heading>
            <Block className="flex flex-col">
              <FormField>
                <TextInputField
                  marginBottom={0}
                  inputHeight={40}
                  name="email"
                  label=""
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormField>
              <FormField label="">
                <TextInputField
                  marginBottom={0}
                  inputHeight={40}
                  label=""
                  name="password"
                  placeholder="Password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormField>
            </Block>

            {errorMessage && (
              <Alert intent="danger" title={errorMessage} marginBottom={32} />
            )}

            <Block className={"flex justify-end"}>
              <Button
                type="submit"
                onClick={submit}
                isLoading={!!isLoading}
                appearance="primary"
                height={majorScale(5)}
              >
                Login
              </Button>
            </Block>
          </Block>
        </Form>
      </Card>
    </Block>
  );
}
