import { useParams } from "react-router-dom";
import { useApi } from "@/context/AxiosContext";
import { AreaActivityType, Shift } from "@/types/apiTypes";
import { useDoRequest, useLoadResource } from "@/lib/request-hooks";
import { useState } from "react";
import { toaster } from "evergreen-ui";
import { formatISO, parseISO } from "date-fns";

export default function useShiftForm({ setAttachedWorkers, attachedWorkers }) {
  const { apiInstance } = useApi();

  const { areaActivityTypeId, shiftId, periodId } = useParams();
  const [areaActivityType, setAreaActivityType] =
    useState<AreaActivityType | null>(null);

  const [date, setDate] = useState<Date | null>(null);
  const [shift, setShift] = useState<Shift | null>(null);
  const [timeslotUid, setTimeslotUid] = useState<string>("");
  const [ignoreTravelTime, setIgnoreTravelTime] = useState<boolean>(false);

  const submitContext = useDoRequest();

  const setData = (shift: Shift) => {
    setDate(parseISO(shift.date));
    setShift(shift);
    setTimeslotUid(shift.timeslotUid);
    setAttachedWorkers(shift.workers);
    setIgnoreTravelTime(shift.ignoreTravelTime);
  };

  const doSubmit = async () => {
    const data = {
      date: formatISO(date!),
      timeslotUid,
      workers: attachedWorkers,
      periodId: parseInt(periodId!),
      areaActivityTypeId: parseInt(areaActivityTypeId!),
      ignoreTravelTime,
    };

    let request;

    if (shiftId) {
      request = apiInstance!.adminShifts.update(shiftId, data);
    } else {
      request = apiInstance!.adminShifts.create(data);
    }

    const result = await submitContext.handle(request);

    toaster.success("Saved!");

    return result;
  };

  const request = () => apiInstance!.adminShifts.findOne(shiftId!);

  return {
    ...useLoadResource(request, setData, !!shiftId),
    date,
    setDate: setDate,
    timeslotUid,
    setTimeslotUid,
    areaActivityType,
    submitContext,
    doSubmit,
    shift,
    ignoreTravelTime,
    setIgnoreTravelTime,
  };
}
