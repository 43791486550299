import {
  Alert,
  BacklinkIcon,
  Card,
  IconButton,
  Link,
  LogOutIcon,
  Pane,
  Paragraph,
} from "evergreen-ui";
import ProfilePictureUploader from "../../components/common/ProfilePictureUploader";
import WorkerDetailsForm, {
  useWorkerDetailsForm,
} from "../../components/shared/WorkerDetailsForm";
import { useAuth } from "../../context/AuthContext";
import { useApi } from "../../context/AxiosContext";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

export default function WorkerDetailsOnBoardingForm() {
  const { apiInstance } = useApi();
  const { user, setJwt, setUser } = useAuth();
  const { t } = useTranslation();

  const detailsForm = useWorkerDetailsForm(user?.worker);

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (user!.profilePicture) {
      setValidationErrors({});
    }
  }, [user?.profilePicture]);

  const doSubmit = detailsForm.doSubmit;

  const submit = async () => {
    if (!user!.profilePicture) {
      setValidationErrors({
        ...validationErrors,
        file: [t("worker_details_onboarding_page.profile_picture_required")],
      });

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      return;
    }

    await doSubmit();

    const { userUpdated } = await apiInstance!.auth.profile();
    setUser!(userUpdated);
  };

  detailsForm.doSubmit = submit;

  const logout = () => {
    setJwt!(null);
    setUser!(null);
  };

  return (
    <Card elevation={1} className="max-w-2xl p-4 sm:p-8">
      <Pane className="flex justify-end">
        <Pane onClick={logout} className="flex flex-col items-center">
          <IconButton appearance="minimal" icon={LogOutIcon} type="button" />
          <Link
            color="neutral"
            className="cursor-pointer !text-xs hover:underline"
          >
            {t("common.logout")}
          </Link>
        </Pane>
      </Pane>

      <Pane className="justify-center pb-10">
        {/*@ts-ignore*/}
        <ProfilePictureUploader validationErrors={validationErrors} />
        <Paragraph className="py-2 !text-xs" color="muted">
          {t("worker_details_onboarding_page.profile_picture_hint")}
        </Paragraph>
      </Pane>

      <Pane className="p-4 sm:p-8">
        <WorkerDetailsForm useForm={detailsForm} />
      </Pane>

      {!isEmpty(validationErrors) && (
        <Alert
          intent="danger"
          title={t("worker_details_onboarding_page.profile_picture_required")}
          marginBottom={32}
        />
      )}
    </Card>
  );
}
