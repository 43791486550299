import { useApi } from "../../context/AxiosContext";
import { useState } from "react";
import { useLoadResource } from "../../lib/request-hooks";
import { WorkerShift } from "../../types/apiTypes";
import Page from "../../components/common/Page";
import { useParams } from "react-router-dom";
import { Spinner } from "evergreen-ui";
import { HookOptions } from "../../types/appTypes";
import useTimeslotResources from "../../hooks/useTimeslotResources";
import { WORKER_SHIFTS_PLANNED_PAGE } from "../../RouteMap";
import PageTitle from "../../components/common/PageTitle";
import { WorkerShiftDetails } from "./_components/WorkerShiftDetails";
import { TableLoadingSpinner } from "../../components/table/StandardTable";
import { useWorkerShift } from "@/pages/worker-shifts/hooks/useWorkerShift";

export default function WorkerShiftDetailPage() {
  const { workerShift, isLoading } = useWorkerShift();
  const { timeslotMapById } = useTimeslotResources();

  return (
    <Page>
      <PageTitle backPath={WORKER_SHIFTS_PLANNED_PAGE}>Details</PageTitle>

      {isLoading && <TableLoadingSpinner />}

      {isLoading === false && workerShift && (
        <WorkerShiftDetails
          key={workerShift.id}
          elevation={0}
          workerShift={workerShift}
          onClick={() => {}}
          timeslotMapById={timeslotMapById}
          showMap={true}
          showPassengerTable={true}
          showDetailsLink={false}
        />
      )}
    </Page>
  );
}
