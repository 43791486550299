import {
  Alert,
  Checkbox,
  Dialog,
  FormField,
  Pane,
  Select,
  toaster,
} from "evergreen-ui";
import Form from "../../components/common/Form";

import { useApi } from "../../context/AxiosContext";
import { useState } from "react";
import { useDoRequest } from "../../lib/request-hooks";
import DateRangeInput from "@/components/formfields/DateRangeInput";
import { useParams } from "react-router-dom";
import { dateWithoutTimezone } from "@/components/common/DateFormat";

type FormValues = {
  exporter: string,
  species: string[],
  startDate?: Date,
  endDate?: Date,
}

export enum Species {
  bat = "Bat",
  birds_of_prey = "Birds of prey",
  sparrow = "Sparrow",
  swallow = "Swallow",
}

export enum Exporters {
  default = "Gaia default",
  tauw = "Tauw",
  ecoresult = "Ecoresult",
}

const defaultValues = {
  exporter: Object.keys(Exporters)[0],
  species: Object.keys(Species),
  startDate: undefined,
  endDate: undefined,
}

export default function ProjectsExportCreateDialog({ isOpen, close }) {
  const { projectId } = useParams();
  const { apiInstance } = useApi();
  const [values, setValues] = useState<FormValues>(defaultValues);

  const { handle, errorMessage, validationErrors, setValidationErrors, isLoading } = useDoRequest();

  const createExport = async () => {
    const request = apiInstance!.adminProjectExports.createForProject(projectId!, values);
    await handle(request);

    toaster.success("Export created!");

    closeDialog()
  };

  function closeDialog() {
    setValues(defaultValues);
    close();
  }

  function updateField(fieldName: string, value: any) {
    const date = new Date()
    setValues((current) => ({ 
      ...current,
      [fieldName]: fieldName === "startDate" || fieldName === "endDate" ? 
      dateWithoutTimezone(value).split("T")[0]
      : value
    }))
  }

  function isSpeciesSelected(current: FormValues, species: string) {
    return current.species.find((selected) => selected === species) !== undefined
  }

  function toggleSpecies(value: string) {
    setValues((current) => {
      return {
        ...current,
        species: isSpeciesSelected(current, value) ? (
          current.species.filter((selected) => selected !== value)
        ) : (
          [...current.species, value]
        )
      }
    })
  }

  return (
    <Dialog
      isShown={isOpen}
      title="Export data"
      onConfirm={() => createExport()}
      isConfirmLoading={isLoading === true}
      onCloseComplete={closeDialog}
      onCancel={() => {
        closeDialog();
      }}
      confirmLabel="Create export"
    >
      {({ close }) => (
        <Pane>
          <Pane className="py-5">
            <Form onSubmit={createExport}>
              <FormField
                required
                validationMessage={validationErrors?.export?.join(", ")}
                label="Exporter"
              >
                <Select
                  value={values.exporter}
                  onChange={(e) => updateField("exporter", e.target.value)}
                  width={240}
                  isInvalid={!!validationErrors?.exporter}
                  height={40}
                >
                  {Object.keys(Exporters).map((exporter) => (
                    <option key={exporter} value={exporter}>
                      {Exporters[exporter]}
                    </option>
                  ))}
                </Select>
              </FormField>
              <FormField
                required
                validationMessage={validationErrors?.species?.join(", ")}
                label="Species"
              >
                {Object.keys(Species).map((species) => (
                  <Checkbox
                    key={species}
                    label={Species[species]}
                    checked={isSpeciesSelected(values, species)}
                    onChange={(e) => toggleSpecies(species)}
                  />
                ))}
              </FormField>
              <FormField label="Start / einddatum">
                <DateRangeInput
                  validationErrors={validationErrors}
                  setValidationErrors={setValidationErrors}
                  fromValue={values.startDate}
                  setFromValue={(value) => updateField("startDate", value)}
                  toValue={values.endDate}
                  setToValue={(value) => updateField("endDate", value)}
                />
              </FormField>
            </Form>

            {errorMessage && (
              <Pane>
                <Alert marginTop={10} intent="danger" title={errorMessage} />
              </Pane>
            )}
          </Pane>
        </Pane>
      )}
    </Dialog>
  );
}
