import { Pane } from "evergreen-ui";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { __r, ADMIN_SUBMITTED_SHIFTS_LIST_PAGE } from "../../RouteMap";
import TopSubNav from "../common/TopSubNav";
import { BsBinoculars } from "react-icons/bs";

export default function SubmittedWorkerShiftsLayout() {
  const { t } = useTranslation();

  const menuItems = [
    {
      label: t("admin_submitted_shifts_page.title"),
      pathname: __r(ADMIN_SUBMITTED_SHIFTS_LIST_PAGE),
      icon: <BsBinoculars />,
    },
  ];

  return (
    <Pane className="h-full">
      <TopSubNav menuItems={menuItems} />

      <Pane className="h-full">
        <Outlet />
      </Pane>
    </Pane>
  );
}
