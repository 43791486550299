import { Dialog, Pane, Text, TextareaField, toaster } from "evergreen-ui";
import { useApi } from "@/context/AxiosContext";
import { useState } from "react";
import { useDoRequest } from "@/lib/request-hooks";
import { WorkerShift } from "@/types/apiTypes";
import Form from "@/components/common/Form";

export function useApproveWorkerShiftForm({ workerShift, status }) {
  const { apiInstance } = useApi();

  const [notes, setNotes] = useState<string>(workerShift.notes);

  const {
    handle,
    setIsLoading: setIsSubmitting,
    isLoading: isSubmitting,
    validationErrors,
    errorMessage: submitErrorMessage,
  } = useDoRequest();

  const doSubmit = async () => {
    const data = [
      {
        notes: notes ?? null,
        status: status,
        shiftId: workerShift.id,
        createdAt: new Date(),
      },
    ];

    const request = apiInstance!.workerShifts.statusUpdate(
      workerShift.id!,
      data,
    );

    await handle(request);

    toaster.success("Ingediend!");
  };

  return {
    submitContext: {
      doSubmit,
      setIsSubmitting,
      isSubmitting,
      submitErrorMessage,
      validationErrors,
    },
    notes,
    setNotes,
    doSubmit,
  };
}

export default function WorkerShiftSubmitDialog({
  workerShift,
  isShown,
  close,
  afterSubmit,
  status,
}: {
  workerShift: WorkerShift;
  isShown: boolean;
  close: () => void;
  afterSubmit: () => void;
  status;
}) {
  const { submitContext, notes, setNotes, doSubmit } =
    useApproveWorkerShiftForm({
      workerShift,
      status,
    });

  async function onConfirm() {
    await doSubmit();
    setNotes("");

    afterSubmit();
  }

  return (
    <Dialog
      confirmLabel="Indienen"
      intent="success"
      isShown={isShown}
      onCloseComplete={close}
      onConfirm={onConfirm}
      shouldCloseOnOverlayClick={false}
      title="Dienst indienen voor goedkeuring"
    >
      <Pane>
        <Form onSubmit={onConfirm}>
          <>
            <Text className="py-4">
              Geef evt. een extra notitie op voor de beoordeelaar.
            </Text>
            <TextareaField
              autoFocus
              name="notes"
              label="Notities"
              placeholder="..."
              isInvalid={!!submitContext.validationErrors?.notes}
              validationMessage={submitContext.validationErrors?.notes?.join(
                ", ",
              )}
              value={notes || ""}
              onChange={(e) => setNotes(e.target.value)}
              marginBottom={0}
              inputHeight={40}
              className="!text-base"
            />
          </>
        </Form>
      </Pane>
    </Dialog>
  );
}
