import { Heading, Pane } from "evergreen-ui";
import { Form, WorkerShiftStatusUpdate } from "@/types/apiTypes";
import React, { createElement, useMemo } from "react";
import DateValue from "@/components/common/DateValue";
import { classNames } from "@/lib/functions";
import Block from "@/components/common/Block";
import {
  ShiftStatusColorMap,
  ShiftStatusIconMap,
} from "@/components/config/iconMaps";
import { useTranslation } from "react-i18next";

type ObservationItemProps = {
  form?: Form;
  status: WorkerShiftStatusUpdate;
};

const statusColorMap = {
  planned: "text-[#4299e1]",
  started: "text-[#b9e142]",
  stopped: "text-[#f56565]",
  cancelled: "text-[#a0aec0]",
  rejected: "text-[#ef0b0b]",
  submitted: "text-[#f6ad55]",
  approved: "text-[#48bb78]",
};

export default function StatusListItem({ status, form }: ObservationItemProps) {
  const { t } = useTranslation();
  const questionsMap = useMemo(() => {
    const questionsMap = new Map();

    if (!form) {
      return questionsMap;
    }

    form.questions.forEach((question) => {
      questionsMap.set(question.id, question);
    });

    return questionsMap;
  }, [form?.questions]);

  return (
    <Pane className="border p-4">
      <Block className="flex justify-between">
        <Block className="flex items-center gap-1">
          <Pane>
            {ShiftStatusIconMap[status.status] &&
              createElement(ShiftStatusIconMap[status.status], {
                color: ShiftStatusColorMap[status.status],
              })}
          </Pane>
          <Heading className={classNames("capitalize")} size={500}>
            <span className={statusColorMap[status.status]}>
              {t("shifts.status." + status.status)}
            </span>
          </Heading>
        </Block>
        <Block className="flex flex-col">
          <Heading size={200}>
            <DateValue formatStr="dd-MM-yyyy HH:mm" date={status.createdAt} />
          </Heading>

          <Block>
            {status.createdByUser && (
              <Heading size={300}>
                {status.createdByUser.firstName} {status.createdByUser.lastName}
              </Heading>
            )}
          </Block>
        </Block>
      </Block>
      <Pane className="py-2">
        <Heading size={400}>Bericht:</Heading>
        {Object.entries(status.answers).map(([questionId, value]) => (
          <Pane key={questionId} className="flex flex-col gap-1 py-2">
            <Heading fontWeight="bold" size={300}>
              {questionsMap.get(questionId)?.title || questionId}
            </Heading>

            <Heading size={200}>{value}</Heading>
          </Pane>
        ))}
      </Pane>

      {!form && <Heading size={300}>-</Heading>}
    </Pane>
  );
}
