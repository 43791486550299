import { format, isValid, parseISO } from "date-fns";
import * as locale from "date-fns/locale/nl";

type CurrencyValueProps = {
  currency: string;
  amount: number;
};

export default function CurrencyValue({ currency = "EUR", amount }: CurrencyValueProps) {
  return (<>
  {new Intl.NumberFormat(locale.default.code, { style: 'currency', currency: currency }).format(amount)}
  </>)
}
