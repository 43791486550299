import Page from "src/components/common/Page";
import PageTitle from "src/components/common/PageTitle";
import { useNavigate } from "react-router-dom";
import StandardTable from "../../components/table/StandardTable";
import {
  Avatar,
  EmptyState,
  FilterIcon,
  Heading,
  majorScale,
  Pane,
  SearchIcon,
  Table,
  Text,
} from "evergreen-ui";
import { useTranslation } from "react-i18next";
import DateValue from "../../components/common/DateValue";
import { useWindowSearchParams } from "../../hooks/useWindowSearchParams";
import { FilterMapType, HookOptions } from "../../types/appTypes";
import { useApi } from "../../context/AxiosContext";
import { useEffect, useState } from "react";
import { ShiftReport } from "../../types/apiTypes";
import { useLoadResource } from "../../lib/request-hooks";
import DateDiffValue from "../../components/common/DateDiffValue";
import { __r, REPORTS_DETAIL_PAGE } from "../../RouteMap";
import ReportFilters from "./_components/ReportFilters";
import { ReportStatusIconMap } from "../../components/shared/report/components/ReportFieldsRow";
import { useDoFilter } from "../../hooks/useDoFilter";
import { endOfMonth, format, formatISO, startOfMonth } from "date-fns";

// const reportStatusIconMap = {
//     published: <TickIcon color="success" />,
//     isOpen: <EditIcon color="muted" />,
//     isLocked: <LockIcon color="muted"/>,
//     isPlannable: <EditIcon color="muted"/>,
//     isNotFilledIn: <CalendarIcon color="muted"/>,
// }

const statusColorMap = {
  published: "border-l-8 !border-l-green-400",
  isOpen: "border-l-4 !border-l-green-400 hover:!bg-blue-50",
  isLocked: "!bg-red-50 hover:!bg-blue-50",
  isPlannable: "border-l-8 !border-l-green-400 hover:!bg-blue-50",
  isNotFilledIn: "border-l-8 !border-l-orange-400 hover:!bg-blue-50",
  // published: '!bg-green-50 hover:!bg-blue-50',
  // isOpen: '!bg-blue-50 hover:!bg-blue-50',
  // isLocked: '!bg-red-50 hover:!bg-blue-50',
  // isPlannable: '!bg-green-50 hover:!bg-blue-50',
  // isNotFilledIn: "!bg-gray-100 hover:!bg-blue-50",
};

const useShiftReports = function (options: HookOptions = {}) {
  const searchParams = useWindowSearchParams();
  const { apiInstance } = useApi();

  const [latLng, setLatLng] = useState<string | null>(null);
  const [status, setStatus] = useState<string | null>(
    searchParams.get("status"),
  );
  const [initialized, setInitialized] = useState<boolean>(false);
  const [activityType, setActivityType] = useState<string | null>(null);
  const [workerId, setWorkerId] = useState<number | null>(null);
  const [projectId, setProjectId] = useState<number | null>(null);
  const [projectType, setProjectType] = useState<string | null>(null);
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);

  const filterMap: FilterMapType = {
    address: {
      setter: setLatLng,
      topic: "latLng",
      value: latLng,
    },
    fromDate: {
      setter: setFromDate,
      topic: "fromDate",
      value: fromDate,
    },
    toDate: {
      setter: setToDate,
      topic: "toDate",
      value: toDate,
    },
    activityType: {
      setter: setActivityType,
      topic: "activityType",
      value: activityType,
    },
    workerId: {
      setter: setWorkerId,
      topic: "workerId",
      value: workerId,
    },
    status: {
      setter: setStatus,
      topic: "status",
      value: status,
      default: "pending",
    },
    projectId: {
      setter: setProjectId,
      topic: "projectId",
      value: projectId,
    },
    projectType: {
      setter: setProjectType,
      topic: "projectType",
      value: projectId,
    },
  };

  const { populate } = options;

  if (populate) {
    searchParams.set("populate", populate.join(","));
  }

  const [shiftReports, setShiftReports] = useState<ShiftReport[]>([]);
  const find = () => apiInstance!.adminShiftReports.findMany(searchParams);

  const setData = (reports) => {
    setShiftReports(reports);
  };

  const loadResourceLogic = useLoadResource(find, setData, initialized);

  const {
    fetchData,
    setDefaultFilters,
    setFilter,
    doSortBy,
    handleInput,
    doFilter,
  } = useDoFilter({
    searchParams,
    setInitialized,
    initialized,
    filterMap,
    loadResourceLogic,
    find,
    setData,
    setMeta: () => {},
  });

  useEffect(() => {
    if (!initialized) {
      setDefaultFilters();
    }
  }, []);

  return {
    ...loadResourceLogic,
    shiftReports,
    doFilter,
    doSortBy,
    fetchData,
    filterMap,
    handleInput,
    setData,
    setDefaultFilters,
    setFilter,
  };
};

export default function ShiftReportsListPage() {
  const { t } = useTranslation();
  const shiftsLogic = useShiftReports();

  const { shiftReports, meta, isLoading, refresh } = shiftsLogic;

  const navigate = useNavigate();

  function navToReport(shiftReport: ShiftReport) {
    navigate(__r(REPORTS_DETAIL_PAGE, { shiftReportId: shiftReport.id }), {
      state: {
        backPath: window.location.pathname + window.location.search,
      },
    });
  }

  return (
    <Page>
      <PageTitle>Rapporten</PageTitle>

      <Pane className="flex flex-col gap-2">
        <Pane className="flex items-center gap-1">
          <FilterIcon color="muted" size={majorScale(2)} />
          <Heading size={300}>Filters</Heading>
        </Pane>
        <Pane className={"h-[1px] w-8 bg-gray-200"} />
        <ReportFilters filterLogic={shiftsLogic} />
        <Pane className={"h-[1px] w-8 bg-gray-200"} />
      </Pane>

      <Pane>
        <StandardTable
          meta={meta}
          refresh={refresh}
          isLoading={isLoading}
          data={shiftReports}
          emptyState={<EmptyPeriodsState />}
        >
          <Table.Head paddingRight={0}>
            <Table.TextHeaderCell>Opdrachtgever</Table.TextHeaderCell>
            <Table.TextHeaderCell>Veldwerker</Table.TextHeaderCell>
            <Table.TextHeaderCell>Activiteit</Table.TextHeaderCell>
            <Table.TextHeaderCell>Gebied</Table.TextHeaderCell>
            <Table.TextHeaderCell>Gelopen op</Table.TextHeaderCell>
            <Table.TextHeaderCell>Ingediend op</Table.TextHeaderCell>
            <Table.TextHeaderCell>Duur</Table.TextHeaderCell>
            <Table.TextHeaderCell>Status</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {shiftReports.map((shiftReport) => (
              <Table.Row
                className={statusColorMap[shiftReport.status]}
                key={shiftReport.id}
                isSelectable
                onSelect={() => {
                  navToReport(shiftReport);
                }}
              >
                <Table.TextCell>
                  {shiftReport.project.name} ({shiftReport.project.type})
                </Table.TextCell>
                <Table.TextCell>
                  <Pane className="flex items-center gap-2">
                    <Avatar
                      className="hover:opacity-90"
                      src={
                        shiftReport.worker.user!.profilePictureThumbnail ?? ""
                      }
                      name={""}
                      size={20}
                    />
                    <Text>
                      {shiftReport.worker.user.firstName[0]}.{" "}
                      {shiftReport.worker.user.lastName}
                    </Text>
                  </Pane>
                </Table.TextCell>
                <Table.TextCell>
                  {shiftReport.areaActivityType.activityType.name}
                </Table.TextCell>
                <Table.TextCell>
                  {shiftReport.areaActivityType.area.name}
                </Table.TextCell>
                <Table.TextCell>
                  <DateValue date={shiftReport.workerShift?.startedAt} />
                </Table.TextCell>
                <Table.TextCell>
                  <DateValue date={shiftReport?.submittedAt} />
                </Table.TextCell>
                <Table.TextCell>
                  {shiftReport.workerShift?.startedAt &&
                    shiftReport.workerShift?.endedAt && (
                      <DateDiffValue
                        leftDate={shiftReport.workerShift?.startedAt}
                        rightDate={shiftReport.workerShift?.endedAt}
                      />
                    )}
                </Table.TextCell>
                <Table.TextCell>
                  <Pane className="flex items-center gap-2">
                    {t("common." + shiftReport.status)}{" "}
                    {ReportStatusIconMap[shiftReport.status]}
                  </Pane>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </StandardTable>
      </Pane>
    </Page>
  );
}

function EmptyPeriodsState() {
  return (
    <EmptyState
      background="light"
      title="Geen rapporten gevonden"
      orientation="horizontal"
      icon={<SearchIcon color="#C1C4D6" />}
      iconBgColor="#EDEFF5"
      description="Het kan zijn dat een actieve filter ervoor zorgt dat je geen rapporten ziet, of dat er nog geen rapporten zijn ingediend."
    />
  );
}
