import mapboxgl from "mapbox-gl";
import mapboxGlDraw from "@mapbox/mapbox-gl-draw";

import Block from "@/components/common/Block";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useShowObjectsOnMap } from "@/hooks/useShowObjectsOnMap";
import { Area, ClientObject } from "@/types/apiTypes";
import { Feature, GeometryCollection } from "geojson";
import { useShowAreasOnMap } from "@/pages/projects/areas/useShowAreasOnMap";

type AreaFeature = Feature & { properties: { area_id: number } };

type AreaObjectsEditMapProps = {
  objects: ClientObject[];
  areas: Area[];
  setNewArea: (area: Partial<Area>) => Promise<Area>;
  updateArea: (area: Area) => Promise<Area>;
  setActiveArea: (area: Area) => void;
  onRemoveArea: (area: Area) => Promise<void>;
  activeArea: Area | null;
  activeObject: ClientObject | null;
};

const AreaObjectsEditMap = forwardRef(function ObjectEditMap(
  props: AreaObjectsEditMapProps,
  ref,
) {
  const {
    activeArea,
    activeObject,
    areas,
    objects,
    setActiveArea,
    setNewArea,
    updateArea,
  } = props;

  const mapContainer = useRef<HTMLDivElement | string>("");

  const mapRef = useRef<mapboxgl.Map | null>(null);
  const drawRef = useRef<mapboxGlDraw | null>(null);
  const newCreatedAreaIdMap = useRef({});

  useEffect(() => {
    if (mapRef.current) {
      return;
    }

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      zoom: 9,
      accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
      center: [4.895168, 52.370216],
    });

    if (!drawRef.current) {
      drawRef.current = new mapboxGlDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
      });

      mapRef.current.addControl(drawRef.current);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    flyTo: (center: mapboxgl.LngLatLike) => {
      mapRef.current!.flyTo({
        center,
        zoom: 18,
        essential: true,
        duration: 1000,
      });
    },
    fitBounds: (bounds: mapboxgl.LngLatBoundsLike) => {
      mapRef.current!.fitBounds(bounds, {
        padding: 10,
      });
    },
  }));

  const objectFeatures = useMemo(() => {
    return {
      type: "FeatureCollection",
      features: objects.map((object) => {
        return {
          id: object.id,
          type: "Feature",
          geometry: object.geometry.geometry,
          properties: {
            name: object.name,
            object_id: object.id,
            icon: "marker",
            state: activeObject?.id === object.id ? "active" : "inactive",
          },
        };
      }),
    };
  }, [objects, activeObject]);

  const areasFeatures = useMemo(() => {
    return {
      type: "FeatureCollection",
      features: areas.map((area) => {
        return {
          type: "Feature",
          id: area.id,
          geometry: area.geometry.features[0].geometry,
          properties: {
            name: area.name,
            area_id: area.id,
            icon: "marker",
          },
        };
      }),
    };
  }, [areas]);

  useEffect(() => {
    if (!mapRef.current || !drawRef.current || !activeArea) {
      setTimeout(() => {
        drawRef.current?.deleteAll();
        drawRef.current?.changeMode("simple_select");
      }, 0);
      return;
    }

    const drawFeature = {
      id: activeArea.id,
      type: "Feature",
      geometry: activeArea.geometry.features[0].geometry,
      properties: {
        name: activeArea?.name,
        area_id: activeArea.id,
      },
    } as AreaFeature;

    const featureIds = drawRef.current?.add(drawFeature);

    drawRef.current?.changeMode("simple_select", { featureIds: featureIds });
  }, [activeArea]);

  useEffect(() => {
    if (!mapRef.current || !drawRef.current) {
      return;
    }

    const map = mapRef.current;

    const onCreateArea = async (e) => {
      const newFeature = e.features[0];

      const geometry = {
        type: "GeometryCollection",
        geometries: e.features,
      } as GeometryCollection;

      const newArea: Partial<Area> = {
        name: "Nieuw gebied",
        geometry: geometry,
        activityTypes: [],
        addresses: [],
      };

      const { id } = await setNewArea(newArea);

      newCreatedAreaIdMap.current[newFeature.id] = id;
    };

    const onDeleteArea = async (e) => {
      const areaFeature = e.features[0];

      const area = areas.find(
        (area) => area.id === areaFeature.properties.area_id,
      );

      if (!area) {
        return;
      }

      await props.onRemoveArea(area);

      setTimeout(() => {
        drawRef.current?.deleteAll();
        drawRef.current?.changeMode("simple_select");
      }, 0);
    };

    const onUpdateArea = async (e) => {
      const newFeature = e.features[0];

      const areaId =
        newCreatedAreaIdMap.current[newFeature.id] ??
        newFeature.properties.area_id;

      if (!areaId) {
        return;
      }

      const area = areas.find((area) => area.id === areaId);

      if (!area) {
        return;
      }

      area.geometry = {
        type: "GeometryCollection",
        geometries: e.features,
      } as GeometryCollection;

      await updateArea(area);
    };

    map.on("draw.create", onCreateArea);
    map.on("draw.delete", onDeleteArea);
    map.on("draw.update", onUpdateArea);

    return () => {
      map.off("draw.create", onCreateArea);
      map.off("draw.delete", onDeleteArea);
      map.off("draw.update", onUpdateArea);
    };
  }, [mapRef.current, drawRef.current, areasFeatures]);

  const onClickArea = (
    selectedAreaFeature: Feature & { properties: { area_id: number } },
    e: mapboxgl.MapMouseEvent & mapboxgl.EventData,
  ) => {
    const area = areas.find(
      (area) => area.id === selectedAreaFeature.properties.area_id,
    );

    if (!area) {
      return;
    }

    setActiveArea(area);
  };

  useShowObjectsOnMap({
    mapRef,
    objectFeatures,
    onClick: () => {},
    objects,
    areasFeatures,
    shouldFitBoundsOnLoad: !!activeArea,
  });

  useShowAreasOnMap({
    mapRef,
    areasFeatures,
    onClick: onClickArea,
    areas,
  });

  return (
    <Block>
      <Block className="mb-2"></Block>
      <Block>
        {/*// @ts-ignore*/}
        <div ref={mapContainer} className="map-container h-[70vh]" />
      </Block>
    </Block>
  );
});

export default AreaObjectsEditMap;
