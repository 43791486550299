import { useState } from "react";
import { useApi } from "@/context/AxiosContext";
import { useDoRequest } from "@/lib/request-hooks";
import {
  Report,
  ReportComponent,
  ReportComponentIds,
  ReportData,
} from "@/types/apiTypes";
import { Paragraph, Spinner, Switch, TextInput, toaster } from "evergreen-ui";
import { PdfPreviewDocument } from "./pdf/PdfPreviewDocument";
import { ReportModuleComponents } from "./modules/ReportModuleComponents";
import { useClientReport } from "./hooks/useClientReport";
import { useAuth } from "@/context/AuthContext";
import { getTemplateMappingValues } from "./utility";
import DatePicker from "../../../components/formfields/DatePicker";

export default function ReportEditPage() {
  const { reportData, projectData } = useClientReport();
  if (reportData && projectData) {
    return <ReportContainer report={reportData} projectData={projectData} />;
  }

  return (
    <div className="absolute left-1/2	top-1/2	 flex items-center">
      <Spinner size={50} />
      <Paragraph marginLeft={12}>Loading Report...</Paragraph>
    </div>
  );
}

const ReportContainer = ({
  report,
  projectData,
}: {
  report: Report;
  projectData: ReportData;
}) => {
  const [reportToBeUpdated, setReportToBeUpdated] = useState(report);
  const { components, title, id, date } = reportToBeUpdated;

  const [saveLoaderVisibility, setSaveLoaderVisibility] = useState(false);
  const [headerSaveLoaderVisibility, setHeaderSaveLoaderVisibility] =
    useState(false);
  const [reportPreviewVisibility, setReportPreviewVisibility] = useState(false);

  const { handle } = useDoRequest();
  const { apiInstance } = useApi();
  const auth = useAuth();

  async function handleReportSave(
    updatedReport: Report,
    updatedReportComponentId?: ReportComponentIds,
  ) {
    const request = apiInstance!.adminReports.updateReport(
      id,
      {
        title: updatedReport.title,
        date: updatedReport.date,
        components: updatedReport.components,
      },
      updatedReportComponentId,
    );
    const response = await handle(request);
    toaster.success("Report saved successfully!");
    setReportToBeUpdated(response);
  }

  async function handleReportComponentsSave(
    updatedComponents: ReportComponent[],
    updatedReportComponentId?: ReportComponentIds,
  ) {
    setSaveLoaderVisibility(true);
    await handleReportSave(
      {
        ...reportToBeUpdated,
        components: updatedComponents,
      },
      updatedReportComponentId,
    );
    setSaveLoaderVisibility(false);
  }

  async function handleReportTitleChange(updatedReportTitle: string) {
    setHeaderSaveLoaderVisibility(true);
    await handleReportSave({ ...reportToBeUpdated, title: updatedReportTitle });
    setHeaderSaveLoaderVisibility(false);
  }

  async function handleReportDateChange(updatedReportDate: Date) {
    setHeaderSaveLoaderVisibility(true);
    await handleReportSave({ ...reportToBeUpdated, date: updatedReportDate });
    setHeaderSaveLoaderVisibility(false);
  }

  const reportTemplateValues = getTemplateMappingValues(
    projectData,
    report,
    auth.user!,
  );
  if (components.length) {
    return (
      <div className="overflow-x-scroll pb-8">
        <ReportEditorHeaderSection
          title={title}
          reportDate={new Date(date)}
          saveLoaderVisibility={headerSaveLoaderVisibility}
          handleReportTitleChange={handleReportTitleChange}
          handleReportDateChanges={handleReportDateChange}
          reportPreviewVisibility={reportPreviewVisibility}
          setReportPreviewVisibility={setReportPreviewVisibility}
        />
        <div className="flex h-screen justify-between">
          <ReportModuleComponents
            report={reportToBeUpdated}
            projectData={projectData}
            components={components}
            saveLoaderVisibility={saveLoaderVisibility}
            handleComponentChanges={handleReportComponentsSave}
          />
          {reportPreviewVisibility && (
            <ReportPreview
              report={reportToBeUpdated}
              reportTemplateValues={reportTemplateValues}
            />
          )}
        </div>
      </div>
    );
  }
};

const ReportEditorHeaderSection = ({
  title,
  reportDate,
  saveLoaderVisibility,
  handleReportTitleChange,
  handleReportDateChanges,
  reportPreviewVisibility,
  setReportPreviewVisibility,
}: {
  title: string;
  reportDate: Date;
  saveLoaderVisibility: boolean;
  handleReportTitleChange: (reportTitle: string) => void;
  handleReportDateChanges: (reportDate: Date) => void;
  reportPreviewVisibility: boolean;
  setReportPreviewVisibility: (value: boolean) => void;
}) => {
  return (
    <div className="mt-4 mb-8 flex items-center justify-between">
      <div className="flex items-center">
        <TextInput
          defaultValue={title}
          name="reportTitle"
          placeholder="Report Title"
          onBlur={(e) => handleReportTitleChange(e.target.value)}
        />
        <div className="ml-4 inline-block">
          <DatePicker
            dateValue={reportDate}
            setDateValue={handleReportDateChanges}
          />
        </div>
        {saveLoaderVisibility && (
          <Spinner size={25} marginLeft={10} className="blue-spinner"></Spinner>
        )}
      </div>
      <div>
        <span className="text-xs">Report Preview</span>
        <Switch
          className="ml-2 !inline-block align-bottom"
          height={24}
          checked={reportPreviewVisibility}
          onChange={(e) => setReportPreviewVisibility(e.target.checked)}
        />
      </div>
    </div>
  );
};

const ReportPreview = ({
  report,
  reportTemplateValues,
}: {
  report: Report;
  reportTemplateValues: { [key: string]: string };
}) => {
  return (
    <div className="shrink-0 grow-0 basis-3/6">
      <PdfPreviewDocument
        report={report}
        reportTemplateValues={reportTemplateValues}
      />
    </div>
  );
};
