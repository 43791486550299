import mapboxgl, { LngLatLike, MapboxGeoJSONFeature } from "mapbox-gl";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ClientObject } from "@/types/apiTypes";
import { Feature, FeatureCollection, GeometryCollection, Point } from "geojson";
import { getBoundingBox } from "@/lib/functions";

export const OBJECT_SOURCE = "project-objects";
export const TMP_OBJECT_SOURCE = "tmp-objects";

export type PointFeature = Feature<GeometryCollection<Point>>;

function addSourceAndLayersToMap(
  map: mapboxgl.Map,
  objectFeatures: FeatureCollection,
) {
  map.addSource(OBJECT_SOURCE, {
    type: "geojson",
    data: objectFeatures,
  });

  map.addSource(TMP_OBJECT_SOURCE, {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });

  map.addLayer({
    id: "tmp-object-points",
    type: "circle",
    source: TMP_OBJECT_SOURCE,
    paint: {
      "circle-radius": ["match", ["get", "state"], "active", 6, 6],
      "circle-stroke-width": 2,
      "circle-color": [
        "match",
        ["get", "state"],
        "active",
        "#ff9044",
        "#ffd97c",
      ],
    },
    filter: ["==", "$type", "Point"],
  });

  map.addLayer({
    id: "object-points",
    type: "circle",
    source: OBJECT_SOURCE,
    paint: {
      "circle-radius": ["match", ["get", "state"], "active", 7, 6],
      "circle-color": [
        "match",
        ["get", "state"],
        "active",
        "#0080ff",
        "#92e159",
      ],
      "circle-stroke-color": "#2d2d2d",
      // 'circle-opacity': [
      //   'case',
      //   ['boolean', ['feature-state', 'hover'], false],
      //   0.5,
      //   1,
      // ],
      "circle-stroke-width": 2,
    },
    filter: ["==", "$type", "Point"],
  });
}

function useMapEvents(
  mapRef: MutableRefObject<mapboxgl.Map | null>,
  onClick,
  objects: ClientObject[],
) {
  const currentPopup = useRef<mapboxgl.Popup | null>(null);
  const hoveredPolygonId = useRef<number | null>(null);

  function selectBoundingBox(
    map,
    e: mapboxgl.MapMouseEvent & mapboxgl.EventData,
  ) {
    const bbox = [
      [e.point.x - 5, e.point.y - 5],
      [e.point.x + 5, e.point.y + 5],
    ] as [[number, number], [number, number]];

    if (map.getLayer("object-points") == null) {
      return [];
    }

    // Find features intersecting the bounding box.
    return map.queryRenderedFeatures(bbox, {
      layers: ["object-points"],
    });
  }

  function showPopup(
    map: mapboxgl.Map,
    e: mapboxgl.MapMouseEvent & mapboxgl.EventData,
    feature: MapboxGeoJSONFeature,
  ) {
    if (currentPopup.current) {
      currentPopup.current.remove();
    }

    if (!feature) {
      return;
    }

    if (!("coordinates" in feature.geometry)) {
      return;
    }

    const coordinates = feature.geometry.coordinates as LngLatLike;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    if (currentPopup.current) {
      currentPopup.current.remove();
    }

    const description = feature.properties?.name;

    currentPopup.current = new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML(description)
      .addTo(map);
  }

  const onClickObject = useCallback(
    (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      if (mapRef.current == null) {
        return;
      }

      const map = mapRef.current;

      // ========== Find selected features with bounding box ==============
      const selectedFeatures = selectBoundingBox(map, e);

      if (selectedFeatures.length === 0) {
        return;
      }
      // ==================================================================

      onClick(selectedFeatures[0], objects);

      showPopup(map, e, selectedFeatures[0]);
    },
    [mapRef, objects],
  );

  const onHoverObject = useCallback(
    (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      const map = mapRef.current;

      if (!map) {
        return;
      }

      // ========== get selected features ==============
      const selectedFeatures = selectBoundingBox(map, e);

      if (selectedFeatures.length === 0) {
        if (currentPopup.current) {
          currentPopup.current.remove();
        }

        return;
      }
      // ==============================================

      // ========== set hover state ====================
      if (hoveredPolygonId.current !== null) {
        map.setFeatureState(
          { source: OBJECT_SOURCE, id: hoveredPolygonId.current },
          { hover: false },
        );
      }

      hoveredPolygonId.current = selectedFeatures[0].id as number;

      map.setFeatureState(
        { source: OBJECT_SOURCE, id: hoveredPolygonId.current },
        { hover: true },
      );
      // ======================================

      showPopup(map, e, selectedFeatures[0]);
    },
    [mapRef, objects],
  );

  const onMouseLeave = useCallback(() => {
    const map = mapRef.current;

    if (!map) {
      return;
    }

    map.getCanvas().style.cursor = "";

    if (currentPopup.current) {
      currentPopup.current.remove();
    }
  }, [mapRef, objects]);

  return {
    onClickObject,
    onHoverObject,
    onMouseLeave,
  };
}

export function useShowObjectsOnMap({
  mapRef,
  objectFeatures,
  onClick,
  objects,
  shouldFitBoundsOnLoad,
  tmpFeatures,
}: {
  mapRef: MutableRefObject<mapboxgl.Map | null>;
  objectFeatures: FeatureCollection;
  onClick: (feature: Feature, objects: ClientObject[]) => void;
  objects: ClientObject[];
  shouldFitBoundsOnLoad: boolean;
  tmpFeatures: FeatureCollection;
}) {
  const { onClickObject, onHoverObject, onMouseLeave } = useMapEvents(
    mapRef,
    onClick,
    objects,
  );
  const [mapIsLoaded, setMapIsLoaded] = useState(false);

  useEffect(() => {
    if (mapRef.current == null || mapIsLoaded) {
      return;
    }

    const map = mapRef.current;

    const onLoad = () => {
      addSourceAndLayersToMap(map, objectFeatures);

      setMapIsLoaded(true);

      if (shouldFitBoundsOnLoad) {
        const boundingBox = getBoundingBox(objectFeatures.features);

        map.fitBounds([
          [boundingBox.xMin - 0.05, boundingBox.yMin - 0.05],
          [boundingBox.xMax + 0.05, boundingBox.yMax + 0.05],
        ]);
      }
    };

    map.on("load", onLoad);

    return () => {
      map.off("load", onLoad);
    };
  }, [mapRef, objectFeatures]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    const map = mapRef.current;

    const onLoad = () => {
      // @ts-ignore
      if (!map.hasControl("navigation")) {
        map.addControl(new mapboxgl.NavigationControl());
      }
    };

    map.on("load", onLoad);

    return () => {
      if (map) {
        map.off("load", onLoad);
      }
    };
  }, [mapRef]);

  useEffect(() => {
    if (mapRef.current == null) {
      return;
    }

    const map = mapRef.current;

    if (map != null) {
      const source = map.getSource(OBJECT_SOURCE) as
        | mapboxgl.GeoJSONSource
        | undefined;

      if (source) {
        source.setData(objectFeatures);
      }
    }
  }, [mapRef, objectFeatures, objects]);

  useEffect(() => {
    if (mapRef.current == null) {
      return;
    }

    const map = mapRef.current;

    if (map != null) {
      const source = map.getSource(TMP_OBJECT_SOURCE) as
        | mapboxgl.GeoJSONSource
        | undefined;

      if (source) {
        source.setData(tmpFeatures);
      }
    }
  }, [mapRef, tmpFeatures]);

  useEffect(() => {
    if (mapRef.current == null) {
      return;
    }

    const map = mapRef.current;

    map.on("click", onClickObject);
    map.on("mousemove", onHoverObject);
    map.on("mouseout", onMouseLeave);

    return () => {
      map.off("click", onClickObject);
      map.off("mousemove", onHoverObject);
      map.off("mouseout", onMouseLeave);
    };
  }, [mapRef, objects, objectFeatures]);
}
