import mapboxgl from "mapbox-gl";

import Block from "@/components/common/Block";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { AddressAutofill, config } from "@mapbox/search-js-react";
import { TextInput } from "evergreen-ui";
import { useShowObjectsOnMap } from "@/hooks/useShowObjectsOnMap";
import { Feature, FeatureCollection } from "geojson";
import { ClientObject } from "@/types/apiTypes";
import mapboxGlDraw from "@mapbox/mapbox-gl-draw";

type ObjectEditMapProps = {
  objects: ClientObject[];
  setObjects: (objects: ClientObject[]) => void;
  setNewObject: (object: ClientObject, setActive?: boolean) => void;
  onClickObject: (object: ClientObject) => void;
  activeObject: ClientObject | null;
  tmpGeometries: FeatureCollection[];
  selectedGeometry: FeatureCollection | null;
};

const ObjectEditMap = forwardRef(function ObjectEditMap(
  props: ObjectEditMapProps,
  ref,
) {
  const {
    objects,
    tmpGeometries,
    setNewObject,
    onClickObject,
    activeObject,
    selectedGeometry,
  } = props;

  const mapContainer = useRef<HTMLDivElement | string>("");
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const drawRef = useRef<mapboxGlDraw | null>(null);

  useEffect(() => {
    if (mapRef.current) {
      return;
    }

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      zoom: 12,
      accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
      center: [4.895168, 52.370216],
    });

    if (!drawRef.current) {
      drawRef.current = new mapboxGlDraw({
        displayControlsDefault: false,
      });

      mapRef.current.addControl(drawRef.current);
    }
  });

  useImperativeHandle(ref, () => ({
    flyTo: (center: mapboxgl.LngLatLike) => {
      if (mapRef.current) {
        mapRef.current!.flyTo({
          center,
          zoom: 18,
          essential: true,
          duration: 1000,
        });
      }
    },
  }));

  const objectFeatures = useMemo(() => {
    return {
      type: "FeatureCollection",
      features: objects.flatMap((object) => {
        return {
          id: object.id,
          type: "Feature",
          geometry: object.geometry.geometry,
          properties: {
            name: object.name,
            object_id: object.id,
            icon: "marker",
            state: activeObject?.id === object.id ? "active" : "inactive",
          },
        };
      }),
    };
  }, [objects, activeObject]);

  const tmpFeatures: FeatureCollection = useMemo(() => {
    return {
      type: "FeatureCollection",
      features: tmpGeometries.flatMap((geometry) => {
        const selectedGeometryMapBoxId =
          selectedGeometry?.features?.[0]?.properties?.mapBoxId;
        const geometryMapBoxId = geometry?.features?.[0]?.properties?.mapBoxId;
        const name = geometry?.features?.[0].properties?.name;

        return {
          id: geometryMapBoxId,
          type: "Feature",
          geometry: geometry.features[0].geometry,
          properties: {
            name: name,
            icon: "marker",
            state:
              selectedGeometryMapBoxId === geometryMapBoxId
                ? "active"
                : "inactive",
          },
        };
      }),
    } as FeatureCollection;
  }, [tmpGeometries, selectedGeometry]);

  const handleRetrieve = useCallback(
    (res) => {
      const features = res.features;

      const geometry = {
        type: "FeatureCollection",
        features: res.features, // res.features.map((feature) => feature.geometry),
      };

      const { properties, geometry: pointGeometry } = features[0];

      const newObject = {
        name: properties.full_address,
        address: {
          city: properties.place,
          country: properties.country,
          countryCode: properties.country_code,
          formattedAddress: properties.full_address,
          mapBoxId: properties.mapbox_id,
          lng: pointGeometry.coordinates[0],
          lat: pointGeometry.coordinates[1],
          number: pointGeometry.address_number,
          postalCode: properties.postcode,
          province: properties.region,
          street: properties.street,
          language: properties.language,
        },
        mapBoxId: properties.mapbox_id,
        geometry: geometry,
        notes: null,
      };

      setNewObject(newObject);

      mapRef.current!.flyTo({
        center: res.features[0].geometry.coordinates,
      });
    },
    [objects],
  );

  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const accessToken = process.env.REACT_APP_MAPBOX_API_KEY;
    if (accessToken) {
      setToken(accessToken);
      config.accessToken = accessToken;
    }
  }, []);

  const onClickFeature = useCallback(
    (feature: Feature, objects: ClientObject[]) => {
      const object = objects.find(
        (object) => object.id === feature.properties?.object_id,
      );

      if (!object) {
        return;
      }

      onClickObject(object);
    },
    [objects, objectFeatures, onClickObject],
  );

  useShowObjectsOnMap({
    mapRef,
    objectFeatures,
    onClick: onClickFeature,
    objects,
    shouldFitBoundsOnLoad: !activeObject,
    tmpFeatures,
  });

  return (
    <Block>
      <Block className="mb-2">
        <AddressAutofill
          options={{ language: "nl", country: "NL" }}
          accessToken={token}
          onRetrieve={handleRetrieve}
        >
          <TextInput
            id="mapbox-autofill"
            placeholder="Start met typen"
            minHeight={40}
            maxWidth={300}
          />
        </AddressAutofill>
      </Block>
      <Block>
        <div ref={mapContainer} className="map-container h-[70vh]" />
      </Block>
    </Block>
  );
});

export default ObjectEditMap;
