import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { Heading, Pane, Switch } from "evergreen-ui";
import { DateFormat } from "../../../components/common/DateFormat";
import {
  dateIsAfterTimeslotWindow,
  dateIsBeforeTimeslotWindow,
} from "../../../lib/functions";

export default function AllTimeslotsAvailabilitySwitcher({
  date,
  availability,
  timeslots,
  refreshView,
  isLocked,
  onChange,
}) {
  const dateKey = date.toISOString();
  const currentAvailability = availability.current[dateKey] ?? {};

  const checkedTimeslots = timeslots.filter(
    (t) => currentAvailability[t.id] ?? false,
  );

  const [isChecked, setIsChecked] = useState<boolean>(
    !isEmpty(timeslots) && checkedTimeslots.length === timeslots.length,
  );

  const allDatesAreBeforeTimeslotWindow = useMemo(() => {
    return (
      timeslots.filter((timeslot) => dateIsBeforeTimeslotWindow(timeslot, date))
        .length === timeslots.length
    );
  }, [timeslots]);

  const allDatesAreAfterTimeslotWindow = useMemo(() => {
    return (
      timeslots.filter((timeslot) => dateIsAfterTimeslotWindow(timeslot, date))
        .length === timeslots.length
    );
  }, [timeslots]);

  const setAllTimeslotsAvailabilityCb = (timeslots) => {
    if (isLocked) {
      return;
    }

    timeslots.forEach((timeslot) => {
      if (!isChecked) {
        currentAvailability[timeslot.id] = {
          date: dateKey,
          timeslotUid: timeslot.id,
        };
      } else {
        delete currentAvailability[timeslot.id];
      }
    });

    if (isEmpty(currentAvailability)) {
      delete availability.current[dateKey];
    } else {
      availability.current = {
        ...availability.current,
        [dateKey]: currentAvailability,
      };
    }

    setIsChecked(!isChecked);
    refreshView();

    // called each time to set the form as dirty
    onChange();
  };

  return (
    <>
      {allDatesAreAfterTimeslotWindow || allDatesAreBeforeTimeslotWindow ? (
        <td className="!w-[2rem] !text-left">
          <Heading size={100} className="flex justify-center">
            N/A
          </Heading>
        </td>
      ) : (
        <td
          onClick={() => setAllTimeslotsAvailabilityCb(timeslots)}
          className="hover:inner-shadow-md !w-[2rem] cursor-pointer !text-left"
        >
          <Pane className="flex items-center justify-center gap-2">
            <Switch
              disabled={isLocked}
              onChange={(e) => {
                e.stopPropagation();
                setAllTimeslotsAvailabilityCb(timeslots);
              }}
              checked={isChecked}
            ></Switch>
          </Pane>
        </td>
      )}

      <td
        className="hover:inner-shadow-md cursor-pointer text-left"
        onClick={() => setAllTimeslotsAvailabilityCb(timeslots)}
      >
        <DateFormat
          className="!text-xs uppercase"
          date={date}
          formatStr="eeeeee d MMM"
        />
      </td>
    </>
  );
}
