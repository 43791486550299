import {
  Alert,
  FormField,
  majorScale,
  Pane,
  Spinner,
  Switch,
  TextareaField,
  TextInputField,
  toaster,
} from "evergreen-ui";

import Actions from "src/components/common/Actions";
import Block from "src/components/common/Block";
import DateRangeInput from "src/components/formfields/DateRangeInput";
import Form from "src/components/common/Form";
import Page from "src/components/common/Page";
import PageTitle from "src/components/common/PageTitle";
import { Period } from "src/types/apiTypes";
import { parseISO } from "date-fns";
import { useApi } from "src/context/AxiosContext";
import { useDoRequest, useLoadResource } from "src/lib/request-hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const useAvailabilityRequestFormFields = function () {
  const navigate = useNavigate();

  const { periodId } = useParams();
  const { apiInstance } = useApi();
  const shouldDoRequest = !!periodId;

  const [name, setName] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [endAt, setEndAt] = useState<Date | null>(null);
  const [startAt, setStartAt] = useState<Date | null>(new Date());
  const [isActive, setIsActive] = useState<boolean>(true);
  const [activeAt, setActiveAt] = useState<Date>(new Date());

  const {
    handle,
    isLoading: isSubmitting,
    validationErrors,
    setValidationErrors,
    errorMessage,
  } = useDoRequest();

  const find = () => apiInstance!.adminPeriods.findOne(periodId!);

  const setData = (availabilityRequest: Period) => {
    setName(availabilityRequest.name);
    setNotes(availabilityRequest.notes);
    setIsActive(availabilityRequest.isActive);
    setStartAt(
      availabilityRequest.startAt
        ? parseISO(availabilityRequest.startAt)
        : null,
    );
    setEndAt(
      availabilityRequest.endAt ? parseISO(availabilityRequest.endAt) : null,
    );
  };

  const doSubmit = async () => {
    const data = {
      name,
      startAt,
      endAt,
      isActive,
      notes,
    };

    let request;

    if (periodId) {
      request = apiInstance!.adminPeriods.update(periodId, data);
    } else {
      request = apiInstance!.adminPeriods.create(data);
    }

    await handle(request);

    toaster.success("Saved!");

    navigate("/planner/availability-requests");
  };

  const doRemove = async () => {
    const request = apiInstance!.adminPeriods.remove(periodId!);
    await handle(request);

    toaster.success("Deleted!");

    navigate("/planner/availability-requests");
  };

  return {
    ...useLoadResource(find, setData, shouldDoRequest),
    doRemove,
    notes,
    setNotes,
    doSubmit,
    name,
    setName,
    startAt,
    setStartAt,
    endAt,
    setEndAt,
    isActive,
    setIsActive,
    activeAt,
    setActiveAt,
    submitContext: {
      isSubmitting,
      errorMessage,
      validationErrors,
      setValidationErrors,
    },
  };
};

export default function PeriodEditPage() {
  const navigate = useNavigate();
  const { periodId } = useParams();
  const { t } = useTranslation();

  const {
    isLoading,
    doSubmit,
    name,
    submitContext,
    setName,
    startAt,
    setStartAt,
    endAt,
    setEndAt,
    refreshCount,
    isActive,
    setIsActive,
    notes,
    setNotes,
  } = useAvailabilityRequestFormFields();

  return (
    <Page>
      <Block className="flex w-full gap-2">
        <PageTitle marginBottom={20}>
          {!periodId
            ? t("period_edit_page.title_add")
            : t("period_edit_page.title_edit")}
        </PageTitle>

        {isLoading && (
          <Block className="flex justify-end">
            <Spinner size={20} />
          </Block>
        )}
      </Block>

      {isLoading === false && (
        <Form>
          <>
            <Actions>
              <FormField className="flex gap-2" label={"Active"}>
                <Switch
                  disabled={!!periodId}
                  onChange={() => setIsActive(!isActive)}
                  checked={isActive}
                ></Switch>
              </FormField>
            </Actions>

            <TextInputField
              name="name"
              label="Name"
              placeholder="Name ..."
              isInvalid={!!submitContext.validationErrors?.name}
              validationMessage={submitContext.validationErrors?.name?.join(
                ", ",
              )}
              value={name}
              onChange={(e) => setName(e.target.value)}
              marginBottom={0}
              inputHeight={50}
            />

            <FormField label="Start / end date">
              <DateRangeInput
                disabled={!!periodId}
                key={refreshCount}
                validationErrors={submitContext.validationErrors}
                setValidationErrors={submitContext.setValidationErrors}
                fromValue={startAt}
                setFromValue={setStartAt}
                toValue={endAt}
                setToValue={setEndAt}
              />
            </FormField>

            <TextareaField
              name="notes"
              label="Notes"
              placeholder="About this time period ..."
              isInvalid={!!submitContext.validationErrors?.notes}
              validationMessage={submitContext.validationErrors?.notes?.join(
                ", ",
              )}
              value={notes || ""}
              onChange={(e) => setNotes(e.target.value)}
              marginBottom={0}
              inputHeight={40}
              className="!text-base"
            />
            {/*<FormField>*/}
            {/*    <DatePicker*/}
            {/*        selected={activeAt}*/}
            {/*        setSelected={setActiveAt}*/}
            {/*        title="Select date"*/}
            {/*        emptySelectText="Select when active..."*/}
            {/*        isInvalid={!!submitContext.validationErrors?.activeAt}*/}
            {/*        validationMessage={submitContext.validationErrors?.activeAt?.join(', ')}*/}
            {/*    />*/}
            {/*</FormField>*/}

            {submitContext.errorMessage && (
              <Pane>
                <Alert
                  marginTop={10}
                  intent="danger"
                  title={submitContext.errorMessage}
                />
              </Pane>
            )}

            <Actions marginTop={10} marginBottom={10}>
              <Actions.Button
                onClick={() => navigate(`/planner/availability-requests`)}
                disabled={!!submitContext.isSubmitting}
                height={majorScale(5)}
              >
                Cancel
              </Actions.Button>

              <Actions.Button
                onClick={doSubmit}
                isLoading={!!submitContext.isSubmitting}
                appearance="primary"
                intent="success"
                height={majorScale(5)}
              >
                Save
              </Actions.Button>
            </Actions>

            {/*<Card title={"Danger zone"} elevation={1} className={"mt-8 p-8"}>*/}
            {/*  <Pane className="flex items-center w-full">*/}
            {/*    <Heading size={400}>Danger zone</Heading>*/}
            {/*    <Button*/}
            {/*      onClick={doRemove}*/}
            {/*      iconBefore={<TrashIcon />}*/}
            {/*      isLoading={!!submitContext.isLoading}*/}
            {/*      intent="danger"*/}
            {/*      appearance="primary"*/}
            {/*      marginLeft='auto'*/}
            {/*      height={majorScale(5)}>Delete*/}
            {/*    </Button>*/}
            {/*  </Pane>*/}
            {/*</Card>*/}
          </>
        </Form>
      )}
    </Page>
  );
}
