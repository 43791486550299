import { TableComponent } from "@/types/apiTypes";
import { Text, View } from "@react-pdf/renderer";
import { pdfTableStyles } from "./styles/pdfReportStyles";
import { getFormattedData } from "../utility";
import { Fragment } from "react";
import { weatherDutchTranslationMapping } from "../constants";

export const PdfPreviewTableComponent = ({
  component,
}: {
  component: TableComponent;
}) => {
  const { columns, data } = component;

  return (
    <View style={[pdfTableStyles.tableContainer]} wrap={false}>
      <View style={[pdfTableStyles.tableHeaderContainer]}>
        {columns.map((column, index) => (
          <Text
            key={index}
            style={[
              pdfTableStyles.tableHeader,
              {
                flexBasis: `${100 / columns.length}%`,
              },
            ]}
            break
          >
            {column.label}
          </Text>
        ))}
      </View>

      {data.length === 0 ? (
        <View style={[pdfTableStyles.tableRowContainer]}>
          <View
            style={[
              pdfTableStyles.tableData,
              {
                flexBasis: `100%`,
              },
            ]}
            break
          >
            <Text>No data available</Text>
          </View>
        </View>
      ) : (
        data.map((row, rowIndex) => (
          <View
            key={rowIndex}
            style={[
              pdfTableStyles.tableRowContainer,
              rowIndex % 2 !== 0 ? pdfTableStyles.tableEvenRow : {},
            ]}
          >
            {columns.map((column, colIndex) => (
              <Fragment key={colIndex}>
                {column.key === "id" ? (
                  <View
                    style={[
                      pdfTableStyles.tableData,
                      {
                        flexBasis: `${100 / columns.length}%`,
                      },
                    ]}
                    break
                  >
                    <Text>{rowIndex + 1}</Text>
                  </View>
                ) : column.key === "precipitation" ? (
                  <View
                    style={[
                      pdfTableStyles.tableData,
                      {
                        flexBasis: `${100 / columns.length}%`,
                      },
                    ]}
                    break
                  >
                    <Text>
                      {
                        weatherDutchTranslationMapping[
                          getFormattedData(row[column.key], column.formatter)
                        ]
                      }
                    </Text>
                  </View>
                ) : column.formatter === "bulletedList" ? (
                  <View
                    style={[
                      pdfTableStyles.tableData,
                      {
                        flexBasis: `${100 / columns.length}%`,
                      },
                    ]}
                    break
                  >
                    {row[column.key]
                      ? row[column.key].map((e, elementIndex) => (
                          <Text key={elementIndex}>• {e}</Text>
                        ))
                      : null}
                  </View>
                ) : (
                  <Text
                    style={[
                      pdfTableStyles.tableData,
                      {
                        flexBasis: `${100 / columns.length}%`,
                      },
                    ]}
                    break
                  >
                    {getFormattedData(row[column.key], column.formatter)}
                  </Text>
                )}
              </Fragment>
            ))}
          </View>
        ))
      )}
    </View>
  );
};
