import { DayPicker } from "react-day-picker";
import {
  CalendarIcon,
  IconButton,
  majorScale,
  Pane,
  Paragraph,
  Popover,
  Position,
  TextInputField,
} from "evergreen-ui";
import { format, isAfter, isBefore, isValid, parse } from "date-fns";
import "react-day-picker/dist/style.css";
import { ChangeEventHandler, useEffect, useState } from "react";

const dateRegex =
  /^\s*(3[01]|[12][0-9]|0?[1-9])-(1[012]|0?[1-9])-((?:2[0-9])\d{2})\s*$/;

export const DateRangeLogic = function ({
  validationErrors,
  setValidationErrors,
  fromValue,
  setFromValue,
  toValue,
  setToValue,
  disabled = false,
}) {
  const [fromInputValue, setFromInputValue] = useState<string | null>();
  const [toInputValue, setToInputValue] = useState<string | null>();

  useEffect(() => {
    if (!fromInputValue && fromValue) {
      setFromInputValue(format(fromValue, "dd-MM-yy"));
      validateFrom(fromValue);
    }

    if (!toInputValue && toValue) {
      setToInputValue(format(toValue, "dd-MM-yy"));
      validateTo(toValue);
    }

    if (!fromValue) {
      setFromInputValue(null);
    }

    if (!toValue) {
      setToInputValue(null);
    }
  }, [fromValue, toValue]);

  const handleFromChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFromInputValue(e.target.value);

    const date = parse(e.target.value, "dd-MM-yy", new Date());

    if (!isValid(date)) {
      setValidationErrors({
        ...(validationErrors ? validationErrors : {}),
        ...{ startAt: ["Invalid date - use the format dd-mm-yy"] },
      });

      return;
    }

    if (!new RegExp(dateRegex).test(e.target.value)) {
      setValidationErrors({
        ...(validationErrors ? validationErrors : {}),
        ...{ startAt: ["Invalid date - use the format dd-mm-yy"] },
      });
    } else {
      setValidationErrors({
        ...(validationErrors ? validationErrors : {}),
        ...{ startAt: undefined },
      });
    }

    setFromValue(date);
    validateFrom(date);
  };

  const handleToChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setToInputValue(e.target.value);

    const date = parse(e.target.value, "dd-MM-yy", new Date());

    if (!isValid(date)) {
      return;
    }

    if (!new RegExp(dateRegex).test(e.target.value)) {
      setValidationErrors({
        ...(validationErrors ? validationErrors : {}),
        ...{ endAt: ["Invalid date - use the format dd-mm-yy"] },
      });
    } else {
      setValidationErrors({
        ...(validationErrors ? validationErrors : {}),
        ...{ endAt: undefined },
      });
    }

    setToValue(date);
    validateTo(date);
  };

  const validateFormat = (value) => {
    if (!new RegExp(dateRegex).test(value)) {
      setValidationErrors({
        ...(validationErrors ? validationErrors : {}),
        ...{ startAt: ["Invalid date - use the format dd-mm-yy"] },
      });
    }
  };

  const validateTo = (date: Date) => {
    if (fromValue && !isAfter(date, fromValue)) {
      setValidationErrors({
        ...(validationErrors ? validationErrors : {}),
        ...{ endAt: ["This date should be after the start date."] },
      });
    } else {
      delete validationErrors.endAt;
      delete validationErrors.startAt;

      setValidationErrors({
        ...(validationErrors ? validationErrors : {}),
      });
    }
  };

  const validateFrom = (date: Date) => {
    if (toValue && !isBefore(date, toValue)) {
      setValidationErrors({
        ...(validationErrors ? validationErrors : {}),
        ...{ startAt: ["This date should be before the end date."] },
      });
    } else {
      delete validationErrors.endAt;
      delete validationErrors.startAt;

      setValidationErrors({
        ...(validationErrors ? validationErrors : {}),
      });
    }
  };

  const handleFromSelect = (value) => {
    setFromValue(value);
    setFromInputValue(format(value, "dd-MM-yy"));
    validateFrom(value);
  };

  const handleToSelect = (value) => {
    setToValue(value);
    setToInputValue(format(value, "dd-MM-yy"));
    validateTo(value);
  };

  return {
    fromInputValue,
    toInputValue,
    handleFromChange,
    handleToChange,
    handleFromSelect,
    handleToSelect,
    validateFormat,
    setFromInputValue,
    setToInputValue,
  };
};

export const DateRangeInput = function ({
  disabled = false,
  validationErrors,
  setValidationErrors,
  fromValue,
  setFromValue,
  toValue,
  setToValue,
  ...rest
}) {
  const {
    fromInputValue,
    toInputValue,
    handleFromChange,
    handleToChange,
    handleFromSelect,
    handleToSelect,
    validateFormat,
  } = DateRangeLogic({
    validationErrors,
    setValidationErrors,
    fromValue,
    setFromValue,
    toValue,
    setToValue,
    disabled,
  });

  return (
    <Pane className="flex gap-2">
      <Pane>
        <Pane className={"flex"}>
          <TextInputField
            disabled={disabled}
            marginBottom={0}
            inputHeight={45}
            onBlur={() => (toValue ? handleFromSelect(fromValue) : null)}
            label=""
            maxWidth={200}
            placeholder="dd-mm-yyyy"
            value={fromInputValue ?? ""}
            onChange={handleFromChange}
            isInvalid={!!validationErrors?.startAt}
            validationMessage={validationErrors?.startAt?.join(", ")}
          />

          <Pane className={"pt-4"}>
            <DayPickerPopover value={fromValue} setValue={handleFromSelect} />
          </Pane>
        </Pane>
      </Pane>

      <Paragraph className={"pt-4"} size={500}>
        /
      </Paragraph>

      <Pane>
        <Pane className={"flex"}>
          <TextInputField
            disabled={disabled}
            label=""
            marginBottom={0}
            inputHeight={45}
            maxWidth={200}
            onBlur={() => (toValue ? handleToSelect(toValue) : null)}
            placeholder="dd-mm-yy"
            value={toInputValue ?? ""}
            onChange={handleToChange}
            isInvalid={!!validationErrors?.endAt}
            validationMessage={validationErrors?.endAt?.join(", ")}
          />

          <Pane className={"pt-4"}>
            <DayPickerPopover value={toValue} setValue={handleToSelect} />
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
};

export const DayPickerPopover = function ({
  value,
  defaultMonth = new Date(),
  setValue,
  ...rest
}) {
  return (
    <Popover
      shouldCloseOnEscapePress={true}
      position={Position.BOTTOM_LEFT}
      content={({ close }) => (
        <>
          <DayPicker
            mode="single"
            showWeekNumber
            captionLayout="dropdown"
            showOutsideDays
            defaultMonth={defaultMonth ?? value}
            selected={value}
            onSelect={(value) => {
              setValue(value);
              close();
            }}
            {...rest}
          />
        </>
      )}
    >
      <IconButton
        appearance="minimal"
        disabled={false}
        icon={CalendarIcon}
        marginLeft="auto"
        marginRight={majorScale(2)}
        type="button"
      />
    </Popover>
  );
};

export default DateRangeInput;
