import {
  Alert,
  FormField,
  majorScale,
  Pane,
  Spinner,
  TextInputField,
  toaster,
} from "evergreen-ui";
import { User } from "../../types/apiTypes";
import { useState } from "react";
import { useDoRequest, useLoadResource } from "../../lib/request-hooks";
import { useApi } from "../../context/AxiosContext";
import { useNavigate } from "react-router-dom";
import Block from "../../components/common/Block";
import Form from "../../components/common/Form";
import Actions from "../../components/common/Actions";
import { useAuth } from "../../context/AuthContext";
import Divider from "../../components/common/Divider";
import Page from "../../components/common/Page";
import ProfilePictureUploader from "../../components/common/ProfilePictureUploader";
import WorkerDetailsForm, {
  useWorkerDetailsForm,
} from "../../components/shared/WorkerDetailsForm";

const useUserFormFields = function () {
  const { apiInstance } = useApi();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);

  const submitContext = useDoRequest();
  const request = () => apiInstance!.users.findMe();

  const setData = (user: User) => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setRole(user.role);
  };

  const doSubmit = async () => {
    const data = {
      firstName,
      lastName,
      email,
      ...(newPassword && { newPassword }),
      ...(currentPassword && { currentPassword }),
    };

    const request = apiInstance!.users.updateMe(data);

    await submitContext.handle(request);
  };

  return {
    ...useLoadResource(request, setData),
    submitContext,
    doSubmit,
    email,
    firstName,
    lastName,
    role,
    setEmail,
    setFirstName,
    setLastName,
    setRole,
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword,
  };
};

export default function AccountSettingsEditPage() {
  const { user, setUser } = useAuth();
  const { apiInstance } = useApi();

  const navigate = useNavigate();

  const {
    isLoading,
    email,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    submitContext,
    doSubmit,
    role,
    setRole,
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword,
  } = useUserFormFields();

  const detailsForm = useWorkerDetailsForm(user?.worker);

  const submit = async () => {
    if (user?.worker) {
      await detailsForm.doSubmit();
    }

    await doSubmit();

    const profile = await apiInstance!.auth.profile();
    setUser!(profile.user);

    navigate("/");

    toaster.success("Saved!");
  };

  return (
    <Page>
      <Block className="flex w-full gap-2">
        {isLoading && (
          <Block className="flex justify-end">
            <Spinner size={20} />
          </Block>
        )}
      </Block>

      {isLoading === false && (
        <Form>
          <>
            <Pane className="flex justify-center py-10">
              <ProfilePictureUploader />
            </Pane>

            <FormField>
              <TextInputField
                required
                name="firstName"
                label="First name"
                placeholder=""
                isInvalid={!!submitContext.validationErrors?.firstName}
                validationMessage={submitContext.validationErrors?.firstName?.join(
                  ", ",
                )}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                marginBottom={0}
                inputHeight={40}
              />
            </FormField>

            <FormField>
              <TextInputField
                required
                name="lastName"
                label="Last name"
                placeholder=""
                isInvalid={!!submitContext.validationErrors?.lastName}
                validationMessage={submitContext.validationErrors?.lastName?.join(
                  ", ",
                )}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                marginBottom={0}
                inputHeight={40}
              />
            </FormField>

            <FormField>
              <TextInputField
                disabled
                name="email"
                label="Email"
                placeholder=""
                isInvalid={!!submitContext.validationErrors?.email}
                validationMessage={submitContext.validationErrors?.email?.join(
                  ", ",
                )}
                value={email}
                marginBottom={0}
                inputHeight={40}
              />
            </FormField>

            <Divider title={"Password"}>
              <FormField>
                <TextInputField
                  name="currentPassword"
                  label="Current password"
                  placeholder=""
                  type="password"
                  isInvalid={!!submitContext.validationErrors?.currentPassword}
                  validationMessage={submitContext.validationErrors?.currentPassword?.join(
                    ", ",
                  )}
                  value={currentPassword ?? ""}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  marginBottom={0}
                  inputHeight={40}
                />
              </FormField>

              <FormField>
                <TextInputField
                  name="newPassword"
                  label="New password"
                  type="password"
                  placeholder=""
                  isInvalid={!!submitContext.validationErrors?.newPassword}
                  validationMessage={submitContext.validationErrors?.newPassword?.join(
                    ", ",
                  )}
                  value={newPassword ?? ""}
                  onChange={(e) => setNewPassword(e.target.value)}
                  marginBottom={0}
                  inputHeight={40}
                />
              </FormField>
            </Divider>
            {submitContext.errorMessage && (
              <Pane>
                <Alert
                  marginTop={10}
                  intent="danger"
                  title={submitContext.errorMessage}
                />
              </Pane>
            )}

            {user?.worker && (
              <Pane>
                <Divider title="Your Details">
                  <WorkerDetailsForm
                    showActionButtons={false}
                    useForm={detailsForm}
                  />
                </Divider>
              </Pane>
            )}

            <Actions marginTop={10} marginBottom={10}>
              <Actions.Button
                type="cancel"
                onClick={() => navigate(`/`)}
                disabled={!!submitContext.isLoading}
                height={majorScale(5)}
              >
                Cancel
              </Actions.Button>

              <Actions.Button
                type="submit"
                onClick={submit}
                isLoading={!!submitContext.isLoading}
                appearance="primary"
                intent="success"
                height={majorScale(5)}
              >
                Save
              </Actions.Button>
            </Actions>
          </>
        </Form>
      )}
    </Page>
  );
}
