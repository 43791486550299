import { Card, EmptyState, Heading, Pane, SearchIcon } from "evergreen-ui";
import PageTitle from "src/components/common/PageTitle";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLoadResource } from "src/lib/request-hooks";
import { Period } from "src/types/apiTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TableLoadingSpinner } from "src/components/table/StandardTable";
import { useApi } from "src/context/AxiosContext";
import Page from "src/components/common/Page";
import { isEmpty } from "lodash";
import { __r, WORKER_PERIODS_AVAILABILITY } from "../../RouteMap";
import AvailabilityPeriodCard from "../../components/shared/AvailabilityPeriodCard";

const usePeriods = function (adminEdit = false) {
  const { apiInstance } = useApi();
  const [searchParams] = useSearchParams();
  const { workerId } = useParams();

  const [periods, setPeriods] = useState<Period[]>([]);

  let find;

  if (adminEdit && workerId) {
    find = () => apiInstance!.adminPeriods.findMany(searchParams);
  } else {
    find = () => apiInstance!.workerAvailabilityRequests.findMany(searchParams);
  }

  const setData = (periods) => {
    setPeriods(periods);
  };

  return {
    ...useLoadResource(find, setData, true),
    periods,
  };
};

export default function WorkerPeriodsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { periods, isLoading } = usePeriods();

  const navTo = (period: Period) => {
    navigate(__r(WORKER_PERIODS_AVAILABILITY, { periodId: period.id }));
  };

  return (
    <Page>
      <PageTitle>{t("worker_requests_page.title")}</PageTitle>

      <Pane className="flex flex-col gap-4 pt-10">
        {isLoading === true && (
          <TableLoadingSpinner title={"Periodes ophalen..."} />
        )}

        {isLoading === false && !isEmpty(periods) && (
          <>
            <Heading size={100} className="!font-bold">
              {t("worker_requests_page.availability_requests")}
            </Heading>

            {periods.map((period, index) => (
              <AvailabilityPeriodCard
                key={period.id}
                period={period}
                navTo={navTo}
              />
            ))}
          </>
        )}

        {isLoading === false && isEmpty(periods) && (
          <EmptyWorkerRequestsState />
        )}
      </Pane>
    </Page>
  );
}

function EmptyWorkerRequestsState() {
  const { t } = useTranslation();

  return (
    <Card elevation={1}>
      <EmptyState
        background="light"
        title="Er zijn nu nog geen periods waar je je beschikbaarheid op kunt aangeven"
        icon={<SearchIcon color="#C1C4D6" />}
        orientation="horizontal"
        iconBgColor="#EDEFF5"
        description="Geen periodes gevonden, maar kom snel terug om te kijken of ze er zijn om je beschikbaarheid aan te geven voor de komende periode!"
      />
    </Card>
  );
}
