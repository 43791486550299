import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { Id, Project } from "../types/apiTypes";
import { PostData, SearchParams } from "../types/appTypes";

export default class AdminProjects extends BaseApi {
  public findOne(id: Id): Promise<Project> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/projects/${id}`,
    });
  }

  public create(data: PostData): Promise<Project> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/projects`,
      data: data,
    });
  }

  public update(id: Id, data: PostData): Promise<Project> {
    return doRequest(this._axiosInstance!, {
      method: "put",
      url: `/admin/projects/${id}`,
      data: data,
    });
  }

  public findMany(searchParams: SearchParams = {}): Promise<Project[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/projects${query}`,
    });
  }

  public remove(id: Id): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: "delete",
      url: `/admin/projects/${id}`,
    });
  }
}
