import { Image, Text, View } from "@react-pdf/renderer";
import { pdfMultipleImagesStyles } from "./styles/pdfReportStyles";
import { memo, useEffect, useState } from "react";
import { fetchImagesUrls } from "../utility";

export const PdfPreviewMultipleImages = memo(
  ({
    medias,
  }: {
    medias: {
      fullPath: string;
      mimeType: string;
      originalFileName: string;
      modelId: string;
    }[];
  }) => {
    const [imageUrls, setImageUrls] = useState<Array<string>>([]);
    useEffect(() => {
      async function fetchImagesFromMedia() {
        const urls = await fetchImagesUrls(medias);
        setImageUrls(urls);
      }
      fetchImagesFromMedia();
    }, [medias]);

    if (medias.length) {
      return (
        <View style={pdfMultipleImagesStyles.observationPhotosContainer}>
          {imageUrls.map((imageUrl, index) => {
            return (
              <View
                key={index}
                style={pdfMultipleImagesStyles.observationPhotoContainer}
              >
                <Image
                  style={pdfMultipleImagesStyles.observationPhoto}
                  src={imageUrl}
                />
                <Text style={pdfMultipleImagesStyles.observationModelId}>
                  {medias[index].modelId}
                </Text>
              </View>
            );
          })}
        </View>
      );
    }
    return null;
  },
  (prevProps, nextProps) => {
    return prevProps.medias === nextProps.medias;
  },
);
