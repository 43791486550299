import { useState } from "react";
import { parallel } from "@/lib/parallel";

export default function useMediaUpload({
  mediaFiles,
  setMediaFiles,
  setIsLoading,
  uploadFn,
}) {
  const [files, setFiles] = useState<File[]>([]);
  const [fileUploadState, setFileUploadState] = useState({});

  const uploadFile = async (file: File) => {
    // Upload the image using the fetch and FormData APIs
    const formData = new FormData();

    // Assume "image" is the name of the form field the server expects
    formData.append("file", file);

    return await uploadFn(formData);
  };

  const setFilesCb = async (acceptedFiles: File[]) => {
    const newUploadState = {
      ...fileUploadState,
    };

    const tasks = [];

    acceptedFiles.forEach((file) => {
      newUploadState[file.name] = {
        isLoading: true,
      };

      //@ts-ignore
      tasks.push(async () => {
        const media = await uploadFile(file);

        setFileUploadState({
          ...fileUploadState,
          [file.name]: {
            isLoading: false,
            media: media.src,
          },
        });

        return media;
      });
    });

    setFileUploadState(newUploadState);
    setFiles([...files, ...acceptedFiles]);

    setIsLoading(true);

    const uploadedMediaFiles = await parallel(tasks, 1);
    setIsLoading(false);

    setMediaFiles([...mediaFiles, ...uploadedMediaFiles]);
  };

  return {
    files,
    setFilesCb,
    setFiles,
    fileUploadState,
  };
}
