import {
  Alert,
  Button,
  FormField,
  majorScale,
  Pane,
  Paragraph,
  Switch,
  TextInputField,
} from "evergreen-ui";
import Block from "src/components/common/Block";
import { useState } from "react";
import { useDoRequest, useLoadResource } from "src/lib/request-hooks";
import { useApi } from "src/context/AxiosContext";
import Divider from "src/components/common/Divider";
import { Address, WorkerApiType } from "src/types/apiTypes";
import { useTranslation } from "react-i18next";
import MapBoxAddressInputField from "../formfields/MapBoxAddressInputField";
import { useAuth } from "../../context/AuthContext";

/**
 * Pass the adminEdit if we an admin is editing the worker. This will change the update and find function.
 *
 * @param worker
 * @param adminEdit
 */
export function useWorkerDetailsForm(worker, adminEdit = false) {
  const { apiInstance } = useApi();

  const {
    handle,
    setIsLoading: setIsSubmitting,
    isLoading: isSubmitting,
    validationErrors,
    errorMessage,
  } = useDoRequest();

  const [iban, setIban] = useState<string | null>(null);
  const [ibanOwner, setIbanOwner] = useState<string | null>(null);
  const [hasTransport, setHasTransport] = useState<boolean>(false);
  const [hasDrivingLicense, setHasDrivingLicense] = useState<boolean>(false);
  const [phone, setPhone] = useState<string | null>(null);
  const [address, setAddress] = useState<Address | null>(null);
  const [isVeteran, setIsVeteran] = useState<boolean>(false);
  const [isPayable, setIsPayable] = useState<boolean>(false);
  const [isPlannable, setIsPlannable] = useState<boolean>(false);
  const [isVerloningUser, setIsVerloningUser] = useState<boolean>(false);
  const [verloningEmail, setVerloningEmail] = useState<string | null>(null);

  let find;

  if (adminEdit) {
    find = () => apiInstance!.adminWorkers.findOne(worker.id);
  } else {
    find = () => apiInstance!.worker.findMe();
  }

  function setData(worker: WorkerApiType) {
    setIban(worker.iban);
    setIbanOwner(worker.ibanOwner);
    setHasTransport(worker.hasTransport);
    setHasDrivingLicense(worker.hasDrivingLicense);
    setPhone(worker.phone ?? "");
    setAddress(worker.address);
    setIsVeteran(worker.isVeteran);
    setIsPlannable(worker.isPlannable);
    setIsPayable(worker.isPayable);
    setIsVerloningUser(worker.isVerloningUser);
    setVerloningEmail(worker.verloningEmail);
  }

  const doSubmit = async () => {
    const data = {
      iban,
      ibanOwner,
      hasTransport,
      hasDrivingLicense,
      phone,
      address,
      isVeteran,
      isPayable,
      isPlannable,
      isVerloningUser,
      verloningEmail,
    };

    let request;

    if (adminEdit) {
      request = apiInstance!.adminWorkers.update(worker.id, data);
    } else {
      request = apiInstance!.worker.updateMe(data);
    }
    return await handle(request);
  };

  return {
    ...useLoadResource(find, setData, !!worker),
    iban,
    setIban,
    ibanOwner,
    setIbanOwner,
    // profilePicture,
    // setProfilePicture,
    hasTransport,
    setHasTransport,
    hasDrivingLicense,
    setHasDrivingLicense,
    isPayable,
    setIsPayable,
    isPlannable,
    setIsPlannable,
    isVerloningUser,
    setIsVerloningUser,
    verloningEmail,
    setVerloningEmail,
    address,
    setAddress,
    doSubmit,
    phone,
    setPhone,
    isVeteran,
    setIsVeteran,
    submitContext: {
      doSubmit,
      setIsSubmitting,
      isSubmitting,
      errorMessage,
      validationErrors,
    },
  };
}

export default function WorkerDetailsForm({
  useForm,
  showActionButtons = true,
  ...rest
}) {
  const { t } = useTranslation();
  const { user } = useAuth();

  const {
    iban,
    setIban,
    address,
    setAddress,
    ibanOwner,
    setIbanOwner,
    hasTransport,
    setHasTransport,
    setHasDrivingLicense,
    hasDrivingLicense,
    isPayable,
    setIsPayable,
    isPlannable,
    setIsPlannable,
    isVerloningUser,
    setIsVerloningUser,
    verloningEmail,
    setVerloningEmail,
    doSubmit,
    phone,
    setPhone,
    submitContext,
    isLoading,
    isVeteran,
    setIsVeteran,
  } = useForm;

  return (
    <Pane>
      {isLoading === false && (
        <Block className={"align-items flex flex-col justify-center gap-5"}>
          <TextInputField
            autoFocus={showActionButtons}
            marginBottom={10}
            inputHeight={40}
            label={t("common.mobile_phone")}
            name="phone"
            inputMode="tel"
            hint={t("worker_details_onboarding_page.mobile_phone_hint")}
            placeholder="+316 ... "
            value={phone ?? ""}
            onChange={(e) => setPhone(e.target.value)}
            isInvalid={!!submitContext.validationErrors?.phone}
            validationMessage={submitContext.validationErrors?.phone?.join(
              ", ",
            )}
          />

          <Divider title="Payment settings">
            <FormField
              label="Betaling via verloning.nl"
              hint="Zet dit uit als je ingeschreven bent bij de KVK en ons zelf facturen kunt sturen met een geldig kvk nummer."
            >
              <Switch
                marginBottom={16}
                checked={isVerloningUser}
                onChange={(e) => setIsVerloningUser(e.target.checked)}
              />
            </FormField>

            {isVerloningUser && (
              <TextInputField
                autoFocus={showActionButtons}
                marginBottom={10}
                inputHeight={40}
                label="Email address voor verloning.nl"
                name="verloningEmail"
                inputMode="email"
                value={verloningEmail ?? ""}
                onChange={(e) => setVerloningEmail(e.target.value)}
                isInvalid={!!submitContext.validationErrors?.verloningEmail}
                validationMessage={submitContext.validationErrors?.verloningEmail?.join(
                  ", ",
                )}
              />
            )}
          </Divider>

          <Divider title="Address">
            <FormField
              label={t(
                "worker_details_onboarding_page.full_address",
              ).toString()}
              marginBottom={20}
            >
              <Paragraph className="!text-xs" color="muted">
                {t("worker_details_onboarding_page.full_address_hint")}
              </Paragraph>
              <MapBoxAddressInputField
                setAddress={setAddress}
                address={address}
                minMapHeight={400}
                textBoxWidth={200}
              />
              {/*<AddressInputField*/}
              {/*  address={address}*/}
              {/*  inputLabel={t('worker_details_onboarding_page.full_address').toString()}*/}
              {/*  setIsEditing={setIsEditingAddress}*/}
              {/*  setAddress={setAddress}*/}
              {/*  validationErrors={submitContext.validationErrors?.address}*/}
              {/*/>*/}
            </FormField>
          </Divider>

          <FormField
            label={t("worker_details_onboarding_page.driving_license")}
          >
            <Switch
              marginBottom={16}
              checked={hasDrivingLicense}
              onChange={(e) => setHasDrivingLicense(e.target.checked)}
            />
          </FormField>

          <FormField
            hint={t("worker_details_onboarding_page.own_transportation_hint")}
            label={t("worker_details_onboarding_page.own_transportation")}
          >
            <Switch
              marginBottom={16}
              checked={hasTransport}
              onChange={(e) => setHasTransport(e.target.checked)}
            />
          </FormField>

          {user?.role === "admin" && (
            <Divider title="Admin settings">
              <FormField
                hint={t("worker_details_onboarding_page.is_veteran_hint")}
                label={t("worker_details_onboarding_page.is_veteran")}
              >
                <Switch
                  marginBottom={16}
                  checked={isVeteran}
                  onChange={(e) => setIsVeteran(e.target.checked)}
                />
              </FormField>

              <FormField
                label="Worker mag ingeplanned worden"
              >
                <Switch
                  marginBottom={16}
                  checked={isPlannable}
                  onChange={(e) => setIsPlannable(e.target.checked)}
                />
              </FormField>

              <FormField
                label="Kan uitbetaald worden"
              >
                <Switch
                  marginBottom={16}
                  checked={isPayable}
                  onChange={(e) => setIsPayable(e.target.checked)}
                />
              </FormField>
            </Divider>
          )}

          {submitContext.errorMessage && (
            <Alert
              intent="danger"
              title={submitContext.errorMessage}
              marginBottom={32}
            />
          )}

          {showActionButtons && (
            <Block className={"flex justify-end"}>
              <Button
                disabled={
                  !address ||
                  !phone ||
                  !user!.profilePicture
                }
                intent="success"
                appearance="primary"
                onClick={doSubmit}
                isLoading={!!submitContext.isSubmitting}
                height={majorScale(5)}
              >
                {t("worker_details_onboarding_page.save_label")}
              </Button>
            </Block>
          )}
        </Block>
      )}
    </Pane>
  );
}
