import {
  ClientActivity,
  ClientObject,
  Media as MediaFile,
} from "@/types/apiTypes";
import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Block from "@/components/common/Block";
import {
  Button,
  FileCard,
  FormField,
  Heading,
  MediaIcon,
  Pane,
  Paragraph,
  TextareaField,
  TextInputField,
} from "evergreen-ui";
import { LabeledOption } from "@/types/appTypes";
import { classNames, shortenString } from "@/lib/functions";
import MultiFileUploader from "@/components/formfields/MultiFileUploader";
import { isEmpty } from "lodash";
import { __r, ADMIN_OBJECT_ADD_PAGE } from "@/RouteMap";
import { useNavigate, useParams } from "react-router-dom";
import ClientActivityMultiSelect from "@/pages/projects/objects/ClientActivityMultiSelect";
import ObjectClientActivitiesStatic from "@/pages/projects/objects/ObjectClientActivities";
import useObjectForm from "@/pages/projects/objects/useObjectForm.hook";

type ObjectListItemProps = {
  activeObject: ClientObject | null;
  afterDelete: (object: ClientObject) => void;
  afterSave: (object: ClientObject) => void;
  clientActivities: ClientActivity[];
  isEditMode: boolean;
  object: ClientObject;
  onClickEdit: (object: ClientObject) => void;
  updateList: (object: ClientObject) => void;
  objectApi: ReturnType<typeof useObjectForm>;
  onClickLocate: (object: ClientObject) => void;
};

type UseObjectItemEditStateProps = {
  objectApi: ReturnType<typeof useObjectForm>;
  object: ClientObject;
  clientActivities: ClientActivity[];
};

type LabeledOptionWithActivity = LabeledOption & {
  activity: ClientActivity;
};

function useObjectItemEditState({
  objectApi,
  object,
  clientActivities,
}: UseObjectItemEditStateProps) {
  const [name, setName] = useState<string>(object.name);
  const [notes, setNotes] = useState<string | null>(object.notes);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [uploadingMediaFiles, setUploadingMediaFiles] = useState<MediaFile[]>(
    object.media || [],
  );
  const [mediaFiles, setMediaFiles] = useState<MediaFile[]>(object.media || []);
  const formContext = objectApi.submitContext;

  const clientActivityOptions: LabeledOptionWithActivity[] =
    clientActivities.map((clientActivity) => ({
      value: clientActivity.id.toString(),
      label: clientActivity.name,
      activity: clientActivity,
    }));

  const selectedOptions: LabeledOptionWithActivity[] = object.activities?.map(
    (clientActivity) => ({
      value: clientActivity.id.toString(),
      label: clientActivity.name,
      activity: clientActivity,
    }),
  );

  const [selectedActivities, setSelectedActivities] =
    useState<LabeledOptionWithActivity[]>(selectedOptions);

  function onUpdatePreciseLocation(
    item: LabeledOption,
    preciseLocationInfo: string,
  ) {
    const newSelectedActivities = selectedActivities.map(
      (clientActivityLabeledOption) => {
        if (clientActivityLabeledOption.value === item.value) {
          return {
            ...clientActivityLabeledOption,
            activity: {
              ...clientActivityLabeledOption.activity,
              preciseLocationInfo: preciseLocationInfo
                ?.split(",")
                .map((v) => v.trim().toLowerCase()),
            },
          };
        }

        return clientActivityLabeledOption;
      },
    );

    setSelectedActivities(newSelectedActivities);
    formContext.setIsDirty(true);
  }

  return {
    name,
    setName,
    notes,
    setNotes,
    isUploading,
    setIsUploading,
    uploadingMediaFiles,
    setUploadingMediaFiles,
    mediaFiles,
    setMediaFiles,
    formContext,
    clientActivityOptions,
    selectedActivities,
    setSelectedActivities,
    onUpdatePreciseLocation,
  };
}

export default function ObjectListItem(props: ObjectListItemProps) {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const {
    object,
    onClickEdit,
    clientActivities,
    activeObject,
    isEditMode,
    afterSave,
    afterDelete,
    updateList,
    objectApi,
  } = props;

  const [editMode, setEditMode] = useState<boolean>(isEditMode);
  const objectRef = useRef<HTMLDivElement | null>(null);

  const {
    name,
    setName,
    notes,
    setNotes,
    setIsUploading,
    uploadingMediaFiles,
    setUploadingMediaFiles,
    mediaFiles,
    setMediaFiles,
    formContext,
    clientActivityOptions,
    selectedActivities,
    setSelectedActivities,
    onUpdatePreciseLocation,
  } = useObjectItemEditState({ objectApi, object, clientActivities });

  async function onSubmit() {
    const isDirty =
      formContext.isDirty ||
      !(
        JSON.stringify(object) === JSON.stringify({ ...object, name, notes })
      ) ||
      !(
        selectedActivities
          .map((v) => v.value)
          .sort()
          .join(",") ===
        object.activities
          ?.map((v) => v.id)
          .sort()
          .join(",")
      ) ||
      !(
        mediaFiles
          .map((v) => v.id)
          .sort()
          .join(",") ===
        object.media
          ?.map((v) => v.id)
          .sort()
          .join(",")
      );

    if (!isDirty) {
      setEditMode(false);

      return;
    }

    const updatedObject = await objectApi!.doUpdate(object.id!, {
      name,
      notes,
      activities: selectedActivities.map((v) => v.activity),
      mediaIds: mediaFiles.map((v) => v.id),
    });

    setEditMode(false);
    setMediaFiles(updatedObject.media);
    afterSave(updatedObject);
  }

  async function onDelete() {
    await objectApi!.doRemove(object.id!);

    afterDelete(object);
  }

  async function uploadMedia(formData: FormData) {
    return objectApi.uploadMedia(object.id!, formData).then((object) => {
      updateList(object);
      return object;
    });
  }

  async function onRemoveMedia(file: MediaFile) {
    const newObject = await objectApi.removeMedia(object.id!, file.id);

    setMediaFiles(newObject.media);
    updateList(newObject);
  }

  useEffect(() => {
    if (activeObject?.id === object.id) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [activeObject, objectRef]);

  useLayoutEffect(() => {
    if (editMode && objectRef.current) {
      objectRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [editMode]);

  return (
    <div
      ref={objectRef}
      className={classNames(
        editMode ? "border-2 bg-gray-50 shadow" : "",
        "mb-4 rounded border-b-2 border-l-2 border-r-2 border-t p-2 py-4",
      )}
    >
      <Block>
        {!editMode && (
          <>
            <Block className="flex flex-col gap-2">
              <Block className="flex flex-grow items-center justify-between gap-2 pr-2">
                <Heading size={400}>
                  {object.name}
                  <Paragraph>{object.notes}</Paragraph>
                </Heading>
                <Button
                  className="self-start"
                  height={24}
                  onClick={() => {
                    setEditMode(!editMode);
                    onClickEdit(object);
                  }}
                >
                  Edit
                </Button>
              </Block>

              <Pane className="flex flex-wrap gap-1">
                <ObjectClientActivitiesStatic
                  clientActivities={object.activities}
                />
              </Pane>
              <Block>
                {object.address && (
                  <Heading size={100}>
                    {object.address.formattedAddress}
                  </Heading>
                )}
              </Block>
              {object.media?.length > 0 && (
                <Block className="flex items-center gap-2">
                  <Paragraph size={300}>{object.media.length}</Paragraph>
                  <MediaIcon color="muted" />
                </Block>
              )}
            </Block>
          </>
        )}
      </Block>

      {editMode && (
        <Block className="flex flex-col gap-2 pr-3">
          <Block className="flex justify-between gap-3">
            <Button
              height={24}
              onClick={() => {
                setEditMode(!editMode);
                navigate(
                  __r(ADMIN_OBJECT_ADD_PAGE, {
                    projectId: projectId!,
                  }),
                );
              }}
            >
              Cancel
            </Button>
            <Button
              height={24}
              intent={editMode ? "success" : "none"}
              appearance={editMode ? "primary" : "minimal"}
              onClick={onSubmit}
            >
              Save
            </Button>
          </Block>
          <TextInputField
            required
            name="name"
            label="Naam"
            placeholder="Naam gebied..."
            isInvalid={!!objectApi.submitContext.validationErrors?.name}
            validationMessage={objectApi.submitContext.validationErrors?.name?.join(
              ", ",
            )}
            value={name}
            onChange={(e) => setName(e.target.value)}
            marginBottom={0}
            inputHeight={50}
          />
          <TextareaField
            name="notes"
            width={"100%"}
            label={"Notities"}
            placeholder="..."
            value={notes || ""}
            isInvalid={!!objectApi.submitContext.validationErrors?.notes}
            validationMessage={objectApi.submitContext.validationErrors?.notes?.join(
              ", ",
            )}
            onChange={(e) => setNotes(e.target.value)}
            marginBottom={0}
            inputHeight={20}
            inputWidth={"100%"}
            className="!text-base"
          />

          <FormField label="Client Activities">
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <ClientActivityMultiSelect
              onUpdatePreciseLocation={onUpdatePreciseLocation}
              clientActivityOptions={clientActivityOptions}
              selected={selectedActivities}
              setSelected={setSelectedActivities}
            />
          </FormField>

          <FormField label="Bestanden" className="max-w-[30rem]">
            <MultiFileUploader
              uploadFn={uploadMedia}
              mediaFiles={uploadingMediaFiles}
              setMediaFiles={(v) => {
                formContext.setIsDirty(true);
                setUploadingMediaFiles(v);
              }}
              setIsLoading={setIsUploading}
            />
            <AttachedMediaFiles
              mediaFiles={mediaFiles}
              maxLengthFileName={60}
              editMode={editMode}
              onRemove={onRemoveMedia}
            />
          </FormField>

          <Block className="flex gap-2">
            <Button
              height={24}
              intent={"danger"}
              appearance={"primary"}
              onClick={onDelete}
            >
              Delete
            </Button>
          </Block>
        </Block>
      )}
    </div>
  );
}

export function AttachedMediaFiles({
  mediaFiles,
  maxLengthFileName,
  editMode,
  onRemove,
}) {
  const handleRemove = (file) => {
    onRemove(file);
  };

  return (
    <Block className="flex gap-1">
      {mediaFiles.map((media, index) => (
        <Fragment key={`${media.originalFileName}-${index}`}>
          <a
            rel="noreferrer"
            href={media.src}
            className="cursor-pointer hover:underline"
            target="_blank"
          >
            <FileCard
              className="overflow-hidden"
              isInvalid={false}
              name={shortenString(media.originalFileName, maxLengthFileName)}
              //@ts-ignore
              onRemove={
                editMode
                  ? (e) => {
                      e.preventDefault();
                      handleRemove(media);
                    }
                  : undefined
              }
              sizeInBytes={media.size}
              type={media.mimeType}
              isLoading={false}
              src={media.src}
            />
          </a>
        </Fragment>
      ))}

      {isEmpty(mediaFiles) && <Paragraph>-</Paragraph>}
    </Block>
  );
}
