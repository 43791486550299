export const roleOptions = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "worker",
    label: "Worker",
  },
  {
    value: "default",
    label: "Default",
  },
];
