import { useApi } from "@/context/AxiosContext";
import { useState } from "react";
import { ClientObject, Id } from "@/types/apiTypes";
import { useLoadResource } from "@/lib/request-hooks";
import { FilterMapType } from "@/types/appTypes";
import { useDoFilter } from "@/hooks/useDoFilter";
import { useWindowSearchParams } from "@/hooks/useWindowSearchParams";
import { FeatureCollection } from "geojson";

export default function useObjectsForArea({
  searchParams,
  areaId,
}: {
  areaId: Id | undefined;
  searchParams?: URLSearchParams;
}) {
  const { apiInstance } = useApi();

  const windowSearchParams = useWindowSearchParams();

  const [objects, setObjects] = useState<ClientObject[]>([]);
  const [geoObjects, setGeoObjects] = useState<FeatureCollection | null>(null);


  const find = (areaId: Id) =>
    apiInstance!.adminObjects.findManyForArea(
      areaId,
      searchParams || windowSearchParams,
    );

  const setData = (objects: ClientObject[]) => {
    setObjects(objects);
    setGeoObjects(
      {
        type: "FeatureCollection",
        features:
          objects?.map((object) => {
            return {
              id: object.id,
              type: "Feature",
              geometry: object.geometry.geometry,
              properties: {
                id: object.id,
                name: object.name,
                type: "object",
                object_id: object.id,
                icon: "marker",
              },
            };
          }) ?? [],
      } as FeatureCollection
    )
  };

  const loadResourceLogic = useLoadResource(
    () => find(areaId!),
    setData,
    !!areaId,
  );

  const [name, setName] = useState<string | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);

  const filterMap: FilterMapType = {
    objectName: {
      setter: setName,
      topic: "objectName",
      value: name,
    },
  };

  const filterActions = useDoFilter({
    searchParams: windowSearchParams,
    setInitialized,
    initialized,
    filterMap,
    loadResourceLogic,
    find,
    setData,
    setMeta: () => {},
  });

  return {
    ...loadResourceLogic,
    objects,
    geoObjects,
    setObjects: setData,
    filterMap,
    handleInput: filterActions.handleInput,
  };
}
