import { Fragment, useCallback, useMemo, useState } from "react";
import {
  Alert,
  FileCard,
  FileRejection,
  FileUploader,
  majorScale,
  Pane,
  rebaseFiles,
} from "evergreen-ui";
import useMediaUpload from "@/hooks/useMediaUpload";

export const acceptedMimeTypes = [
  "image/png",
  "image/jpeg",
  "application/pdf",
  "video/quicktime",
  "video/3gpp",
  "video/x-msvideo",
  "video/mp4",
  "application/mp4",
  "application/zip",
  "application/x-rar-compressed",
  "application/x-tar",
  "application/x-7z-compressed",
  "application/x-bzip",
  "application/x-bzip2",
  "application/x-gzip",
  "application/x-xz",
  "application/x-msdownload",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "multipart/x-zip",
  "multipart/x-gzip",
  "application/octet-stream",
  "application/x-zip-compressed",
];

export default function MultiFileUploader({
  mediaFiles,
  setMediaFiles,
  setIsLoading,
  uploadFn,
  ...rest
}) {
  const { fileUploadState, files, setFiles, setFilesCb } = useMediaUpload({
    mediaFiles,
    setMediaFiles,
    setIsLoading,
    uploadFn,
  });

  const maxFiles = 50;
  const maxSizeInBytes = 250 * 1024 ** 2; // 250 MB
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([]);

  const values = useMemo(
    () => [
      ...files,
      ...fileRejections.map((fileRejection) => fileRejection.file),
    ],
    [files, fileRejections],
  );

  const handleRemove = useCallback(
    (file: File) => {
      const updatedFiles = files.filter(
        (existingFile) => existingFile !== file,
      );
      const updatedFileRejections = fileRejections.filter(
        (fileRejection) => fileRejection.file !== file,
      );

      // Call rebaseFiles to ensure accepted + rejected files are in sync (some might have previously been
      // rejected for being over the file count limit, but might be under the limit now!)
      const { accepted, rejected } = rebaseFiles(
        [
          ...updatedFiles,
          ...updatedFileRejections.map((fileRejection) => fileRejection.file),
        ],
        //@ts-ignore
        { acceptedMimeTypes, maxFiles, maxSizeInBytes },
      );

      setFiles(accepted);
      setFileRejections(rejected);
    },
    [acceptedMimeTypes, files, fileRejections, maxFiles, maxSizeInBytes],
  );

  const fileCountOverLimit = files.length + fileRejections.length - maxFiles;
  const fileCountError = `You can upload up to 50 files at a time. Please remove ${fileCountOverLimit} ${
    fileCountOverLimit === 1 ? "file" : "files"
  }.`;

  return (
    <Pane {...rest}>
      <FileUploader
        //@ts-ignore
        // acceptedMimeTypes={acceptedMimeTypes}
        description="Files can be up to 250MB. You can upload .jpg, .png and .pdf, .mov, .mp4, .zip file formats."
        // disabled={files.length + fileRejections.length >= maxFiles}
        maxSizeInBytes={maxSizeInBytes}
        // maxFiles={maxFiles}
        onAccepted={setFilesCb}
        onRejected={setFileRejections}
        renderFile={(file, index) => {
          const { name, size, type } = file;
          const renderFileCountError = index === 0 && fileCountOverLimit > 0;

          // We're displaying an <Alert /> component to aggregate files rejected for being over the maxFiles limit,
          // so don't show those errors individually on each <FileCard />
          // const fileRejection = fileRejections.find(
          //     (fileRejection) => fileRejection.file === file && fileRejection.reason !== FileRejectionReason.OverFileLimit
          // )
          // const { message } = fileRejection || {}

          return (
            <Fragment key={`${file.name}-${index}`}>
              {renderFileCountError && (
                <Alert
                  intent="danger"
                  marginBottom={majorScale(2)}
                  title={fileCountError}
                />
              )}
              <FileCard
                isInvalid={false}
                name={name}
                onRemove={() => handleRemove(file)}
                sizeInBytes={size}
                type={type}
                isLoading={fileUploadState[file.name]?.isLoading}
                src={fileUploadState[file.name]?.src}
                // validationMessage={message}
              />
            </Fragment>
          );
        }}
        values={values}
      />
    </Pane>
  );
}
