import { useNavigate, useParams } from "react-router-dom";
import Page from "@/components/common/Page";
import PageTitle from "@/components/common/PageTitle";
import { useApi } from "@/context/AxiosContext";
import { useLoadResource } from "@/lib/request-hooks";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ObservationMap from "@/components/shared/observation/ObservationMap.component";
import { useWorkerShift } from "./hooks/useWorkerShift";
import { FeatureCollection } from "geojson";
import WorkerShiftSubmitDialog from "@/pages/worker-shifts/_components/WorkerShiftSubmitDialog";
import { WORKER_SHIFTS_PLANNED_PAGE } from "@/RouteMap";
import Actions from "@/components/common/Actions";
import { Heading, majorScale, Paragraph } from "evergreen-ui";
import Block from "@/components/common/Block";

function useWorkerShiftObservations({ shiftId }) {
  const { apiInstance } = useApi();
  const [observations, setObservations] = useState<FeatureCollection>();

  const find = () =>
    apiInstance!.workerShiftObservation.findAllForShift(shiftId!, {
      asGeoJson: true,
    });

  function setData(data) {
    setObservations(data);
  }

  function uploadFn(formData) {
    // return apiInstance!.workerShiftObservation.uploadFile(formData);
  }

  return {
    ...useLoadResource(find, setData, !!shiftId),
    observations,
  };
}

export default function WorkerShiftObservationsPage() {
  const { t } = useTranslation();
  const { shiftId } = useParams();

  const { workerShift } = useWorkerShift();
  const { observations } = useWorkerShiftObservations({ shiftId });

  const [shiftStatusChange, setShiftStatusChange] = useState<string>("");

  const navigate = useNavigate();

  function goBack() {
    navigate(WORKER_SHIFTS_PLANNED_PAGE);
  }

  return (
    <Page>
      <PageTitle
        actions={
          <Actions className="py-2">
            <Actions.Button
              onClick={() => setShiftStatusChange("started")}
              appearance="primary"
              height={majorScale(5)}
            >
              Starten
            </Actions.Button>
            <Actions.Button
              onClick={() => setShiftStatusChange("stopped")}
              appearance="primary"
              intent="danger"
              height={majorScale(5)}
            >
              Stoppen
            </Actions.Button>
            <Actions.Button
              onClick={() => setShiftStatusChange("submitted")}
              appearance="primary"
              intent="success"
              height={majorScale(5)}
            >
              Indienen
            </Actions.Button>
          </Actions>
        }
      >
        {t("worker_shifts_observations_page.title")}
      </PageTitle>

      {workerShift && (
        <>
          <ShiftStatus workerShift={workerShift} />
          <WorkerShiftSubmitDialog
            workerShift={workerShift}
            isShown={!!shiftStatusChange}
            status={shiftStatusChange}
            close={() => setShiftStatusChange("")}
            afterSubmit={() => {
              setShiftStatusChange("");
            }}
          />
          <ObservationMap
            geoObjects={workerShift.geoObjects ?? []}
            geoArea={workerShift?.geoArea}
            // @ts-ignore
            observations={observations ?? []}
          />
        </>
      )}
    </Page>
  );
}

function ShiftStatus({ workerShift }) {
  const currentStatus = workerShift.statusUpdates.at(-1);

  return (
    <Block>
      <Block>
        <Heading size={400}>Status</Heading>
        <Paragraph>{workerShift.status}</Paragraph>
      </Block>
      <Block>
        <Heading size={400}>Opmerking afkeuring:</Heading>
        <Paragraph>{currentStatus.adminNotes}</Paragraph>
      </Block>
    </Block>
  );
}
