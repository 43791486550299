import {
  Alert,
  Button,
  Card,
  FormField,
  Heading,
  majorScale,
  Pane,
  SendMessageIcon,
  Spinner,
  Text,
  TextInputField,
  toaster,
  TrashIcon,
} from "evergreen-ui";
import { Invite } from "src/types/apiTypes";
import { useDoRequest, useLoadResource } from "src/lib/request-hooks";
import { useApi } from "src/context/AxiosContext";
import { useNavigate, useParams } from "react-router-dom";
import Block from "src/components/common/Block";
import Form from "src/components/common/Form";
import Actions from "src/components/common/Actions";
import { useState } from "react";
import PageTitle from "src/components/common/PageTitle";
import DateValue from "src/components/common/DateValue";
import TextClipBoard from "src/components/common/TextClipBoard";
import Page from "../../../components/common/Page";

const useInviteFormFields = function () {
  const navigate = useNavigate();

  const { inviteId } = useParams();
  const { apiInstance } = useApi();

  const submitContext = useDoRequest();
  const request = () => apiInstance!.adminInvites.findOne(inviteId!);

  const [invite, setInvite] = useState<Invite | null>();

  const setData = (invite: Invite) => {
    setInvite(invite);
  };

  const useLoadResources = useLoadResource(request, setData);

  const doRemove = async () => {
    const request = apiInstance!.adminInvites.remove(inviteId!);
    await submitContext.handle(request);

    toaster.success("Deleted!");

    navigate("/admin/invites");
  };

  const doSendNew = async () => {
    const request = apiInstance!.adminInvites.sendNew(inviteId!);
    await submitContext.handle(request);

    useLoadResources.refresh();

    toaster.success("Token Renewed!");
  };

  return {
    ...useLoadResources,
    submitContext,
    invite,
    doSendNew,
    doRemove,
  };
};

export default function InviteEditPage() {
  const navigate = useNavigate();

  const { isLoading, invite, submitContext, doSendNew, doRemove } =
    useInviteFormFields();

  return (
    <Page>
      <Block className="flex w-full gap-2">
        <PageTitle marginBottom={20}>Edit Invite</PageTitle>

        {isLoading && (
          <Block className="flex justify-end">
            <Spinner size={20} />
          </Block>
        )}
      </Block>

      {isLoading === false && invite && (
        <Form>
          <>
            <FormField label="Expires at">
              <Text>
                <DateValue date={invite.expiresAt} />
              </Text>
            </FormField>

            <FormField className="w-full" label="Url">
              <TextClipBoard text={invite.url} value={invite.url} />
            </FormField>
            <TextInputField
              disabled
              name="email"
              label="Email"
              placeholder=""
              value={invite.email}
              marginBottom={0}
              inputHeight={40}
            />

            <TextInputField
              disabled
              name="token"
              label="Token"
              placeholder=""
              value={invite.token}
              marginBottom={0}
              inputHeight={40}
            />

            {submitContext.errorMessage && (
              <Pane>
                <Alert
                  marginTop={10}
                  intent="danger"
                  title={submitContext.errorMessage}
                />
              </Pane>
            )}

            <Actions marginTop={10} marginBottom={10}>
              <Actions.Button
                onClick={() => navigate(`/admin/invites/`)}
                isLoading={!!submitContext.isLoading}
                height={majorScale(5)}
              >
                Back
              </Actions.Button>
              <Actions.Button
                onClick={doSendNew}
                iconBefore={<SendMessageIcon />}
                isLoading={!!submitContext.isLoading}
                appearance="primary"
                height={majorScale(5)}
              >
                Renew
              </Actions.Button>
            </Actions>

            <Card title={"Danger zone"} elevation={1} className={"mt-8 p-8"}>
              <Pane className="flex w-full items-center">
                <Heading size={400}>Danger zone</Heading>
                <Button
                  onClick={doRemove}
                  iconBefore={<TrashIcon />}
                  isLoading={!!submitContext.isLoading}
                  intent="danger"
                  appearance="primary"
                  marginLeft="auto"
                  height={majorScale(5)}
                >
                  Delete
                </Button>
              </Pane>
            </Card>
          </>
        </Form>
      )}
    </Page>
  );
}
