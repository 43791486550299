import { useApi } from "@/context/AxiosContext";
import { useState } from "react";
import { useLoadResource } from "@/lib/request-hooks";
import { Form } from "@/types/apiTypes";

export default function useForms() {
  const { apiInstance } = useApi();

  const [forms, setForms] = useState<Form[]>([]);

  const request = () => apiInstance!.forms.findMany();

  return {
    ...useLoadResource(request, setForms, true),
    forms,
  };
}
