import { Address } from "../../../types/apiTypes";
import { CrossIcon, Heading, IconButton, Pane, Paragraph } from "evergreen-ui";
import { useMemo, useState } from "react";
import MapBoxAddressInputField from "../../formfields/MapBoxAddressInputField";
import { FilterProps } from "./types";

export type DisplayAddressProps = {
  address?: Address;
};

export function DisplayAddressSimple({
  address,
  ...rest
}: DisplayAddressProps) {
  if (!address) {
    return <></>;
  }

  return (
    <Pane {...rest} className="flex items-center gap-2 whitespace-normal">
      <Pane>
        <Paragraph className="!text-xs">{address?.formattedAddress}</Paragraph>
        {address?.postalCode && (
          <Paragraph className="!text-xs" size={200}>
            {address.postalCode}
          </Paragraph>
        )}
        {address?.lat && (
          <Paragraph className="!text-xs" size={200}>
            {address.lat}, {address.lng}
          </Paragraph>
        )}
      </Pane>
    </Pane>
  );
}

export default function AddressFilter({ filterMap, handleInput }: FilterProps) {
  const [address, setAddress] = useState<Address | null>(null);

  function searchAddress(address) {
    if (address) {
      handleInput(filterMap.address, [address.lat, address.lng].join(","));
    } else {
      handleInput(filterMap.address, null);
    }

    setAddress(address);
  }

  const selectedAddress = useMemo((): Address | null => {
    if (
      !address &&
      filterMap.address?.value &&
      typeof filterMap.address.value === "string"
    ) {
      const [lat, lng] = filterMap.address.value.split(",");

      return { lat: parseFloat(lat), lng: parseFloat(lng) } as Address;
    } else {
      return address;
    }
  }, [address, filterMap.address?.value]);

  return (
    <Pane className="flex flex-col gap-1">
      <Pane className="flex h-4 justify-between">
        <Heading className={address ? "!font-bold !text-black" : ""} size={300}>
          Adres
        </Heading>
        {selectedAddress && (
          <IconButton
            icon={CrossIcon}
            appearance="minimal"
            height={24}
            onClick={() => searchAddress(null)}
          />
        )}
      </Pane>
      <MapBoxAddressInputField
        address={selectedAddress}
        setAddress={searchAddress}
        showMap={false}
        showAddress={false}
      />
      {selectedAddress && <DisplayAddressSimple address={selectedAddress} />}
    </Pane>
  );
}
