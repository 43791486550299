
import { useParams } from "react-router-dom";

import Actions from "../../components/common/Actions";
import Block from "../../components/common/Block";
import Form from "../../components/common/Form";
import Page from "../../components/common/Page";
import { useApi } from "@/context/AxiosContext";
import { Fragment, useState } from "react";
import { useDoRequest, useLoadResource } from "@/lib/request-hooks";
import { ProjectExport } from "@/types/apiTypes";
import StandardTable from "@/components/table/StandardTable";
import { Button, CloudDownloadIcon, FormField, IconButton, RefreshIcon, Select, ShareIcon, Table, TrashIcon, toaster } from "evergreen-ui";
import DateValue from "@/components/common/DateValue";
import ProjectsExportCreateDialog from "./ProjectsExportCreateDialog";

export function useExports() {
    const { projectId } = useParams();
    const [exports, setExports] = useState<ProjectExport[]>([]);

    const { apiInstance } = useApi();

    const request = () => apiInstance!.adminProjectExports.findMany(projectId!);

    function setData(data) {
        setExports(data);
    }

    const loadRes = useLoadResource(request, setData)

    const doRestart = async (exportId: string) => {
        setExports(exports.map((exp) => exp.id === exportId ? { ...exp, disabled: true } : exp))
        await apiInstance!.adminProjectExports.restart(projectId!, exportId)
        loadRes.refresh()
    }

    const doRemove = async (exportId: string) => {
        setExports(exports.map((exp) => exp.id === exportId ? { ...exp, disabled: true } : exp))
        await apiInstance!.adminProjectExports.remove(projectId!, exportId)
        loadRes.refresh()
    }

    const doPrepMedia = async (exportId: string) => {
        setExports(exports.map((exp) => exp.id === exportId ? { ...exp, disabled: true } : exp))
        await apiInstance!.adminProjectExports.prepareMediaExport(projectId!, exportId)
        loadRes.refresh()
    }

    async function downloadExport(exportId: string, fileId: string) {
        setExports(exports.map((exp) => exp.id === exportId ? { ...exp, disabled: true } : exp))
        const { url } = await apiInstance!.adminProjectExports.download(projectId!, exportId, fileId)
        const a = document.createElement("a");

        a.target = "_blank";
        a.style.display = "none";
        a.href = url;

        // the filename you want
        a.download = "export.zip";

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        loadRes.refresh()
    }

    return {
        loadRes,
        exports,
        doRestart,
        doRemove,
        doPrepMedia,
        downloadExport,
    };
}

export default function ProjectsExportPage() {
    const [showDialog, setShowDialog] = useState(false)
    const { exports, loadRes, doRestart, doRemove, doPrepMedia, downloadExport } = useExports();
    return (
        <Page>
            <Block>
                <Actions marginBottom={10}>
                    <Actions.Button appearance="primary" onClick={() => { setShowDialog(true) }}>
                        New export
                    </Actions.Button>
                    <ProjectsExportCreateDialog isOpen={showDialog} close={() => {
                        setShowDialog(false)
                        loadRes.refresh()
                    }} />
                </Actions>

                <StandardTable
                    refresh={loadRes.refresh}
                    meta={loadRes.meta}
                    isLoading={loadRes.isLoading}
                    data={exports}
                    emptyState={<div />}
                >
                    <Table.Head>
                        <Table.TextHeaderCell>Period</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Species</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Exporter</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Status</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Code</Table.TextHeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Head>
                    <Table.Body height="auto">
                        {exports.map((projectExport) => (
                            <Fragment key={projectExport.id}>
                                <Table.Row >
                                    <Table.TextCell>
                                        <DateValue date={projectExport.filter.startDate} />
                                        <br />
                                        <DateValue date={projectExport.filter.endDate} />
                                    </Table.TextCell>
                                    <Table.TextCell>
                                        {projectExport.filter.species.join(", ")}
                                    </Table.TextCell>
                                    <Table.TextCell>
                                        {projectExport.exporter}
                                    </Table.TextCell>
                                    <Table.TextCell>
                                        {projectExport.status}
                                    </Table.TextCell>
                                    <Table.TextCell>{projectExport.code}</Table.TextCell>
                                    <Table.Cell className="flex flex-row gap-2 justify-end">
                                        <IconButton
                                            is="a"
                                            href={`/share/export/${projectExport.id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            icon={ShareIcon}
                                        />
                                        <IconButton
                                            icon={RefreshIcon}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                doRestart(projectExport.id)
                                            }}
                                            disabled={projectExport.disabled === true}
                                        />
                                        <IconButton
                                            icon={TrashIcon}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                doRemove(projectExport.id)
                                            }}
                                            disabled={projectExport.disabled === true}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                {projectExport.files?.map((file) => (
                                    <Table.Row key={file.name}>
                                        <Table.Cell>
                                            {file.name}
                                        </Table.Cell>
                                        <Table.Cell className="flex flex-row gap-2 justify-end">
                                            <Button
                                                iconBefore={CloudDownloadIcon}
                                                appearance="primary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    downloadExport(projectExport.id, file.id)
                                                }}
                                                disabled={projectExport.disabled === true}
                                            >
                                                Download
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                                {projectExport.status === "DataComplete" && (
                                    <Table.Row>
                                        <Table.Cell className="flex flex-row gap-2 justify-end">
                                            <Button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    doPrepMedia(projectExport.id)
                                                }}
                                            >
                                                Add media files to export
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Fragment>
                        ))}
                    </Table.Body>
                </StandardTable>
            </Block>
        </Page >
    )
}
