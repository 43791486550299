import { EmptyState, Heading, SearchIcon, Table } from "evergreen-ui";
import Block from "../../components/common/Block";
import Actions from "../../components/common/Actions";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useLoadResource } from "../../lib/request-hooks";
import { useApi } from "../../context/AxiosContext";
import { Project } from "../../types/apiTypes";
import StandardTable from "../../components/table/StandardTable";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/common/PageTitle";
import { projectTypeOptions } from "../../components/config/project-types";
import Page from "../../components/common/Page";
import { useWindowSearchParams } from "src/hooks/useWindowSearchParams";
import { __r, ADMIN_CLIENT_ACTIVITY_PAGE } from "../../RouteMap";

export function useProjects() {
  const searchParams = useWindowSearchParams();
  const [projects, setProjects] = useState<Project[]>([]);

  const { apiInstance } = useApi();

  const request = () => apiInstance!.adminProjects.findMany(searchParams);

  return {
    ...useLoadResource(request, setProjects, true),
    projects,
  };
}

export default function ProjectsPage() {
  const { t } = useTranslation();

  const { projects, refresh, isLoading, meta } = useProjects();
  const navigate = useNavigate();

  const navTo = (project: Project) => {
    navigate(__r(ADMIN_CLIENT_ACTIVITY_PAGE, { projectId: project.id }));
  };

  return (
    <Page>
      <PageTitle>{t("projects_page.title")}</PageTitle>

      <Block>
        <Actions marginBottom={10}>
          <Link to="/projects/add">
            <Actions.Button appearance="primary">
              {t("projects_page.add_new")}
            </Actions.Button>
          </Link>
        </Actions>
        <StandardTable
          refresh={refresh}
          meta={meta}
          isLoading={isLoading}
          data={projects}
          emptyState={<EmptyProjectsState />}
        >
          <Table.Head>
            <Table.TextHeaderCell>{t("common.name")}</Table.TextHeaderCell>
            <Table.TextHeaderCell>
              {t("common.client_type")}
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              {t("common.client_name")}
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {projects.map((project) => (
              <Table.Row
                key={project.id}
                isSelectable
                onSelect={() => {
                  navTo(project);
                }}
              >
                <Table.TextCell>{project.name}</Table.TextCell>
                <Table.TextCell>
                  {projectTypeOptions.find((v) => v.value === project.type)
                    ?.label || "-"}
                </Table.TextCell>
                <Table.TextCell>{project.organisationName}</Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </StandardTable>
      </Block>
    </Page>
  );
}

function EmptyProjectsState() {
  return (
    <EmptyState
      background="light"
      title="Geen projecten gevonden"
      orientation="horizontal"
      icon={<SearchIcon color="#C1C4D6" />}
      iconBgColor="#EDEFF5"
      description="Maak een nieuw project aan middels de 'Nieuw Project' knop."
    />
  );
}
