import { useApi } from "src/context/AxiosContext";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { WorkerShift } from "src/types/apiTypes";
import { useLoadResource } from "src/lib/request-hooks";
import {
  Avatar,
  CrossIcon,
  EmptyState,
  FilterIcon,
  Heading,
  majorScale,
  Pane,
  SearchIcon,
  Table,
  Text,
  TickIcon,
} from "evergreen-ui";
import Page from "src/components/common/Page";
import { endOfMonth, formatISO, startOfMonth } from "date-fns";
import StandardTable, {
  TableLoadingSpinner,
} from "../../components/table/StandardTable";
import TravelDistanceValue from "../../components/common/TravelDistanceValue";
import { useWindowSearchParams } from "../../hooks/useWindowSearchParams";
import DurationValue from "../../components/common/DurationValue";
import DateValue from "../../components/common/DateValue";
import PageTitle from "../../components/common/PageTitle";
import { ADMIN_USERS_EDIT_PAGE } from "../../RouteMap";
import { useDoFilter } from "../../hooks/useDoFilter";
import { FilterMapType } from "../../types/appTypes";
import { useAuth } from "../../context/AuthContext";
import WorkerShiftHistoryFilters from "./_components/WorkerShiftHistoryFilters";
import { WorkerShiftAggregations } from "./_components/WorkerShiftAggregations";

function useWorker() {
  const { user } = useAuth();

  return {
    user,
  };
}

function useWorkerShifts() {
  const searchParams = useWindowSearchParams();
  const { apiInstance } = useApi();
  const { workerId } = useParams();

  const [workerShifts, setWorkerShifts] = useState<WorkerShift[]>([]);

  const find = () => apiInstance!.workerShifts.findManyHistory(searchParams);

  const [initialized, setInitialized] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);

  const filterMap: FilterMapType = {
    fromDate: {
      setter: setFromDate,
      topic: "fromDate",
      value: fromDate,
      default: formatISO(startOfMonth(new Date())),
    },
    toDate: {
      setter: setToDate,
      topic: "toDate",
      value: toDate,
      default: formatISO(endOfMonth(new Date())),
    },
  };

  function setData(workerShifts: WorkerShift[]) {
    setWorkerShifts(workerShifts);
  }

  const loadResourceLogic = useLoadResource(find, setData, initialized);

  const {
    fetchData,
    setDefaultFilters,
    setFilter,
    doSortBy,
    handleInput,
    doFilter,
  } = useDoFilter({
    searchParams,
    setInitialized,
    initialized,
    filterMap,
    loadResourceLogic,
    find,
    setData,
    setMeta: () => {},
  });

  useEffect(() => {
    if (!initialized) {
      setDefaultFilters();
    }
  }, [initialized]);

  return {
    ...loadResourceLogic,
    // ...useLoadResource(find, setData, !!workerId),
    workerShifts,
    doFilter,
    doSortBy,
    fetchData,
    filterMap,
    handleInput,
    setData,
    setDefaultFilters,
    setFilter,
  };
}

export default function WorkerShiftsHistoryPage() {
  const { user } = useWorker();

  const filterLogic = useWorkerShifts();

  const {
    isLoading: workerShiftsLoading,
    meta,
    refresh,
    workerShifts,
  } = filterLogic;

  if (workerShiftsLoading) {
    return (
      <Page>
        <TableLoadingSpinner title={"Data aan het laden.."} />
      </Page>
    );
  }

  return (
    <Page>
      <PageTitle backPath={ADMIN_USERS_EDIT_PAGE}>Gelopen diensten.</PageTitle>

      <Pane className="py-2">
        <Pane className="flex flex-col items-center justify-center">
          <Avatar
            className="hover:opacity-90"
            src={user!.profilePicture ?? ""}
            name={""}
            size={100}
          />
          <Pane className="flex flex gap-1 py-4">
            <Text>{user!.firstName}</Text>
            <Text>{user!.lastName}</Text>
          </Pane>
          <Pane>
            <WorkerShiftAggregations workerShifts={workerShifts} />
          </Pane>
        </Pane>

        <Pane className="flex flex-col gap-2">
          <Pane className="flex items-center gap-1">
            <FilterIcon color="muted" size={majorScale(2)} />
            <Heading size={300}>Filters</Heading>
          </Pane>
          <Pane className={"h-[1px] w-8 bg-gray-200"} />
          <WorkerShiftHistoryFilters filterLogic={filterLogic} />
          <Pane className={"h-[1px] w-8 bg-gray-200"} />
        </Pane>

        <StandardTable
          refresh={refresh}
          meta={meta}
          isLoading={workerShiftsLoading}
          data={workerShifts}
          emptyState={<EmptyWorkerShiftState />}
        >
          <Table.Head>
            <StandardTable.HeaderCell>Goedgekeurd op</StandardTable.HeaderCell>
            <StandardTable.HeaderCell>Gelopen op</StandardTable.HeaderCell>
            <StandardTable.HeaderCell>Gebied</StandardTable.HeaderCell>
            <StandardTable.HeaderCell>Activiteit</StandardTable.HeaderCell>
            <StandardTable.HeaderCell>Gewerkt</StandardTable.HeaderCell>
            <StandardTable.HeaderCell>Reistijd</StandardTable.HeaderCell>
            <StandardTable.HeaderCell>Kilometers</StandardTable.HeaderCell>
            <StandardTable.HeaderCell>Bestuurder</StandardTable.HeaderCell>
            <StandardTable.HeaderCell>Nachtdienst</StandardTable.HeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {workerShifts.map((workerShift) => (
              <Table.Row key={workerShift.id}>
                <Table.TextCell>
                  <DateValue date={workerShift.shiftReport?.approvedAt} />
                </Table.TextCell>
                <Table.TextCell>
                  <DateValue date={workerShift.startedAt} />
                </Table.TextCell>
                <Table.TextCell>{workerShift.shift.area?.name}</Table.TextCell>
                <Table.TextCell>
                  {workerShift.shift.areaActivityType?.activityType.name}
                </Table.TextCell>
                <Table.TextCell>
                  <DurationValue seconds={workerShift.shiftDuration} />
                </Table.TextCell>
                <Table.TextCell>
                  <DurationValue seconds={workerShift.travelDuration} />
                </Table.TextCell>
                <Table.TextCell>
                  <TravelDistanceValue meters={workerShift.travelDistance} />
                </Table.TextCell>
                <Table.TextCell>
                  {workerShift.isDriving ? <TickIcon /> : <CrossIcon />}
                </Table.TextCell>
                <Table.TextCell>
                  {workerShift.shift.isNightShift ? (
                    <TickIcon />
                  ) : (
                    <CrossIcon />
                  )}
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </StandardTable>
      </Pane>
    </Page>
  );
}

function EmptyWorkerShiftState() {
  return (
    <EmptyState
      background="light"
      title="Geen diensten gevonden"
      orientation="horizontal"
      icon={<SearchIcon color="#C1C4D6" />}
      iconBgColor="#EDEFF5"
      description="Diensten verschijnen hier als een veldwerker diensten heeft gelopen."
    />
  );
}
