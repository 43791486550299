export const MultipleImagesPreviewComponent = ({
  images,
}: {
  images: {
    fullPath: string;
    mimeType: string;
    originalFileName: string;
  }[];
}) => {
  return (
    <div className="flex-column flex gap-8">
      {images.map((image, index) => (
        <img
          key={index}
          width={"25%"}
          src={`https://api.app.gaia-ecologie.nl/cdn/${image.fullPath}`}
          alt="Alt media"
        />
      ))}
    </div>
  );
};
