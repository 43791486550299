import Page from "src/components/common/Page";
import PageTitle from "src/components/common/PageTitle";
import { useNavigate } from "react-router-dom";
import StandardTable from "../../components/table/StandardTable";
import {
  CalendarIcon,
  EditIcon,
  EmptyState,
  LockIcon,
  Pane,
  SearchIcon,
  Table,
  TickIcon,
} from "evergreen-ui";
import { useTranslation } from "react-i18next";
import DateValue from "../../components/common/DateValue";
import {
  __r,
  PLANNER_AVAILABILITY_REQUEST_PROGRESS_PAGE,
  PLANNER_PERIOD_PLANNING_PAGE,
} from "../../RouteMap";
import { usePeriods } from "../../hooks/usePeriods";
import { useMemo } from "react";
import { classNames } from "../../lib/functions";
import { Period } from "../../types/apiTypes";

const statusIconMap = {
  published: <TickIcon color="success" />,
  isOpen: <EditIcon color="muted" />,
  isLocked: <LockIcon color="muted" />,
  isPlannable: <EditIcon color="muted" />,
  isNotFilledIn: <CalendarIcon color="muted" />,
};

const statusColorMap = {
  published: "border-l-8 !border-l-green-400",
  isOpen: "border-l-4 !border-l-green-400 hover:!bg-blue-50",
  isLocked: "",
  isPlannable: "border-l-8 !border-l-green-400 hover:!bg-blue-50",
  isNotFilledIn: "border-l-8 !border-l-orange-400 hover:!bg-blue-50",
  // published: '!bg-green-50 hover:!bg-blue-50',
  // isOpen: '!bg-blue-50 hover:!bg-blue-50',
  // isLocked: '!bg-red-50 hover:!bg-blue-50',
  // isPlannable: '!bg-green-50 hover:!bg-blue-50',
  // isNotFilledIn: "!bg-gray-100 hover:!bg-blue-50",
};

export default function PeriodsListPage() {
  const { t } = useTranslation();

  const { periods, meta, isLoading, refresh } = usePeriods({
    populate: ["filledInCount"],
  });

  const navigate = useNavigate();

  const navTo = (period) => {
    if (
      period.isFilledIn ||
      period.status === "published" ||
      period.status === "isLocked"
    ) {
      navigate(__r(PLANNER_PERIOD_PLANNING_PAGE, { periodId: period.id }));
    } else {
      navigate(
        __r(PLANNER_AVAILABILITY_REQUEST_PROGRESS_PAGE, {
          periodId: period.id,
        }),
      );
    }
  };

  function getRowClasses(period: Period) {
    return classNames(
      period.isLocked ? statusColorMap.isLocked : "",
      period.isFilledIn ? statusColorMap.published : "",
      period.status === "published" ? statusColorMap.published : "",
    );
  }

  function getIcon(period: Period) {
    if (period.isLocked) {
      return <LockIcon color="muted" />;
    }

    if (period.isFilledIn) {
      return <TickIcon color="success" />;
    } else {
      return <CalendarIcon color="muted" />;
    }
  }

  return (
    <Page>
      <PageTitle>{t("availability_planner_page.title")}</PageTitle>

      <Pane>
        <StandardTable
          meta={meta}
          refresh={refresh}
          isLoading={isLoading}
          data={periods}
          emptyState={<EmptyPeriodsState />}
        >
          <Table.Head>
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Start</Table.TextHeaderCell>
            <Table.TextHeaderCell>End</Table.TextHeaderCell>
            <Table.TextHeaderCell>Status</Table.TextHeaderCell>
            <Table.TextHeaderCell># Ingevuld</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {periods.map((period) => (
              <Table.Row
                className={getRowClasses(period)}
                key={period.id}
                isSelectable
                onSelect={() => {
                  navTo(period);
                }}
              >
                <Table.TextCell>{period.name}</Table.TextCell>
                <Table.TextCell>
                  <DateValue date={period.startAt} />
                </Table.TextCell>
                <Table.TextCell>
                  <DateValue date={period.endAt} />
                </Table.TextCell>
                <Table.TextCell>{getIcon(period)}</Table.TextCell>
                <Table.TextCell>{period.filledInCount}</Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </StandardTable>
      </Pane>
    </Page>
  );
}

function EmptyPeriodsState() {
  return (
    <EmptyState
      background="light"
      title="Geen periodes gevonden"
      orientation="horizontal"
      icon={<SearchIcon color="#C1C4D6" />}
      iconBgColor="#EDEFF5"
      description="Periodes worden automatisch aangemaakt. Je zou dus altijd iets moeten zien."
    />
  );
}
