import { Image, Text, View } from "@react-pdf/renderer";
import { pdfHeaderStyles } from "./styles/pdfReportStyles";

export const ReportPdfHeader = ({ reportTitle }: { reportTitle: string }) => {
  return (
    <View style={pdfHeaderStyles.header} fixed>
      <Text style={pdfHeaderStyles.reportTitle}>{reportTitle}</Text>
      <Image style={pdfHeaderStyles.logo} src={"/logo.png"} />
    </View>
  );
};
