import { Heading, Pane } from "evergreen-ui";
import BackButton from "./BackButton";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../lib/functions";

export default function PageTitle({ children, ...rest }) {
  const { backPath, actions, onLeave, ...restProps } = rest;

  const navigate = useNavigate();
  const headingClasses = classNames(
    backPath ? "cursor-pointer hover:underline" : "",
    "!text-gray-500 flex grow gap-2 pb-2",
  );

  function onClick() {
    if (rest.onLeave) {
      rest.onLeave();
    } else if (backPath) {
      navigate(backPath);
    }
  }

  return (
    <Pane className="flex flex-wrap" paddingTop={10} paddingBottom={15}>
      <Heading className={headingClasses} size={800} {...restProps}>
        {backPath && <BackButton navigationPath={backPath} onLeave={onLeave} />}
        <Pane
          className={backPath ? "cursor-pointer hover:underline" : ""}
          onClick={onClick}
        >
          {children}
        </Pane>
      </Heading>
      {actions && <Pane className="flex shrink gap-2">{actions}</Pane>}
      <Pane className={"h-[1px] w-full bg-gray-200"} />
    </Pane>
  );
}
