import {
  ActivityType,
  Area,
  AreaActivityType,
  ClientObject,
  Media as MediaFile,
  Media,
} from "@/types/apiTypes";
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Block from "@/components/common/Block";
import {
  Button,
  DotIcon,
  FormField,
  Heading,
  Paragraph,
  TextareaField,
  TextInputField,
} from "evergreen-ui";
import { classNames, shortenString } from "@/lib/functions";
import useAreaForm from "@/pages/projects/areas/useAreaForm.hook";
import { AttachedMediaFiles } from "@/pages/projects/objects/ObjectListItem.component";
import MultiFileUploader from "@/components/formfields/MultiFileUploader";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  __r,
  ADMIN_ACTIVITY_TYPE_EDIT_PAGE,
  ADMIN_AREA_ADD_PAGE,
} from "@/RouteMap";
import Divider from "@/components/common/Divider";
import ActivityTypesSelect from "@/components/activity-types/ActivityTypesSelect";
import { MdPerson, MdWork } from "react-icons/md";
import ObjectList from "@/components/objects/ObjectList.component";
import AreYouSureDialog from "@/components/common/AreYouSureDialog";
import useSafeSubmit from "@/hooks/useSafeSubmit";
import { useTranslation } from "react-i18next";
import { InfoBox } from "@/pages/projects/activity-types/ActivityTypeEditPage";

export interface AreaListItemProps {
  activeArea: Area | null;
  afterDelete: (area: Area) => void;
  afterSave: (area: Area) => void;
  area: Area;
  areaForm: ReturnType<typeof useAreaForm>;
  isEditMode: boolean;
  onClickEdit: (area: Area) => void;
  updateList: (object: Area) => void;
  onClickObject: (object: ClientObject) => void;
}

function useAreaEditState(area: Area) {
  const [name, setName] = useState<string>(area.name);
  const [notes, setNotes] = useState<string>(area.notes);
  const [activityTypes, setActivityTypes] = useState<
    Partial<AreaActivityType>[]
  >([...area.activityTypes]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [mediaFiles, setMediaFiles] = useState<Media[]>(area.media);
  const [uploadingMediaFiles, setUploadingMediaFiles] = useState<Media[]>(
    area.media,
  );

  const removedActivityTypes = useMemo(() => {
    return area.activityTypes.filter((activityType) => {
      return !activityTypes.find((at) => at.id === activityType.id);
    });
  }, [activityTypes]);

  return {
    name,
    setName,
    notes,
    setNotes,
    // activityTypes,
    // setActivityTypes,
    mediaFiles,
    setMediaFiles,
    uploadingMediaFiles,
    setUploadingMediaFiles,
    isUploading,
    setIsUploading,
    activityTypes,
    setActivityTypes,
    removedActivityTypes,
  };
}

export default function AreaListItem(props: AreaListItemProps) {
  const {
    area,
    onClickEdit,
    areaForm,
    isEditMode,
    activeArea,
    afterSave,
    afterDelete,
    updateList,
    onClickObject,
  } = props;

  const navigate = useNavigate();
  const { projectId } = useParams();
  const areaListRef = useRef<HTMLDivElement>(null);
  const [editMode, setEditMode] = useState<boolean>(isEditMode);

  const { t } = useTranslation();

  const {
    name,
    setName,
    notes,
    setNotes,
    mediaFiles,
    setMediaFiles,
    uploadingMediaFiles,
    setUploadingMediaFiles,
    setIsUploading,
    activityTypes,
    setActivityTypes,
    removedActivityTypes,
  } = useAreaEditState(area);

  async function onDelete() {
    await areaForm!.doRemove(area.id!);

    afterDelete(area);
  }

  async function uploadMedia(formData: FormData) {
    return areaForm.uploadFile(area.id!, formData).then((area) => {
      updateList(area);

      return area;
    });
  }

  async function doSubmit() {
    const updatedArea = await areaForm!.doUpdate({
      id: area.id!,
      name,
      notes,
      //@ts-ignore
      activityTypes,
    });

    setEditMode(false);
    afterSave(updatedArea);
  }

  async function onRemoveMedia(file: MediaFile) {
    const newArea = await areaForm.removeFile(area.id!, file.id);

    setMediaFiles(newArea.media);
    updateList(newArea);
  }

  useEffect(() => {
    if (activeArea?.id === area.id) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [activeArea, areaListRef]);

  useLayoutEffect(() => {
    if (editMode && areaListRef.current) {
      // areaListRef.current.scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" });
      areaListRef.current.scrollIntoView({
        inline: "nearest",
        block: "start",
      });
    }
  }, [editMode]);

  const {
    safeSubmitDialogShown,
    closeSafeDialog,
    doSafeSubmit: doSafeSubmit,
    onSafeSubmit: onSafeSubmit,
  } = useSafeSubmit({
    onConfirm: () => {},
    doSubmit: doSubmit,
    isDirty: true,
  });

  const {
    safeSubmitDialogShown: safeDeleteDialogShown,
    closeSafeDialog: closeSafeDeleteDialog,
    doSafeSubmit: doSafeDelete,
    onSafeSubmit: onSafeDelete,
  } = useSafeSubmit({
    onConfirm: () => {},
    doSubmit: onDelete,
    isDirty: true,
  });

  return (
    <div
      ref={areaListRef}
      className={classNames(
        editMode ? "border-2 bg-gray-50 shadow" : "",
        "mb-2 rounded border-b-2 border-l-2 border-r-2 border-t p-2 py-4",
      )}
    >
      <Block>
        <AreYouSureDialog
          doAction={doSafeSubmit}
          text={t(
            "area_page.dialog_are_you_sure_area_activity.text",
          ).toString()}
          intent="danger"
          isShown={safeSubmitDialogShown}
          onCloseComplete={closeSafeDialog}
          isConfirmLoading={false}
        >
          <Block className="flex flex-col gap-2">
            <InfoBox
              className="mt-4"
              description={
                "De volgende activiteiten worden verwijderd, en dus ook alle diensten, data en bestanden die eraan gekoppeld is:"
              }
            />
            {removedActivityTypes.map((activityType) => (
              <Block
                key={activityType.id}
                className="my-2 flex items-center gap-2"
              >
                <DotIcon /> <Paragraph>{activityType.name}</Paragraph>
              </Block>
            ))}
          </Block>
        </AreYouSureDialog>

        <AreYouSureDialog
          doAction={doSafeDelete}
          text={t("area_page.dialog_are_you_sure_area_delete.text").toString()}
          intent="danger"
          isShown={safeDeleteDialogShown}
          onCloseComplete={closeSafeDeleteDialog}
          isConfirmLoading={false}
        />

        {!editMode && (
          <Block className="flex flex-col gap-2">
            <Block className="flex flex-grow justify-between gap-2 pr-2">
              <Heading size={500}>
                {area.name}
                <Paragraph size={300}>{area.notes ?? ""}</Paragraph>
              </Heading>
              <Button
                className="self-start"
                height={24}
                onClick={() => {
                  setEditMode(!editMode);
                  onClickEdit(area);
                }}
              >
                Edit
              </Button>
            </Block>
            <ActivityTypeList activityTypes={area.activityTypes} />
            <ObjectList
              listClasses={"flex-col"}
              onClickObject={onClickObject}
              objects={area.objects}
            />
            <Block></Block>
          </Block>
        )}
      </Block>

      {editMode && (
        <Block>
          <Block className="flex flex-col gap-2 pr-3">
            <Block className="flex justify-between gap-3">
              <Button
                height={24}
                onClick={() => {
                  setEditMode(!editMode);
                  navigate(__r(ADMIN_AREA_ADD_PAGE, { projectId }));
                }}
              >
                Cancel
              </Button>
              <Button
                height={24}
                intent={editMode ? "success" : "none"}
                appearance={editMode ? "primary" : "minimal"}
                onClick={() =>
                  removedActivityTypes.length > 0 ? onSafeSubmit() : doSubmit()
                }
              >
                Save
              </Button>
            </Block>
            <TextInputField
              required
              name="name"
              label="Naam"
              placeholder="Naam gebied..."
              isInvalid={!!areaForm.submitContext.validationErrors?.name}
              validationMessage={areaForm.submitContext.validationErrors?.name?.join(
                ", ",
              )}
              value={name}
              onChange={(e) => setName(e.target.value)}
              marginBottom={0}
              inputHeight={50}
            />
            <TextareaField
              name="notes"
              width={"100%"}
              label={"Notities"}
              placeholder="..."
              value={notes || ""}
              isInvalid={!!areaForm.submitContext.validationErrors?.notes}
              validationMessage={areaForm.submitContext.validationErrors?.notes?.join(
                ", ",
              )}
              onChange={(e) => setNotes(e.target.value)}
              marginBottom={0}
              inputHeight={20}
              inputWidth={"100%"}
              className="!text-base"
            />
            <Divider title={"Objecten"}>
              <ObjectList
                maxLengthName={50}
                listClasses="flex-col"
                onClickObject={onClickObject}
                objects={area.objects}
              />
            </Divider>
            <Divider title={"Activities"}>
              <ActivityTypesSelect
                projectId={projectId!}
                selected={activityTypes}
                setSelected={setActivityTypes}
                enableAll={false}
                editMode={true}
              />
            </Divider>
            <FormField label="Bestanden" className="max-w-[30rem]">
              <MultiFileUploader
                className="max-w-[30rem]"
                uploadFn={uploadMedia}
                mediaFiles={uploadingMediaFiles}
                setMediaFiles={(v) => {
                  setUploadingMediaFiles(v);
                }}
                setIsLoading={setIsUploading}
              />
              <AttachedMediaFiles
                mediaFiles={mediaFiles}
                maxLengthFileName={60}
                editMode={editMode}
                onRemove={onRemoveMedia}
              />
            </FormField>
          </Block>

          <Block className="flex gap-2">
            <Button
              height={24}
              intent={"danger"}
              appearance={"primary"}
              onClick={onSafeDelete}
            >
              Delete
            </Button>
          </Block>
        </Block>
      )}
    </div>
  );
}

function ActivityTypeList({
  activityTypes,
}: {
  activityTypes: ActivityType[];
}) {
  const { projectId } = useParams();

  return (
    <Block className="flex flex-wrap gap-2">
      {activityTypes.map((activityType) => (
        <Link
          key={activityType.id}
          to={__r(ADMIN_ACTIVITY_TYPE_EDIT_PAGE, {
            projectId,
            activityTypeId: activityType.id,
          })}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Block className="border hover:border-gray-400 hover:bg-gray-100">
            <Block className="flex cursor-pointer flex-nowrap items-center border">
              <Block className="px-1">
                <MdWork color={"#7c7c7c"} size={15} />
              </Block>
              <Block className="flex-nowrap border-l px-1">
                <Paragraph title={activityType.name} size={300}>
                  {shortenString(activityType.name, 20)}
                </Paragraph>
              </Block>
              <Paragraph>|</Paragraph>
              <Block className="flex flex-nowrap items-center px-1">
                <MdPerson color={"#7c7c7c"} size={15} />
                <Paragraph className="px-1">
                  {/*@ts-ignore*/}
                  {activityType.nWorkers}
                </Paragraph>
              </Block>
            </Block>
          </Block>
        </Link>
      ))}
    </Block>
  );
}
