import { CircleIcon, Heading, Pane, Text, TickCircleIcon } from "evergreen-ui";
import { Outlet, useNavigate } from "react-router-dom";
import Block from "../common/Block";
import { createContext, useContext, useReducer } from "react";
import { useAuth } from "../../context/AuthContext";

export const OnBoardingContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "onComplete":
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export default function OnBoardingLayout() {
  const [state, dispatch] = useReducer(reducer, {});
  const navigate = useNavigate();

  const context = {
    state: state,
    onCompleteTokenVerification: (value) => {
      dispatch({ type: "onComplete", value });
    },
    onCompleteUserCreation: (value) => {
      dispatch({ type: "onComplete", value });

      return navigate("/");
    },
  };

  return (
    <Pane display="flex">
      <Pane className="mx-auto min-w-[20rem] p-8 md:min-w-[40rem]">
        <Pane paddingX={20} className="mx-auto max-w-sm">
          <img className="mx-auto" src={"/logo.png"} alt="logo" />
        </Pane>

        <Pane className="mx-5" marginY={20}>
          <Heading className="text-center" size={800}>
            Welcome to Gaia Ecologie!
          </Heading>
        </Pane>

        <Pane className="pb-[10rem]">
          <OnBoardingContext.Provider value={context}>
            <OnBoardingProgressSteps />
            <Outlet />
          </OnBoardingContext.Provider>
        </Pane>
      </Pane>
    </Pane>
  );
}

function OnBoardingProgressSteps() {
  const { user } = useAuth();

  const { state } = useContext(OnBoardingContext);

  return (
    <Pane className="flex items-center justify-center" marginY={50}>
      <Pane className="flex items-center gap-2">
        {state.invite || user ? (
          <TickCircleIcon color="success" />
        ) : (
          <CircleIcon color="green700" />
        )}
        <Text className="!text-xs" color="blue800">
          Token validation
        </Text>
      </Pane>

      <Pane className={"mx-2 h-[1px] flex-1 bg-gray-400"} />

      <Pane className="flex items-center gap-2">
        {user ? (
          <TickCircleIcon color="success" />
        ) : (
          <CircleIcon color="green700" />
        )}
        <Text className="!text-xs" color="gray800">
          Login details
        </Text>
      </Pane>

      <Pane className={"mx-2 h-[1px] flex-1 bg-gray-400"} />

      <Pane className="flex items-center gap-2">
        {user?.worker?.isActive ? (
          <TickCircleIcon color="success" />
        ) : (
          <CircleIcon color="green700" />
        )}
        <Text className="!text-xs" color="gray800">
          Your Details
        </Text>
      </Pane>
    </Pane>
  );
}
