import { Pane, TabNavigation } from "evergreen-ui";
import { useCallback } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { classNames } from "@/lib/functions";
import { __r } from "@/RouteMap";

export type TopSubNavProps = {
  menuItems: { label: string; pathname: string; icon?: JSX.Element }[];
  [key: string]: any;
};

export default function TopSubNav({ menuItems, ...rest }: TopSubNavProps) {
  const location = useLocation();
  const params = useParams();

  const isSelected = useCallback(
    (tabItem: { label: string; pathname: string }) => {
      const route = __r(tabItem.pathname, params);

      if (route === location.pathname) {
        return true;
      }

      // if sub-route.
      return location.pathname.startsWith(route);
    },
    [location.pathname],
  );

  return (
    <Pane {...rest} background="tint2" className="py-5 shadow">
      <TabNavigation className="sub-nav ml-3 flex gap-3">
        {menuItems.map((tab) => (
          <Link key={tab.pathname} to={tab.pathname}>
            <Pane
              key={tab.pathname}
              className={classNames(
                isSelected(tab) ? "inner-shadow-lg bg-white !font-bold" : "",
                "ml-2 flex items-center rounded-md py-3 px-5 !text-gray-600",
              )}
              id={tab.label}
            >
              {tab.icon && <span className="mr-2">{tab?.icon}</span>}
              <span className="!text-md">{tab.label}</span>
            </Pane>
          </Link>
        ))}
      </TabNavigation>
    </Pane>
  );
}
