import { PaneProps } from "evergreen-ui";
import { createContext, MutableRefObject, useContext } from "react";

interface AppContextType {
  setInitialized: Function;
  initialized: boolean;
  contentRef: MutableRefObject<HTMLDivElement | null>;
  layoutProps: PaneProps;
  setLayoutProps: Function;
  sidebarIsOpen: boolean;
  setSidebarIsOpen: Function;
  isDarkMode: boolean;
}

export function useApp() {
  return useContext(AppContext);
}

export const AppContext = createContext({
  layoutProps: {},
  isDarkMode: false,
} as AppContextType);
