import { TableComponent } from "@/types/apiTypes";
import { Button, Table } from "evergreen-ui";
import { getFormattedData } from "../utility";
import { __r, ADMIN_SUBMITTED_SHIFTS_DETAIL_PAGE } from "../../../../RouteMap";
import { ReactNode } from "react";
import { weatherDutchTranslationMapping } from "../constants";

export const TablePreviewComponent = ({
  component,
  children,
}: {
  component: TableComponent;
  children?: ReactNode;
}) => {
  const { columns, data } = component;
  return (
    <>
      <Table>
        <Table.Head>
          {columns.map((column, index) => (
            <Table.TextHeaderCell key={index}>
              {column.label}
            </Table.TextHeaderCell>
          ))}
          {component.tableIdentifier.tableType === "observation" && (
            <Table.TextHeaderCell>Navigation</Table.TextHeaderCell>
          )}
        </Table.Head>
        <Table.Body>
          {data.length === 0 ? (
            <Table.Row height="auto" paddingY={12}>
              <Table.TextCell> No data available</Table.TextCell>
            </Table.Row>
          ) : (
            data.map((data, rowIndex) => (
              <Table.Row key={rowIndex} height="auto" paddingY={12}>
                {columns.map((column, colIndex) => (
                  <Table.TextCell key={colIndex}>
                    {column.key === "id" ? (
                      <>{rowIndex + 1}</>
                    ) : column.key === "precipitation" ? (
                      <>
                        {
                          weatherDutchTranslationMapping[
                            getFormattedData(data[column.key], column.formatter)
                          ]
                        }
                      </>
                    ) : column.formatter === "bulletedList" ? (
                      <>
                        {data[column.key].map((e, elementIndex) => (
                          <div key={elementIndex}>• {e}</div>
                        ))}
                      </>
                    ) : (
                      <>
                        {getFormattedData(data[column.key], column.formatter)}
                      </>
                    )}
                  </Table.TextCell>
                ))}
                {component.tableIdentifier.tableType === "observation" && (
                  <Table.TextCell>
                    <Button
                      appearance="primary"
                      minWidth={70}
                      onClick={() =>
                        window.open(
                          __r(ADMIN_SUBMITTED_SHIFTS_DETAIL_PAGE, {
                            workerId: data.workerId,
                            shiftId: data.originalShiftId,
                          }),
                          "_blank",
                        )
                      }
                    >
                      View observation
                    </Button>
                  </Table.TextCell>
                )}
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
      {children}
    </>
  );
};
