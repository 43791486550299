import {
  ChevronLeftIcon,
  ChevronRightIcon,
  IconButton,
  majorScale,
  Pane,
  SearchIcon,
  useTheme,
} from "evergreen-ui";
import { useState } from "react";
import Page from "./Page";

/**
 * @param children
 * @param closedContent
 * @param pageTitleContent
 * @param filterContent
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export default function PageWithSubSidebarLeft({
  children,
  closedContent,
  pageTitleContent,
  filterContent,
  ...rest
}) {
  const { colors } = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Pane className={isOpen ? "flex h-full" : ""}>
      <Pane className="h-full">
        {!isOpen && (
          <Pane>
            {pageTitleContent && (
              <Pane paddingTop={20} paddingX={20}>
                {pageTitleContent}
              </Pane>
            )}

            <Pane
              paddingTop={20}
              paddingX={20}
              className="flex items-center"
              onClick={() => setIsOpen(true)}
            >
              {closedContent ? (
                closedContent
              ) : (
                <ChevronRightIcon color={colors.gray800} />
              )}
            </Pane>
          </Pane>
        )}
        {isOpen && (
          <Pane
            paddingTop={20}
            paddingX={20}
            className="flex h-full min-w-[18rem] flex-col px-4 shadow-2xl"
          >
            <IconButton
              marginLeft="auto"
              appearance="minimal"
              disabled={false}
              icon={ChevronLeftIcon}
              onClick={() => {
                setIsOpen(false);
              }}
              type="button"
            />
            <Pane className="grow">{filterContent && filterContent}</Pane>
          </Pane>
        )}
      </Pane>
      <Page {...rest} className="w-full">
        {isOpen && pageTitleContent && (
          <Pane paddingTop={20}>{pageTitleContent}</Pane>
        )}
        {children}
      </Page>
    </Pane>
  );
}
