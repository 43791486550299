import React, { PropsWithChildren, createContext, useEffect, useRef, useState } from "react"
import mapboxgl from "mapbox-gl"
import { loadGaiaImages } from "./GaiaMapSources"

type GaiaMapContextContent = {
    mapRef: React.MutableRefObject<mapboxgl.Map | null> | null,
    isMapLoaded: boolean,
}

export const GaiaMapContext = createContext<GaiaMapContextContent>({
    mapRef: null,
    isMapLoaded: false
})

type GaiaMapProps = {
    initialBounds: {
        xMin: number
        xMax: number
        yMin: number
        yMax: number
    } | undefined
}

const GaiaMap = (
    {
        initialBounds,
        children,
    }: PropsWithChildren<GaiaMapProps>
) => {
    const mapContainer = useRef<HTMLDivElement | string>("")
    const mapRef = useRef<mapboxgl.Map | null>(null)
    const [isMapLoaded, setIsMapLoaded] = useState(false)

    // Load map effect
    useEffect(() => {
        if (mapRef.current !== null) return
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            bounds: initialBounds && [
                [initialBounds.xMin - 0.0005, initialBounds.yMin - 0.0005],
                [initialBounds.xMax + 0.0005, initialBounds.yMax + 0.0005],
            ],
            accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
        })
        mapRef.current = map

        const onLoad = () => {
            loadGaiaImages(map, () => {
                setIsMapLoaded(true)
            })
        }
        map.on("load", onLoad)
        return () => {
            map.off("load", onLoad)
        }
    }, [])

    return (
        <div>
            <div
                ref={mapContainer}
                className="w-full aspect-video relative"
            />
            <GaiaMapContext.Provider
                value={{
                    mapRef,
                    isMapLoaded,
                }}
            >
                {children}
            </GaiaMapContext.Provider>
        </div>
    )
}

export default GaiaMap
