import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { Id, Observation } from "@/types/apiTypes";
import { PostData } from "@/types/appTypes";

export default class WorkerShiftObservationApi extends BaseApi {
  findOneForShift(id: Id): Promise<Observation> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/shifts/${id}/observations`,
    });
  }

  findAllForArea(areaId: Id): Promise<Observation[]> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/areas/${areaId}/observations`,
    });
  }

  findAllForShift(
    id: Id,
    options: { asGeoJson: boolean },
  ): Promise<Observation[]> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/shifts/${id}/observations${options.asGeoJson ? "?geojson=true" : ""}`,
    });
  }

  createForWorkerShift(id: Id, data: PostData): Promise<Observation> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/shifts/${id}/observations`,
      data: data,
    });
  }
}
