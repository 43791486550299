import { format, isValid, parseISO } from "date-fns";
import * as locale from "date-fns/locale/nl";

type DateValueProps = {
  date?: string | null | undefined | Date;
  formatStr?: string;
};

export function formatDate(date: string, formatStr = "PP") {
  return format(parseISO(date), formatStr, { locale: locale.default });
}

export default function DateValue({ date, formatStr = "PP" }: DateValueProps) {
  if (date instanceof Date) {
    return <>{format(date, formatStr, { locale: locale.default })}</>;
  }

  return (
    <>
      {date && formatDate(date, formatStr)}
      {!date && "-"}
    </>
  );
}
