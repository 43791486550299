import { Avatar, Card, Heading, Pane, Paragraph, Text } from "evergreen-ui";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import DateDiffValue from "../../common/DateDiffValue";
import DurationValue from "../../common/DurationValue";
import TravelDistanceValue from "../../common/TravelDistanceValue";
import { WorkerShift } from "@/types/apiTypes";
import { ReportStatusIconMap } from "@/components/shared/report/components/ReportFieldsRow";
import Block from "@/components/common/Block";
import { DateFormat } from "@/components/common/DateFormat";

type ShiftReportSummaryProps = {
  children?: ReactNode;
  workerShift: WorkerShift;
  showWorker?: boolean;
  showProject?: boolean;
};

export function WorkerShiftSummary({
  children,
  workerShift,
  ...rest
}: ShiftReportSummaryProps) {
  const { showWorker = true, showProject = false } = rest;

  const { t } = useTranslation();

  if (!workerShift) {
    return <></>;
  }

  return (
    <Card elevation={1} padding={16} marginBottom={16}>
      <Pane className="flex gap-1 pt-2">
        <Pane className="flex w-full flex-col gap-2">
          <Pane>
            {showProject && (
              <Pane className="flex gap-1 py-2">
                <Heading size={300}>Project:</Heading>
                <Heading className="!font-normal" size={200}>
                  {workerShift.shift.project?.name}
                </Heading>
              </Pane>
            )}
            <Pane className="flex gap-1 py-2">
              <Heading size={300}>Gebied:</Heading>
              <Heading className="!font-normal" size={200}>
                {workerShift.shift.area?.name}
              </Heading>
            </Pane>

            <Pane className="flex gap-1 py-2">
              <Pane className="flex w-full flex-col gap-2">
                <Pane>
                  <Heading size={400}>Activiteit(en):</Heading>
                  <Pane>
                    {workerShift.shift.areaActivityTypes.map((aat) => (
                      <Pane title={aat.activityType.type?.label} key={aat.id}>
                        <Text>- </Text>
                        <Text>{aat.activityType.name}</Text>
                        {/*<Text>: </Text>*/}
                        {/*<Text>*/}
                        {/*  <DateFormat*/}
                        {/*    className="font-bold !normal-case"*/}
                        {/*    size={100}*/}
                        {/*    formatStr="d MMM p"*/}
                        {/*    date={new Date(aat.startAt!)}*/}
                        {/*  />*/}
                        {/*</Text>*/}
                        {/*<Text> - </Text>*/}
                        {/*<Text>*/}
                        {/*  <DateFormat*/}
                        {/*    className="font-bold !normal-case"*/}
                        {/*    size={100}*/}
                        {/*    formatStr="d MMM p"*/}
                        {/*    date={new Date(aat.endAt!)}*/}
                        {/*  />*/}
                        {/*</Text>*/}
                      </Pane>
                    ))}
                  </Pane>
                </Pane>
              </Pane>
              {/*<Heading size={300}>Activiteit:</Heading>*/}
              {/*<Heading className="!font-normal" size={200}>*/}
              {/*  /!*{workerShift.shift.areaActivityType.activityType.name}*!/*/}
              {/*</Heading>*/}
            </Pane>
          </Pane>

          {showWorker && (
            <Pane className="items-center">
              <Pane className="flex gap-1 pb-2">
                <Heading size={300}>Veldwerker:</Heading>

                <Heading size={200}>
                  {workerShift.worker.user.firstName}{" "}
                  {workerShift.worker.user.lastName}
                </Heading>
              </Pane>
              <Pane className="flex">
                <Avatar
                  className="hover:opacity-90"
                  src={workerShift.worker.user!.profilePictureThumbnail ?? ""}
                  size={80}
                />
              </Pane>
            </Pane>
          )}
        </Pane>

        <Pane className="flex w-full flex-col gap-2">
          <Pane className="flex  gap-1">
            <Heading size={300}>Status:</Heading>
            <Heading size={200}>
              {t("shifts.status." + workerShift.status)}
            </Heading>
            {ReportStatusIconMap[workerShift.status]}
          </Pane>

          <Pane className="flex gap-1">
            <Heading size={300}>Originele Diensttijd:</Heading>
            <Heading className="!font-normal" size={200}>
              <DateDiffValue
                leftDate={workerShift.shift.startAt}
                rightDate={workerShift.shift.endAt}
              />
            </Heading>
          </Pane>

          <Pane
            title="Dit is de tijd die daadwerkelijk gewerkt is."
            className="flex gap-1"
          >
            <Heading size={300}>Diensttijd:</Heading>
            <Heading className="!font-normal" size={200}>
              {workerShift.startedAt && workerShift.endedAt && (
                <DateDiffValue
                  leftDate={workerShift.startedAt}
                  rightDate={workerShift.endedAt}
                />
              )}
            </Heading>
          </Pane>

          <Pane className="flex gap-1">
            <Heading size={300}>Reistijd:</Heading>
            <Heading className="!font-normal" size={200}>
              <DurationValue seconds={workerShift.travelDuration} />
            </Heading>
          </Pane>

          <Pane className="flex gap-1">
            <Heading size={300}>Rijdt zelf:</Heading>
            <Heading className="!font-normal" size={200}>
              {workerShift.isDriving ? "Ja" : "Nee"}
            </Heading>
          </Pane>

          {workerShift.isDriving && (
            <Pane className="flex gap-1">
              <Heading size={300}>Afstand:</Heading>
              <Heading className="!font-normal" size={200}>
                <TravelDistanceValue meters={workerShift.distanceToArea} />
              </Heading>
            </Pane>
          )}
        </Pane>
      </Pane>

      <Pane className="py-4">
        {/*<DisplayReportAddress*/}
        {/*  minHeightMap={250}*/}
        {/*  address={workerShift.address}*/}
        {/*/>*/}
      </Pane>

      {children && children}
    </Card>
  );
}
