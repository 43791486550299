import { useEffect, useMemo, useState } from "react";
import { Heading, Pane, Switch } from "evergreen-ui";
import {
  dateIsAfterTimeslotWindow,
  dateIsBeforeTimeslotWindow,
} from "../../../lib/functions";

export default function AvailabilitySwitcher({
  refreshCount,
  date,
  availability,
  refreshView,
  timeslot,
  isLocked,
  onChange,
}) {
  const dateKeyOfRow = date.toISOString();

  const availabilityOfRow = availability.current[dateKeyOfRow] ?? null;
  const isCheckedNow = availabilityOfRow
    ? !!availabilityOfRow[timeslot.id]
    : false;
  const [isChecked, setIsChecked] = useState(isCheckedNow);

  useEffect(() => {
    const availabilityRecord = availability.current[dateKeyOfRow] ?? null;
    const isCheckedNow = availabilityRecord
      ? !!availabilityRecord[timeslot.id]
      : false;

    setIsChecked(isCheckedNow);
  }, [refreshCount]);

  const isDateIsBeforeTimeslotWindow = useMemo(
    () => dateIsBeforeTimeslotWindow(timeslot, date),
    [timeslot],
  );
  const isDateIsAfterTimeslotWindow = useMemo(
    () => dateIsAfterTimeslotWindow(timeslot, date),
    [timeslot],
  );

  const setAvailabilityCb = (date, timeslot) => {
    if (isLocked) {
      return;
    }

    const currentAvailability = availability.current[dateKeyOfRow] ?? {};

    let newAvailability;

    if (!currentAvailability[timeslot.id]) {
      newAvailability = {
        ...currentAvailability,
        [timeslot.id]: {
          date: dateKeyOfRow,
          timeslotUid: timeslot.id,
        },
      };

      setIsChecked(true);
    } else {
      delete currentAvailability[timeslot.id];
      newAvailability = currentAvailability;

      setIsChecked(false);
    }

    availability.current = {
      ...availability.current,
      [dateKeyOfRow]: newAvailability,
    };

    refreshView();
    onChange();
  };

  return (
    <>
      {isDateIsBeforeTimeslotWindow || isDateIsAfterTimeslotWindow ? (
        <td className="cursor-not-allowed" key={"switcher-" + timeslot.id}>
          <Heading size={100} className="flex justify-center">
            N/A
          </Heading>
        </td>
      ) : (
        <td
          onClick={() => setAvailabilityCb(date, timeslot)}
          className="hover:inner-shadow-md cursor-pointer"
          key={"switcher-" + timeslot.id}
        >
          <Pane className="flex justify-center">
            <Switch
              disabled={isLocked}
              onChange={(e) => {
                e.stopPropagation();
                setAvailabilityCb(date, timeslot);
              }}
              checked={isChecked}
            ></Switch>
          </Pane>
        </td>
      )}
    </>
  );
}
