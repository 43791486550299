import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useState } from "react";
import { useApi } from "src/context/AxiosContext";
import { ActivityType } from "src/types/apiTypes";
import { useLoadResource } from "../../../lib/request-hooks";
import { EmptyState, Pane, SearchIcon, Table } from "evergreen-ui";
import Actions from "src/components/common/Actions";
import StandardTable from "src/components/table/StandardTable";
import DateValue from "src/components/common/DateValue";

export function useActivityTypes() {
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const [activityTypes, setActivityTypes] = useState<ActivityType[]>([]);
  const { apiInstance } = useApi();

  const request = () =>
    apiInstance!.adminActivityTypes.findMany(projectId!, searchParams);

  function setData(data) {
    setActivityTypes(data);
  }

  return {
    ...useLoadResource(request, setData),
    activityTypes,
  };
}

export default function ActivityTypesPage() {
  const { projectId } = useParams();

  const { activityTypes, refresh, isLoading, meta } = useActivityTypes();
  const navigate = useNavigate();

  const navTo = (activity) => {
    navigate(`/projects/${projectId}/activity-types/${activity.id}`);
  };

  return (
    <Pane>
      <Actions marginBottom={10}>
        <Link to={`/projects/${projectId}/activity-types/add`}>
          <Actions.Button appearance="primary">
            Nieuwe Activiteit
          </Actions.Button>
        </Link>
      </Actions>
      <StandardTable
        refresh={refresh}
        meta={meta}
        isLoading={isLoading}
        data={activityTypes}
        emptyState={<EmptyActivityTypesState />}
      >
        <Table.Head>
          <Table.TextHeaderCell>Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>Type</Table.TextHeaderCell>
          <Table.TextHeaderCell>Start / End</Table.TextHeaderCell>
          <Table.TextHeaderCell maxWidth={80}># Rounds</Table.TextHeaderCell>
          <Table.TextHeaderCell maxWidth={80}>
            # Days Between
          </Table.TextHeaderCell>
        </Table.Head>
        <Table.Body height="auto">
          {activityTypes.map((activity) => (
            <Table.Row
              key={activity.id}
              isSelectable
              onSelect={() => {
                navTo(activity);
              }}
            >
              <Table.TextCell className="!text-slate-800">
                {activity.name}
              </Table.TextCell>
              <Table.TextCell>{activity.type?.label}</Table.TextCell>
              <Table.TextCell>
                <DateValue formatStr={"PP"} date={activity.startAt} /> /{" "}
                <DateValue formatStr={"PP"} date={activity.endAt} />
              </Table.TextCell>
              <Table.TextCell maxWidth={80}>
                {activity.numberOfRounds}
              </Table.TextCell>
              <Table.TextCell maxWidth={80}>
                {activity.minDaysBetweenRounds}
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </StandardTable>
    </Pane>
  );
}

function EmptyActivityTypesState() {
  return (
    <EmptyState
      background="light"
      title="Nog geen activiteiten toegevoegd"
      orientation="horizontal"
      icon={<SearchIcon color="#C1C4D6" />}
      iconBgColor="#EDEFF5"
      description="Activiteiten verschijnen als je op de knop 'Nieuwe Activiteit' klikt. Daar kun je een nieuwe activiteit toevoegen."
    />
  );
}
