import { Heading, Pane } from "evergreen-ui";
import Divider from "@/components/common/Divider";
import { Form, WorkerShiftStatusUpdate } from "@/types/apiTypes";
import StatusListItem from "@/pages/submitted-worker-shifts/components/StatusListItem";

type StatusListProps = {
  statusUpdates?: WorkerShiftStatusUpdate[];
  forms: Form[];
};

export default function StatusUpdatesList({
  statusUpdates,
  forms,
}: StatusListProps) {
  return (
    <Pane className="flex flex-col gap-2">
      <Heading>Status updates</Heading>
      <Divider />
      {statusUpdates?.map((status) => (
        <StatusListItem
          key={status.id}
          status={status}
          form={forms.find((form) => form.id === status.formId)}
        />
      ))}
    </Pane>
  );
}
