import Page from "src/components/common/Page";
import PageTitle from "src/components/common/PageTitle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  ChevronLeftIcon,
  FormField,
  Heading,
  majorScale,
  Pane,
  Paragraph,
  TrashIcon,
} from "evergreen-ui";
import { useTranslation } from "react-i18next";
import { HookOptions } from "@/types/appTypes";
import { useApi } from "@/context/AxiosContext";
import { useEffect, useReducer, useState } from "react";
import {
  Media,
  ReportTemplate,
  ShiftReport,
  WorkerShift,
} from "../../types/apiTypes";
import { useDoRequest, useLoadResource } from "../../lib/request-hooks";
import { REPORTS_NEW_LIST_PAGE } from "../../RouteMap";
import Divider from "../../components/common/Divider";
import {
  AttachedShiftReportMediaFiles,
  ShiftFormContext,
  shiftReportFormReducer,
} from "../worker-shifts/WorkerShiftReportPage";
import { DateFormat } from "../../components/common/DateFormat";
import MultiFileUploader from "../../components/formfields/MultiFileUploader";
import Actions from "../../components/common/Actions";
import { parseISO } from "date-fns";
import ApproveReportDialog from "./_components/ApproveReportDialog";
import RejectReportDialog from "./_components/RejectReportDialog";
import { ReportFieldsRow } from "../../components/shared/report/components/ReportFieldsRow";
import { ShiftReportSummary } from "../../components/shared/report/ShiftReportSummary";
import { TableLoadingSpinner } from "../../components/table/StandardTable";
import AreYouSureDialog from "../../components/common/AreYouSureDialog";
import useSafeSubmit from "../../hooks/useSafeSubmit";

const useShiftReport = function (options: HookOptions = {}) {
  const { shiftReportId } = useParams();
  const { apiInstance } = useApi();

  const [shiftReport, setShiftReport] = useState<ShiftReport>();
  const request = () => apiInstance!.adminShiftReports.findOne(shiftReportId!);

  const setData = (reports) => {
    setShiftReport(reports);
  };

  return {
    ...useLoadResource(request, setData, true),
    shiftReport,
  };
};

export function useWorkerShiftReportTemplate({ shiftReport, afterSubmit }) {
  const { apiInstance } = useApi();

  const [template, setTemplate] = useState<ReportTemplate | null>(null);
  const [startedAt, setStartedAt] = useState<Date | null>(null);
  const [endedAt, setEndedAt] = useState<Date | null>(null);
  const [notes, setNotes] = useState<String | null>(null);

  const [mediaFiles, setMediaFiles] = useState<Media[]>([]);
  const [uploadingMediaFiles, setUploadingMediaFiles] = useState<Media[]>([]);

  const {
    setIsLoading: setIsSubmitting,
    isLoading: isSubmitting,
    validationErrors,
    errorMessage: submitErrorMessage,
    setValidationErrors,
    handle,
  } = useDoRequest();

  const findTemplate = () => apiInstance!.workerShiftReport.getTemplate();

  const [formState, dispatch] = useReducer(shiftReportFormReducer, {
    isDirty: false,
    reportFields: {},
  });

  const templateResource = useLoadResource(findTemplate, setTemplate, true);

  const doSubmit = async () => {
    const data = {
      ...formState,
      startedAt,
      endedAt,
      notes,
    };

    let request;

    if (shiftReport) {
      request = apiInstance!.adminShiftReports.update(shiftReport.id!, data);
    }

    const result = await handle(request);

    afterSubmit(result);
  };

  const submitContext = {
    doSubmit,
    setIsSubmitting,
    isSubmitting,
    submitErrorMessage,
    validationErrors,
    setValidationErrors,
  };

  const formContext = {
    formState,
    submitContext,
    setDirty: () => dispatch({ type: "setDirty" }),
    onChange: (key, value) => {
      dispatch({ type: "onChange", key: key, value: { value } });
    },
  };

  useEffect(() => {
    if (shiftReport) {
      dispatch({
        type: "setReportFields",
        reportFields: shiftReport.reportFields,
      });

      if (shiftReport.workerShift) {
        setStartedAt(parseISO(shiftReport.workerShift.startedAt));
        setEndedAt(parseISO(shiftReport.workerShift.endedAt));
      }

      setMediaFiles(shiftReport.media);
    }
  }, [shiftReport]);

  function uploadFn(formData) {
    return apiInstance!.media.uploadToShiftReportTmp(formData);
  }

  return {
    template,
    templateResource,
    formState,
    formContext,
    uploadingMediaFiles,
    setUploadingMediaFiles,
    mediaFiles,
    setMediaFiles,
    uploadFn,
    setStartedAt,
    setEndedAt,
    startedAt,
    endedAt,
  };
}

export default function ShiftReportDetailPage() {
  const location = useLocation();
  const { backPath } = location?.state ?? {};

  const { isLoading, refresh, shiftReport } = useShiftReport();

  return (
    <Page>
      <PageTitle backPath={backPath ?? REPORTS_NEW_LIST_PAGE}>Report</PageTitle>

      <Pane className="max-w-2xl">
        {isLoading && (
          <TableLoadingSpinner title="Rapport aan het ophalen..." />
        )}
        {isLoading === false && shiftReport && (
          <ReportForm
            editMode={false}
            shiftReport={shiftReport}
            refresh={refresh}
          />
        )}
      </Pane>
    </Page>
  );
}

function useRemoveWorker() {
  const { apiInstance } = useApi();

  function doRemoveWorker(workerShift: WorkerShift) {
    return apiInstance!.adminShifts.removeWorker(
      workerShift.shift.id,
      workerShift.workerId,
    );
  }

  return {
    doRemoveWorker,
  };
}

export function ReportForm({ editMode, shiftReport, refresh }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const location = useLocation();
  const { backPath } = location?.state ?? {};

  const [approveDialogIsShown, setApproveDialogIsShown] =
    useState<boolean>(false);
  const [rejectDialogIsShown, setRejectDialogIsShown] =
    useState<boolean>(false);

  function goBack() {
    navigate(REPORTS_NEW_LIST_PAGE);
  }

  const {
    template,
    formContext,
    uploadingMediaFiles,
    setUploadingMediaFiles,
    mediaFiles,
    setMediaFiles,
    uploadFn,
    startedAt,
    endedAt,
  } = useWorkerShiftReportTemplate({
    shiftReport,
    afterSubmit: refresh,
  });

  const { doRemoveWorker } = useRemoveWorker();

  const {
    safeSubmitDialogShown,
    closeSafeDialog,
    doSafeSubmit: doSafeRemoveWorker,
    onSafeSubmit: onSafeRemoveWorker,
  } = useSafeSubmit({
    onConfirm: goBack,
    doSubmit: () => doRemoveWorker(shiftReport.workerShift!),
    isDirty: true,
  });

  const sections = template?.reportTemplateSections || [];

  return (
    <ShiftFormContext.Provider value={formContext}>
      <AreYouSureDialog
        doAction={doSafeRemoveWorker}
        text={t("dialog_are_you_sure.text").toString()}
        intent="danger"
        isShown={safeSubmitDialogShown}
        onCloseComplete={closeSafeDialog}
        isConfirmLoading={false}
      />

      <ApproveReportDialog
        isShown={approveDialogIsShown}
        close={() => setApproveDialogIsShown(false)}
        afterSubmit={() => {
          setApproveDialogIsShown(false);
          goBack();
        }}
        shiftReport={shiftReport}
      />

      <RejectReportDialog
        isShown={rejectDialogIsShown}
        close={() => setRejectDialogIsShown(false)}
        afterSubmit={() => {
          setRejectDialogIsShown(false);
          goBack();
        }}
        shiftReport={shiftReport}
      />

      <Actions className="py-2">
        <Actions.Button
          iconBefore={ChevronLeftIcon}
          onClick={() => navigate(backPath ?? REPORTS_NEW_LIST_PAGE)}
          height={majorScale(5)}
        >
          {t("common.go_back")}
        </Actions.Button>
        <Actions.Button
          onClick={() => setRejectDialogIsShown(true)}
          isLoading={!!formContext.submitContext.isSubmitting}
          appearance="primary"
          intent="danger"
          height={majorScale(5)}
        >
          Afkeuren
        </Actions.Button>

        <Actions.Button
          onClick={() => setApproveDialogIsShown(true)}
          isLoading={!!formContext.submitContext.isSubmitting}
          appearance="primary"
          intent="success"
          height={majorScale(5)}
        >
          Goedkeuren
        </Actions.Button>
      </Actions>

      <ShiftReportSummary
        showProject={true}
        showWorker={true}
        shiftReport={shiftReport}
      />

      {shiftReport.notes && (
        <Pane className="py-4">
          <Heading size={500}>Feedback:</Heading>
          <Paragraph size={400}>{shiftReport.notes}</Paragraph>
        </Pane>
      )}

      <Divider title="Observatietijd">
        <Pane className="flex gap-2">
          <Pane>
            <Heading size={300}>Starttijd:</Heading>
            {startedAt && (
              <DateFormat size={400} formatStr="PPPPp" date={startedAt!} />
            )}
          </Pane>

          <Pane>
            <Heading size={300}>Eindtijd:</Heading>
            {endedAt && (
              <DateFormat size={400} formatStr="PPPPp" date={endedAt!} />
            )}
          </Pane>
        </Pane>
      </Divider>

      {formContext.formState.reportFields &&
        sections.map((section) => (
          <Pane className="py-2" key={section.key}>
            <Divider small={true} title={t("report_fields." + section.key)}>
              <ReportFieldsRow editMode={editMode} section={section} />
            </Divider>
          </Pane>
        ))}

      <Divider title="Bestanden">
        {editMode === true && (
          <FormField>
            <MultiFileUploader
              uploadFn={uploadFn}
              mediaFiles={uploadingMediaFiles}
              setMediaFiles={(v) => {
                formContext.setDirty();
                setUploadingMediaFiles(v);
              }}
              setIsLoading={formContext.submitContext.setIsSubmitting}
            />
          </FormField>
        )}

        {editMode === false && (
          <FormField label={"Attached media"}>
            <AttachedShiftReportMediaFiles
              editMode={false}
              mediaFiles={mediaFiles}
              setMediaFiles={(v) => {
                formContext.setDirty();
                setMediaFiles(v);
              }}
            />
          </FormField>
        )}
      </Divider>

      <Divider title="Danger zone">
        <Card elevation={1} className={"mt-8 p-8"}>
          <Pane className="flex w-full items-center">
            <Heading size={400}>Danger zone</Heading>
            <Button
              onClick={onSafeRemoveWorker}
              iconBefore={<TrashIcon />}
              isLoading={!!formContext.submitContext.isSubmitting}
              intent="danger"
              appearance="primary"
              marginLeft="auto"
              height={majorScale(5)}
            >
              Dienst annuleren
            </Button>
          </Pane>
        </Card>
      </Divider>
    </ShiftFormContext.Provider>
  );
}
// const ReportFieldMap = {
//     windDirection: WindDirection,
//     notes: ReportNotes,
//     temperature: Temperature,
//     precipitation: Precipitation,
//     cloudiness: Cloudiness,
//     windSpeed: WindSpeed,
// }
