type DurationValueProps = {
  meters?: number;
};

export default function TravelDistanceValue({ meters }: DurationValueProps) {
  if (!meters) {
    return <>-</>;
  }

  return <>{(meters / 1000).toFixed(2)} km</>;
}
