import { ADMIN_BOOKKEEPING_EXPORT_LIST_PAGE } from "@/RouteMap";
import Block from "@/components/common/Block";
import Page from "@/components/common/Page";
import PageTitle from "@/components/common/PageTitle";
import {
    Text,
    Pane,
    Spinner,
    Table,
    TableCell,
    TableRow,
} from "evergreen-ui";
import { useApi } from "@/context/AxiosContext";
import { useLoadResource } from "@/lib/request-hooks";
import { PaymentSpecificationGroupedByUser } from "@/types/apiTypes";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { DateFormat } from "@/components/common/DateFormat";
import CurrencyValue from "@/components/common/CurrencyValue";

function useLoadExport() {
    const { paymentId } = useParams()
    const [paymentSpec, setPaymentSpec] = useState<PaymentSpecificationGroupedByUser | undefined>(undefined)

    const { apiInstance } = useApi()

    const request = () => apiInstance!.adminPayments.findOne(paymentId!)
    const loadRes = useLoadResource(request, setPaymentSpec, true)

    return {
        ...loadRes,
        paymentSpec,
    }
}

export default function BookkeepingExportPage() {
    const { isLoading, meta, refresh, paymentSpec } = useLoadExport()

    return (
        <Page>
            <Pane className="grow">
                <PageTitle backPath={ADMIN_BOOKKEEPING_EXPORT_LIST_PAGE} marginBottom={20}>
                    Export controle
                </PageTitle>

                {isLoading && (
                    <Block className="flex justify-end">
                        <Spinner size={20} />
                    </Block>
                )}
            </Pane>

            {paymentSpec && (
                <>
                    <Pane>

                    </Pane>

                    {paymentSpec.users.map((user) => (<UserRecord key={user.id} user={user} />))}
                </>
            )}
        </Page>
    )
}

function UserRecord({ user }) {
    return (
        <Pane>
            <Table>
                <Table.Head>
                    <TableCell>
                        <Text><strong>{user.name}</strong></Text>
                    </TableCell>
                    <TableCell>
                        {user.hasTransport && (<Text>Has transport</Text>)}
                        {user.isVeteran && (<Text>Veteraan</Text>)}
                    </TableCell>
                </Table.Head>

                {user.shifts.map((shift) => (
                    <ShiftRecord key={shift.id} shift={shift} />
                ))}

                <Table.Head>
                    <TableCell>
                        <Text>
                            {user.hours} Total ex travel:<br />
                            {user.kilometers} Total travel:<br />
                            Total:<br />
                        </Text>
                    </TableCell>
                    <TableCell >
                        <Text>
                            <CurrencyValue amount={user.totalExTravel} currency="EUR" /><br />
                            <CurrencyValue amount={user.totalTravel} currency="EUR" /><br />
                            <strong><CurrencyValue amount={user.total} currency="EUR" /></strong><br />
                        </Text>
                    </TableCell>
                </Table.Head>
            </Table>
        </Pane>

    )
}

function ShiftRecord({ shift }) {
    return (
        <>
            <TableRow height={32}>
                <TableCell>
                    {shift.name}
                    {shift.isDriving && "(isDriver)"}
                </TableCell>
                <Table.TextCell>
                    <DateFormat date={new Date(shift.date)} />
                </Table.TextCell>
            </TableRow>
            {shift.rows.map((shiftRow) => (
                <TableRow key={shiftRow.id} height={24}>
                    <Table.TextCell>
                        {shiftRow.amount}
                    </Table.TextCell>
                    <Table.TextCell>
                        {shiftRow.description}
                    </Table.TextCell>
                    <Table.TextCell>
                        <CurrencyValue amount={shiftRow.total} currency="EUR" />
                    </Table.TextCell>
                </TableRow>
            ))}
        </>
    )
}