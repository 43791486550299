import mapboxgl from "mapbox-gl";

import Block from "@/components/common/Block";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useShowFeaturesOnMap } from "@/hooks/useShowFeaturesOnMap";
import { useShowAreasOnMap } from "@/pages/projects/areas/useShowAreasOnMap";
import {
  Area,
  ClientObject,
  ObservationFeatureCollection,
} from "@/types/apiTypes";
import { Feature, FeatureCollection, GeometryCollection, Point } from "geojson";
import { classNames } from "@/lib/functions";

type ObjectEditMapProps = {
  observations: ObservationFeatureCollection | {};
  createdLocations?: Feature[];
  objects?: ClientObject[];
  geoObjects?: FeatureCollection<Point>;
  geoArea?: Feature<GeometryCollection>;
  area?: Area;
  className?: string;
  fitBoundsToObservations?: boolean;
  fitBoundsToObjects?: boolean;
};

const ObservationMap = forwardRef(function ObjectEditMap(
  props: ObjectEditMapProps,
  ref,
) {
  const {
    geoArea,
    geoObjects,
    observations,
    createdLocations = [],
    objects,
    area,
  } = props;
  const mapContainer = useRef<HTMLDivElement | string>("");
  const mapRef = useRef<mapboxgl.Map | null>(null);

  useImperativeHandle(ref, () => ({
    resize: () => {
      if (mapRef.current) {
        mapRef.current.resize();
      }
    },
  }));

  const onLoaded = (map: mapboxgl.Map) => {
    if (!mapRef.current) {
      return;
    }

    mapRef.current?.resize();
  };

  useEffect(() => {
    if (mapRef.current) {
      return;
    }

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      zoom: 9,
      accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
      center: [4.895168, 52.370216],
    });
  });

  useImperativeHandle(ref, () => ({
    flyTo: (center: mapboxgl.LngLatLike) => {
      if (mapRef.current) {
        mapRef.current!.flyTo({
          center,
          zoom: 18,
          essential: true,
          duration: 1000,
        });
      }
    },
  }));

  const objectFeatures = useMemo(() => {
    if (geoObjects) {
      return geoObjects;
    }

    return {
      type: "FeatureCollection",
      features:
        objects?.map((object) => {
          return {
            id: object.id,
            type: "Feature",
            geometry: object.geometry.geometry,
            properties: {
              id: object.id,
              name: object.name,
              type: "object",
              object_id: object.id,
              icon: "marker",
            },
          };
        }) ?? [],
    } as FeatureCollection;
  }, [objects, geoObjects]);

  const createdLocationFeatures = useMemo(() => {
    return {
      type: "FeatureCollection",
      features: createdLocations.map((location, index) => {
        const locationId = Math.random().toString(36).substring(7);

        return {
          type: "Feature",
          id: locationId,
          geometry: location.geometry,
          properties: {
            location_id: locationId,
            icon: "marker",
            type: "created-location",
            ...location.properties,
          },
        };
      }),
    } as FeatureCollection;
  }, [createdLocations]);

  const observationFeatures = useMemo(() => {
    return {
      type: "FeatureCollection",
      features:
        observations?.features?.map((observation) => {
          return {
            type: "Feature",
            id: Math.random().toString(36).substring(7),
            geometry: observation.geometry,
            properties: {
              name:
                observation.properties?.createdBy?.firstName +
                " " +
                observation.properties?.createdBy?.lastName,
              observation_id: observation.id,
              type: "observation",
              icon: "marker",
              ...observation.properties,
            },
          };
        }) ?? [],
    } as FeatureCollection;
  }, [observations]);

  const areaFeatures = useMemo(() => {
    if (geoArea) {
      return geoArea;
    }

    return {
      type: "FeatureCollection",
      features: area?.geometry.features ?? [],
    } as FeatureCollection;
  }, [area, geoArea]);

  useShowFeaturesOnMap({
    mapRef,
    featureCollection: observationFeatures,
    onClick: () => {},
    shouldFitBoundsOnLoad: props.fitBoundsToObservations ?? false,
    sourceId: "observation-source",
    color: "red",
    addControl: true,
  });

  useShowFeaturesOnMap({
    mapRef,
    featureCollection: createdLocationFeatures,
    onClick: () => {},
    shouldFitBoundsOnLoad: false,
    sourceId: "created-location-source",
    color: "blue",
  });

  useShowFeaturesOnMap({
    mapRef,
    featureCollection: objectFeatures,
    onClick: () => {},
    shouldFitBoundsOnLoad: false,
    sourceId: "object-source",
  });

  useShowAreasOnMap({
    mapRef,
    onClick: () => {},
    areasFeatures: areaFeatures ?? [],
    shouldFitBoundsOnLoad: true,
    areas: [area],
    onLoaded,
  });

  return (
    <Block>
      <Block>
        <div
          ref={mapContainer}
          className={classNames("map-container", props.className)}
        />
      </Block>
    </Block>
  );
});

export default ObservationMap;
