import {
  ChevronDownIcon,
  ChevronUpIcon,
  IconButton,
  Pane,
  PaneProps,
} from "evergreen-ui";
import { forwardRef, useState } from "react";
import Divider from "@/components/common/Divider";

interface CollapseProps extends PaneProps {
  title?: string;
  defaultIsOpen?: boolean;
}

const Collapse = forwardRef<HTMLDivElement, CollapseProps>((props, ref) => {
  const { title, children, defaultIsOpen, ...rest } = props;

  const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen ?? true);

  return (
    <Pane className="w-full" ref={ref} {...rest}>
      {/*<Pane onClick={() => setIsOpen(!isOpen)} className="flex gap-1 items-center justify-center max-w-[7rem]">*/}
      {/*    { title &&*/}
      {/*      <Heading className={"text-center"} size={100}>{ title }</Heading>*/}
      {/*    }*/}
      {/*</Pane>*/}
      <Pane
        onClick={() => setIsOpen(!isOpen)}
        className="flex w-full items-center gap-2"
      >
        <Divider className="grow" title={title} />
        <IconButton
          onClick={() => setIsOpen(!isOpen)}
          icon={isOpen ? ChevronDownIcon : ChevronUpIcon}
          appearance="minimal"
          type="button"
        />
      </Pane>
      {isOpen && children}
    </Pane>
  );
});

export default Collapse;
