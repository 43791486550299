import mapboxgl, { LngLatLike, MapboxGeoJSONFeature } from "mapbox-gl";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ClientObject } from "@/types/apiTypes";
import { Feature, FeatureCollection, GeometryCollection, Point } from "geojson";
import { formatTimeDefaultLocale, getBoundingBox } from "@/lib/functions";

type useFeaturesOnMap = {};

export type PointFeature = Feature<GeometryCollection<Point>>;

function getTmpSourceId(sourceId: string, suffix?: string) {
  return `${sourceId}-tmp${suffix ? `-${suffix}` : ""}`;
}

function getObjectSourceId(sourceId: string, suffix?: string) {
  return `${sourceId}${suffix ? `-${suffix}` : ""}`;
}

function addSourceAndLayersToMap(
  map: mapboxgl.Map,
  featureCollection: FeatureCollection,
  sourceId: string,
  color?: string,
) {
  map.addSource(getObjectSourceId(sourceId), {
    type: "geojson",
    data: featureCollection,
  });

  map.addSource(getTmpSourceId(sourceId), {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });

  map.addLayer({
    id: getTmpSourceId(sourceId, "layer"),
    type: "circle",
    source: getTmpSourceId(sourceId),
    paint: {
      "circle-radius": ["match", ["get", "state"], "active", 6, 6],
      "circle-stroke-width": 2,
      "circle-color": [
        "match",
        ["get", "state"],
        "active",
        "#ff9044",
        "#ffd97c",
      ],
    },
    filter: ["==", "$type", "Point"],
  });

  map.addLayer({
    id: getObjectSourceId(sourceId, "layer"),
    type: "circle",
    source: getObjectSourceId(sourceId),
    paint: {
      "circle-radius": ["match", ["get", "state"], "active", 7, 6],
      "circle-color": [
        "match",
        ["get", "state"],
        "active",
        "#0080ff",
        color ?? "#92e159",
      ],
      "circle-stroke-color": "#2d2d2d",
      "circle-stroke-width": 2,
    },
    filter: ["==", "$type", "Point"],
  });
}

function useMapEvents(
  mapRef: MutableRefObject<mapboxgl.Map | null>,
  onClick: (feature: Feature, objects: ClientObject[]) => void,
  features: FeatureCollection[],
  sourceId: string,
) {
  const currentPopup = useRef<mapboxgl.Popup | null>(null);
  const hoveredPolygonId = useRef<number | null>(null);

  function selectBoundingBox(
    map,
    e: mapboxgl.MapMouseEvent & mapboxgl.EventData,
  ) {
    const bbox = [
      [e.point.x - 5, e.point.y - 5],
      [e.point.x + 5, e.point.y + 5],
    ] as [[number, number], [number, number]];

    if (map.getLayer(getObjectSourceId(sourceId, "layer")) == null) {
      return [];
    }

    // Find features intersecting the bounding box.
    return map.queryRenderedFeatures(bbox, {
      layers: [getObjectSourceId(sourceId, "layer")],
    });
  }

  function showPopup(
    map: mapboxgl.Map,
    e: mapboxgl.MapMouseEvent & mapboxgl.EventData,
    feature: MapboxGeoJSONFeature,
  ) {
    if (currentPopup.current) {
      currentPopup.current.remove();
    }

    if (!feature) {
      return;
    }

    if (!("coordinates" in feature.geometry)) {
      return;
    }

    const coordinates = feature.geometry.coordinates as LngLatLike;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    if (currentPopup.current) {
      currentPopup.current.remove();
    }

    const description = [
      `<p class="font-bold">${feature.properties?.type}</p>`,
      `<h3>${feature.properties?.name}</h3>`,
    ];

    if (feature.properties?.notes) {
      description.push(`<p>${feature.properties?.notes}</p>`);
    }

    if (feature.properties?.createdAt) {
      description.push(
        `<p>${formatTimeDefaultLocale(new Date(feature.properties?.createdAt), "dd-MM-yy pp")}</p>`,
        `<p>${formatTimeDefaultLocale(new Date(feature.properties?.createdAt), "PPPPp")}</p>`,
      );
    }

    const html = `<div>${description.join("")}</div>`;

    currentPopup.current = new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML(html)
      .addTo(map);
  }

  // const onClickObject = useCallback(
  //   (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
  //     if (mapRef.current == null) {
  //       return;
  //     }
  //
  //     const map = mapRef.current;
  //
  //     // ========== Find selected features with bounding box ==============
  //     const selectedFeatures = selectBoundingBox(map, e);
  //
  //     if (selectedFeatures.length === 0) {
  //       return;
  //     }
  //     // ==================================================================
  //
  //     onClick(selectedFeatures[0], objects);
  //
  //     showPopup(map, e, selectedFeatures[0]);
  //   },
  //   [mapRef, objects],
  // );

  const onHoverObject = useCallback(
    (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      const map = mapRef.current;

      if (!map) {
        return;
      }

      // ========== get selected features ==============
      const selectedFeatures = selectBoundingBox(map, e);

      if (selectedFeatures.length === 0) {
        if (currentPopup.current) {
          currentPopup.current.remove();
        }

        return;
      }
      // ==============================================

      // ========== set hover state ====================
      if (hoveredPolygonId.current !== null) {
        map.setFeatureState(
          { source: getObjectSourceId(sourceId), id: hoveredPolygonId.current },
          { hover: false },
        );
      }

      hoveredPolygonId.current = selectedFeatures[0].id as number;

      map.setFeatureState(
        { source: getObjectSourceId(sourceId), id: hoveredPolygonId.current },
        { hover: true },
      );
      // ======================================

      showPopup(map, e, selectedFeatures[0]);
    },
    [mapRef, features],
  );

  const onMouseLeave = useCallback(() => {
    const map = mapRef.current;

    if (!map) {
      return;
    }

    map.getCanvas().style.cursor = "";

    if (currentPopup.current) {
      currentPopup.current.remove();
    }
  }, [mapRef, features]);

  return {
    onHoverObject,
    onMouseLeave,
  };
}

export function useShowFeaturesOnMap({
  mapRef,
  featureCollection,
  onClick,
  shouldFitBoundsOnLoad,
  sourceId,
  tmpFeatures,
  color,
  addControl = false,
}: {
  mapRef: MutableRefObject<mapboxgl.Map | null>;
  featureCollection: FeatureCollection;
  onClick: (feature: Feature, objects: ClientObject[]) => void;
  areasFeatures?: Feature[];
  shouldFitBoundsOnLoad: boolean;
  tmpFeatures?: PointFeature[];
  sourceId: string;
  color?: string;
  addControl?: boolean;
}) {
  const { onHoverObject, onMouseLeave } = useMapEvents(
    mapRef,
    onClick,
    featureCollection,
    sourceId,
  );
  const [mapIsLoaded, setMapIsLoaded] = useState(false);

  useEffect(() => {
    if (mapRef.current == null || mapIsLoaded) {
      return;
    }

    const map = mapRef.current;

    const onLoad = () => {
      addSourceAndLayersToMap(map, featureCollection, sourceId, color);
      setMapIsLoaded(true);

      if (shouldFitBoundsOnLoad && featureCollection.features?.length > 0) {
        const boundingBox = getBoundingBox(featureCollection.features);

        map.fitBounds([
          [boundingBox.xMin - 0.0005, boundingBox.yMin - 0.0005],
          [boundingBox.xMax + 0.0005, boundingBox.yMax + 0.0005],
        ]);
      }
    };

    map.on("load", onLoad);

    const onIdle = () => {
      map.resize();
    };

    map.once("idle", onIdle);

    return () => {
      map.off("load", onLoad);
    };
  }, [mapRef, featureCollection]);

  useEffect(() => {
    if (mapRef.current == null) {
      return;
    }

    if (mapIsLoaded && addControl) {
      const map = mapRef.current;

      map.addControl(new mapboxgl.NavigationControl());
    }
  }, [mapIsLoaded]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    const map = mapRef.current;

    const onLoad = () => {
      // @ts-ignore
    };

    map.on("load", onLoad);

    return () => {
      if (map) {
        map.off("load", onLoad);
      }
    };
  }, [mapRef]);

  useEffect(() => {
    if (mapRef.current == null) {
      return;
    }

    const map = mapRef.current;

    if (map != null) {
      const source = map.getSource(getObjectSourceId(sourceId)) as
        | mapboxgl.GeoJSONSource
        | undefined;

      if (
        source &&
        featureCollection &&
        featureCollection.features?.length > 0
      ) {
        source.setData(featureCollection);
      }

      if (shouldFitBoundsOnLoad && featureCollection.features?.length > 0) {
        const boundingBox = getBoundingBox(featureCollection.features);

        map.fitBounds([
          [boundingBox.xMin - 0.0005, boundingBox.yMin - 0.0005],
          [boundingBox.xMax + 0.0005, boundingBox.yMax + 0.0005],
        ]);
      }
    }
  }, [mapRef, featureCollection]);

  // useEffect(() => {
  //   if (mapRef.current == null) {
  //     return;
  //   }
  //
  //   const map = mapRef.current;
  //
  //   if (map != null) {
  //     const source = map.getSource(getTmpSourceId(sourceId)) as
  //       | mapboxgl.GeoJSONSource
  //       | undefined;
  //
  //     if (source && tmpFeatures) {
  //       source.setData({
  //         type: "FeatureCollection",
  //         features: tmpFeatures,
  //       });
  //     }
  //   }
  // }, [mapRef, tmpFeatures]);
  useEffect(() => {
    if (mapRef.current == null) {
      return;
    }

    const map = mapRef.current;

    // map.on("click", onClickObject);
    map.on("mousemove", onHoverObject);
    map.on("mouseout", onMouseLeave);

    return () => {
      // map.off("click", onClickObject);
      map.off("mousemove", onHoverObject);
      map.off("mouseout", onMouseLeave);
    };
  }, [mapRef, featureCollection]);
}
