import {
  ExportIcon,
  IconButton,
  majorScale,
  Pane,
  Spinner,
  Table,
} from "evergreen-ui";
import { useApi } from "../../context/AxiosContext";
import StandardTable from "../../components/table/StandardTable";
import { useDoRequest, useLoadResource } from "@/lib/request-hooks";
import { useState } from "react";
import { PaymentSpecification } from "@/types/apiTypes";
import { useWindowSearchParams } from "src/hooks/useWindowSearchParams";
import Page from "../../components/common/Page";
import PageTitle from "../../components/common/PageTitle";
import { useTranslation } from "react-i18next";
import Actions from "../../components/common/Actions";
import DateValue from "@/components/common/DateValue";
import CurrencyValue from "@/components/common/CurrencyValue";
import { useNavigate } from "react-router-dom";

function useWorkerBookkeeping(onExportCreated: (exp) => void) {
  const searchParams = useWindowSearchParams();
  const [payments, setPayments] = useState<PaymentSpecification[]>([])

  const { apiInstance } = useApi()

  const request = () => apiInstance!.adminPayments.findMany(searchParams)
  const loadRes = useLoadResource(request, setPayments, true)

  const { handle, isLoading } = useDoRequest();

  async function doCreate() {
    const request = apiInstance!.adminPayments.create({})
    const exp = await handle(request)
    onExportCreated(exp.data)
  }

  return {
    doCreate,
    isCreating: isLoading,
    ...loadRes,
    payments,
  }
}

function useExportLogic() {
  const { apiInstance } = useApi()

  const { handle, isLoading } = useDoRequest()

  async function doExport(id) {
    const request = apiInstance!.adminPayments.exportToCsv(id)

    return await handle(request)
  }

  return {
    doExport,
    isLoading,
  };
}

export default function BookkeepingExportListPage() {
  const navigate = useNavigate()

  const { t } = useTranslation();
  const { isLoading, meta, refresh, payments, doCreate, isCreating } = useWorkerBookkeeping((exp) => { 
    navigate(`/admin/bookkeeping/export/${exp.id}`)
   });

  const { doExport, isLoading: isExporting } = useExportLogic();

  async function doExportCb(id) {
    const { url } = await doExport(id);
    const a = document.createElement("a");

    a.target = "_blank";
    a.style.display = "none";
    a.href = url;

    // the filename you want
    a.download = "export.csv";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  return (
    <Page>
      <PageTitle
        actions={
          <Actions>
            <Actions.Button
              textOverflow={"ellipsis"}
              appearance="primary"
              intent="success"
              isLoading={isCreating}
              onClick={doCreate}
              height={majorScale(4)}
            >
              Create payment
            </Actions.Button>
          </Actions>
        }
      >
        <Pane className="flex gap-1">
          {t("admin_bookkeeping_page.title")}
          {isLoading && (
            <Pane className="flex justify-end">
              <Spinner size={30} />
            </Pane>
          )}
        </Pane>
      </PageTitle>

      <StandardTable
        minHeight={400}
        refresh={refresh}
        meta={meta}
        isLoading={isLoading}
        data={payments}
        emptyState={<></>}
      >
        <Table.Head className="!pr-0">
          <StandardTable.HeaderCell>Id</StandardTable.HeaderCell>
          <StandardTable.HeaderCell>Created</StandardTable.HeaderCell>
          <StandardTable.HeaderCell>Shifts</StandardTable.HeaderCell>
          <StandardTable.HeaderCell>Total</StandardTable.HeaderCell>
          <StandardTable.HeaderCell></StandardTable.HeaderCell>
        </Table.Head>
        {payments.map((row) => (
          <Table.Row 
            key={row.id}
            isSelectable
            onSelect={() => {
              navigate(`/admin/bookkeeping/export/${row.id}`)
            }}
          >
            <Table.TextCell>
              {row.id}
            </Table.TextCell>
            <Table.TextCell>
              <DateValue date={row.createdAt} />
            </Table.TextCell>
            <Table.TextCell>
              {row.numberOfShifts}
            </Table.TextCell>
            <Table.TextCell>
              <CurrencyValue amount={row.total} currency="EUR" />
            </Table.TextCell>
            <Table.Cell>
              <IconButton disabled={isExporting === true} icon={ExportIcon} onClick={() => { doExportCb(row.id) }}/>
            </Table.Cell>
          </Table.Row>
        ))}
      </StandardTable>
    </Page>
  );
}
