import { FormField, Pane, Select, TextInputField } from "evergreen-ui";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useCallback, useState } from "react";
import { roleOptions } from "../../components/config/roles";
import { debounce } from "lodash";

export default function UsersFilter() {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");

  const location = useLocation();
  const navigate = useNavigate();

  const FilterMap = {
    email: {
      setter: setEmail,
      topic: "email",
    },
    firstName: {
      setter: setFirstName,
      topic: "firstName",
    },
    lastName: {
      setter: setLastName,
      topic: "lastName",
    },
    role: {
      setter: setRole,
      topic: "role",
    },
  };

  const [searchParams] = useSearchParams();

  const doFilter = (filterEntry, value) => {
    if (value) {
      searchParams.set(filterEntry.topic, value);
    } else {
      searchParams.delete(filterEntry.topic);
    }

    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(searchParams)}`,
    });
  };

  const debouncedFilter = useCallback(debounce(doFilter, 300), []);

  const handleInput = (filterEntry, value) => {
    filterEntry.setter(value);

    debouncedFilter(filterEntry, value);
  };

  return (
    <Pane className="flex flex-col gap-3">
      <TextInputField
        name="email"
        label="Email"
        placeholder=""
        value={email}
        onChange={(e) => handleInput(FilterMap.email, e.target.value)}
        marginBottom={0}
        inputHeight={40}
      />

      <TextInputField
        name="firstName"
        label="First name"
        placeholder=""
        value={firstName}
        onChange={(e) => handleInput(FilterMap.firstName, e.target.value)}
        marginBottom={0}
        inputHeight={40}
      />

      <TextInputField
        name="lastName"
        label="Last name"
        placeholder=""
        value={lastName}
        onChange={(e) => handleInput(FilterMap.lastName, e.target.value)}
        marginBottom={0}
        inputHeight={40}
      />

      <FormField label="Role">
        <Select
          value={role}
          onChange={(e) => handleInput(FilterMap.role, e.target.value)}
          width={240}
          height={40}
        >
          <option key="-" value="">
            -
          </option>
          {roleOptions.map((roleOption) => (
            <option key={roleOption.value} value={roleOption.value}>
              {roleOption.label}
            </option>
          ))}
        </Select>
      </FormField>
    </Pane>
  );
}
