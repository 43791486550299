import {
  Avatar,
  BadgeOwnProps,
  BanCircleIcon,
  CrossIcon,
  DriveTimeIcon,
  IconButton,
  KnownVehicleIcon,
  Pane,
  PaperclipIcon,
  Popover,
  Position,
  SmallCrossIcon,
  TickIcon,
  TrashIcon,
} from "evergreen-ui";

import { WorkerApiType } from "src/types/apiTypes";
import { classNames } from "@/lib/functions";
import { createElement, useMemo, useState } from "react";
import { FaRegAddressCard } from "react-icons/fa";
import { AiOutlineCar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { __r, REPORTS_DETAIL_PAGE } from "@/RouteMap";
import ManageCarpoolingDialog from "./ManageCarpoolingDialog";
import { GrGroup } from "react-icons/gr";
import {
  ShiftStatusColorMap,
  ShiftStatusIconMap,
} from "@/components/config/iconMaps";

export interface WorkerItemProps extends BadgeOwnProps {
  worker: WorkerApiType & { reportOverdue: boolean; status: string };
  onDelete?: (worker: WorkerApiType) => void;
  onClick?: (e) => void;
  setWorkerPopoverShown?: (id: number | null) => void;
  workerPopoverShown?: number | null;
  setIsDriving?: (worker: WorkerApiType) => void;
  setDriver?: (worker: WorkerApiType, driverId: number | null) => void;
}

export const IconMap = {
  declined: CrossIcon,
  hasDrivingLicense: FaRegAddressCard,
  hasTransport: AiOutlineCar,
  ok: TickIcon,
  cancel: BanCircleIcon,
  isDriving: DriveTimeIcon,
  hasDriver: KnownVehicleIcon,
};

export default function WorkerItem({
  worker,
  onClick = undefined,
  setDriver = undefined,
  setIsDriving = undefined,
  onDelete = undefined,
  ...rest
}: WorkerItemProps) {
  const hasWorkerShiftRequest = !!worker.workerShiftRequest;
  const navigate = useNavigate();

  const classes = classNames(
    onClick ? "cursor-pointer" : "",
    worker.reportOverdue ? "border-1 border-red-500" : "",
    worker.workerShiftRequest?.status === "pending"
      ? "border-1 border-red-500"
      : "",
    worker.workerShiftRequest?.status === "accepted"
      ? "border-1 border-green-500"
      : "",
    worker.workerShiftRequest?.status === "declined" ? "border-1" : "",
    !hasWorkerShiftRequest ? "pr-2" : "",
    "self-start pl-2 bg-gray-50 hover:bg-grofalex-forest-light btn-contrast hover:!text-white !text-left border !text-gray-700",
  );

  const workerShiftClasses = classNames(
    worker.workerShiftRequest?.type === "swap" ? " bg-red-700 pr-2" : "",
    worker.workerShiftRequest?.type === "cancel" ? " bg-red-700 pr-2" : "",
    worker.workerShiftRequest?.status === "accepted"
      ? " bg-green-700 pr-2"
      : "",
    worker.workerShiftRequest?.status === "declined"
      ? " bg-green-700 pr-2"
      : "",
    "flex items-center w-full ml-2 pl-2",
  );

  function navToReport() {
    navigate(
      {
        pathname: __r(REPORTS_DETAIL_PAGE, {
          shiftReportId: worker.shiftReportId!,
        }),
      },
      {
        state: {
          backPath: window.location.pathname + window.location.search,
        },
      },
    );
  }

  return (
    <Pane className="flex">
      <Pane onClick={onClick} className={classes} {...rest}>
        <Pane className="flex">
          <Pane className="relative flex items-center gap-1 whitespace-nowrap py-2">
            <Pane>
              <Pane className="absolute right-0 top-0 flex gap-1">
                {worker.hasDrivingLicense &&
                  createElement(IconMap["hasDrivingLicense"], {
                    size: 12,
                    color: "green",
                  })}
                {worker.hasDrivingLicense &&
                  createElement(IconMap["hasTransport"], {
                    size: 12,
                    color: "#656565",
                  })}
              </Pane>
            </Pane>

            <Pane className="border-r-2 px-1">
              <Pane>
                {ShiftStatusIconMap[worker.status] &&
                  createElement(ShiftStatusIconMap[worker.status], {
                    color: ShiftStatusColorMap[worker.status],
                  })}
              </Pane>
            </Pane>

            <Avatar
              className="mr-1 hover:opacity-90"
              src={worker.user?.profilePictureThumbnail ?? ""}
              name={""}
              size={25}
            />
            <Pane>
              <span className="overflow-ellipsis pr-1 !text-xs !font-bold capitalize">
                {`${worker.user?.firstName}`}
              </span>
              <span className="overflow-ellipsis !text-xs !font-bold capitalize">
                {`${worker.user?.lastName}`}
              </span>
            </Pane>
          </Pane>

          {worker.workerShiftRequest && (
            <Pane className={workerShiftClasses}>
              {worker.workerShiftRequest.status === "pending" &&
                createElement(IconMap[worker.workerShiftRequest.type], {
                  size: 14,
                  color: "white",
                })}
              {worker.workerShiftRequest.status === "accepted" &&
                createElement(IconMap.ok, { size: 14, color: "white" })}
              {worker.workerShiftRequest.status === "declined" &&
                createElement(IconMap.declined, { size: 14, color: "white" })}
            </Pane>
          )}

          {worker.shiftReportId && (
            <IconButton
              icon={<PaperclipIcon />}
              onClick={(e) => {
                e.stopPropagation();
                navToReport();
              }}
              className="btn-dark-contrast my-auto ml-1"
              appearance="minimal"
              type="button"
            />
          )}

          {worker.reportOverdue && (
            <Pane className="relative">
              <IconButton
                disabled
                icon={<PaperclipIcon color={"red"} />}
                className="btn-dark-contrast my-auto ml-1 mt-1"
                appearance="minimal"
                type="button"
              />
              <SmallCrossIcon
                color="red"
                className="absolute right-0 bottom-0"
                size={15}
              />
            </Pane>
          )}
        </Pane>
      </Pane>

      {onDelete || setIsDriving ? (
        //@ts-ignore
        <Pane
          className="!flex min-h-[2rem] items-center !justify-center gap-1 border border-gray-300 px-1 !lowercase"
          color="white"
        >
          {setIsDriving && (
            <DrivingIconWithPopover worker={worker} setDriver={setDriver} />
          )}

          {onDelete && (
            <IconButton
              onClick={() => onDelete(worker)}
              className="my-auto"
              appearance="minimal"
              intent="danger"
              icon={TrashIcon}
              type="button"
            />
          )}
        </Pane>
      ) : (
        <Pane
          className="!flex min-h-[2rem] items-center !justify-center gap-1 border border-gray-300 px-1 !lowercase"
          color="white"
        >
          <StaticDrivingIconWithPopover onClick={onClick} worker={worker} />
          {/*<IconButton*/}
          {/*    icon={carpoolStatusIcon}*/}
          {/*    */}
          {/*    type="button"*/}
          {/*    appearance="minimal"*/}
          {/*/>*/}
        </Pane>
      )}
    </Pane>
  );
}

function StaticDrivingIconWithPopover({ worker, onClick }) {
  const [isShown, setIsShown] = useState(false);

  const { carpool } = worker;

  const carpoolStatusIcon = useMemo(() => {
    if (worker.isDriving) {
      return <KnownVehicleIcon color="green" />;
    }

    if (worker.driverId || worker.carpoolId) {
      return <GrGroup color="green" />;
    } else {
      return <DriveTimeIcon color="red" />;
    }
  }, [worker.driverId, worker.isDriving]);

  return (
    <Popover
      bringFocusInside
      key={worker.id}
      shouldCloseOnExternalClick={true}
      isShown={isShown}
      shouldCloseOnEscapePress={true}
      minHeight={100}
      minWidth={200}
      trigger="hover"
      position={Position.TOP}
      content={
        <Pane>
          {worker.carpool && (
            <Pane
              className="btn-contrast flex cursor-pointer items-center gap-2 border bg-gray-50 pl-2 !text-left !text-gray-700 hover:bg-grofalex-forest-light hover:!text-white"
              key={carpool.driver.id}
            >
              <span>
                <span className="overflow-ellipsis pr-1 !text-xs !font-bold capitalize">
                  {`${carpool.driver.user?.firstName}`}
                </span>
                <span className="overflow-ellipsis !text-xs !font-bold capitalize">
                  {`${carpool.driver.user?.lastName}`}
                </span>
              </span>
            </Pane>
          )}
        </Pane>
      }
    >
      <IconButton
        // isActive={worker.isDriving}
        onClick={(e) => {
          e.stopPropagation();
          setIsShown(true);
          onClick(e);
        }}
        icon={carpoolStatusIcon}
        type="button"
      />
    </Popover>
  );
}

function DrivingIconWithPopover({ worker, setDriver }) {
  const [isShown, setIsShown] = useState(false);

  const carpoolStatusIcon = useMemo(() => {
    if (worker.isDriving) {
      return <KnownVehicleIcon color={"green"} />;
    }

    if (worker.driverId || worker.carpoolId) {
      return <GrGroup color="green" />;
    } else {
      return <DriveTimeIcon color="red" />;
    }
  }, [worker.driverId, worker.isDriving]);

  return (
    <Pane>
      <ManageCarpoolingDialog
        title={"Manage carpooling"}
        width={600}
        isShown={isShown}
        worker={worker}
        onCloseComplete={() => {
          setIsShown(false);
        }}
      />
      <IconButton
        // isActive={worker.isDriving}
        onClick={(e) => {
          e.stopPropagation();
          setIsShown(true);
        }}
        icon={carpoolStatusIcon}
        type="button"
        appearance="minimal"
      />
    </Pane>
  );
}
