import { useApi } from "../context/AxiosContext";
import { useState } from "react";
import { Period } from "../types/apiTypes";
import { useLoadResource } from "../lib/request-hooks";
import { HookOptions } from "../types/appTypes";
import { useWindowSearchParams } from "./useWindowSearchParams";

export const usePeriods = function (options: HookOptions = {}) {
  const searchParams = useWindowSearchParams();
  const { apiInstance } = useApi();

  const { populate } = options;

  if (populate) {
    searchParams.set("populate", populate.join(","));
  }

  const [periods, setPeriods] = useState<Period[]>([]);
  const request = () => apiInstance!.adminPeriods.findMany(searchParams);

  const setData = (periods) => {
    setPeriods(periods);
  };

  return {
    ...useLoadResource(request, setData, true),
    periods,
  };
};
