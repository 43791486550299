import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { Id, ReportTemplate } from "../types/apiTypes";
import { PostData } from "../types/appTypes";

export default class WorkerShiftReport extends BaseApi {
  findOneForShift(id: Id): Promise<WorkerShiftReport> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/worker/shifts/${id}/report`,
    });
  }

  updateShiftReport(id: Id, data: PostData): Promise<WorkerShiftReport> {
    return doRequest(this._axiosInstance!, {
      method: "put",
      url: `/worker/reports/${id}`,
      data: data,
    });
  }

  createForWorkerShift(id: Id, data: PostData): Promise<WorkerShiftReport> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/worker/shifts/${id}/report`,
      data: data,
    });
  }

  getTemplate(): Promise<ReportTemplate> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/report/template`,
    });
  }
}
