import markerGeneral from "./marker_general.png"
import markerBat from "./marker_bat.png"
import markerPerson from "./marker_person.png"
import markerSparrow from "./marker_sparrow.png"
import markerSwallow from "./marker_swallow.png"

const IMG_MARKER_GENERAL = "marker_general"
const IMG_MARKER_BAT = "marker_bat"
const IMG_MARKER_PERSON = "marker_person"
const IMG_MARKER_SPARROW = "marker_sparrow"
const IMG_MARKER_SWALLOW = "marker_swallow"

export enum GaiaMapSourceType {
    Objects = "objects",
    Areas = "areas",
    Observations = "observations",
    Heatmap = "heatmap",
}

// map data
export const GaiaMapStyles = {
    // Objects
    [GaiaMapSourceType.Objects]: (map: mapboxgl.Map, onComplete: () => void) => {
        map.addSource(`gaia-${GaiaMapSourceType.Objects}`, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            }
        })

        // Markers
        map.addLayer({
            id: "gaia-object-markers",
            source: `gaia-${GaiaMapSourceType.Objects}`,
            type: "circle",
            slot: "top",
            paint: {
                "circle-radius": ["match", ["get", "state"], "active", 7, 6],
                "circle-color": [
                    "match",
                    ["get", "state"],
                    "active",
                    "#0080ff",
                    "#92e159",
                ],
                "circle-stroke-color": "#2d2d2d",
                "circle-stroke-width": 2,
            },
            filter: ["==", "$type", "Point"],
        })

        onComplete()
    },

    // Areas
    [GaiaMapSourceType.Areas]: (map: mapboxgl.Map, onComplete: () => void) => {
        map.addSource(`gaia-${GaiaMapSourceType.Areas}`, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            }
        })

        // Outline
        map.addLayer({
            id: "gaia-area-outlines",
            source: `gaia-${GaiaMapSourceType.Areas}`,
            type: "line",
            slot: "top",
            paint: {
                "line-color": "#3764fa",
                "line-width": 3.0,
            },
            filter: ["==", "$type", "Polygon"],
        })
        // Names
        map.addLayer({
            id: "gaia-area-labels",
            source: `gaia-${GaiaMapSourceType.Areas}`,
            type: "symbol",
            slot: "top",
            minzoom: 15,
            layout: {
                "text-field": ["get", "name"],
            }
        })

        onComplete()
    },

    // Observations
    [GaiaMapSourceType.Observations]: (map: mapboxgl.Map, onComplete: () => void) => {
        map.addSource(`gaia-${GaiaMapSourceType.Observations}`, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            }
        })

        // Points
        map.addLayer({
            id: "gaia-observation-points",
            source: `gaia-${GaiaMapSourceType.Observations}`,
            type: "symbol",
            slot: "top",
            layout: {
                "icon-image": [
                    "case",
                    ["==", ["get", "species"], "bat"],
                    IMG_MARKER_BAT,
                    ["==", ["get", "species"], "sparrow"],
                    IMG_MARKER_SPARROW,
                    ["==", ["get", "species"], "swallow"],
                    IMG_MARKER_SWALLOW,
                    IMG_MARKER_GENERAL,
                ],
                "icon-offset": [0.0, -10.0],
                "icon-ignore-placement": true,
                "icon-allow-overlap": true,
            },
            filter: ["==", "$type", "Point"],
        })

        // Lines
        map.addLayer({
            id: "gaia-observation-lines",
            source: `gaia-${GaiaMapSourceType.Observations}`,
            type: "line",
            slot: "top",
            paint: {
                "line-color": "#E58C07",
                "line-width": 6.0,
                "line-opacity": 0.7
            },
            filter: ["==", "$type", "LineString"],
        })

        // Areas
        map.addLayer({
            id: "gaia-observation-areas",
            source: `gaia-${GaiaMapSourceType.Observations}`,
            type: "fill",
            slot: "top",
            paint: {
                "fill-color": "#E58C07",
                "fill-opacity": 0.4
            },
            filter: ["==", "$type", "Polygon"],
        })

        onComplete()
    },

    [GaiaMapSourceType.Heatmap]: (map: mapboxgl.Map, onComplete: () => void) => {
        onComplete()
    }
}

export function loadGaiaImages(map: mapboxgl.Map, onComplete: () => void) {
    const images = [
        { url: markerGeneral, id: IMG_MARKER_GENERAL },
        { url: markerBat, id: IMG_MARKER_BAT },
        { url: markerPerson, id: IMG_MARKER_PERSON },
        { url: markerSparrow, id: IMG_MARKER_SPARROW },
        { url: markerSwallow, id: IMG_MARKER_SWALLOW },
    ]
    Promise.all(
        images.map(img => new Promise((resolve, reject) => {
            if (map.hasImage(img.id)) {
                resolve(1)
                return
            }
            map.loadImage(img.url, function (error, res) {
                if (error) throw error;
                if (res != null && !map.hasImage(img.id)) {
                    map.addImage(img.id, res)
                }
                resolve(1);
            })
        }))
    ).then(() => { onComplete() })
}
