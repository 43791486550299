import { useCallback, useEffect, useMemo, useState } from "react";
import { Pane, Paragraph, TextInput } from "evergreen-ui";
import {
  AddressAutofill,
  AddressMinimap,
  config,
  useConfirmAddress,
} from "@mapbox/search-js-react";

export const MapBoxAddressInputField = function ({
  address,
  setAddress,
  clearable = false,
  buttonOptions = {},
  ...rest
}) {
  const {
    showMap = true,
    minMapHeight = 400,
    textBoxWidth = null,
    satelliteToggle = false,
    adjustPin = false,
    showAddress = true,
    showLatLng = false,
  } = rest;

  const defaultFeature = useMemo(() => {
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          address?.lng ? address.lng : 4.897574,
          address?.lat ? address.lat : 52.366015,
        ],
      },
      properties: {
        full_address: address?.formattedAddress,
      },
    };
  }, [address]);

  const [feature, setFeature] = useState(defaultFeature);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

    if (accessToken) {
      setToken(accessToken);
      config.accessToken = accessToken;
    }
  }, []);

  const { formRef, showConfirm } = useConfirmAddress({
    minimap: true,
    skipConfirmModal: (feature) => {
      return ["exact", "high"].includes(
        feature.properties.match_code.confidence,
      );
    },
  });

  const handleRetrieve = useCallback(
    (res) => {
      const feature = res.features[0];

      setFeature(feature);

      const { properties, geometry } = feature;

      setAddress({
        city: properties.place,
        country: properties.country,
        countryCode: properties.country_code,
        formattedAddress: properties.full_address,
        mapBoxId: properties.mapbox_id,
        lng: geometry.coordinates?.[0],
        lat: geometry.coordinates?.[1],
        number: geometry.address_number,
        postalCode: properties.postcode,
        province: properties.region,
        street: properties.street,
        language: properties.language,
      });
    },
    [setFeature],
  );

  function handleSaveMarkerLocation(coordinate) {
    if (address) {
      setAddress({
        ...address,
        lng: coordinate[0],
        lat: coordinate[1],
        adjusted: true,
      });
    }
  }

  return (
    <Pane className="relative">
      <form ref={formRef}>
        {showAddress && address && (
          <Pane>
            <Paragraph size={500}>{address.formattedAddress}</Paragraph>
            {address.postalCode && (
              <Paragraph size={500}>{address.postalCode}</Paragraph>
            )}
            {showLatLng && address?.lat && (
              <Paragraph className="!text-xs" size={200}>
                {address.lat}, {address.lng}
              </Paragraph>
            )}
          </Pane>
        )}
        {showMap && token ? (
          <Pane
            className="relative h-full w-full shadow"
            minHeight={minMapHeight}
            id="minimap-container"
          >
            <AddressMinimap
              canAdjustMarker={adjustPin}
              satelliteToggle={satelliteToggle}
              isScrollZoomEnabled={true}
              //@ts-ignore
              feature={feature}
              show={!!token}
              onSaveMarkerLocation={handleSaveMarkerLocation}
              footer={false}
              accessToken={token}
            />

            <Pane className="z-1 absolute top-4 left-4">
              <AddressAutofill
                options={{ language: "nl", country: "NL", streets: false, }}
                accessToken={token}
                onRetrieve={handleRetrieve}
              >
                <TextInput
                  id="mapbox-autofill"
                  placeholder="Start met typen"
                  minHeight={40}
                  maxWidth={textBoxWidth}
                />
              </AddressAutofill>
            </Pane>
          </Pane>
        ) : (
          <Pane>
            {token && (
              <AddressAutofill
                options={{ language: "nl", country: "NL" }}
                accessToken={token}
                onRetrieve={handleRetrieve}
              >
                <TextInput
                  id="mapbox-autofill"
                  placeholder="Start met typen"
                  minHeight={40}
                  maxWidth={textBoxWidth}
                />
              </AddressAutofill>
            )}
          </Pane>
        )}
      </form>
    </Pane>
  );
};

export default MapBoxAddressInputField;
