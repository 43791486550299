import { Button, Combobox, TextInput } from "evergreen-ui";
import {
  TableComponentColumns,
  TableFormatterType,
} from "../../../../types/apiTypes";

export const GenerateTableComponent = ({
  columns,
  handleColumnsChange,
}: {
  columns: TableComponentColumns[];
  handleColumnsChange: (columns: TableComponentColumns[]) => void;
}) => {
  const columnTypes: TableFormatterType[] = [
    "string",
    "number",
    "date",
    "dateTime",
    "link",
    "bulletedList",
  ];

  function handleColumnNameChange(value, index: number) {
    const updatedColumns = [...columns];
    updatedColumns[index].key = value;
    updatedColumns[index].label = value;

    handleColumnsChange(updatedColumns);
  }

  function handleColumnTypeChange(value, index: number) {
    const updatedColumns = [...columns];
    updatedColumns[index].formatter = value;

    handleColumnsChange(updatedColumns);
  }

  function addNewColumn(index) {
    const updatedColumns = [...columns];
    updatedColumns.splice(index + 1, 0, generateNewColumn());
    handleColumnsChange(updatedColumns);
  }

  return (
    <div className="generate-table">
      <div>Configure the table</div>
      {columns.map((column, index) => (
        <div key={index} className="mt-4 flex flex-row gap-4">
          <TextInput
            placeholder="Column name"
            value={column.key}
            onChange={(e) => handleColumnNameChange(e.target.value, index)}
          ></TextInput>
          <Combobox
            className="column-type"
            selectedItem={column.formatter}
            width="100%"
            items={columnTypes}
            onChange={(e) => handleColumnTypeChange(e, index)}
            placeholder="Column type"
          />
          <Button onClick={() => addNewColumn(index)}>Add</Button>
        </div>
      ))}
    </div>
  );
};

export function generateNewColumn(): TableComponentColumns {
  return {
    key: "",
    label: "",
    formatter: "string",
  };
}
