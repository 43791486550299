import { Dialog, Text, Pane, TextareaField, toaster } from "evergreen-ui";
import { useParams } from "react-router-dom";
import { useApi } from "src/context/AxiosContext";
import { useState } from "react";
import { useDoRequest } from "src/lib/request-hooks";
import { useTranslation } from "react-i18next";
import { ShiftReport } from "../../../types/apiTypes";

function useRejectReportForm({ shiftReport }) {
  const { shiftReportId } = useParams();
  const { apiInstance } = useApi();

  const [notes, setNotes] = useState<string>(shiftReport.notes);

  const {
    handle,
    setIsLoading: setIsSubmitting,
    isLoading: isSubmitting,
    validationErrors,
    errorMessage: submitErrorMessage,
  } = useDoRequest();

  const doSubmit = async () => {
    const data = {
      notes: notes ?? null,
    };

    const request = apiInstance!.adminShiftReports.reject(shiftReportId!, data);
    await handle(request);

    toaster.success("Afgekeurd!");
  };

  return {
    submitContext: {
      doSubmit,
      setIsSubmitting,
      isSubmitting,
      submitErrorMessage,
      validationErrors,
    },
    notes,
    setNotes,
    doSubmit,
  };
}

export default function RejectReportDialog({
  shiftReport,
  isShown,
  close,
  afterSubmit,
}: {
  shiftReport: ShiftReport;
  isShown: boolean;
  close: () => void;
  afterSubmit: () => void;
}) {
  const { t } = useTranslation();

  const { submitContext, notes, setNotes, doSubmit } = useRejectReportForm({
    shiftReport,
  });

  async function onConfirm() {
    await doSubmit();

    setNotes("");
    afterSubmit();
  }

  return (
    <Dialog
      confirmLabel="Afkeuren"
      intent="danger"
      isShown={isShown}
      onCloseComplete={close}
      onConfirm={onConfirm}
      shouldCloseOnOverlayClick={false}
      title="Rapport afkeuren"
    >
      <Pane>
        <Text className="py-4">Geef een reden op van aftkeuring.</Text>
        <TextareaField
          required
          name="notes"
          label="Notities"
          placeholder="Ik moet dit rapport afkeuren omdat..."
          isInvalid={!!submitContext.validationErrors?.notes}
          validationMessage={submitContext.validationErrors?.notes?.join(", ")}
          value={notes || ""}
          onChange={(e) => setNotes(e.target.value)}
          marginBottom={0}
          inputHeight={40}
          className="!text-base"
        />
      </Pane>
    </Dialog>
  );
}
