import { parseISO } from "date-fns";
import { Pane } from "evergreen-ui";
import ProjectFilter from "../../../components/shared/filters/ProjectFilter";
import DateRangeFilter from "./DateRangeFilter";

export default function AreaResultsFilters({ filterLogic }) {
  const { handleInput, filterMap } = filterLogic;

  return (
    <Pane className="flex flex-wrap gap-2 lg:flex-nowrap">
      <ProjectFilter filterMap={filterMap} handleInput={handleInput} />

      {filterMap.fromDate?.value && filterMap.toDate?.value && (
        <DateRangeFilter
          filterMap={filterMap}
          startAt={
            filterMap.fromDate?.value
              ? parseISO(filterMap.fromDate.value)
              : null
          }
          endAt={
            filterMap.toDate?.value ? parseISO(filterMap.toDate.value) : null
          }
          fromDateFilter={filterMap.fromDate}
          toDateFilter={filterMap.toDate}
          handleInput={handleInput}
        />
      )}
    </Pane>
  );
}
