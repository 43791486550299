import { FileUploader, toaster } from "evergreen-ui";
import { useEffect, useState } from "react";

export const ImageEditor = ({
  availableImageUrl,
  description,
  acceptedFileFormats,
  handleFileUpload,
}: {
  availableImageUrl?: string;
  description: string;
  acceptedFileFormats: string[];
  handleFileUpload: (file: File) => void;
}) => {
  const [uploadedFileUrl, setUploadedFileUrl] = useState(availableImageUrl);

  useEffect(() => {
    setUploadedFileUrl(availableImageUrl);
  }, [availableImageUrl]);

  function handleOnChange(files: File[]) {
    const [file] = files;
    const fileFormat = file.type.split("/")[1];
    if (!acceptedFileFormats.includes(fileFormat)) {
      return toaster.danger("Unsupported file type");
    }
    setUploadedFileUrl(URL.createObjectURL(file));
    handleFileUpload(file);
  }
  return (
    <>
      <div className="mb-4">
        {uploadedFileUrl ? (
          <img width={"25%"} src={uploadedFileUrl} alt="Alt media" />
        ) : (
          <h6>No photo avaiable to preview</h6>
        )}
      </div>
      <FileUploader
        label="Upload cover photo"
        description={description}
        maxSizeInBytes={10 * 1024 * 2}
        maxFiles={1}
        onChange={handleOnChange}
      />
    </>
  );
};
