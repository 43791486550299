import {
  Button,
  Dialog,
  DialogProps,
  FilePicker,
  Pane,
  UploadIcon,
} from "evergreen-ui";
import Block from "@/components/common/Block";
import { useState } from "react";
import Papa from "papaparse";

function parseCsv<CsvRow>(csvText: string) {
  // Parse the CSV text
  const parsed = Papa.parse<CsvRow>(csvText, { header: true });

  // Return the data
  return parsed.data;
}

export default function CsvImportButton({
  doImportRow,
  title,
  setRowsToImport,
}) {
  const [showCsvUploadDialog, setShowCsvUploadDialog] = useState(false);

  async function startImport() {
    setShowCsvUploadDialog(false);
  }

  return (
    <Block>
      <Button
        onClick={() => setShowCsvUploadDialog(true)}
        iconBefore={UploadIcon}
      >
        {title}
      </Button>

      <CsvUploadDialog
        title={title}
        isShown={showCsvUploadDialog}
        onCloseComplete={() => setShowCsvUploadDialog(false)}
        onConfirm={startImport}
        doImportRow={doImportRow}
        setRowsToImport={setRowsToImport}
      />
    </Block>
  );
}

interface UploadDialogProps extends DialogProps {
  doImportRow: (parsedCsv: unknown, index: number) => Promise<any>;
  renderItems?: (rows: any[], statusMap, processRows) => JSX.Element;
  onCloseComplete: () => void;
  setRowsToImport: (rows: any[]) => void;
  onConfirm: () => void;
}

function CsvUploadDialog({
  renderItems,
  onCloseComplete,
  setRowsToImport,
  onConfirm,
  ...rest
}: UploadDialogProps) {
  const { title = null } = rest;

  async function doParseCsv(csvAsText: string) {
    const parsed = parseCsv(csvAsText);

    setRowsToImport(parsed);
  }

  function handleCsvFile(files: FileList) {
    const file = files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const text = e.target.result;

        if (typeof text !== "string") {
          alert("File not readable");

          return;
        }

        return doParseCsv(text);
      };

      reader.readAsText(file);
    }
  }

  return (
    <Dialog
      width={800}
      title={title}
      hasCancel={false}
      confirmLabel={"Annuleren"}
      onCloseComplete={onCloseComplete}
      {...rest}
    >
      <Pane>
        <FilePicker
          onChange={handleCsvFile}
          placeholder={"Selecteer een CSV bestand"}
        />
      </Pane>
    </Dialog>
  );
}
