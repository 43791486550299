type RouteParams = {
  [key: string]: string | number | undefined;
};

export const ADMIN_PROJECTS_PAGE = "/projects";
export const ADMIN_PROJECT_ADD_PAGE = "/projects/add";
export const ADMIN_PROJECT_SETTINGS_PAGE = "/projects/:projectId/settings";
export const ADMIN_PROJECT_EXPORT_PAGE = "/projects/:projectId/export";
export const ADMIN_ACTIVITY_TYPE_ADD_PAGE =
  "/projects/:projectId/activity-types/add";
export const ADMIN_ACTIVITY_TYPES_PAGE = "/projects/:projectId/activity-types";

export const ADMIN_ACTIVITY_TYPE_EDIT_PAGE =
  "/projects/:projectId/activity-types/:activityTypeId";

export const ADMIN_AREA_ADD_PAGE = "/projects/:projectId/areas";
export const ADMIN_AREA_EDIT_PAGE = "/projects/:projectId/areas/:areaId";

export const ADMIN_WORKER_AVAILABILITY_PAGE =
  "/admin/worker/:workerId/periods/:periodId/availability";
export const ADMIN_WORKER_PERIODS_PAGE = "/admin/worker/:workerId/periods";
export const ADMIN_WORKER_SHIFT_HISTORY_PAGE = "/admin/worker/:workerId/shifts";

export const ADMIN_CLIENT_ACTIVITY_PAGE =
  "/projects/:projectId/client-activities";
export const ADMIN_CLIENT_ACTIVITY_ADD_PAGE =
  "/projects/:projectId/client-activities/add";
export const ADMIN_CLIENT_ACTIVITY_EDIT_PAGE =
  "/projects/:projectId/client-activities/:activityId";

//Project Reports
export const ADMIN_PROJECT_REPORTS = "/projects/:projectId/reports";
export const ADMIN_PROJECT_REPORT = "/projects/:projectId/report/:reportId";
export const ADMIN_PROJECT_REPORT_ADD = "/projects/:projectId/report/add";
export const ADMIN_PROJECT_REPORT_EDIT =
  "/projects/:projectId/report/:reportId/edit";

export const ADMIN_OBJECTS_PAGE = "/projects/:projectId/objects";
export const ADMIN_OBJECT_ADD_PAGE = "/projects/:projectId/objects";
export const ADMIN_OBJECT_EDIT_PAGE = "/projects/:projectId/objects/:objectId";

export const PLANNER_PAGE = "/planner";
export const PLANNER_AVAILABILITY_ADD_PAGE = "/planner/availability/add";
export const PLANNER_AVAILABILITY_LAYOUT = "/planner/period/:periodId";

export const WORKER_PERIODS = "/periods";
export const WORKER_PERIODS_AVAILABILITY = "/periods/:periodId/availability";

export const WORKER_SHIFTS_LAYOUT = "/your-shifts";
export const WORKER_SHIFTS_GO_TO_APP_PAGE = "/your-shifts/app";
export const WORKER_SHIFTS_PLANNED_PAGE = "/your-shifts/planned";
export const WORKER_SHIFTS_ARCHIVE_PAGE = "/your-shifts/archive";
export const WORKER_SHIFTS_HISTORY_PAGE = "/your-shifts/history";

export const WORKER_SHIFTS_DETAIL_LAYOUT = "/your-shifts/:shiftId";
export const WORKER_SHIFTS_DETAIL_PAGE = "/your-shifts/:shiftId/details";
export const WORKER_SHIFTS_OBSERVATIONS_PAGE =
  "/your-shifts/:shiftId/observations";
export const WORKER_SHIFTS_REPORT_PAGE = "/your-shifts/:shiftId/report";
export const WORKER_SHIFTS_CHANGES_PAGE = "/your-shifts/:shiftId/changes";

export const PLANNER_AVAILABILITY_REQUEST_PLANNING_PAGE =
  "/planner/period/:periodId/planning";
export const PLANNER_AVAILABILITY_REQUEST_PROGRESS_PAGE =
  "/planner/period/:periodId/progress";
export const PLANNER_AVAILABILITY_REQUEST_EDIT_PAGE =
  "/planner/period/:periodId/edit";

export const PLANNER = "/planner";
export const PLANNER_PERIOD_PLANNING_PAGE =
  "/planner/period/:periodId/planning";
export const PLANNER_PERIOD_PLANNING_ROUNDS_PAGE =
  "/planner/period/:periodId/planning/rounds";
export const PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_ADD_OVERLAY =
  ":areaActivityTypeId/shift/add";
export const PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_OVERLAY =
  ":areaActivityTypeId/shift/:shiftId";
export const PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_OVERLAY_FULL_URL =
  "/planner/period/:periodId/planning/:areaActivityTypeId/shift/:shiftId";

export const PLANNER_PERIOD_PLANNING_WORKER_SHIFT_REQUEST_OVERLAY =
  "/planner/period/:periodId/planning/rounds/worker-request/:workerShiftRequestId";

export const REPORTS_LAYOUT = "/reports";
export const REPORTS_NEW_LIST_PAGE = "/reports/incoming";
export const REPORTS_DETAIL_PAGE = "/reports/incoming/:shiftReportId/details";
export const REPORTS_EXPORTS_LIST_PAGE = "/reports/exports";
export const REPORTS_RESULTS_PAGE = "/reports/results";

export const ADMIN_SUBMITTED_SHIFTS_LAYOUT = "/admin/submitted-shifts";
export const ADMIN_SUBMITTED_SHIFTS_LIST_PAGE = "/admin/submitted-shifts/list";
export const ADMIN_SUBMITTED_SHIFTS_DETAIL_PAGE =
  "/admin/submitted-shifts/workers/:workerId/shift/:shiftId";

export const ADMIN_USERS_PAGE = "/admin/users";
export const ADMIN_USERS_EDIT_PAGE = "/admin/users/:userId";

export const ADMIN_BOOKKEEPING_LAYOUT = "/admin/bookkeeping";
export const ADMIN_BOOKKEEPING_EXPORT_LIST_PAGE = "/admin/bookkeeping/export";
export const ADMIN_BOOKKEEPING_EXPORT_PAGE =
  "/admin/bookkeeping/export/:paymentId";

export const SHARE_EXPORT_PAGE = "/share/export/:exportId";

/**
 * Get the routePath, if params are given, the static route will be changed into the dynamic route path with the
 * variables filled in.
 *
 * eg., /admin/users/:userId/worker/:workerId/requests/:periodId/availability, {userId: 1, workerId: 2}
 * /admin/users/1/worker/2/requests/:periodId/availability, {userId: 1, workerId: 2}
 *
 * @param routePath
 * @param params
 */
export function __r(routePath, params: RouteParams = {}) {
  for (const [field, value] of Object.entries(params)) {
    routePath = routePath.replace(":" + field, value);
  }

  return routePath;
}
