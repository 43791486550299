import { IntroductionComponent } from "@/types/apiTypes";

export const IntroductionPreviewComponent = ({
  component,
}: {
  component: IntroductionComponent;
}) => {
  return (
    <h1>
      <strong>This module contains introduction content</strong>
    </h1>
  );
};
