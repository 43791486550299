import {
  Alert,
  Button,
  Card,
  FormField,
  Heading,
  majorScale,
  Pane,
  Spinner,
  TextareaField,
  TextInputField,
  toaster,
  TrashIcon,
} from "evergreen-ui";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useApi } from "../../../context/AxiosContext";
import { useDoRequest, useLoadResource } from "../../../lib/request-hooks";
import Block from "../../../components/common/Block";
import Form from "../../../components/common/Form";
import Actions from "../../../components/common/Actions";
import { ClientActivity } from "../../../types/apiTypes";
import { useApp } from "../../../context/AppContext";

function useClientActivityFormFields() {
  const { apiInstance } = useApi();
  const { activityId, projectId } = useParams();

  const [name, setName] = useState<string>("");
  const [notes, setNotes] = useState<string>("");

  const {
    handle,
    errorMessage: submitErrorMessage,
    isLoading: isSubmitting,
    setValidationErrors,
    validationErrors,
  } = useDoRequest();

  const doSubmit = async () => {
    let request: Promise<ClientActivity>;

    const data = {
      name,
      notes,
    };

    if (activityId) {
      request = apiInstance!.adminClientActivity.update(activityId, data);
    } else {
      request = apiInstance!.adminClientActivity.createForProject(
        projectId!,
        data,
      );
    }

    await handle(request);

    toaster.success("Saved!");
  };

  const find = () => apiInstance!.adminClientActivity.findOne(activityId!);
  const remove = () => apiInstance!.adminClientActivity.remove(activityId!);

  const setData = (activityType: ClientActivity) => {
    setName(activityType.name);
    setNotes(activityType.notes);
  };

  function doRemove() {
    if (window.confirm("Are you sure you want to delete this activity?")) {
      return remove();
    }
  }

  function resetForm() {
    setName("");
    setNotes("");
  }

  return {
    ...useLoadResource(find, setData, !!activityId),
    resetForm,
    name,
    setName,
    notes,
    setNotes,
    isSubmitting,
    doRemove,
    submitContext: {
      doSubmit,
      isSubmitting,
      submitErrorMessage,
      validationErrors,
      setValidationErrors,
    },
  };
}

export default function ClientActivityEditPage() {
  const { activityId, projectId } = useParams();
  const navigate = useNavigate();
  const { contentRef } = useApp();

  const {
    name,
    setName,
    notes,
    setNotes,
    isLoading,
    submitContext,
    resetForm,
    refreshCount,
    setRefreshCount,
    doRemove,
  } = useClientActivityFormFields();

  async function submit() {
    await submitContext.doSubmit();

    navigate(`/projects/${projectId}/client-activities`);
  }

  async function doRemoveCb() {
    await doRemove();

    navigate(`/projects/${projectId}/client-activities`);
  }

  async function submitAndNew() {
    try {
      await submitContext.doSubmit();

      resetForm();
      setRefreshCount(refreshCount + 1);

      // if an area id was present, we need to go the new url.
      if (activityId) {
        navigate(`/projects/${projectId}/client-activities/add`);
      }
    } finally {
      if (contentRef.current) {
        contentRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  }

  return (
    <Block>
      <Block className="flex w-full gap-2">
        <Heading marginBottom={20}>
          {!activityId ? "Maak een nieuwe " : "Edit "}
          Activiteit
        </Heading>

        {isLoading && (
          <Block className="flex justify-end">
            <Spinner size={20} />
          </Block>
        )}
      </Block>

      <Form>
        <>
          <FormField>
            <TextInputField
              required
              isInvalid={!!submitContext.validationErrors?.name}
              validationMessage={submitContext.validationErrors?.name?.join(
                ", ",
              )}
              name="name"
              label="Name"
              placeholder="e.g., verbouwing ... "
              value={name}
              onChange={(e) => setName(e.target.value)}
              marginBottom={0}
              inputHeight={40}
              maxWidth={500}
            />
          </FormField>
          <TextareaField
            name="notes"
            label="Notities"
            placeholder="..."
            isInvalid={!!submitContext.validationErrors?.notes}
            validationMessage={submitContext.validationErrors?.notes?.join(
              ", ",
            )}
            value={notes || ""}
            onChange={(e) => setNotes(e.target.value)}
            marginBottom={0}
            inputHeight={40}
            className="!text-base"
          />
          {submitContext.submitErrorMessage && (
            <Pane>
              <Alert
                marginTop={10}
                intent="danger"
                title={submitContext.submitErrorMessage}
              />
            </Pane>
          )}

          <Actions marginTop={10} marginBottom={10}>
            <Actions.Button
              onClick={() =>
                navigate(`/projects/${projectId}/client-activities`)
              }
              disabled={!!submitContext.isSubmitting}
              height={majorScale(5)}
            >
              Terug
            </Actions.Button>

            <Actions.Button
              onClick={submit}
              isLoading={!!submitContext.isSubmitting}
              appearance="primary"
              intent="success"
              height={majorScale(5)}
            >
              Opslaan
            </Actions.Button>

            <Actions.Button
              onClick={submitAndNew}
              isLoading={!!submitContext.isSubmitting}
              appearance="primary"
              height={majorScale(5)}
            >
              Opslaan en nieuwe
            </Actions.Button>
          </Actions>

          {activityId && (
            <Card title={"Danger zone"} elevation={1} className={"mt-8 p-8"}>
              <Pane className="flex w-full items-center">
                <Heading size={400}>Danger zone</Heading>
                <Button
                  onClick={doRemoveCb}
                  iconBefore={<TrashIcon />}
                  intent="danger"
                  appearance="primary"
                  marginLeft="auto"
                  height={majorScale(5)}
                >
                  Delete
                </Button>
              </Pane>
            </Card>
          )}
        </>
      </Form>
    </Block>
  );
}
