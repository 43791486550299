import Html, { HtmlStyles } from "react-pdf-html";
import { pdfReportHtmlDefaultStyles } from "./styles/pdfReportContentStyles";

export const HTMLComponent = ({
  content,
  styles = pdfReportHtmlDefaultStyles,
}: {
  content: string;
  styles?: HtmlStyles;
}) => {
  const htmlContent = `<html><body>${content}</body></html>`;
  return <Html stylesheet={styles}>{htmlContent}</Html>;
};
