import DateValue from "@/components/common/DateValue";
import { useApi } from "@/context/AxiosContext";
import { useDoRequest } from "@/lib/request-hooks";
import { ProjectExport } from "@/types/apiTypes";
import { Button, CloudDownloadIcon, Heading, Pane, Paragraph, Strong, Table, TextInputField } from "evergreen-ui"
import { useState } from "react"
import { useParams } from "react-router-dom"

const speciesMap = {
    bat: "Vleermuis",
    birds_of_prey: "Roofvogel",
    sparrow: "Huismus",
    swallow: "Gierzwaluw",
}

function ExportLayout({ children }) {
    return (
        <Pane className="container max-w-md mx-auto my-8 p-4" elevation={1}>
            <Pane className="max-w-[15rem] mx-auto pb-4 text-3xl">
                <img src={"/logo.png"} alt="Gaia ecologie logo" />
            </Pane>
            <Heading size={800} textAlign="center">Download data export</Heading>
            <Pane className="mt-8">
                {children}
            </Pane>
        </Pane>
    )
}

function useLoadExportInfo(exportId) {
    const { apiInstance } = useApi()

    const [projectExport, setExport] = useState<ProjectExport | undefined>()

    const setData = async (data) => {
        return new Promise((resolve) => {
            setExport(data)
            resolve(data)
        })
    }

    const {
        handle,
        setIsLoading: setIsSubmitting,
        isLoading: isSubmitting,
        validationErrors,
        errorMessage: submitErrorMessage,
    } = useDoRequest()

    const doSubmit = async (code) => {
        const request = apiInstance!.shareProjectExport.findExport(exportId, code)
        await handle(request, setData)
    }

    async function downloadFile(exportId: string, file: { id: string, name: string }, code: string) {
        const { url } = await apiInstance!.shareProjectExport.download(exportId, file.id, code)
        const a = document.createElement("a");

        a.target = "_blank";
        a.style.display = "none";
        a.href = url;

        // the filename you want
        a.download = file.name;

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
    }

    return {
        submitContext: {
            doSubmit,
            setIsSubmitting,
            isSubmitting,
            submitErrorMessage,
            validationErrors,
        },
        projectExport,
        downloadFile,
    }
}

export default function ShareExportPage() {
    const { exportId } = useParams()
    const [code, setCode] = useState<string | undefined>("")

    const { submitContext, projectExport, downloadFile } = useLoadExportInfo(exportId)

    if (!projectExport) {
        return (
            <ExportLayout>
                <TextInputField
                    label="Enter security code"
                    placeholder="Security code"
                    value={code || ""}
                    onChange={(e) => setCode(e.target.value)}
                    isInvalid={submitContext.submitErrorMessage !== null}
                    validationMessage={submitContext.submitErrorMessage}
                />
                <Button
                    className="w-full"
                    appearance="primary"
                    size="large"
                    disabled={submitContext.isSubmitting || false}
                    onClick={() => {
                        submitContext.doSubmit(code)
                    }}
                >
                    Submit
                </Button>
            </ExportLayout>
        )
    }

    return (
        <ExportLayout>
            <Pane className="mb-8">
                <Paragraph>
                    <Strong>Project:</Strong>&nbsp;
                    {projectExport.project?.name}
                </Paragraph>
                <Paragraph>
                    <Strong>Periode export:</Strong>&nbsp;
                    <DateValue date={projectExport.filter.startDate} /> tot <DateValue date={projectExport.filter.endDate} />
                </Paragraph>
                <Paragraph>
                    <Strong>Soort(en):</Strong>&nbsp;
                    {projectExport.filter.species.map((specie) => speciesMap[specie]).join(", ")}
                </Paragraph>
            </Pane>
            
            <Table>
                <Table.Head>
                    <Table.TextHeaderCell>Bestanden</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                    {projectExport.files?.map((file) => (
                        <Table.Row key={file.name}>
                            <Table.Cell>
                                {file.name}
                            </Table.Cell>
                            <Table.Cell className="flex flex-row gap-2 justify-end">
                                <Button
                                    iconBefore={CloudDownloadIcon}
                                    appearance="primary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        downloadFile(projectExport.id, file, code!!)
                                    }}
                                    disabled={projectExport.disabled === true}
                                >
                                    Download
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </ExportLayout>
    )
}
