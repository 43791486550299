import PageTitle from "src/components/common/PageTitle";
import { useLocation, useParams } from "react-router-dom";
import { useApi } from "src/context/AxiosContext";
import { useTranslation } from "react-i18next";
import Page from "src/components/common/Page";
import AvailabilityTable from "../../worker-availability/_components/AvailabilityTable";
import usePeriodForm, { FormContext, useForm } from "src/hooks/usePeriodForm";
import { __r, ADMIN_WORKER_PERIODS_PAGE } from "../../../RouteMap";

export const useAdminAvailabilityRequests = function () {
  const { workerId } = useParams();
  const { apiInstance } = useApi();
  const { periodId } = useParams();

  const find = () =>
    apiInstance!.adminWorkerAvailability.findForWorker(workerId!, periodId!);
  const submit = (data) =>
    apiInstance!.adminWorkerAvailability.syncAvailability(workerId!, data);

  return usePeriodForm(find, submit, {
    ignoreIsLocked: true,
  });
};

export default function AdminWorkerAvailabilityPage() {
  const { t } = useTranslation();
  const adminAvailabilityProps = useAdminAvailabilityRequests();

  const location = useLocation();
  const { backPath } = location?.state ?? {};

  const { userId, workerId } = useParams();
  const bPath =
    backPath ?? __r(ADMIN_WORKER_PERIODS_PAGE, { userId, workerId });

  const availabilityProps = {
    ...adminAvailabilityProps,
    backPath: bPath,
    useForm: useForm,
  };

  return (
    <Page>
      <FormContext.Provider value={adminAvailabilityProps.formContext}>
        <PageTitle backPath={bPath}>
          {t("worker_availability_page.title")}
        </PageTitle>

        <AvailabilityTable {...availabilityProps} />
      </FormContext.Provider>
    </Page>
  );
}
