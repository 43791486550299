import { useApi } from "@/context/AxiosContext";
import { useState } from "react";
import { ClientObject, Id } from "@/types/apiTypes";
import { useLoadResource } from "@/lib/request-hooks";
import { FilterMapType } from "@/types/appTypes";
import { useDoFilter } from "@/hooks/useDoFilter";
import { useWindowSearchParams } from "@/hooks/useWindowSearchParams";

export default function useObjectsForProject({
  searchParams,
  projectId,
}: {
  projectId: Id | undefined;
  searchParams?: URLSearchParams;
}) {
  const { apiInstance } = useApi();

  const windowSearchParams = useWindowSearchParams();

  const [objects, setObjects] = useState<ClientObject[]>([]);

  const find = () =>
    apiInstance!.adminObjects.findManyForProject(
      projectId!,
      searchParams || windowSearchParams,
    );

  const setData = (objects: ClientObject[]) => {
    setObjects(objects);
  };

  const loadResourceLogic = useLoadResource(find, setData, true);

  const [name, setName] = useState<string | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);

  const filterMap: FilterMapType = {
    objectName: {
      setter: setName,
      topic: "objectName",
      value: name,
    },
  };

  const filterActions = useDoFilter({
    searchParams: windowSearchParams,
    setInitialized,
    initialized,
    filterMap,
    loadResourceLogic,
    find,
    setData,
    setMeta: () => {},
  });

  return {
    ...loadResourceLogic,
    objects,
    setObjects,
    filterMap,
    handleInput: filterActions.handleInput,
  };
}
