import {
  CrossIcon,
  Heading,
  IconButton,
  Pane,
  Select,
  useTheme,
} from "evergreen-ui";
import { memo, useMemo, useState } from "react";
import ProjectFilter from "../../../components/shared/filters/ProjectFilter";
import WorkerFilter from "../../../components/shared/filters/WorkerFilter";
import ActivityTypeFilter from "../../../components/shared/filters/ActivityTypeFilter";
import AddressFuzzyFilter from "../../../components/shared/filters/AdressFuzzyFilter";
import DateRangeFilter from "../../reports/_components/DateRangeFilter";
import { Period } from "src/types/apiTypes";
import { parseISO } from "date-fns";
import ShowDemoFilter from "@/pages/submitted-worker-shifts/components/ShowDemoFilter";

const shiftStatusOptions = [
  { value: "pending", label: "In afwachting" },
  { value: "published", label: "Gepubliceerd" },
  { value: "cancelled", label: "Geannuleerd" },
  { value: "reportOverdue", label: "Rapport achterstallig" },
  { value: "noTransport", label: "Geen vervoer" },
];

function ShiftStatusFilter({ filterMap, handleInput }) {
  const [status, setStatus] = useState<string | null>();
  const { colors } = useTheme();

  function setStatusFilter(value) {
    setStatus(value);
    handleInput(filterMap.shiftStatus, value ?? null);
  }

  const selected = useMemo(() => {
    if (!status && filterMap.shiftStatus?.value) {
      return filterMap.shiftStatus.value;
    } else {
      return status;
    }
  }, [status, filterMap.shiftStatus?.value]);

  return (
    <Pane className="flex flex-col gap-1">
      <Pane className="flex h-4 justify-between">
        <Heading
          className={selected ? "!font-bold !text-black" : ""}
          size={200}
        >
          Dienst status
        </Heading>
        {selected && (
          <IconButton
            icon={CrossIcon}
            appearance="minimal"
            height={24}
            onClick={() => setStatusFilter(null)}
          />
        )}
      </Pane>
      <Pane maxWidth={200} height={40}>
        <Select
          onChange={(e) => setStatusFilter(e.target.value)}
          value={selected ?? ""}
          background={selected ? colors.blue50 : ""}
          border={selected ? colors.selected : ""}
          height={40}
        >
          <option key="-" value="">
            Filter op dienst status...
          </option>
          {shiftStatusOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Pane>
    </Pane>
  );
}

const shiftRequestTypeOptions = [{ value: "cancel", label: "Wilt annuleren" }];

function ShiftRequestTypeFilter({ filterMap, handleInput }) {
  const [type, setType] = useState<string | null>();
  const { colors } = useTheme();

  function setRequestTypeFilter(value) {
    setType(value);
    handleInput(filterMap.shiftRequestType, value ?? null);
  }

  const selected = useMemo(() => {
    if (!type && filterMap.shiftRequestType?.value) {
      return filterMap.shiftRequestType.value;
    } else {
      return type;
    }
  }, [type, filterMap.shiftRequestType?.value]);

  return (
    <Pane className="flex flex-col gap-1">
      <Pane className="flex h-4 justify-between">
        <Heading
          className={selected ? "!font-bold !text-black" : ""}
          size={200}
        >
          Aanvraag type
        </Heading>
        {selected && (
          <IconButton
            icon={CrossIcon}
            appearance="minimal"
            height={24}
            onClick={() => setRequestTypeFilter(null)}
          />
        )}
      </Pane>
      <Pane maxWidth={200} height={40}>
        <Select
          onChange={(e) => setRequestTypeFilter(e.target.value)}
          value={selected ?? ""}
          background={selected ? colors.blue50 : ""}
          border={selected ? colors.selected : ""}
          height={40}
        >
          <option key="-" value="">
            Filter op aanvraag type...
          </option>
          {shiftRequestTypeOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Pane>
    </Pane>
  );
}

export const PlannerFilters = memo(function PlannerFilters({
  filterLogic,
  period,
}: {
  filterLogic: any;
  period: Period;
}) {
  const { handleInput, filterMap } = filterLogic;

  return (
    <Pane className="xs:flex-nowrap flex flex-wrap gap-2">
      {/*<AddressFilter filterMap={filterMap} handleInput={handleInput} />*/}
      <AddressFuzzyFilter filterMap={filterMap} handleInput={handleInput} />
      <WorkerFilter filterMap={filterMap} handleInput={handleInput} />
      <ProjectFilter filterMap={filterMap} handleInput={handleInput} />
      <ActivityTypeFilter filterMap={filterMap} handleInput={handleInput} />
      <ShiftStatusFilter filterMap={filterMap} handleInput={handleInput} />
      <ShiftRequestTypeFilter filterMap={filterMap} handleInput={handleInput} />
      <DateRangeFilter
        filterMap={filterMap}
        startAt={
          filterMap.fromDate?.value ? parseISO(filterMap.fromDate.value) : null
        }
        endAt={
          filterMap.toDate?.value ? parseISO(filterMap.toDate.value) : null
        }
        fromDateFilter={filterMap.fromDate}
        toDateFilter={filterMap.toDate}
        datePickerOptions={{
          disabled: {
            before: parseISO(period.startAt),
            after: parseISO(period.endAt),
          },
        }}
        defaultMonth={parseISO(period.startAt)}
        handleInput={handleInput}
      />
      <ShowDemoFilter filterMap={filterMap} handleInput={handleInput} />
    </Pane>
  );
});

export default PlannerFilters;
