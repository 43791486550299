import { DuplicateIcon, IconButton, Pane, Text, toaster } from "evergreen-ui";
import { copyTextToClipboard } from "../../lib/functions";

export default function ClipboardWrapper(props) {
  const { children, value, text, ...rest } = props;

  function onClick(e) {
    e.stopPropagation();
    e.preventDefault();

    copyTextToClipboard(value).then(() => {
      toaster.success("Copied to clipboard!");
    });
  }

  return (
    <Pane
      onClick={onClick}
      className="xs:flex-wrap flex cursor-pointer items-center gap-1"
      {...rest}
    >
      {children && children}
      <IconButton appearance="minimal" icon={DuplicateIcon} type="button" />
    </Pane>
  );
}
