import { Dialog, Text, Pane, TextareaField, toaster } from "evergreen-ui";
import { useParams } from "react-router-dom";
import { useApi } from "@/context/AxiosContext";
import { useState } from "react";
import { useDoRequest } from "@/lib/request-hooks";
import { useTranslation } from "react-i18next";
import { ShiftReport, WorkerShift } from "../../../types/apiTypes";
import Form from "@/components/common/Form";
import WorkerShifts from "@/api/_workerShifts";
import { WORKER_SHIFT_APPROVED_FORM_ID } from "@/components/config/forms";

function useApproveWorkerShiftForm({ workerShift }) {
  const { apiInstance } = useApi();

  const submittedNotes = workerShift?.statusUpdates?.find((i) => i.status === "submitted")?.answers?.shift_status_submitted__dayreport
  const approvedNotes = workerShift?.statusUpdates?.find((i) => i.status === "approved")?.answers?.shift_status_approved__dayreport
  
  const [notes, setNotes] = useState<string>(approvedNotes || submittedNotes || "");

  const {
    handle,
    setIsLoading: setIsSubmitting,
    isLoading: isSubmitting,
    validationErrors,
    errorMessage: submitErrorMessage,
  } = useDoRequest();

  const doSubmit = async () => {
    const data = {
      formId: WORKER_SHIFT_APPROVED_FORM_ID,
      answers: {
        shift_status_approved__dayreport: notes,
      },
    };

    const request = apiInstance!.adminWorkerShifts.approve(
      workerShift.workerId,
      workerShift.id!,
      data,
    );

    await handle(request);

    toaster.success("Goedgekeurd!");
  };

  return {
    submitContext: {
      doSubmit,
      setIsSubmitting,
      isSubmitting,
      submitErrorMessage,
      validationErrors,
    },
    notes,
    setNotes,
    doSubmit,
  };
}

export default function ApproveWorkerShiftDialog({
  workerShift,
  isShown,
  close,
  afterSubmit,
}: {
  workerShift: WorkerShift;
  isShown: boolean;
  close: () => void;
  afterSubmit: () => void;
}) {
  const { submitContext, notes, setNotes, doSubmit } =
    useApproveWorkerShiftForm({
      workerShift,
    });

  async function onConfirm() {
    await doSubmit();
    setNotes("");

    afterSubmit();
  }

  return (
    <Dialog
      confirmLabel="Goedkeuren"
      intent="success"
      isShown={isShown}
      onCloseComplete={close}
      onConfirm={onConfirm}
      shouldCloseOnOverlayClick={false}
      title="Dienst goedkeuren"
    >
      <Pane>
        <Form onSubmit={onConfirm}>
          <>
            <Text className="py-4">
              Geef evt. een extra notitie op de goedkeuring.
            </Text>
            <TextareaField
              autoFocus
              name="notes"
              label="Goedgekeurd dagverslag"
              placeholder="..."
              isInvalid={!!submitContext.validationErrors?.notes}
              validationMessage={submitContext.validationErrors?.notes?.join(
                ", ",
              )}
              value={notes || ""}
              onChange={(e) => setNotes(e.target.value)}
              marginBottom={0}
              inputHeight={40}
              className="!text-base"
            />
          </>
        </Form>
      </Pane>
    </Dialog>
  );
}
