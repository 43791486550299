import BaseApi from "./_baseApi";
import { doRequest } from "./_helpers";
import { createSearchParams } from "react-router-dom";
import { PostData, SearchParams } from "@/types/appTypes";
import { ClientObject, Id } from "@/types/apiTypes";

export default class AdminObjects extends BaseApi {
  findOne(id: Id): Promise<ClientObject> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/objects/${id}`,
    });
  }

  update(id: Id, data: PostData): Promise<ClientObject> {
    return doRequest(this._axiosInstance!, {
      method: "put",
      url: `/admin/objects/${id}`,
      data: data,
    });
  }

  findManyForArea(
    areaId: Id,
    searchParams: SearchParams = {},
  ): Promise<ClientObject[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/areas/${areaId}/objects${query}`,
    });
  }

  findManyForProject(
    projectId: Id,
    searchParams: SearchParams = {},
  ): Promise<ClientObject[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/projects/${projectId}/objects${query}`,
    });
  }

  createForProject(projectId: Id, data: PostData): Promise<ClientObject> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/projects/${projectId}/objects`,
      data: data,
    });
  }

  remove(id: Id): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: "delete",
      url: `/admin/objects/${id}`,
    });
  }

  removeFile(id: Id, fileId: Id): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: "delete",
      url: `/admin/objects/${id}/media/${fileId}`,
    });
  }

  uploadMedia(id: Id, formData: FormData): Promise<ClientObject> {
    return doRequest(this._axiosInstance!, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      method: "post",
      url: `/admin/objects/${id}/media`,
    });
  }
}
