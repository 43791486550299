import { Checkbox, Dialog } from "evergreen-ui";
import { DialogFooterComponent } from "./DialogFooterComponent";
import { useNavigate } from "react-router-dom";
import { useApi } from "@/context/AxiosContext";
import { __r, ADMIN_PROJECT_REPORT } from "@/RouteMap";
import { useState } from "react";
import { useDoRequest } from "@/lib/request-hooks";
import {
  IntroductionComponent,
  ObservationTypeCheckbox,
  AdditionalDataObservationTypes,
  Report,
  ReportData,
} from "@/types/apiTypes";
import { IntroductionEditor } from "../editors/IntroductionEditor";
import { getSpeciesObservationTypeOptions } from "../constants";

export const CreateReportDialogComponent = ({
  projectData,
  createReportDialogVisibility,
  handleOnClose,
}: {
  projectData: ReportData;
  createReportDialogVisibility: boolean;
  handleOnClose: () => void;
}) => {
  const {
    project: { id },
  } = projectData;
  const { apiInstance } = useApi();
  const navigate = useNavigate();
  const { handle } = useDoRequest();

  const [loaderVisibility, setLoaderVisibility] = useState(false);
  const [introductionComponent, setIntroductionComponent] =
    useState<IntroductionComponent>({
      title: "",
      subtitle: "",
      coverPhoto: "",
      type: "introduction",
    });

  const speciesInProject = Object.keys(projectData.species);

  const getSpeciesAvailableForProject = getSpeciesObservationTypeOptions(
    speciesInProject,
    true,
  ).filter((e) => speciesInProject.includes(e.value));
  const [specieObservationTypes, setSpecieObservationTypes] = useState(
    getSpeciesAvailableForProject,
  );

  const navTo = (reportId, projectId) => {
    navigate(
      __r(ADMIN_PROJECT_REPORT, {
        projectId,
        reportId,
      }),
    );
  };
  const [introductionCoverPhoto, setIntroductionCoverPhoto] =
    useState<File | null>(null);

  function getObservationTypes(): AdditionalDataObservationTypes {
    const result = {};
    specieObservationTypes.forEach((specie) => {
      const selectedObservationTypes = specie.observationTypes.filter(
        (e) => e.isSelected,
      );

      // If specie is selected, and empty array, include all observation types
      if (specie.isSelected) {
        result[specie.value] = [];
      }

      if (selectedObservationTypes.length) {
        result[specie.value] = selectedObservationTypes.map((e) => e.value);
      }
    });

    return result;
  }

  async function uploadCoverPhoto() {
    if (!introductionCoverPhoto) {
      return;
    }
    try {
      const mediaItemId = await apiInstance!.adminReports.uploadMedia(
        id,
        introductionCoverPhoto,
      );
      setIntroductionComponent({
        ...introductionComponent,
        coverPhoto: mediaItemId.toString(),
      });
    } catch (e) {
      console.error("Filed to upload Cover Photo", e);
    }
  }

  async function handleConfirmDialog() {
    setLoaderVisibility(true);
    await uploadCoverPhoto();
    const observationTypes = getObservationTypes();
    const request = apiInstance!.adminReports.createReport(
      id,
      observationTypes,
      introductionComponent,
    );
    const response: Report = await handle(request);
    setLoaderVisibility(false);
    navTo(response.id, response.projectId);
  }
  function handleIntroductionComponentUpdate(component: IntroductionComponent) {
    setIntroductionComponent(component);
  }

  function handleSpeciesSelect(specieIndex: number, value: boolean) {
    const updatedSpecies = [...specieObservationTypes];
    updatedSpecies[specieIndex].isSelected = value;
    updatedSpecies[specieIndex].observationTypes =
      getSpeciesObservationTypeOptions(Object.keys(projectData.species), value)[
        specieIndex
      ].observationTypes;

    setSpecieObservationTypes(updatedSpecies);
  }

  function handleObservationTypesSelect(
    specieIndex: number,
    index: number,
    value: boolean,
  ) {
    const updatedSpecies = [...specieObservationTypes];
    const updatedObservationTypes =
      updatedSpecies[specieIndex].observationTypes;
    updatedObservationTypes[index].isSelected = value;

    const isAllSelected = !specieObservationTypes[
      specieIndex
    ].observationTypes.find((e) => !e.isSelected);
    updatedSpecies[specieIndex].isSelected = isAllSelected;

    setSpecieObservationTypes(updatedSpecies);
  }

  function handleFileUploadChanges(files: { [key: string]: File }) {
    const { introductionCoverPhoto } = files;
    if (introductionCoverPhoto) {
      setIntroductionCoverPhoto(introductionCoverPhoto);
    }
  }

  function isSaveButtonEnabled() {
    return (
      (introductionComponent.title && introductionComponent.subtitle && true) ||
      false
    );
  }

  return (
    <Dialog
      title="Create Report"
      width="90%"
      shouldCloseOnOverlayClick={false}
      topOffset="2%"
      shouldCloseOnEscapePress={false}
      isShown={createReportDialogVisibility}
      onCloseComplete={handleOnClose}
      footer={
        <DialogFooterComponent
          isConfirmEnabled={isSaveButtonEnabled()}
          saveLoaderVisibility={loaderVisibility}
          handleCloseDialog={handleOnClose}
          handleConfirmDialog={handleConfirmDialog}
        />
      }
    >
      <IntroductionEditor
        mediaItems={undefined}
        component={introductionComponent}
        handleComponentUpdate={handleIntroductionComponentUpdate}
        handleFileUploadChanges={handleFileUploadChanges}
      >
        <span className="text-sm text-zinc-700">
          Select the observation types
        </span>
        {specieObservationTypes.map((specie, index) => (
          <div key={index}>
            <Checkbox
              label={specie.label}
              value={specie.value}
              checked={specie.isSelected}
              marginTop={8}
              onChange={(e) => handleSpeciesSelect(index, e.target.checked)}
            />
            <SpecieObservationTypes
              observationTypes={specie.observationTypes}
              specieIndex={index}
              handleObservationTypesSelect={handleObservationTypesSelect}
            />
          </div>
        ))}
      </IntroductionEditor>
    </Dialog>
  );
};

const SpecieObservationTypes = ({
  observationTypes,
  specieIndex,
  handleObservationTypesSelect,
}: {
  observationTypes: ObservationTypeCheckbox[];
  specieIndex: number;
  handleObservationTypesSelect: (
    specieIndex: number,
    index: number,
    value: boolean,
  ) => void;
}) => {
  return (
    <div className="ml-4">
      {observationTypes.map((e, index) => (
        <Checkbox
          key={index}
          label={e.label}
          value={e.value}
          checked={e.isSelected}
          marginTop={8}
          onChange={(e) =>
            handleObservationTypesSelect(specieIndex, index, e.target.checked)
          }
        />
      ))}
    </div>
  );
};
