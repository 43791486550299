import { CrossIcon, Heading, IconButton, Pane } from "evergreen-ui";
import { SelectOption } from "../../../types/apiTypes";
import SelectInputField from "../../formfields/SelectInputField";
import { memo, useMemo, useState } from "react";
import useWorkers from "src/hooks/useWorkers";
import { FilterMapType } from "../../../types/appTypes";

export default memo(function WorkerFilter({
  filterMap,
  handleInput,
}: {
  filterMap: FilterMapType;
  handleInput: Function;
}) {
  const [worker, setWorker] = useState<SelectOption | null>(null);

  const { workers } = useWorkers({
    workerId: filterMap.workerId.value!,
    setWorker,
  });

  const workerOptions = useMemo(
    function () {
      return workers.map((v) => ({
        value: v.id,
        label: v.user.firstName + " " + v.user.lastName,
      }));
    },
    [workers],
  );

  function setSelected(value) {
    setWorker(value);
    handleInput(filterMap.workerId, value?.value ?? null);
  }

  return (
    <Pane className="flex flex-col gap-1">
      <Pane className="flex h-4 justify-between">
        <Heading className={worker ? "!font-bold !text-black" : ""} size={300}>
          {filterMap.workerId.label}
        </Heading>

        {worker && (
          <IconButton
            icon={CrossIcon}
            appearance="minimal"
            height={24}
            onClick={() => setSelected(null)}
          />
        )}
      </Pane>
      <SelectInputField
        closeOnSelect
        selected={worker}
        setSelected={setSelected}
        title="Select type"
        emptySelectText="Filter op veldwerker..."
        options={workerOptions}
      />
    </Pane>
  );
});
