import { useTranslation } from "react-i18next";
import { useReportForm } from "../../../../pages/worker-shifts/WorkerShiftReportPage";
import { FormField, Pane, Text, Select } from "evergreen-ui";
import { ReportFormFieldProps } from "../../../../types/appTypes";
import { useMemo } from "react";

const precipitationOptions = [
  {
    value: "dry",
    label: "Droog",
  },
  {
    value: "rain",
    label: "Regen",
  },
  {
    value: "heavy_rain",
    label: "Zware regen",
  },
  {
    value: "drizzle",
    label: "Miezer",
  },
  {
    value: "hail",
    label: "Hagel",
  },
  {
    value: "snow",
    label: "Sneeuw",
  },
];

export function Precipitation({ reportKey, editMode }: ReportFormFieldProps) {
  const { t } = useTranslation();
  const { onChange, submitContext, formState } = useReportForm();

  function setValue(value) {
    onChange(reportKey.key, value);
  }

  const valueLabel = useMemo(() => {
    return (
      precipitationOptions.find(
        (v) => v.value === formState?.reportFields?.precipitation?.value,
      )?.label ?? "-"
    );
  }, [formState.reportFields?.precipitation]);

  return (
    <Pane className="grow">
      <FormField
        required
        validationMessage={submitContext.validationErrors?.precipitation?.join(
          ", ",
        )}
        label={t("report_fields." + reportKey.key)}
      >
        {editMode ? (
          <Select
            disabled={!editMode}
            onChange={(e) => setValue(e.target.value)}
            isInvalid={!!submitContext.validationErrors?.precipitation}
            //@ts-ignore
            value={formState?.reportFields?.precipitation?.value ?? 0}
            height={40}
            width="100%"
          >
            {precipitationOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        ) : (
          <Text>{valueLabel}</Text>
        )}
      </FormField>
    </Pane>
  );
}
