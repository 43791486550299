import * as React from "react";

export default function SunsetIcon(props) {
  const { size = 24 } = props;

  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511.984 511.984"
      xmlSpace="preserve"
      {...props}
    >
      <path
        style={{
          fill: "#f6bb42",
        }}
        d="M95.998 282.643H32c-5.891 0-10.664 4.781-10.664 10.688 0 5.875 4.773 10.656 10.664 10.656h63.998c5.89 0 10.663-4.781 10.663-10.656 0-5.906-4.773-10.688-10.663-10.688zM479.985 282.643h-63.997c-5.891 0-10.656 4.781-10.656 10.688 0 5.875 4.766 10.656 10.656 10.656h63.997c5.891 0 10.656-4.781 10.656-10.656 0-5.906-4.765-10.688-10.656-10.688z"
      />
      <path
        style={{
          fill: "#ffce54",
        }}
        d="m406.832 127.39-45.25 45.257c-4.155 4.164-4.155 10.914 0 15.085 4.156 4.164 10.922 4.164 15.094 0l45.249-45.256c4.156-4.164 4.156-10.922 0-15.086-4.171-4.164-10.921-4.164-15.093 0z"
      />
      <path
        style={{
          fill: "#f6bb42",
        }}
        d="M245.328 69.33v63.998c0 5.891 4.773 10.664 10.664 10.664s10.664-4.773 10.664-10.664V69.33c0-5.89-4.773-10.671-10.664-10.671s-10.664 4.781-10.664 10.671z"
      />
      <path
        style={{
          fill: "#ffce54",
        }}
        d="m90.068 142.476 45.248 45.256c4.164 4.164 10.922 4.164 15.086 0 4.164-4.171 4.164-10.921 0-15.085l-45.257-45.257c-4.164-4.164-10.913-4.164-15.077 0-4.172 4.165-4.172 10.922 0 15.086zM146.426 335.985c-5.305-13.562-7.984-27.89-7.984-42.654 0-64.826 52.732-117.559 117.551-117.559 64.812 0 117.559 52.732 117.559 117.559 0 14.765-2.688 29.093-8 42.654H146.426z"
      />
      <path
        style={{
          fill: "#f6bb42",
        }}
        d="M255.992 165.1c-70.811 0-128.215 57.405-128.215 128.23 0 19.03 4.148 37.076 11.586 53.311h233.25c7.438-16.234 11.594-34.28 11.594-53.311 0-70.824-57.404-128.23-128.215-128.23zM358.02 325.329H153.972c-3.234-10.296-4.867-21.014-4.867-31.998 0-28.562 11.116-55.405 31.311-75.591 20.188-20.188 47.022-31.304 75.576-31.304 28.562 0 55.39 11.116 75.576 31.304 20.202 20.186 31.311 47.029 31.311 75.591 0 10.984-1.641 21.702-4.859 31.998z"
      />
      <path
        style={{
          fill: "#ed5564",
        }}
        d="m225.821 427.561 22.632 22.64h.008a10.66 10.66 0 0 0 7.531 3.124c2.938 0 5.602-1.203 7.531-3.124h.008l22.632-22.624c4.172-4.172 4.172-10.938 0-15.094-2.078-2.094-4.813-3.125-7.546-3.125a10.603 10.603 0 0 0-7.539 3.125l-4.422 4.406V378.64c0-5.875-4.773-10.656-10.664-10.656s-10.664 4.781-10.664 10.656v38.249l-4.422-4.406a10.574 10.574 0 0 0-7.538-3.125 10.596 10.596 0 0 0-7.547 3.125c-4.164 4.156-4.164 10.906 0 15.078z"
      />
      <path
        style={{
          fill: "#656d78",
        }}
        d="M501.328 325.329H10.664C4.773 325.329 0 330.095 0 335.985s4.773 10.656 10.664 10.656h490.664c5.875 0 10.656-4.766 10.656-10.656s-4.781-10.656-10.656-10.656z"
      />
    </svg>
  );
}
