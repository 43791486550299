import { DuplicateIcon, IconButton, Text, toaster } from "evergreen-ui";
import { copyTextToClipboard } from "../../lib/functions";

export default function TextClipBoard(props) {
  const { value, text, ...rest } = props;

  function onClick(e) {
    e.stopPropagation();
    e.preventDefault();

    copyTextToClipboard(value).then(() => {
      toaster.success("Copied to clipboard!");
    });
  }

  return (
    <Text className="cursor-pointer" {...rest}>
      {text && text}

      <IconButton
        onClick={onClick}
        appearance="minimal"
        icon={DuplicateIcon}
        type="button"
      />
    </Text>
  );
}
