import { Button, IconButton, Pane } from "evergreen-ui";
import { classNames } from "../../lib/functions";

const ActionButton = function (props) {
  const { children, ...rest } = props;

  return <Button {...rest}>{children}</Button>;
};

const ActionIconButton = function (props) {
  const { children, ...rest } = props;

  return <IconButton {...rest}>{children}</IconButton>;
};
const Actions = function Actions(props) {
  const { children, className, ...rest } = props;

  const classes = classNames("flex gap-2 flex-wrap justify-end", className);

  return (
    <Pane className={classes} {...rest}>
      {children}
    </Pane>
  );
};

Actions.Button = ActionButton;
Actions.IconButton = ActionIconButton;

export default Actions;
