import React from "react";
import { CgPinAlt } from "react-icons/cg";
import { Heading, Paragraph } from "evergreen-ui";

import { Area, ClientObject } from "@/types/apiTypes";
import { classNames, shortenString } from "@/lib/functions";
import Block from "@/components/common/Block";

type ObjectListProps = {
  objects: Area["objects"];
  onClickObject?: (object: ClientObject) => void;
  listClasses?: string;
  itemClasses?: string;
  maxLengthName?: number;
};

export default function ObjectList({
  objects,
  onClickObject,
  listClasses,
  itemClasses,
  maxLengthName = 30,
}: ObjectListProps) {
  return (
    <Block className={classNames("flex gap-2", listClasses || "")}>
      {objects.map((object) => (
        <Block
          className={classNames(
            "relative items-center gap-1 shadow-sm",
            onClickObject && "cursor-pointer",
          )}
          title={object.name}
          key={object.id}
          onClick={() => onClickObject && onClickObject(object)}
        >
          <Block
            className={classNames(
              "flex items-center border bg-white py-2",
              onClickObject && "hover:border-blue-400 hover:bg-blue-100",
              itemClasses || "",
            )}
          >
            <Block>
              <CgPinAlt color={"#3366FF"} size={15} />
            </Block>
            <Block className="flex-nowrap border-l px-1 hover:!text-white">
              <Heading title={object.name} size={200}>
                {shortenString(object.name, maxLengthName)}
              </Heading>
            </Block>
          </Block>
        </Block>
      ))}
    </Block>
  );
}
