import BaseApi from "./_baseApi";
import { doRequest } from "./_helpers";
import { createSearchParams } from "react-router-dom";
import { PostData, SearchParams } from "@/types/appTypes";
import { Id, ProjectExport } from "@/types/apiTypes";

export default class AdminProjectExports extends BaseApi {
  findMany(
    projectId: Id,
    searchParams: SearchParams = {},
  ): Promise<ProjectExport[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/projects/${projectId}/exports${query}`,
    });
  }

  createForProject(projectId: Id, data: PostData): Promise<{ success: ProjectExport }> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/projects/${projectId}/exports/create`,
      data: data,
    });
  }

  prepareMediaExport(projectId: Id, id: Id): Promise<{ success: ProjectExport }> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/projects/${projectId}/exports/${id}/prepare-media`,
    });
  }

  remove(projectId: Id, id: Id): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: "delete",
      url: `/admin/projects/${projectId}/exports/${id}`,
    });
  }

  restart(projectId: Id, id: Id): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/projects/${projectId}/exports/${id}/restart`,
    });
  }

  download(projectId: Id, exportId: Id, filedId: Id): Promise<{ url: string }> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/projects/${projectId}/exports/${exportId}/${filedId}/download`,
    });
  }
}
