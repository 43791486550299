import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { Invite } from "../types/apiTypes";

export default class Invites extends BaseApi {
  findOne(token: string): Promise<Invite> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/invites?token=${token}`,
    });
  }
}
