import {
  Alert,
  Avatar,
  BanCircleIcon,
  Button,
  Card,
  DriveTimeIcon,
  EyeOpenIcon,
  FileCard,
  Heading,
  InfoSignIcon,
  Pane,
  Paragraph,
  Position,
  Text,
  TickIcon,
  TimeIcon,
  Tooltip,
} from "evergreen-ui";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { createElement, useMemo } from "react";
import { Media, WorkerApiType, WorkerShift } from "@/types/apiTypes";
import { TimeslotMapById } from "@/hooks/useTimeslotResources";
import { classNames, shortenString } from "@/lib/functions";
import { DateFormat } from "@/components/common/DateFormat";
import { DisplayReportAddress } from "@/components/shared/report/components/DisplayReportAddress";
import {
  ShiftStatusColorMap,
  ShiftStatusIconMap,
  TimeslotIconMap,
} from "@/components/config/iconMaps";
import DurationValue from "../../../components/common/DurationValue";
import TravelDistanceValue from "../../../components/common/TravelDistanceValue";
import ClipboardWrapper from "../../../components/common/ClipboardWrapper";

type WorkerShiftCardProps = {
  workerShift: WorkerShift;
  onClick: () => void;
  timeslotMapById: TimeslotMapById;
  elevation: number;
  showMap?: boolean;
  showDetailsLink?: boolean;
  showPassengerTable?: boolean;
};

// export const ShiftStatusIconMap = {
//   pending: <TimeIcon color="muted" size={14} />,
//   planned: <TimeIcon color="muted" size={14} />,
//   submitted: <TickCircleIcon color="success" size={14} />,
//   published: <TickCircleIcon color="success" size={14} />,
//   cancelled: <BanCircleIcon color="danger" size={14} />,
//   changesRequested: <BsQuestion color="danger" size={14} />,
// };

export function WorkerShiftDetails({
  workerShift,
  onClick,
  timeslotMapById,
  elevation = 0,
  ...rest
}: WorkerShiftCardProps) {
  const {
    showMap = false,
    showDetailsLink = true,
    showPassengerTable = false,
  } = rest;

  const { t } = useTranslation();

  const shiftReport = workerShift.shiftReport ?? null;
  const timeslot = workerShift.shift.timeslot;

  const classes = useMemo(
    () =>
      classNames(
        workerShift.shiftReport?.status === "rejected"
          ? "border-l-4 border-red-600"
          : "",
      ),
    [workerShift],
  );

  const passengers = useMemo(() => {
    if (!workerShift.carpool?.workers) {
      return [];
    }

    return workerShift.carpool.workers.filter((worker) => {
      return worker.id !== workerShift.workerId;
    });
  }, [workerShift]);

  const iconKey = workerShift?.shift?.timeslotUid
    ? timeslotMapById[workerShift.shift.timeslotUid]?.icon
    : null;
  const icon = iconKey ? TimeslotIconMap[iconKey] : null;

  const driverId = workerShift.carpool?.driverId;

  const areaActivityTypes = workerShift.areaActivityTypes;

  return (
    <Card className={classes} elevation={1} padding={16} marginBottom={16}>
      <Pane className="flex flex-wrap items-end gap-1 pb-1">
        <Pane className="flex w-full justify-between">
          <Pane className="flex flex-col gap-1">
            <Heading className="!font-normal" size={600}>
              {areaActivityTypes[0].area.name}
              <Heading size={100}>
                {areaActivityTypes[0].activityType.name}
              </Heading>
            </Heading>

            {workerShift.status === "changesRequested" && (
              <Alert intent="danger">
                Deze dienst is afgekeurd, bekijk de opmerking en pas zo nodig je
                waarnemingen aan.
              </Alert>
            )}

            {workerShift.workerShiftRequest?.status === "pending" && (
              <Alert intent="warning">Je annulering is in behandeling</Alert>
            )}

            {workerShift.workerShiftRequest?.status === "declined" && (
              <Alert intent="warning">
                <Heading size={400}>Je annulering is afgekeurd</Heading>

                <Paragraph size={400}>
                  {workerShift.workerShiftRequest?.adminNotes}
                </Paragraph>
              </Alert>
            )}

            <Pane className="pt-1">
              {icon && createElement(icon, { size: 65 })}
            </Pane>

            <Pane className="flex flex-wrap gap-1">
              <Heading size={100}>
                {workerShift.status !== "cancelled" ? (
                  <DateFormat
                    className="!normal-case"
                    size={100}
                    formatStr="PPPPp"
                    date={new Date(workerShift.startAt!)}
                  />
                ) : (
                  <Text>-</Text>
                )}
              </Heading>
            </Pane>
          </Pane>

          <Pane className="flex flex-wrap justify-end gap-1">
            {showDetailsLink && (
              <Button
                className="shrink"
                appearance="primary"
                intent="info"
                onClick={onClick}
                iconBefore={EyeOpenIcon}
              >
                {t("worker_shifts_page.see_shift_details")}
              </Button>
            )}

            {/*{workerShift.reportIsSubmittable && (*/}
            {/*  <Link*/}
            {/*    to={__r(WORKER_SHIFTS_REPORT_PAGE, {*/}
            {/*      shiftId: workerShift.id,*/}
            {/*    })}*/}
            {/*  >*/}
            {/*    <Button*/}
            {/*      appearance="primary"*/}
            {/*      intent="success"*/}
            {/*      className="shrink self-end"*/}
            {/*      iconBefore={UploadIcon}*/}
            {/*    >*/}
            {/*      {t("worker_shifts_page.submit_report")}*/}
            {/*    </Button>*/}
            {/*  </Link>*/}
            {/*)}*/}
          </Pane>
        </Pane>

        <Pane className={"h-[1px] w-full bg-gray-200"} />
      </Pane>

      <Pane className="flex flex-wrap gap-2 pt-2 md:flex-nowrap">
        <Pane className="flex w-full flex-col gap-2">
          <Pane>
            <Heading size={400}>Activiteit(en):</Heading>
            <Pane className="border-l pl-2">
              {areaActivityTypes.map((aat) => (
                <Pane title={aat.activityType.type?.label} key={aat.id}>
                  <Text>- </Text>
                  <Text>{aat.activityType.name}</Text>
                  <Text>: </Text>
                  <Text>
                    <DateFormat
                      className="font-bold !normal-case"
                      size={100}
                      formatStr="d MMM p"
                      date={new Date(aat.startAt!)}
                    />
                  </Text>
                  <Text> - </Text>
                  <Text>
                    <DateFormat
                      className="font-bold !normal-case"
                      size={100}
                      formatStr="d MMM p"
                      date={new Date(aat.endAt!)}
                    />
                  </Text>
                </Pane>
              ))}
            </Pane>
          </Pane>

          <Pane>
            <Pane className="flex gap-1">
              <Heading size={400}>Min. starttijd:</Heading>

              {workerShift.status !== "cancelled" && (
                <Tooltip
                  position={Position.RIGHT}
                  content={t("worker_shifts_page.start_time_info_tooltip", {
                    startHours: timeslot.start
                      ? timeslot.start === 0
                        ? "meteen"
                        : timeslot.start > 0
                          ? `+${timeslot.start} uur`
                          : `${timeslot.start} uur`
                      : "",
                    beforeOrAfter: timeslot.start < 0 ? "voor" : "na",
                    relativeTo: t(
                      "worker_shifts_page.relative_to." + timeslot.relativeTo,
                    ),
                    endHours: timeslot.end,
                    endBeforeOrAfter: timeslot.end < 0 ? "voor" : "na",
                  })}
                >
                  <Pane className="cursor-pointer !justify-self-end">
                    <InfoSignIcon color="muted" />
                  </Pane>
                </Tooltip>
              )}
            </Pane>
            <Pane className="flex items-end">
              <Text className="normal-case !text-gray-200" size={400}>
                {workerShift.status !== "cancelled" ? (
                  <DateFormat
                    className="!normal-case"
                    size={100}
                    formatStr="PPPPp"
                    date={new Date(workerShift.startAt!)}
                  />
                ) : (
                  <Text>-</Text>
                )}
              </Text>
            </Pane>
          </Pane>

          <Pane>
            <Heading size={400}>Max. eindtijd:</Heading>
            <Pane className="flex items-end">
              <Text className="normal-case !text-gray-200" size={400}>
                {workerShift.status !== "cancelled" ? (
                  <DateFormat
                    className="!normal-case"
                    size={100}
                    formatStr="PPPPp"
                    date={new Date(workerShift.endAt!)}
                  />
                ) : (
                  <Text>-</Text>
                )}
              </Text>
            </Pane>
          </Pane>

          <Pane>
            <Heading size={400}>Tijdsduur:</Heading>
            <Text className="!font-normal" size={400}>
              <DurationValue seconds={workerShift.duration} />
            </Text>
          </Pane>

          <Pane>
            <Heading className="flex items-center gap-1" size={400}>
              <TimeIcon color="muted" size={14} />
              Geschatte reistijd (met auto):
            </Heading>

            <Text className="!font-normal" size={400}>
              <DurationValue seconds={workerShift.travelDuration} />
            </Text>
          </Pane>

          <Pane>
            <Heading className="flex items-center gap-1" size={400}>
              <DriveTimeIcon />
              Afstand tot locatie:
            </Heading>
            <Text className="!font-normal" size={400}>
              <TravelDistanceValue meters={workerShift.distanceToArea} />
            </Text>
          </Pane>

          <Pane className="flex gap-4">
            {!showPassengerTable && (
              <Carpool
                workerShift={workerShift}
                passengers={passengers}
                driverId={driverId}
              />
            )}
          </Pane>
        </Pane>
        <Pane className="w-full">
          <Pane className="flex w-full flex-col gap-2">
            <Pane>
              <Pane className="flex items-center gap-1 self-end">
                <Heading size={400}>Dienst status:</Heading>

                {workerShift.shiftHasEnded && (
                  <Heading size={200}>
                    {t("shifts.status." + workerShift.status)}
                  </Heading>
                )}

                {!workerShift.shiftHasEnded && (
                  <Heading size={200}>
                    {t("shifts.status." + workerShift.status)}
                  </Heading>
                )}
                <Pane className="border-r-2 px-1">
                  <Pane>
                    {ShiftStatusIconMap[workerShift.status] &&
                      createElement(ShiftStatusIconMap[workerShift.status], {
                        color: ShiftStatusColorMap[workerShift.status],
                      })}
                  </Pane>
                </Pane>
              </Pane>
            </Pane>

            <Pane>
              <Notes workerShift={workerShift} />
            </Pane>

            <Pane>
              <DocumentsList workerShift={workerShift} />
            </Pane>
          </Pane>
        </Pane>
      </Pane>

      {showPassengerTable && (
        <Pane className="py-2">
          <Heading className="py-2" size={400}>
            Carpool contact informatie:
          </Heading>
          <Pane className="max-w-4xl">
            <PassengersTable
              // workerShift={workerShift}
              passengers={passengers}
              driverId={driverId}
            />
          </Pane>
        </Pane>
      )}
      <Pane>
        <Pane className="py-2">
          <Heading size={400}>Adres: </Heading>
          <DisplayReportAddress
            showMap={showMap}
            minHeightMap={500}
            address={workerShift.address}
          />
        </Pane>
      </Pane>
    </Card>
  );
}

function Notes({ workerShift }: { workerShift: WorkerShift }) {
  return (
    <Pane className="items-center gap-1 self-end">
      <Heading size={400}>Notities:</Heading>
      <Paragraph
        className="border-l-1 whitespace-pre-wrap border-gray-500 text-gray-500"
        size={100}
      >
        {workerShift.areaActivityTypes[0].area.notes || "-"}
      </Paragraph>
    </Pane>
  );
}

function DocumentsList({ workerShift }: { workerShift: WorkerShift }) {
  return (
    <>
      <Heading className="pt-2 pb-2" size={400}>
        Gebied documenten:
      </Heading>
      {!isEmpty(workerShift.areaActivityTypes[0].area.media) ? (
        <AttachedMediaFileList
          mediaFiles={workerShift.areaActivityTypes[0].area.media}
        />
      ) : (
        <Text className="text-gray-500">-</Text>
      )}
    </>
  );
}

function Carpool({
  workerShift,
  passengers,
  driverId,
}: {
  workerShift: WorkerShift;
  passengers: WorkerApiType[];
  driverId?: number;
}) {
  return (
    <>
      <Pane>
        <Heading className="flex items-center gap-1" size={400}>
          Rijdt zelf:
        </Heading>
        <Text className="!font-normal" size={400}>
          {workerShift.isDriving ? "Ja" : "Nee"}
        </Text>
      </Pane>
      <Pane>
        <Heading className="flex items-center gap-1" size={400}>
          Carpool:
        </Heading>
        {isEmpty(passengers) && (
          <Text className="!font-normal" size={400}>
            -
          </Text>
        )}
        <Pane className="flex gap-2 py-1 !font-normal">
          {passengers.map((worker) => (
            <Pane className="flex items-center gap-1">
              <Avatar
                className="hover:opacity-90"
                src={worker.user!.profilePictureThumbnail ?? ""}
                name={""}
                size={22}
              />

              {worker.id === driverId && (
                <span>
                  <DriveTimeIcon size={14} color="muted" />
                </span>
              )}
              <Text
                className={classNames(
                  worker.id === driverId ? "!font-bold" : "",
                  "grow",
                )}
              >
                {worker.user.firstName} {worker.user.lastName}
                {worker.id === driverId && (
                  <Text className="!text-sm">(bestuurder)</Text>
                )}
              </Text>
            </Pane>
          ))}
        </Pane>
      </Pane>
    </>
  );
}

function PassengersTable({
  passengers,
  driverId,
}: {
  passengers: WorkerApiType[];
  driverId?: number;
}) {
  return (
    <table className="data-table w-full">
      <thead>
        <tr>
          <th style={{ width: "15em", textAlign: "left" }}>Naam</th>
          <th style={{ textAlign: "left" }}>Contact informatie</th>
          {/*<th style={{width: '15em', textAlign: 'center'}}>*/}
          {/*    Telefoonnummer*/}
          {/*</th>*/}
        </tr>
      </thead>
      <tbody>
        {passengers.map((worker) => (
          <tr key={worker.id}>
            <td className="flex items-center gap-1">
              <Avatar
                className="hover:opacity-90"
                src={worker.user!.profilePictureThumbnail ?? ""}
                name={""}
                size={25}
              />

              <Pane className="flex flex-col">
                {worker.id === driverId && (
                  <Pane className="flex flex-wrap gap-1">
                    <DriveTimeIcon size={14} color="muted" />
                    <Text size="small">(bestuurder)</Text>
                  </Pane>
                )}

                <Text
                  className={classNames(
                    worker.id === driverId ? "!font-bold" : "",
                    "",
                  )}
                >
                  {worker.user.firstName} {worker.user.lastName}
                </Text>
              </Pane>
            </td>
            <td>
              <Pane className="flex flex-wrap gap-1 text-center">
                <ClipboardWrapper>
                  {worker.address && `${worker.address.formattedAddress}`}
                </ClipboardWrapper>
                <ClipboardWrapper>{worker.phone}</ClipboardWrapper>
              </Pane>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

type AttachedMediaFileListProps = {
  mediaFiles: Media[];
  maxLengthFileName?: number;
};

export function AttachedMediaFileList({
  mediaFiles,
  maxLengthFileName = 300,
}: AttachedMediaFileListProps) {
  function doDownload(media: Media) {
    const a = document.createElement("a");

    a.target = "_blank";
    a.style.display = "none";
    a.href = media.src;

    a.download = media.originalFileName;

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(media.src);

    document.body.removeChild(a);
  }

  return (
    <Pane className="flex flex-col gap-1">
      {mediaFiles.map((media: Media, index) => (
        <Pane key={media.id} className="flex w-full grow items-center gap-1">
          <Pane
            className="grow cursor-pointer hover:underline"
            onClick={() => doDownload(media)}
            key={`${media.originalFileName}-${index}`}
          >
            <FileCard
              className="grow"
              isInvalid={media.isUploaded === false}
              name={shortenString(media.originalFileName, maxLengthFileName)}
              width="100%"
              paddingRight={20}
              sizeInBytes={media.size}
              type={media.mimeType}
              isLoading={false}
              src={media.src}
            />
          </Pane>
          <Pane>
            {media.isUploaded && <TickIcon color="success" size={14} />}
            {media.isUploaded === false && (
              <Pane className="flex items-start gap-1">
                <Heading size={100} color="danger">
                  Niet geupload
                </Heading>
                <BanCircleIcon color="danger" size={14} />
              </Pane>
            )}
          </Pane>
        </Pane>
      ))}

      {isEmpty(mediaFiles) && (
        <Paragraph>Geen bestanden toegevoegd...</Paragraph>
      )}
    </Pane>
  );
}
