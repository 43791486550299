import { useApi } from "src/context/AxiosContext";
import * as locale from "date-fns/locale/nl";
import { format, formatISO, parseISO } from "date-fns";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";

import { AreaActivityType, Shift, Timeslot } from "src/types/apiTypes";
import { __r, PLANNER_PERIOD_PLANNING_PAGE } from "src/RouteMap";

import { useLoadResource } from "src/lib/request-hooks";
import useTimeslotResources from "src/hooks/useTimeslotResources";
import { useMinMaxStartDate } from "../PlannerRoundsItem";
import useActivityTypeOptions from "src/hooks/useActivityTypeOptions";
import usePeriod from "src/hooks/usePeriod";
import usePlannerDialogReducer from "./PlannerDialogReducer";
import useShiftForm from "./useShiftForm";

function useAreaActivityType() {
  const { areaActivityTypeId } = useParams();
  const { apiInstance } = useApi();

  const [areaActivityType, setAreaActivityType] =
    useState<AreaActivityType | null>(null);
  const [timeslotsOfType, setTimeslotsOfType] = useState<Timeslot | null>(null);

  const request = () =>
    apiInstance!.adminAreaActivityTypes.findOne(areaActivityTypeId!);

  const setData = (areaActivityType) => {
    setAreaActivityType(areaActivityType);
    setTimeslotsOfType(areaActivityType.timeslots);
  };

  return {
    ...useLoadResource(request, setData, !!areaActivityTypeId),
    areaActivityType,
  };
}

function useAllActivityTypeShifts() {
  const { apiInstance } = useApi();
  const { areaActivityTypeId } = useParams();

  const [allShifts, setAllShifts] = useState<Shift[]>([]);

  const find = () => apiInstance!.adminShifts.findMany({ areaActivityTypeId });

  function setData(shifts) {
    setAllShifts(shifts);
  }

  return {
    ...useLoadResource(find, setData, !!areaActivityTypeId),
    allShifts,
  };
}

function usePlannerDateLogic({
  areaActivityType,
  period,
  date,
  setDate,
  shift,
}) {
  const { allShifts } = useAllActivityTypeShifts();

  const prevShift = useMemo(() => {
    if (isEmpty(allShifts)) {
      return null;
    }

    // note: this works if they shifts are ordered in order of their rounds - we make sure they are.
    const myIndex = shift?.id
      ? (allShifts ?? [])!.findIndex((v) => v.id === shift.id)
      : allShifts.length;
    return allShifts[myIndex - 1] ?? null;
  }, [allShifts, shift]);

  const {
    minStartDate: minStartDate,
    leftStartDate: notBeforeDate,
    rightStartDate: notAfterDate,
    isPlannableWithinPeriod,
  } = useMinMaxStartDate({
    shifts: allShifts,
    period,
    shift,
    areaActivityType,
  });

  const datePickerHint = useMemo(() => {
    if (prevShift) {
      return minStartDate
        ? format(minStartDate, "eeeeee - PP", { locale: locale.default }) +
            " + " +
            areaActivityType?.activityType.minDaysBetweenRounds +
            " min. dagen"
        : "";
    } else {
      return null;
    }
  }, [prevShift, areaActivityType, notBeforeDate, notAfterDate]);

  return {
    notAfterDate,
    notBeforeDate,
    prevShift,
    datePickerHint,
    isPlannableWithinPeriod,
  };
}

export default function usePlannerDialogLogic() {
  const location = useLocation();
  const { backPath } = location?.state ?? {};

  const { period } = usePeriod();
  const [searchParams] = useSearchParams();
  const { areaActivityId, periodId } = useParams();

  const navigate = useNavigate();

  const { timeslots, isLoading: timeslotsIsLoading } = useTimeslotResources();

  const { state, dispatch, helpers } = usePlannerDialogReducer();

  const {
    setDate,
    date,
    shift,
    timeslotUid,
    setTimeslotUid,
    isLoading: shiftIsLoading,
    doSubmit,
    submitContext,
    ignoreTravelTime,
    setIgnoreTravelTime,
  } = useShiftForm({
    setAttachedWorkers: helpers.setAttachedWorkers,
    attachedWorkers: state.attachedWorkers,
  });

  const { areaActivityType } = useAreaActivityType();
  const { activityTypeOptions } = useActivityTypeOptions();

  function setDateCb(value: Date) {
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.forEach((value, key) => {
      searchParams.set(key, value);
    });

    searchParams.set("date", formatISO(value));

    const url =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?${createSearchParams(searchParams)}`;
    window.history.pushState({ path: url }, "", url);

    setDate(value);
    // setIsDirty(true)
  }

  const { notAfterDate, notBeforeDate, datePickerHint } = usePlannerDateLogic({
    areaActivityType,
    period,
    date,
    setDate: setDateCb,
    shift,
  });

  const timeslotsAvailable = useMemo(() => {
    const activityTypeKey = areaActivityType?.activityType?.type.value ?? "";
    const activityTimeslots = activityTypeOptions[activityTypeKey]
      ? Object.keys(activityTypeOptions[activityTypeKey].timeslots)
      : [];

    return timeslots.filter((v) => activityTimeslots.includes(v.id));
  }, [activityTypeOptions, areaActivityType, timeslots]);

  const selectedTimeslot = useMemo(() => {
    return timeslotsAvailable.find((v) => v.id === timeslotUid);
  }, [timeslots, timeslotUid, timeslotsAvailable]);

  function handleTimeslotChange(timeslotUid) {
    setTimeslotUid(timeslotUid);
  }

  function onCloseDialog() {
    const path = backPath ?? __r(PLANNER_PERIOD_PLANNING_PAGE, { periodId });

    navigate(path, {
      state: {
        refresh: true,
      },
    });
  }

  function setIgnoreTravelTimeCb(value: boolean) {
    helpers.setIsDirty(true);
    setIgnoreTravelTime(value);
  }

  async function doSubmitCb() {
    if (state.isDirty) {
      await doSubmit();
    }

    onCloseDialog();
  }

  useEffect(() => {
    if (
      !timeslotUid &&
      !isEmpty(timeslotsAvailable) &&
      timeslotsAvailable.length === 1
    ) {
      setTimeslotUid(timeslotsAvailable[0].id);
    }
  }, [timeslotsAvailable, timeslotUid]);

  return {
    activityTypeOptions,
    areaActivityId,
    areaActivityType,
    date,
    datePickerHint,
    doSubmit: doSubmitCb,
    handleTimeslotChange,
    notAfterDate,
    notBeforeDate,
    onCloseDialog,
    period,
    // plannerContext,
    selectedTimeslot,
    shift,
    shiftIsLoading,
    timeslotUid,
    timeslots,
    timeslotsAvailable,
    timeslotsIsLoading,
    setDate: setDateCb,
    submitContext,
    // isDirty,
    state,
    dispatch,
    helpers,
    ignoreTravelTime,
    setIgnoreTravelTime: setIgnoreTravelTimeCb,
  };
}
