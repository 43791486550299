import React, { useContext, useEffect } from "react";
import { GaiaMapContext } from "./GaiaMap";
import { GaiaMapSourceType, GaiaMapStyles } from "./GaiaMapSources";
import { getGeoJsonBoundingBox } from "@/lib/functions";

type GaiaMapSourceProps = {
    sourceType: GaiaMapSourceType,
    data: GeoJSON.Feature<GeoJSON.Geometry> | GeoJSON.FeatureCollection<GeoJSON.Geometry> | null,
    shouldFitBounds: boolean,
}

function GaiaMapSource(
    {sourceType, data, shouldFitBounds}: GaiaMapSourceProps,
) {
    const { mapRef, isMapLoaded } = useContext(GaiaMapContext)

    useEffect(() => {
        const map = mapRef?.current
        if (map != null && isMapLoaded) {
            const mapSource = map.getSource(`gaia-${sourceType}`)
            if (mapSource == null) {
                GaiaMapStyles[sourceType](map, () => {
                    const mapSource = map.getSource(`gaia-${sourceType}`)
                    if (mapSource.type === "geojson") {
                        mapSource.setData(data || "{}")
                    }
                })
            } else {
                let mapSource = map.getSource(`gaia-${sourceType}`)
                if (mapSource.type === "geojson") {
                    mapSource.setData(data || "{}")
                }
            }
        }
    }, [mapRef, isMapLoaded, data, sourceType])

    // Center map on data
    useEffect(() => {
        const map = mapRef?.current
        if (map != null && isMapLoaded && data != null && shouldFitBounds) {
            const boundingBox = getGeoJsonBoundingBox(data);

            map.fitBounds([
                [boundingBox.xMin - 0.0005, boundingBox.yMin - 0.0005],
                [boundingBox.xMax + 0.0005, boundingBox.yMax + 0.0005],
            ]);
        }
    }, [mapRef, data, isMapLoaded, shouldFitBounds])

    return (<></>)
}

export default GaiaMapSource
