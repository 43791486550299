import { Heading, Pane } from "evergreen-ui";
import DatePicker from "./DatePicker";
import { interpolateMinutes } from "../../lib/functions";

function TimePicker({ value, setValue }) {
  function setHoursCb(hours) {
    setValue(new Date(value.setHours(hours)));
  }

  function setMinutesCb(minutes) {
    setValue(new Date(value.setMinutes(minutes)));
  }

  return (
    <Pane className="flex">
      <Pane className="mt-2 rounded-lg border bg-white p-5">
        <Pane className="flex">
          <select
            name="hours"
            value={value?.getHours()}
            onChange={(e) => setHoursCb(e.target.value)}
            className="appearance-none bg-transparent text-xl outline-none"
          >
            <option value={0}>00</option>
            {Array.from({ length: 23 }, (_, i) => i + 1).map((i) => (
              <option value={i}>{i < 10 ? `0${i}` : i}</option>
            ))}
          </select>
          <span className="mr-3 text-xl">:</span>
          <select
            name="minutes"
            value={interpolateMinutes(value?.getMinutes())}
            onChange={(e) => setMinutesCb(e.target.value)}
            className="appearance-none bg-transparent text-xl outline-none"
          >
            <option value={0}>00</option>
            {Array.from({ length: 12 }, (_, i) => i).map((i) => (
              // <option value={i * 5}>{i * 5}</option>
              <option value={i * 5}>{i * 5 < 10 ? `0${i * 5}` : i * 5}</option>
            ))}
          </select>
        </Pane>
      </Pane>
    </Pane>
  );
}

export default function DateTimeRangeInput({
  fromValue,
  setFromValue,
  toValue,
  setToValue,
  ...rest
}) {
  return (
    <Pane className="flex flex-wrap gap-1">
      <Pane>
        <Heading size={100}>Begintijd:</Heading>
        <DatePicker
          dateValue={fromValue}
          setDateValue={setFromValue}
          {...rest}
        />
        <TimePicker value={fromValue} setValue={setFromValue} />
      </Pane>
      <Pane>
        <Heading size={100}>Eindtijd:</Heading>
        <DatePicker dateValue={toValue} setDateValue={setToValue} {...rest} />
        <TimePicker value={toValue} setValue={setToValue} />
      </Pane>
    </Pane>
  );
}
