import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import Layout from "./components/layouts/Layout";
import LoginPage from "./pages/login/LoginPage";
import { useAuth } from "./context/AuthContext";
import { HasChildren } from "./providers/AuthProvider";
import UnAuthLayout from "./components/layouts/UnAuthLayout";
import UsersPage from "./pages/users/UsersPage";
import ProjectsPage from "./pages/projects/ProjectsPage";
import ProjectsEditPage from "./pages/projects/ProjectsEditPage";
import ProjectLayout from "./components/layouts/ProjectLayout";
import ActivityTypesPage from "./pages/projects/activity-types/ActivityTypesPage";
import ActivityTypesEditPage from "./pages/projects/activity-types/ActivityTypeEditPage";
import { useApp } from "./context/AppContext";
import { Pane, Paragraph, Spinner } from "evergreen-ui";
import UsersEditPage from "./pages/users/UserEditPage";
import AccountSettingsEditPage from "./pages/account-settings/AccountSettingsEditPage";
import UsersLayout from "./components/layouts/UsersLayout";
import InvitesPage from "./pages/users/Invites/InvitesPage";
import InviteEditPage from "./pages/users/Invites/InviteEditPage";
import WorkerAvailabilityPage from "./pages/worker-availability/WorkerAvailabilityPage";
import WorkerPeriodsPage from "./pages/worker-periods/WorkerPeriodsPage";
import WorkerShiftsListPage from "./pages/worker-shifts/WorkerShiftsListPage";
import OnBoardingLayout from "./components/layouts/OnBoardingLayout";
import RegisterTokenForm from "./pages/onboarding/RegisterTokenForm";
import PeriodEditPage from "./pages/planner/periods/PeriodEditPage";
import WorkerDetailsOnBoardingForm from "./pages/onboarding/WorkerDetailsOnBoardingForm";
import PlannerLayout from "./components/layouts/PlannerLayout";
import PlannerPage from "./pages/planner/PlannerPage";
import PeriodsListPage from "./pages/planner/PeriodsListPage";
import PlannerDialog from "./pages/planner/_components/PlannerDialog";
import AdminWorkerPeriodsPage from "./pages/users/periods/AdminWorkerPeriodsPage";
import AdminWorkerAvailabilityPage from "./pages/users/availability/AdminWorkerAvailabilityPage";

import {
  ADMIN_ACTIVITY_TYPE_ADD_PAGE,
  ADMIN_ACTIVITY_TYPES_PAGE,
  ADMIN_BOOKKEEPING_EXPORT_PAGE,
  ADMIN_CLIENT_ACTIVITY_ADD_PAGE,
  ADMIN_CLIENT_ACTIVITY_EDIT_PAGE,
  ADMIN_CLIENT_ACTIVITY_PAGE,
  ADMIN_OBJECTS_PAGE,
  ADMIN_PROJECT_ADD_PAGE,
  ADMIN_PROJECT_SETTINGS_PAGE,
  ADMIN_PROJECTS_PAGE,
  ADMIN_USERS_EDIT_PAGE,
  ADMIN_USERS_PAGE,
  ADMIN_WORKER_AVAILABILITY_PAGE,
  ADMIN_WORKER_PERIODS_PAGE,
  ADMIN_WORKER_SHIFT_HISTORY_PAGE,
  ADMIN_AREA_ADD_PAGE,
  ADMIN_AREA_EDIT_PAGE,
  PLANNER_AVAILABILITY_ADD_PAGE,
  PLANNER_AVAILABILITY_LAYOUT,
  PLANNER_AVAILABILITY_REQUEST_EDIT_PAGE,
  PLANNER_AVAILABILITY_REQUEST_PROGRESS_PAGE,
  PLANNER_PERIOD_PLANNING_PAGE,
  PLANNER_PERIOD_PLANNING_ROUNDS_PAGE,
  PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_ADD_OVERLAY,
  PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_OVERLAY,
  PLANNER_PERIOD_PLANNING_WORKER_SHIFT_REQUEST_OVERLAY,
  REPORTS_DETAIL_PAGE,
  REPORTS_LAYOUT,
  REPORTS_NEW_LIST_PAGE,
  REPORTS_RESULTS_PAGE,
  WORKER_PERIODS,
  WORKER_PERIODS_AVAILABILITY,
  WORKER_SHIFTS_CHANGES_PAGE,
  WORKER_SHIFTS_DETAIL_LAYOUT,
  WORKER_SHIFTS_DETAIL_PAGE,
  WORKER_SHIFTS_HISTORY_PAGE,
  WORKER_SHIFTS_LAYOUT,
  WORKER_SHIFTS_GO_TO_APP_PAGE,
  WORKER_SHIFTS_PLANNED_PAGE,
  WORKER_SHIFTS_REPORT_PAGE,
  ADMIN_OBJECT_EDIT_PAGE,
  ADMIN_ACTIVITY_TYPE_EDIT_PAGE,
  WORKER_SHIFTS_OBSERVATIONS_PAGE,
  ADMIN_SUBMITTED_SHIFTS_LAYOUT,
  ADMIN_SUBMITTED_SHIFTS_LIST_PAGE,
  ADMIN_SUBMITTED_SHIFTS_DETAIL_PAGE,
  ADMIN_BOOKKEEPING_EXPORT_LIST_PAGE,
  ADMIN_BOOKKEEPING_LAYOUT,
  ADMIN_PROJECT_EXPORT_PAGE,
  SHARE_EXPORT_PAGE,
  ADMIN_PROJECT_REPORTS,
  ADMIN_PROJECT_REPORT,
  ADMIN_PROJECT_REPORT_ADD,
  ADMIN_PROJECT_REPORT_EDIT,
} from "./RouteMap";

import PeriodProgressPage from "./pages/planner/periods/progress/PeriodProgressPage";
import WorkerShiftDetailPage from "./pages/worker-shifts/WorkerShiftDetailPage";
import WorkerShiftDetailsLayout from "./components/layouts/WorkerShiftDetailsLayout";
import WorkerShiftReportPage from "./pages/worker-shifts/WorkerShiftReportPage";
import WorkerShiftChangesPage from "./pages/worker-shifts/WorkerShiftChangesPage";
import WorkerShiftRequestDialog from "./pages/planner/_components/WorkerShiftRequestDialog";
import WorkerShiftsLayout from "./components/layouts/WorkerShiftsLayout";
import ReportsLayout from "./components/layouts/ReportsLayout";
import ShiftReportsListPage from "./pages/reports/ShiftReportsListPage";
import ShiftReportDetailPage from "./pages/reports/ShiftReportDetailPage";
import ShiftResultsPage from "./pages/reports/ShiftResultsPage";
import BookkeepingLayout from "./components/layouts/BookkeepingLayout";
import BookkeepingExportListPage from "./pages/bookkeeping/BookkeepingExportListPage";
import BookkeepingExportPage from "./pages/bookkeeping/BookkeepingExportPage";
import AdminWorkerShiftHistoryPage from "./pages/users/shift-history/AdminWorkerShiftHIstoryPage";
import WorkerShiftsHistoryPage from "./pages/worker-shifts/WorkerShiftsHistoryPage";
import ClientActivityTypesPage from "./pages/projects/client-activities/ClientActivityPage";
import ClientActivityEditPage from "./pages/projects/client-activities/ClientActivityEditPage";
import ObjectEditPage from "./pages/projects/objects/ObjectEditPage";
import AreaAddPage from "@/pages/projects/areas/AreaAddPage";
import WorkerShiftObservationsPage from "@/pages/worker-shifts/WorkerShiftObservationsPage";
import SubmittedWorkerShiftsLayout from "@/components/layouts/SubmittedWorkerShiftsLayout";
import SubmittedWorkerShiftsListPage from "@/pages/submitted-worker-shifts/SubmittedWorkerShiftsListPage";
import SubmittedWorkerShiftDetailPage from "@/pages/submitted-worker-shifts/SubmittedWorkerShiftDetailPage";
import WorkerGoToAppPage from "./pages/worker-shifts/WorkerGoToAppPage";
import ProjectsExportPage from "./pages/projects/ProjectsExportPage";
import ShareExportPage from "./pages/share/ShareExportPage";
import ReportListPage from "./pages/projects/reports/ReportListPage";
import ReportPage from "./pages/projects/reports/ReportEditPage";

export const history = createBrowserHistory();

export default function AppRoutes() {
  const app = useApp();
  const { user } = useAuth();

  if (!app.initialized) {
    return (
      <Pane
        className={
          "fixed top-0 left-0 mx-auto flex min-h-screen w-[100vw] items-center justify-center bg-gray-400 bg-opacity-10"
        }
      >
        <Pane className="my-20 flex max-w-sm flex-col items-center justify-center">
          <Spinner size={50} />
          <Paragraph>Loading Portal...</Paragraph>
        </Pane>
      </Pane>
    );
  }

  return (
    <Routes>
      <Route path={SHARE_EXPORT_PAGE} element={<ShareExportPage />} />

      {!user && (
        <Route path="/">
          <Route element={<UnAuthLayout />}>
            <Route index path="/" element={<RouteDecider />} />
            <Route path="/login" element={<LoginPage />} />
          </Route>

          <Route path="/worker-onboarding/*" element={<OnBoardingLayout />}>
            <Route index element={<RegisterTokenForm />} />
          </Route>

          <Route path="*" element={<RouteDecider />} />
        </Route>
      )}

      {user?.role === "worker" && !user?.worker?.isActive && (
        <Route path="/" element={<OnBoardingLayout />}>
          <Route index element={<WorkerDetailsOnBoardingForm />} />
          <Route path="*" element={<RouteDecider />} />
        </Route>
      )}

      {user?.role === "worker" && user?.worker?.isActive && (
        <Route element={<Layout />}>
          <Route
            path={WORKER_PERIODS}
            element={
              <AuthRoute>
                <WorkerPeriodsPage />
              </AuthRoute>
            }
          />
          <Route
            path={WORKER_PERIODS_AVAILABILITY}
            element={
              <AuthRoute>
                <WorkerAvailabilityPage />
              </AuthRoute>
            }
          />

          <Route path={WORKER_SHIFTS_LAYOUT} element={<WorkerShiftsLayout />}>
            <Route
              path={WORKER_SHIFTS_GO_TO_APP_PAGE}
              element={
                <AuthRoute>
                  <WorkerGoToAppPage />
                </AuthRoute>
              }
            />
            <Route
              path={WORKER_SHIFTS_PLANNED_PAGE}
              element={
                <AuthRoute>
                  <WorkerShiftsListPage />
                </AuthRoute>
              }
            />
            <Route
              path={WORKER_SHIFTS_HISTORY_PAGE}
              element={
                <AuthRoute>
                  <WorkerShiftsHistoryPage />
                </AuthRoute>
              }
            />
          </Route>

          <Route
            path={WORKER_SHIFTS_DETAIL_LAYOUT}
            element={<WorkerShiftDetailsLayout />}
          >
            <Route
              path={WORKER_SHIFTS_DETAIL_PAGE}
              element={
                <AuthRoute>
                  <WorkerShiftDetailPage />
                </AuthRoute>
              }
            />
            <Route
              path={WORKER_SHIFTS_REPORT_PAGE}
              element={
                <AuthRoute>
                  <WorkerShiftReportPage />
                </AuthRoute>
              }
            />
            <Route
              path={WORKER_SHIFTS_CHANGES_PAGE}
              element={
                <AuthRoute>
                  <WorkerShiftChangesPage />
                </AuthRoute>
              }
            />
            <Route
              path={WORKER_SHIFTS_OBSERVATIONS_PAGE}
              element={
                <AuthRoute>
                  <WorkerShiftObservationsPage />
                </AuthRoute>
              }
            />
          </Route>
          <Route
            path="/account-settings"
            element={
              <AuthRoute>
                <AccountSettingsEditPage />
              </AuthRoute>
            }
          />
          <Route path="*" element={<RouteDecider />} />
        </Route>
      )}

      {user?.role === "admin" && (
        <Route element={<Layout />}>
          <Route path="/admin" element={<UsersLayout />}>
            <Route
              index
              path={ADMIN_USERS_PAGE}
              element={
                <AuthRoute>
                  <UsersPage />
                </AuthRoute>
              }
            />

            <Route
              path={ADMIN_USERS_EDIT_PAGE}
              element={
                <AuthRoute>
                  <UsersEditPage />
                </AuthRoute>
              }
            />

            <Route
              path={ADMIN_WORKER_PERIODS_PAGE}
              element={
                <AuthRoute>
                  <AdminWorkerPeriodsPage />
                </AuthRoute>
              }
            />
            <Route
              path={ADMIN_WORKER_SHIFT_HISTORY_PAGE}
              element={
                <AuthRoute>
                  <AdminWorkerShiftHistoryPage />
                </AuthRoute>
              }
            />
            <Route
              path={ADMIN_WORKER_AVAILABILITY_PAGE}
              element={
                <AuthRoute>
                  <AdminWorkerAvailabilityPage />
                </AuthRoute>
              }
            />

            <Route
              path="/admin/invites"
              element={
                <AuthRoute>
                  <InvitesPage />
                </AuthRoute>
              }
            />
            <Route
              path="/admin/invites/:inviteId"
              element={
                <AuthRoute>
                  <InviteEditPage />
                </AuthRoute>
              }
            />
            {/*<Route path="/admin/availability-requests" element={<AuthRoute><AvailabilityRequestsPage /></AuthRoute>}/>*/}
            {/*<Route path="/admin/availability-requests/:periodId" element={<AuthRoute><AvailabilityRequestEditPage /></AuthRoute>}/>*/}
            {/*<Route path="/admin/availability-requests/add" element={<AuthRoute><AvailabilityRequestEditPage /></AuthRoute>}/>*/}
          </Route>

          <Route path="/planner">
            <Route
              index
              element={
                <AuthRoute>
                  <PeriodsListPage />
                </AuthRoute>
              }
            />
            <Route
              path={PLANNER_AVAILABILITY_ADD_PAGE}
              element={
                <AuthRoute>
                  <PeriodEditPage />
                </AuthRoute>
              }
            />
          </Route>

          <Route
            path={ADMIN_SUBMITTED_SHIFTS_LAYOUT}
            element={<SubmittedWorkerShiftsLayout />}
          >
            <Route
              path={ADMIN_SUBMITTED_SHIFTS_LIST_PAGE}
              element={
                <AuthRoute>
                  <SubmittedWorkerShiftsListPage />
                </AuthRoute>
              }
            />
            <Route
              path={ADMIN_SUBMITTED_SHIFTS_DETAIL_PAGE}
              element={
                <AuthRoute>
                  <SubmittedWorkerShiftDetailPage />
                </AuthRoute>
              }
            />
          </Route>

          <Route path={REPORTS_LAYOUT} element={<ReportsLayout />}>
            <Route
              index
              path={REPORTS_NEW_LIST_PAGE}
              element={
                <AuthRoute>
                  <ShiftReportsListPage />
                </AuthRoute>
              }
            />
            <Route
              index
              path={REPORTS_DETAIL_PAGE}
              element={
                <AuthRoute>
                  <ShiftReportDetailPage />
                </AuthRoute>
              }
            />
            <Route
              index
              path={REPORTS_RESULTS_PAGE}
              element={
                <AuthRoute>
                  <ShiftResultsPage />
                </AuthRoute>
              }
            />
          </Route>

          <Route
            path={ADMIN_BOOKKEEPING_LAYOUT}
            element={<BookkeepingLayout />}
          >
            <Route
              index
              path={ADMIN_BOOKKEEPING_EXPORT_LIST_PAGE}
              element={
                <AuthRoute>
                  <BookkeepingExportListPage />
                </AuthRoute>
              }
            />
            <Route
              index
              path={ADMIN_BOOKKEEPING_EXPORT_PAGE}
              element={
                <AuthRoute>
                  <BookkeepingExportPage />
                </AuthRoute>
              }
            />
          </Route>

          <Route path={PLANNER_AVAILABILITY_LAYOUT} element={<PlannerLayout />}>
            <Route
              index
              path={PLANNER_AVAILABILITY_REQUEST_PROGRESS_PAGE}
              element={
                <AuthRoute>
                  <PeriodProgressPage />
                </AuthRoute>
              }
            />
            <Route
              path={PLANNER_AVAILABILITY_REQUEST_EDIT_PAGE}
              element={
                <AuthRoute>
                  <PeriodEditPage />
                </AuthRoute>
              }
            />

            <Route
              path={PLANNER_PERIOD_PLANNING_ROUNDS_PAGE}
              element={
                <AuthRoute>
                  <PlannerPage />
                </AuthRoute>
              }
            >
              <Route
                path={PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_ADD_OVERLAY}
                element={
                  <AuthRoute>
                    <PlannerDialog />
                  </AuthRoute>
                }
              />
              <Route
                path={PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_OVERLAY}
                element={
                  <AuthRoute>
                    <PlannerDialog />
                  </AuthRoute>
                }
              />
              <Route
                path={PLANNER_PERIOD_PLANNING_WORKER_SHIFT_REQUEST_OVERLAY}
                element={
                  <AuthRoute>
                    <WorkerShiftRequestDialog />
                  </AuthRoute>
                }
              />
            </Route>

            <Route
              path={PLANNER_PERIOD_PLANNING_PAGE}
              element={
                <AuthRoute>
                  <PlannerPage />
                </AuthRoute>
              }
            >
              <Route
                path={PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_ADD_OVERLAY}
                element={
                  <AuthRoute>
                    <PlannerDialog />
                  </AuthRoute>
                }
              />
              <Route
                path={PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_OVERLAY}
                element={
                  <AuthRoute>
                    <PlannerDialog />
                  </AuthRoute>
                }
              />
            </Route>
          </Route>

          <Route
            path="/account-settings"
            element={
              <AuthRoute>
                <AccountSettingsEditPage />
              </AuthRoute>
            }
          />
          <Route
            path={ADMIN_PROJECTS_PAGE}
            element={
              <AuthRoute>
                <ProjectsPage />
              </AuthRoute>
            }
          />
          <Route
            path={ADMIN_PROJECT_ADD_PAGE}
            element={
              <AuthRoute>
                <ProjectsEditPage />
              </AuthRoute>
            }
          />

          <Route path="/projects/:projectId" element={<ProjectLayout />}>
            <Route
              index
              path={ADMIN_AREA_ADD_PAGE}
              element={
                <AuthRoute>
                  <AreaAddPage />
                </AuthRoute>
              }
            />
            <Route
              path={ADMIN_AREA_EDIT_PAGE}
              element={
                <AuthRoute>
                  <AreaAddPage />
                </AuthRoute>
              }
            />
            <Route
              path={ADMIN_PROJECT_SETTINGS_PAGE}
              element={
                <AuthRoute>
                  <ProjectsEditPage />
                </AuthRoute>
              }
            />
            <Route
              path={ADMIN_PROJECT_EXPORT_PAGE}
              element={
                <AuthRoute>
                  <ProjectsExportPage />
                </AuthRoute>
              }
            />
            {/*<Route path="/projects/:projectId/areas/add" element={<AuthRoute><AreaEditPage/></AuthRoute>}/>*/}
            <Route
              path={ADMIN_ACTIVITY_TYPES_PAGE}
              element={
                <AuthRoute>
                  <ActivityTypesPage />
                </AuthRoute>
              }
            />
            <Route
              path={ADMIN_ACTIVITY_TYPE_ADD_PAGE}
              element={
                <AuthRoute>
                  <ActivityTypesEditPage />
                </AuthRoute>
              }
            />
            <Route
              path={ADMIN_PROJECT_REPORTS}
              element={
                <AuthRoute>
                  <ReportListPage />
                </AuthRoute>
              }
            />
            <Route
              path={ADMIN_PROJECT_REPORT}
              element={<AuthRoute>{<ReportPage />}</AuthRoute>}
            />
            <Route
              path={ADMIN_PROJECT_REPORT_EDIT}
              element={<AuthRoute>{<ReportPage />}</AuthRoute>}
            />
            <Route
              path={ADMIN_ACTIVITY_TYPE_EDIT_PAGE}
              element={
                <AuthRoute>
                  <ActivityTypesEditPage />
                </AuthRoute>
              }
            />
            <Route
              path={ADMIN_CLIENT_ACTIVITY_PAGE}
              element={
                <AuthRoute>
                  <ClientActivityTypesPage />
                </AuthRoute>
              }
            />
            <Route
              path={ADMIN_CLIENT_ACTIVITY_ADD_PAGE}
              element={
                <AuthRoute>
                  <ClientActivityEditPage></ClientActivityEditPage>
                </AuthRoute>
              }
            />
            <Route
              path={ADMIN_CLIENT_ACTIVITY_EDIT_PAGE}
              element={
                <AuthRoute>
                  <ClientActivityEditPage></ClientActivityEditPage>
                </AuthRoute>
              }
            />

            <Route
              path={ADMIN_OBJECTS_PAGE}
              element={
                <AuthRoute>
                  <ObjectEditPage />
                </AuthRoute>
              }
            />
            <Route
              path={ADMIN_OBJECT_EDIT_PAGE}
              element={
                <AuthRoute>
                  <ObjectEditPage />
                </AuthRoute>
              }
            />
          </Route>
          <Route path="*" element={<RouteDecider />} />
        </Route>
      )}
    </Routes>
  );
}

function AuthRoute({ children }: HasChildren) {
  return <RequireAuth>{children}</RequireAuth>;
}

function RouteDecider() {
  const { user } = useAuth();
  const location = useLocation();

  if (user) {
    if (user.role === "worker" && !user.worker?.isActive) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }

    if (user.role === "worker") {
      return (
        <Navigate to={WORKER_PERIODS} state={{ from: location }} replace />
      );
    }

    if (user.role === "admin") {
      return <Navigate to="/planner" state={{ from: location }} replace />;
    }

    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
}

function RequireAuth({ children }: HasChildren) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.jwt) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
