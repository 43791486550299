import { useLoadResource } from "src/lib/request-hooks";
import { useApi } from "../context/AxiosContext";
import { useState } from "react";
import { Media, ShiftReport } from "../types/apiTypes";
import { useParams } from "react-router-dom";

export default function useShiftReport() {
  const { shiftId } = useParams();
  const { apiInstance } = useApi();
  const [shiftReport, setShiftReport] = useState<ShiftReport | null>(null);
  const [mediaFiles, setMediaFiles] = useState<Media[]>([]);

  const find = () => apiInstance!.workerShiftReport.findOneForShift(shiftId!);

  function setData(report) {
    setShiftReport(report);
    setMediaFiles(report.media);
  }

  return {
    ...useLoadResource(find, setData, !!shiftId),
    shiftReport,
  };
}
