import { Context, createContext, useReducer } from "react";

import { Carpool, WorkerApiType } from "src/types/apiTypes";

const plannerDialogReducer = (state, action) => {
  switch (action.type) {
    case "doAssignWorker": {
      const { worker } = action.payload;
      const attachedWorkers = [...(state.attachedWorkers ?? []), worker];

      return {
        ...state,
        attachedWorkers: attachedWorkers,
        isDirty: true,
      };
    }
    case "doUnAssignWorker": {
      const { worker: workerToRemove } = action.payload;

      const attachedWorkers = state.attachedWorkers.filter(
        (v) => v.id !== workerToRemove.id,
      );

      return {
        ...state,
        attachedWorkers: attachedWorkers,
        isDirty: true,
      };
    }
    case "updateWorker": {
      const { worker, data } = action.payload;

      const workerInAttachedIndex = state.attachedWorkers.findIndex(
        (v) => v.id === worker.id,
      );
      let workerInAttached = state.attachedWorkers[workerInAttachedIndex];

      if (workerInAttached) {
        workerInAttached = { ...workerInAttached, ...data };
        state.attachedWorkers[workerInAttachedIndex] = workerInAttached;
      }

      return {
        ...state,
        attachedWorkers: [...state.attachedWorkers],
        isDirty: true,
      };
    }
    case "removeWorkerFromCarpool": {
      const { carpoolId, worker } = action.payload;

      let carpool = state.carpoolsAvailable.find((v) => v.id === carpoolId);

      // filter the worker list if the worker is already in there.
      const workers = carpool.workers.filter((v) => v.id === worker.id);
      carpool.workers = workers.concat(worker);

      const carpoolsAvailable = [...state.carpoolsAvailable];

      return {
        ...state,
        carpoolsAvailable: carpoolsAvailable,
        isDirty: true,
      };
    }
    case "addWorkerToCarpool": {
      const { driverId, carpoolId, worker } = action.payload;

      let carpool = state.carpoolsAvailable.find((v) => v.id === carpoolId);

      if (!carpool && driverId && !carpoolId) {
        carpool = {
          driverId: driverId,
          workers: [],
        };
      }

      carpool.workers = carpool.workers.concat(worker);

      const carpoolsAvailable = [...state.carpoolsAvailable];

      return {
        ...state,
        carpoolsAvailable: carpoolsAvailable,
        isDirty: true,
      };
    }
    case "setCarpoolsAvailable": {
      return { ...state, carpoolsAvailable: action.payload };
    }
    case "setAttachedWorkers": {
      return { ...state, attachedWorkers: action.payload };
    }
    case "setFilterLogic":
      return { ...state, filterLogic: action.payload };
    case "setSelectedDriverId":
      return { ...state, selectedDriverId: action.payload };
    case "setSelectedCarpoolId":
      return { ...state, selectedCarpoolId: action.payload };
    case "setPopupShownId":
      return { ...state, popupShownId: action.payload };
    case "setIsDirty":
      return { ...state, isDirty: action.payload };
    default:
      return state;
  }
};

export default function usePlannerDialogReducer() {
  const [state, dispatch] = useReducer(plannerDialogReducer, {
    attachedWorkers: [],
    carpoolsAvailable: [],
    filterLogic: {},
    selectedDriverId: null,
    selectedCarpoolId: null,
    popupShownId: null,
    setIgnoreTravelTime: false,
  });

  function setAttachedWorkers(attachedWorkers: WorkerApiType[]) {
    dispatch({ type: "setAttachedWorkers", payload: attachedWorkers });
  }

  function setCarpoolsAvailable(carpoolsAvailable: Carpool[]) {
    dispatch({ type: "setCarpoolsAvailable", payload: carpoolsAvailable });
  }

  function setFilterLogic(filterLogic: any) {
    dispatch({ type: "setFilterLogic", payload: filterLogic });
  }

  function setSelectedDriverId(selectedDriverId: number | null) {
    dispatch({ type: "setSelectedDriverId", payload: selectedDriverId });
  }

  function setSelectedCarpoolId(selectedCarpoolId: number | null) {
    dispatch({ type: "setSelectedCarpoolId", payload: selectedCarpoolId });
  }

  function setPopupShownId(popupShownId: number | null) {
    dispatch({ type: "setPopupShownId", payload: popupShownId });
  }

  function doAssignWorker(worker: WorkerApiType, data: any | undefined) {
    const canBeDriver = worker.hasDrivingLicense && worker.hasTransport;
    const isDriving =
      canBeDriver &&
      (state.selectedDriverId !== worker.id || !state.selectedCarpoolId);

    const updatedWorker = {
      ...worker,
      driverId: state.selectedDriverId || (canBeDriver ? worker.id : null),
      carpoolId: state.selectedCarpoolId || null,
      isDriving: isDriving,
    };

    dispatch({
      type: "doAssignWorker",
      payload: {
        worker: updatedWorker,
      },
    });
  }

  function doUnAssignWorker(worker: WorkerApiType) {
    dispatch({
      type: "doUnAssignWorker",
      payload: {
        worker,
      },
    });
  }

  function updateWorker(worker: WorkerApiType, data: any) {
    dispatch({
      type: "updateWorker",
      payload: {
        worker,
        data,
      },
    });
  }

  function toggleIsDriving(worker: WorkerApiType) {
    const isDrivingToggled = !worker.isDriving;

    dispatch({
      type: "updateWorker",
      payload: {
        worker,
        data: {
          isDriving: isDrivingToggled,
          driverId: isDrivingToggled ? worker.id : null,
          carpoolId: state.selectedCarpoolId || null,
        },
      },
    });
  }

  function addWorkerToCarpool(
    worker: WorkerApiType,
    driverId?: number | null,
    carpoolId?: number | null,
  ) {
    dispatch!({
      type: "updateWorker",
      payload: {
        worker,
        data: {
          isDriving: false,
          driverId: driverId,
          carpoolId: carpoolId,
        },
      },
    });
  }

  function removeWorkerFromCarpool(
    worker: WorkerApiType,
    driverId?: number | null,
    carpoolId?: number | null,
  ) {
    dispatch!({
      type: "removeWorkerFromCarpool",
      payload: {
        worker,
        carpoolId: carpoolId,
        driverId: driverId,
      },
    });
  }

  function setDriver(
    worker: WorkerApiType,
    driverId: number | null,
    carpoolId?: number | null,
  ) {
    dispatch!({
      type: "updateWorker",
      payload: {
        worker,
        data: {
          isDriving: false,
          driverId: driverId,
          carpoolId: carpoolId,
        },
      },
    });
  }

  function setIsDirty(isDirty: boolean) {
    dispatch({ type: "setIsDirty", payload: isDirty });
  }

  return {
    state,
    dispatch,
    helpers: {
      setIsDirty,
      setAttachedWorkers,
      setCarpoolsAvailable,
      setFilterLogic,
      setSelectedDriverId,
      setSelectedCarpoolId,
      doAssignWorker,
      doUnAssignWorker,
      updateWorker,
      setPopupShownId,
      toggleIsDriving,
      addWorkerToCarpool,
      removeWorkerFromCarpool,
    },
  };
}
