import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { Id, Shift } from "@/types/apiTypes";
import { PostData, SearchParams } from "@/types/appTypes";

export default class AdminShifts extends BaseApi {
  findOne(id: Id): Promise<Shift> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/shifts/${id}`,
    });
  }

  findMany(searchParams: SearchParams = {}): Promise<Shift[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/shifts${query}`,
    });
  }

  create(data: PostData): Promise<Shift> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/shifts`,
      data: data,
    });
  }

  update(id: Id, data: PostData): Promise<Shift> {
    return doRequest(this._axiosInstance!, {
      method: "put",
      url: `/admin/shifts/${id}`,
      data: data,
    });
  }

  addChildShift(
    parentId: Id,
    areaActivityTypeId: Id,
    timeslotUid: string,
  ): Promise<Shift> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/shifts/${parentId}/add-child`,
      data: {
        areaActivityTypeId,
        timeslotUid,
      },
    });
  }

  removeChildShift(childId: Id): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/shifts/${childId}/remove-child`,
    });
  }

  removeWorker(id: Id, workerId: Id): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: "delete",
      url: `/admin/shifts/${id}/workers/${workerId}`,
    });
  }

  remove(id: Id): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: "delete",
      url: `/worker/availability/${id}`,
    });
  }
}
