import { useApi } from "@/context/AxiosContext";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useDoRequest } from "@/lib/request-hooks";
import { Id, ClientObject } from "@/types/apiTypes";
import { toaster } from "evergreen-ui";

export default function useObjectForm() {
  const { apiInstance } = useApi();
  const { projectId } = useParams();

  const [isDirty, setIsDirty] = useState(false);

  const {
    handle,
    errorMessage: submitErrorMessage,
    isLoading: isSubmitting,
    setValidationErrors,
    validationErrors,
  } = useDoRequest();

  const doUpdate = async (objectId: Id, data) => {
    const request = apiInstance!.adminObjects.update(objectId, data);
    const result = await handle(request);

    toaster.success("Saved!", {
      id: "success",
    });

    return result;
  };

  const doCreate = async (objectData) => {
    const request: Promise<ClientObject> =
      apiInstance!.adminObjects.createForProject(projectId!, objectData);

    const result = await handle(request);

    toaster.success("Saved!", {
      id: "success",
    });

    return result;
  };

  const uploadFile = async (objectId: Id, formData: FormData) => {
    const request = apiInstance!.adminObjects.uploadMedia(objectId, formData);

    return handle(request);
  };

  const removeFile = async (objectId: Id, mediaId: Id) => {
    const request = apiInstance!.adminObjects.removeFile(objectId, mediaId);

    const result = await handle(request);

    toaster.success("Removed!", {
      id: "removed",
    });

    return result;
  };

  function doRemove(objectId: Id) {
    if (window.confirm("Are you sure you want to delete this object?")) {
      return apiInstance!.adminObjects.remove(objectId!);
    }
  }

  return {
    isSubmitting,
    doRemove,
    doCreate,
    doUpdate,
    uploadMedia: uploadFile,
    removeMedia: removeFile,
    submitContext: {
      doSubmit: doUpdate,
      isSubmitting,
      submitErrorMessage,
      validationErrors,
      setValidationErrors,
      setIsDirty,
      isDirty,
    },
  };
}
