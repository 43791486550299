import Page from "src/components/common/Page";
import PageTitle from "src/components/common/PageTitle";
import { useNavigate } from "react-router-dom";
import StandardTable from "../../components/table/StandardTable";
import {
  Avatar,
  EmptyState,
  FilterIcon,
  Heading,
  majorScale,
  Pane,
  SearchIcon,
  Table,
  Text,
} from "evergreen-ui";
import DateValue from "../../components/common/DateValue";
import { FilterMapType, HookOptions } from "@/types/appTypes";
import { WorkerShift } from "@/types/apiTypes";
import { __r, ADMIN_SUBMITTED_SHIFTS_DETAIL_PAGE } from "@/RouteMap";
import { useApi } from "@/context/AxiosContext";
import { useDoFilter } from "@/hooks/useDoFilter";
import React, { createElement, useEffect, useState } from "react";
import { useLoadResource } from "@/lib/request-hooks";
import { useTranslation } from "react-i18next";
import { useWindowSearchParams } from "@/hooks/useWindowSearchParams";
import WorkerShiftFilters from "@/pages/submitted-worker-shifts/components/WorkerShiftFilters";
import {
  ShiftStatusColorMap,
  ShiftStatusIconMap,
} from "@/components/config/iconMaps";
import { parseISO } from "date-fns";

const statusColorMap = {
  published: "border-l-8 !border-l-green-400",
  isOpen: "border-l-4 !border-l-green-400 hover:!bg-blue-50",
  isLocked: "!bg-red-50 hover:!bg-blue-50",
  isPlannable: "border-l-8 !border-l-green-400 hover:!bg-blue-50",
  isNotFilledIn: "border-l-8 !border-l-orange-400 hover:!bg-blue-50",
  // published: '!bg-green-50 hover:!bg-blue-50',
  // isOpen: '!bg-blue-50 hover:!bg-blue-50',
  // isLocked: '!bg-red-50 hover:!bg-blue-50',
  // isPlannable: '!bg-green-50 hover:!bg-blue-50',
  // isNotFilledIn: "!bg-gray-100 hover:!bg-blue-50",
};

const useSubmittedWorkerShifts = function (options: HookOptions = {}) {
  const searchParams = useWindowSearchParams();
  const { apiInstance } = useApi();

  const [latLng, setLatLng] = useState<string | null>(null);
  const [status, setStatus] = useState<string | null>();

  const [initialized, setInitialized] = useState<boolean>(false);
  const [activityType, setActivityType] = useState<string | null>(null);
  const [workerId, setWorkerId] = useState<number | null>(null);
  const [projectId, setProjectId] = useState<number | null>(null);
  const [projectType, setProjectType] = useState<string | null>(null);
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  const [showDemo, setShowDemo] = useState<string | null>(null)

  const filterMap: FilterMapType = {
    address: {
      setter: setLatLng,
      topic: "latLng",
      value: latLng,
    },
    fromDate: {
      setter: setFromDate,
      topic: "fromDate",
      value: fromDate,
    },
    toDate: {
      setter: setToDate,
      topic: "toDate",
      value: toDate,
    },
    activityType: {
      setter: setActivityType,
      topic: "activityType",
      value: activityType,
    },
    workerId: {
      setter: setWorkerId,
      topic: "workerId",
      value: workerId,
      label: "Veldwerker",
    },
    status: {
      setter: setStatus,
      topic: "status",
      value: status,
      default: "submitted",
    },
    projectId: {
      setter: setProjectId,
      topic: "projectId",
      value: projectId,
    },
    projectType: {
      setter: setProjectType,
      topic: "projectType",
      value: projectId,
    },
    showDemo: {
      setter: setShowDemo,
      topic: "showDemo",
      value: showDemo,
    }
  };

  const { populate } = options;

  if (populate) {
    searchParams.set("populate", populate.join(","));
  }

  const [workerShifts, setWorkerShifts] = useState<WorkerShift[]>([]);
  const find = () => apiInstance!.adminWorkerShifts.findMany(searchParams);

  const setData = (workerShifts) => {
    setWorkerShifts(workerShifts);
  };

  const loadResourceLogic = useLoadResource(find, setData, initialized);

  const {
    fetchData,
    setDefaultFilters,
    setFilter,
    doSortBy,
    handleInput,
    doFilter,
  } = useDoFilter({
    searchParams,
    setInitialized,
    initialized,
    filterMap,
    loadResourceLogic,
    find,
    setData,
    setMeta: () => {},
  });

  useEffect(() => {
    if (!initialized) {
      setDefaultFilters();
    }
  }, []);

  return {
    ...loadResourceLogic,
    workerShifts,
    doFilter,
    doSortBy,
    fetchData,
    filterMap,
    handleInput,
    setData,
    setDefaultFilters,
    setFilter,
  };
};

export default function SubmittedWorkerShiftsListPage() {
  const { t } = useTranslation();

  const shiftsLogic = useSubmittedWorkerShifts();

  const { refreshCount, workerShifts, meta, isLoading, refresh } = shiftsLogic;

  const navigate = useNavigate();

  function navToReport(workerShift: WorkerShift) {
    navigate(
      __r(ADMIN_SUBMITTED_SHIFTS_DETAIL_PAGE, {
        shiftId: workerShift.id,
        workerId: workerShift.workerId,
      }),
      {
        state: {
          backPath: window.location.pathname + window.location.search,
        },
      },
    );
  }

  return (
    <Page>
      <PageTitle>Rapporten</PageTitle>

      <Pane className="flex flex-col gap-2">
        <Pane className="flex items-center gap-1">
          <FilterIcon color="muted" size={majorScale(2)} />
          <Heading size={300}>Filters</Heading>
        </Pane>

        <Pane className={"h-[1px] w-8 bg-gray-200"} />
        <WorkerShiftFilters filterLogic={shiftsLogic} />
        <Pane className={"h-[1px] w-8 bg-gray-200"} />
      </Pane>

      <Pane>
        <StandardTable
          minWidth={1100}
          meta={meta}
          refresh={refresh}
          isLoading={isLoading}
          data={workerShifts}
          emptyState={<EmptyWorkerShiftsState />}
        >
          <Table.Head paddingRight={0}>
            <Table.TextHeaderCell>Opdrachtgever</Table.TextHeaderCell>
            <Table.TextHeaderCell>Veldwerker</Table.TextHeaderCell>
            <Table.TextHeaderCell>Activiteit</Table.TextHeaderCell>
            <Table.TextHeaderCell>Geplanned op</Table.TextHeaderCell>
            <Table.TextHeaderCell>Weer</Table.TextHeaderCell>
            <Table.TextHeaderCell minWidth={150}>Status</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {workerShifts.map((workerShift) => (
              <Table.Row
                className={statusColorMap[workerShift.status]}
                key={
                  refreshCount +
                  workerShift.shift?.id +
                  "-" +
                  workerShift.workerId +
                  "-" +
                  workerShift.status
                }
                isSelectable
                onSelect={() => {
                  navToReport(workerShift);
                }}
              >
                <Table.TextCell>
                  {workerShift.shift?.project?.name}
                </Table.TextCell>
                <Table.TextCell>
                  <Pane className="flex items-center gap-2">
                    <Avatar
                      className="hover:opacity-90"
                      src={
                        workerShift.worker.user.profilePictureThumbnail ?? ""
                      }
                      name={""}
                      size={20}
                    />
                    <Text>
                      {workerShift.worker.user.firstName[0]}.{" "}
                      {workerShift.worker.user.lastName}
                    </Text>
                  </Pane>
                </Table.TextCell>
                <Table.TextCell>
                  <strong>{workerShift.shift.area?.name}</strong>
                  <br />
                  <Pane className="flex flex-wrap">
                    {workerShift.shift?.areaActivityTypes
                      .map((activityType) => activityType.name)
                      .join(", ")}
                  </Pane>
                </Table.TextCell>
                <Table.TextCell>
                  <DateValue date={workerShift.startAt} />
                </Table.TextCell>
                <Table.TextCell>
                  {workerShift.startedStatusUpdate?.answers
                    .start_shift__temperature || 0}
                  °C&nbsp;
                  {workerShift.startedStatusUpdate?.answers.start_shift__wind ||
                    0}
                  bft&nbsp;
                  {workerShift.startedStatusUpdate?.answers
                    .start_shift__precipitation || "-"}
                </Table.TextCell>
                <Table.TextCell minWidth={150}>
                  <Pane className="flex items-center gap-2">
                    {mapExpiredStatus(workerShift, (status) => (
                      <>
                        {ShiftStatusIconMap[status] &&
                          createElement(ShiftStatusIconMap[status], {
                            color: ShiftStatusColorMap[status],
                            size: 18,
                          })}
                        {t("shifts.status." + status)}{" "}
                      </>
                    ))}
                  </Pane>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </StandardTable>
      </Pane>
    </Page>
  );
}

function mapExpiredStatus(workerShift, onStatus) {
  const shiftEndTime = parseISO(workerShift.endAt);
  if (
    ["planned", "started", "stopped", "pending", "published"].includes(
      workerShift.status,
    ) &&
    shiftEndTime < new Date()
  ) {
    return onStatus("expired");
  } else {
    return onStatus(workerShift.status);
  }
}

function EmptyWorkerShiftsState() {
  return (
    <EmptyState
      background="light"
      title="Geen diensten gevonden"
      orientation="horizontal"
      icon={<SearchIcon color="#C1C4D6" />}
      iconBgColor="#EDEFF5"
      description="Het kan zijn dat een actieve filter ervoor zorgt dat je geen rapporten ziet, of dat er nog geen diensten zijn ingediend."
    />
  );
}
