import { Area, AreaGroup, AreaCheckbox } from "@/types/apiTypes";
import { Button, Checkbox, DeleteIcon, TextInput } from "evergreen-ui";
import { clone, sortBy } from "lodash";
import { useEffect, useState } from "react";

export const AreasListComponent = (props: {
  areas: Area[];
  handleAreaGroupsChange: (updatedAreaGroups: AreaGroup[]) => void;
}) => {
  function generateAreasCheckboxes(value: boolean): AreaCheckbox[] {
    const areas = props.areas.map((area, areaIndex) => ({
      index: areaIndex,
      area: area,
      isSelected: value,
    }));
    return sortBy(areas, [
      function (e) {
        return e.area.name;
      },
    ]);
  }

  const [areaCheckboxes, setAreasCheckboxes] = useState<AreaCheckbox[]>(
    generateAreasCheckboxes(false),
  );

  if (props.areas.length === 1) {
    return (
      <SingleArea
        {...props}
        areaCheckboxes={areaCheckboxes}
        setAreasCheckboxes={setAreasCheckboxes}
      />
    );
  }
  return (
    <MultiArea
      {...props}
      areaCheckboxes={areaCheckboxes}
      setAreasCheckboxes={setAreasCheckboxes}
      generateAreasCheckboxes={generateAreasCheckboxes}
    />
  );
};

const SingleArea = ({
  areaCheckboxes,
  setAreasCheckboxes,
  handleAreaGroupsChange,
}: {
  areaCheckboxes: AreaCheckbox[];
  setAreasCheckboxes: (updatedAreasCheckboxes: AreaCheckbox[]) => void;
  handleAreaGroupsChange: (updatedAreaGroups: AreaGroup[]) => void;
}) => {
  const [areaCheckbox] = areaCheckboxes;

  // Adding the only area to itself as a group
  const areaGroups = [
    {
      groupName: areaCheckbox.area.name,
      areas: areaCheckboxes,
    },
  ];

  useEffect(() => {
    handleAreaGroupsChange(areaGroups);
  }, []);

  function handleCheckboxChange(areaCheckboxes: AreaCheckbox[]) {
    let areaGroup = areaCheckboxes[0].isSelected ? areaGroups : [];
    setAreasCheckboxes(areaCheckboxes);
    handleAreaGroupsChange(areaGroup);
  }
  return (
    <Checkboxes
      areaCheckboxes={areaCheckboxes}
      setAreasCheckboxes={handleCheckboxChange}
    />
  );
};

const MultiArea = ({
  areaCheckboxes,
  setAreasCheckboxes,
  generateAreasCheckboxes,
  handleAreaGroupsChange,
}: {
  areaCheckboxes: AreaCheckbox[];
  setAreasCheckboxes: (updatedAreasCheckboxes: AreaCheckbox[]) => void;
  generateAreasCheckboxes(value: boolean): AreaCheckbox[];
  handleAreaGroupsChange: (updatedAreaGroups: AreaGroup[]) => void;
}) => {
  const [areaGroupName, setAreaGroupName] = useState("");
  const [areaGroups, setAreaGroups] = useState<AreaGroup[]>([]);

  function handleAddAreaGroup() {
    const selectedAreas = areaCheckboxes.filter((e) => e.isSelected);
    const updatedAreaGroups = [
      ...areaGroups,
      {
        groupName: areaGroupName,
        areas: selectedAreas,
      },
    ];

    handleUpdatedAreaGroups(updatedAreaGroups);
    reset();
  }

  function deleteAreaGroup(index: number) {
    const updatedAreaGroups = clone(areaGroups);

    updatedAreaGroups.splice(index, 1);
    handleUpdatedAreaGroups(updatedAreaGroups);
  }

  function reset() {
    setAreaGroupName("");
    setAreasCheckboxes(generateAreasCheckboxes(false));
  }

  function handleUpdatedAreaGroups(updatedAreaGroups: AreaGroup[]) {
    setAreaGroups(updatedAreaGroups);
    handleAreaGroupsChange(updatedAreaGroups);
  }

  return (
    <>
      <span className="text-lg text-zinc-700">
        Groepeer de gebieden in het rapport
      </span>
      <div>
        <TextInput
          className="mt-2"
          placeholder="Gebiedsnaam"
          value={areaGroupName}
          onChange={(e) => setAreaGroupName(e.target.value)}
        />
        <Button
          className="ml-4"
          appearance="primary"
          disabled={
            !areaGroupName || !areaCheckboxes.filter((e) => e.isSelected).length
          }
          onClick={handleAddAreaGroup}
        >
          Add
        </Button>
      </div>
      <Checkboxes
        areaCheckboxes={areaCheckboxes}
        setAreasCheckboxes={setAreasCheckboxes}
      />
      <div className="mt-4 flex flex-row flex-wrap gap-4">
        {areaGroups.map((areaGroup, index) => (
          <div
            className="basis-1/4 rounded border border-zinc-200 p-4 text-xl text-slate-600 shadow-sm"
            key={index}
          >
            <div className="flex flex-row justify-between">
              {areaGroup.groupName}
              <DeleteIcon
                marginLeft={12}
                color="#ff6565"
                cursor="pointer"
                onClick={() => deleteAreaGroup(index)}
              />
            </div>

            <ol className="ml-4 mt-2">
              {areaGroup.areas.map((e, index) => (
                <li className="list-decimal text-sm" key={index}>
                  {e.area.name}
                </li>
              ))}
            </ol>
          </div>
        ))}
      </div>
    </>
  );
};

const Checkboxes = ({
  areaCheckboxes,
  setAreasCheckboxes,
}: {
  areaCheckboxes: AreaCheckbox[];
  setAreasCheckboxes: (updatedSelectedAreas: AreaCheckbox[]) => void;
}) => {
  function handleAreasSelect(areaIndex: number, value: boolean) {
    const updatedSelectedAreas = [...areaCheckboxes];
    updatedSelectedAreas[areaIndex].isSelected = value;
    setAreasCheckboxes(updatedSelectedAreas);
  }

  return (
    <>
      {areaCheckboxes.map((areaCheckbox, index) => (
        <div key={index}>
          <Checkbox
            checked={areaCheckbox.isSelected}
            label={areaCheckbox.area.name}
            onChange={(e) => handleAreasSelect(index, e.target.checked)}
          />
        </div>
      ))}
    </>
  );
};
