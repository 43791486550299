import { useApi } from "@/context/AxiosContext";
import { toaster } from "evergreen-ui";

export default function useObservation({ onUpdated }) {
  const { apiInstance } = useApi();

  async function findOne(observationId: string) {
    return apiInstance?.adminWorkerShiftObservation.findOne(observationId);
  }

  async function update(observationId: string, observation: any) {
    const data = {
      ...observation,
    };

    const result = await apiInstance?.adminWorkerShiftObservation.update(
      observationId,
      data,
    );

    toaster.success("Observation updated", { id: "update-observation" });

    if (onUpdated) {
      onUpdated(result);
    }

    return result;
  }

  return {
    update,
    findOne,
  };
}
