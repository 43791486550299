import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "@/context/AppContext";
import { useEffect, useRef, useState } from "react";
import useMapboxAddressSearch from "@/hooks/useMapboxAddressSearch";
import useClientActivities from "@/hooks/useClientActivities";
import useObjectsForProject from "@/hooks/useObjectsForProject";
import { ClientObject } from "@/types/apiTypes";
import { __r, ADMIN_OBJECT_ADD_PAGE, ADMIN_OBJECT_EDIT_PAGE } from "@/RouteMap";
import { ImportObjectCsvRow } from "@/types/appTypes";
import { sleep } from "@/lib/functions";
import useObjectForm from "@/pages/projects/objects/useObjectForm.hook";
import { FeatureCollection, GeometryCollection } from "geojson";
import ApiError from "@/api/ApiError";

const postalCodeRegEx = /^(\d{4})\s*([a-zA-z]{2})$/;

export default function useObjectViewState() {
  const { objectId, projectId } = useParams();
  const objectApi = useObjectForm();

  const { setLayoutProps } = useApp();

  // note: make layout unscrollable in y direction
  useEffect(() => {
    setLayoutProps({
      overflowY: "hidden",
    });
  }, [setLayoutProps]);

  const {
    objects,
    setObjects,
    isLoading: isLoadingObjects,
    filterMap,
    handleInput,
    refresh,
  } = useObjectsForProject({ projectId });

  const navigate = useNavigate();
  const objectMapRef = useRef();

  const { searchAddress } = useMapboxAddressSearch();
  const { clientActivities } = useClientActivities();

  const [rowsToImport, setRowsToImport] = useState<ImportObjectCsvRow[]>([]);
  const [tmpGeometries, setTmpGeometries] = useState<FeatureCollection[]>([]);
  const [selectedGeometry, setSelectedGeometry] =
    useState<GeometryCollection | null>(null);
  const [activeObject, setActiveObject] = useState<ClientObject | null>(null);

  async function setNewObject(object: ClientObject, setActive = true) {
    const newObject = await objectApi.doCreate(object);

    setObjects((prev) => {
      return [...prev, newObject];
    });

    if (setActive) {
      setActiveObject(newObject);
    }
  }

  function updateList() {
    refresh();
  }

  function afterSave(object: ClientObject) {
    updateList();

    navigate(
      __r(ADMIN_OBJECT_ADD_PAGE, {
        projectId: projectId!,
      }),
    );
  }

  function afterDelete(object: ClientObject) {
    const newObjects = objects.filter((o) => o.id !== object.id);

    setObjects(newObjects);
  }

  useEffect(() => {
    if (!objectId || !objects.length) {
      setActiveObject(null);

      return;
    }

    const object = objects.find((o) => o.id === parseInt(objectId));

    if (object && objectMapRef.current) {
      // @ts-ignore
      objectMapRef.current.flyTo(
        object.geometry.geometry.geometries[0].coordinates,
      );

      setActiveObject(object);
    }
  }, [objects, objectId]);

  function onClickEdit(object: ClientObject) {
    navigate(
      __r(ADMIN_OBJECT_EDIT_PAGE, {
        projectId: projectId!,
        objectId: object.id,
      }),
    );
  }

  async function doImportCsvRow(
    csvObject: ImportObjectCsvRow,
    index: number,
    forceImport = false,
  ) {
    if (
      !csvObject.Straatnaam ||
      !csvObject.Huisnummer ||
      !csvObject.Postcode ||
      !csvObject.Woonplaats
    ) {
      return {
        index: index,
        message: "Address not complete, please check the input file.",
        row: csvObject,
        status: "error",
      };
    }

    const postalCodeParts = csvObject.Postcode.trim().match(postalCodeRegEx);
    const fixedPostalCode = postalCodeParts
      ? `${postalCodeParts[1]} ${postalCodeParts[2]}`
      : csvObject.Postcode.trim();

    const mapBoxQuery = `${csvObject.Straatnaam.trim()} ${csvObject.Huisnummer.trim()}, ${fixedPostalCode}, ${csvObject.Woonplaats.trim()}`;

    let feature: Awaited<ReturnType<typeof searchAddress>>;

    try {
      feature = await searchAddress(mapBoxQuery);
    } catch (e) {
      return {
        index: index,
        message: "Address not found, please check the input file.",
        row: csvObject,
        status: "error",
      };
    }

    const geometry: FeatureCollection = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: feature.coordinates,
          },
          properties: {
            mapBoxId: feature.address.mapBoxId,
            name: feature.address.formattedAddress,
          },
        },
      ],
    };

    if (feature.relevance < 1 && !forceImport) {
      setTmpGeometries((prev) => {
        return [...prev, geometry];
      });

      // @ts-ignore
      setSelectedGeometry(geometry);

      return {
        row: csvObject,
        message: `Address did not match 100%. Best match: ${feature.address.formattedAddress}. Click 'Force Import' to import anyway.`,
        index: index,
        status: "warning",
        geometry: geometry,
      };
    }

    setTmpGeometries(
      tmpGeometries.filter((g) => {
        return g.features[0].properties?.mapBoxId !== feature.address.mapBoxId;
      }),
    );

    const newObject = {
      name: feature.address.formattedAddress,
      address: {
        city: feature.address.city,
        country: feature.address.country,
        countryCode: feature.address.countryCode,
        formattedAddress: feature.address?.formattedAddress,
        mapBoxId: feature.address.mapBoxId,
        lng: feature.coordinates[0],
        lat: feature.coordinates[1],
        number: feature.address.number,
        postalCode: feature.address.postalCode,
        province: feature.address.province,
        street: feature.address.street,
        language: feature.address.language,
      },
      mapBoxId: feature.address.mapBoxId,
      geometry: geometry,
      notes: "",
    };

    try {
      await setNewObject(newObject, false);
    } catch (e) {
      if (e instanceof ApiError) {
        return {
          row: csvObject,
          message:
            e.messages?.[0] ||
            "Unable to import row due to an error - please check the input file.",
          index: index,
          status: "error",
          geometry: geometry,
        };
      } else {
        throw e;
      }
    }

    await sleep(250);

    return {
      row: csvObject,
      index: index,
      status: "success",
      message: "Success",
      object: newObject,
      geometry: geometry,
    };
  }

  function flyTo(coordinates: [number, number]) {
    // @ts-ignore
    if (objectMapRef.current && objectMapRef.current.flyTo) {
      // @ts-ignore
      objectMapRef.current.flyTo(coordinates);
    }
  }

  function onClickImportRow(geometry: any) {
    setSelectedGeometry(geometry);
    flyTo(geometry.features[0].geometry.coordinates);
  }

  function onClickLocate(object: ClientObject) {
    navigate(
      __r(ADMIN_OBJECT_EDIT_PAGE, {
        projectId: projectId!,
        objectId: object.id,
      }),
    );
  }

  function setRowsToImportCb(rows: ImportObjectCsvRow[]) {
    if (rows.length === 0) {
      setTmpGeometries([]);
    }

    setRowsToImport(rows);
  }

  return {
    objectMapRef,
    objects,
    setObjects,
    isLoadingObjects,
    activeObject,
    setActiveObject,
    setNewObject,
    updateList,
    afterSave,
    afterDelete,
    onClickEdit,
    clientActivities,
    rowsToImport,
    setRowsToImport: setRowsToImportCb,
    doImportCsvRow,
    objectId,
    objectApi,
    onClickImportRow,
    onClickLocate,
    tmpGeometries,
    selectedGeometry,
    filterMap,
    handleInput,
  };
}
