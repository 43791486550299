import { IntroductionComponent, MediaItems } from "@/types/apiTypes";
import { Image, Page, Styles, Text, View } from "@react-pdf/renderer";
import { pdfIntroductionStyles } from "./styles/pdfReportStyles";
import { PdfPreviewImage } from "./PdfPreviewImage";
import { getFormattedDate } from "../utility";

export const PdfPreviewIntroductionComponent = ({
  component,
  date,
  mediaItems,
}: {
  component: IntroductionComponent;
  date: Date;
  mediaItems: MediaItems;
}) => {
  const { title, subtitle, coverPhoto } = component;
  return (
    <Page size="A4" dpi={100} style={pdfIntroductionStyles.introductionPage}>
      <View>
        <Image
          style={pdfIntroductionStyles.introductionPageLogo}
          src={"/logo.png"}
        />
      </View>
      <View>
        {coverPhoto !== null ? (
          <PdfPreviewImage
            imageComponent={{
              type: "image",
              mediaItemId: parseInt(coverPhoto),
            }}
            styles={pdfIntroductionStyles.coverPhoto as Styles}
            mediaItems={mediaItems}
          />
        ) : (
          <View style={pdfIntroductionStyles.coverPhoto as Styles}></View>
        )}
      </View>
      <View
        style={pdfIntroductionStyles.introductionPageReportDetailsContainer}
      >
        <View style={pdfIntroductionStyles.reportTitleContainer}>
          <Text>{title}</Text>
          <Text>{subtitle}</Text>
        </View>
        <View style={pdfIntroductionStyles.reportDateContainer}>
          <Text>{getFormattedDate(`${date}`)}</Text>
        </View>
      </View>
    </Page>
  );
};
