import {
  MediaItems,
  ReportComponent,
  TableComponentData,
} from "@/types/apiTypes";
import { Dialog } from "evergreen-ui";
import { useState } from "react";
import { DialogFooterComponent } from "./DialogFooterComponent";
import { TextEditor } from "../editors/TextEditor";
import { TableEditor } from "../editors/TableEditor";
import { IntroductionEditor } from "../editors/IntroductionEditor";
import { ImagePreviewComponent } from "../preview-components/ImagePreviewComponent";
import { useParams } from "react-router-dom";
import { useApi } from "@/context/AxiosContext";
import { clone } from "lodash";
import { sortRowsByDateAndReorder } from "../utility";

export function EditReportModuleDialogComponent({
  component,
  editorVisiblity,
  saveLoaderVisibility,
  mediaItems,
  onClose,
  onSave,
}: {
  component: ReportComponent;
  editorVisiblity: boolean;
  saveLoaderVisibility: boolean;
  mediaItems: MediaItems;
  onClose: () => void;
  onSave: (component: ReportComponent) => void;
}) {
  const { reportId } = useParams();
  const { apiInstance } = useApi();
  const [componentToBeUpdated, setComponentToBeUpdated] = useState(component);
  const [addedRows, setAddedRows] = useState<TableComponentData[]>([]);
  const [fileToUpload, setFileToUpload] = useState<{ [key: string]: File }>();
  function handleCloseDialog() {
    setComponentToBeUpdated(component);
    onClose();
  }

  async function handleConfirmDialog() {
    let updatedComponent = clone(componentToBeUpdated);
    if (fileToUpload?.introductionCoverPhoto) {
      await saveCoverPhoto(fileToUpload.introductionCoverPhoto);
      return;
    }
    if (updatedComponent.type === "table" && addedRows.length) {
      updatedComponent.data.push(...addedRows);
      updatedComponent.data = sortRowsByDateAndReorder(updatedComponent.data);
    }
    onSave(updatedComponent);
  }

  async function saveCoverPhoto(file: File) {
    await apiInstance!.adminReports
      .uploadMedia(reportId!, file)
      .then((mediaItemId) => {
        const updatedComponent = {
          ...componentToBeUpdated,
          coverPhoto: mediaItemId.toString(),
          mediaItemId: mediaItemId,
        };
        onSave(updatedComponent);
      });
  }

  function handleAddedRows(addedRows) {
    setAddedRows(addedRows);
  }

  return (
    <Dialog
      title="Edit Report Module"
      shouldCloseOnOverlayClick={false}
      width="53%"
      topOffset="2%"
      shouldCloseOnEscapePress={false}
      isShown={editorVisiblity}
      shouldAutoFocus={true}
      onCloseComplete={handleCloseDialog}
      footer={
        <DialogFooterComponent
          saveLoaderVisibility={saveLoaderVisibility}
          handleCloseDialog={handleCloseDialog}
          handleConfirmDialog={handleConfirmDialog}
        />
      }
    >
      <div className="report-editor-dialog">
        <EditorComponent
          component={componentToBeUpdated}
          handleComponentUpdate={(component) =>
            setComponentToBeUpdated(component)
          }
          handleFileUploadChanges={(files) => setFileToUpload(files)}
          handleAddedRows={handleAddedRows}
          mediaItems={mediaItems}
        />
      </div>
    </Dialog>
  );
}

function EditorComponent({
  component,
  mediaItems,
  handleComponentUpdate,
  handleFileUploadChanges,
  handleAddedRows,
}: {
  component: ReportComponent;
  mediaItems: MediaItems;
  handleComponentUpdate: (component: ReportComponent) => void;
  handleFileUploadChanges: (file: { [key: string]: File }) => void;
  handleAddedRows: (addedRows: TableComponentData[]) => void;
}) {
  switch (component.type) {
    case "textEditor":
    case "bibliography":
    case "tableOfContents":
      return (
        <TextEditor
          component={component}
          handleComponentUpdate={handleComponentUpdate}
        />
      );
    case "table":
      return (
        <TableEditor component={component} handleAddedRows={handleAddedRows} />
      );
    case "introduction":
      return (
        <IntroductionEditor
          mediaItems={mediaItems}
          component={component}
          handleComponentUpdate={handleComponentUpdate}
          handleFileUploadChanges={handleFileUploadChanges}
        />
      );

    case "image":
    case "mapImage":
      return (
        <ImagePreviewComponent
          component={component}
          mediaItems={mediaItems}
          handleComponentUpdate={handleComponentUpdate}
        />
      );
  }
}
