import { useApi } from "../../context/AxiosContext";
import { useState } from "react";
import { useDoRequest, useLoadResource } from "../../lib/request-hooks";
import { WorkerShift } from "../../types/apiTypes";
import Page from "../../components/common/Page";
import { useParams } from "react-router-dom";
import {
  Alert,
  BanCircleIcon,
  Button,
  Card,
  CrossIcon,
  Dialog,
  Heading,
  IssueIcon,
  majorScale,
  Pane,
  Paragraph,
  Text,
  TextareaField,
  TickCircleIcon,
  toaster,
} from "evergreen-ui";
import { useTranslation } from "react-i18next";
import { TableLoadingSpinner } from "../../components/table/StandardTable";

function useWorkerShift() {
  const { apiInstance } = useApi();
  const { shiftId } = useParams();

  const [workerShift, setWorkerShift] = useState<WorkerShift>();

  const find = () => apiInstance!.workerShifts.findOne(shiftId!);

  return {
    ...useLoadResource(find, setWorkerShift, !!shiftId),
    workerShift,
  };
}

export const IconMap = {
  declined: <CrossIcon size={14} color="danger" />,
  cancel: <BanCircleIcon size={14} color="danger" />,
  accepted: <TickCircleIcon size={14} color="success" />,
};

function useWorkerShiftRequestForm() {
  const { shiftId } = useParams();
  const { apiInstance } = useApi();

  const [notes, setNotes] = useState<string>("");

  const {
    handle,
    setIsLoading: setIsSubmitting,
    isLoading: isSubmitting,
    validationErrors,
    setValidationErrors,
    errorMessage: submitErrorMessage,
  } = useDoRequest();

  const doSubmit = async (type) => {
    const data = {
      notes: notes ?? null,
      type: type,
    };

    const request = apiInstance!.workerShifts.requestChange(shiftId!, data);
    await handle(request);

    toaster.success("Saved!");
  };

  return {
    submitContext: {
      doSubmit,
      setIsSubmitting,
      isSubmitting,
      submitErrorMessage,
      validationErrors,
    },
    notes,
    setNotes,
    doSubmit,
  };
}

function CancelRequestDialog({ isShown, onCloseComplete }) {
  const { submitContext, notes, setNotes, doSubmit } =
    useWorkerShiftRequestForm();

  async function onConfirm() {
    await doSubmit("cancel");

    setNotes("");
    onCloseComplete();
  }

  return (
    <Dialog
      confirmLabel="Dienst annuleren"
      title="Annuleren aanvragen"
      shouldCloseOnOverlayClick={false}
      intent="danger"
      onConfirm={onConfirm}
      onCloseComplete={onCloseComplete}
      isShown={isShown}
    >
      <Pane>
        <Text className="py-4">
          Geef aan waarom je wilt of moet annuleren, je hebt ook de mogelijkheid
          om iets anders voor te stellen.
        </Text>
        <TextareaField
          name="aanvraag"
          label="Wijziging aanvragen"
          placeholder="Ik moet annuleren omdat..."
          isInvalid={!!submitContext.validationErrors?.notes}
          validationMessage={submitContext.validationErrors?.notes?.join(", ")}
          value={notes || ""}
          onChange={(e) => setNotes(e.target.value)}
          marginBottom={0}
          inputHeight={40}
          className="!text-base"
        />
      </Pane>
    </Dialog>
  );
}
export default function WorkerShiftChangesPage() {
  const { t } = useTranslation();
  const { workerShift, isLoading, refresh } = useWorkerShift();
  const [cancelRequestIsShown, setCancelRequestIsShown] =
    useState<boolean>(false);
  const { workerShiftRequest } = workerShift
    ? workerShift
    : { workerShiftRequest: null };

  return (
    <Page>
      {isLoading && <TableLoadingSpinner />}

      {/*{workerShift && (workerShift.status !== 'planned') &&*/}
      {/*  <EmptyState*/}
      {/*    background="light"*/}
      {/*    title="Deze dienst is niet meer aanpasbaar"*/}
      {/*    orientation="horizontal"*/}
      {/*    icon={<BanCircleIcon color="#C1C4D6"/>}*/}
      {/*    iconBgColor="#EDEFF5"*/}
      {/*    description="Wijzingen kunnen alleen worden doorgegeven als de dienst nog niet is begonnen of nog geen rapport is toegevoegd."*/}
      {/*  />*/}
      {/*}*/}

      {isLoading === false && workerShift && (
        <Pane>
          <CancelRequestDialog
            isShown={cancelRequestIsShown}
            onCloseComplete={() => {
              setCancelRequestIsShown(false);
              refresh();
            }}
          />

          <Pane className="flex flex-col gap-2">
            {!workerShiftRequest && (
              <Alert intent="warning">
                <Paragraph size={300} className="!text-xs">
                  Je kunt hier een aanvraag doen om je dienst te annuleren. De
                  dienstverantwoordelijke zal naar je aanvraag kijken en
                  vervolgens goedkeuren of afkeuren Als je de aanvraagt hebt
                  gedaan, kun je naar dit scherm terugkeren om de status te
                  bekijken van je aanvraag. Als je aanvraag is goedgekeurd dan
                  vervalt deze dienst. Bij een afwijzing zal je de dienst moeten
                  uitvoeren.
                </Paragraph>
              </Alert>
            )}

            {!workerShiftRequest && (
              <Button
                onClick={() => setCancelRequestIsShown(true)}
                iconBefore={<IssueIcon size={14} color="warning"></IssueIcon>}
                height={majorScale(5)}
              >
                Dienst annuleren
              </Button>
            )}

            {workerShiftRequest && (
              <Card elevation={1} className={"mt-8 p-8"}>
                <Pane className="flex grow">
                  <Pane>
                    <Pane className="flex items-center gap-1 py-4">
                      <Pane> {IconMap[workerShiftRequest.status]} </Pane>
                      {workerShiftRequest.status === "accepted" && (
                        <Paragraph
                          className="!text-bold my-4 border-b"
                          size={300}
                        >
                          Je verzoek is geaccepteerd.
                        </Paragraph>
                      )}
                      {workerShiftRequest.status === "declined" && (
                        <Paragraph
                          className="!text-bold my-4 border-b"
                          size={300}
                        >
                          Helaas is je verzoek afgewezen
                        </Paragraph>
                      )}
                    </Pane>

                    <Pane className="flex w-full items-center">
                      <Heading size={700}>Annuleren aanvragen</Heading>
                    </Pane>
                    {workerShiftRequest &&
                      workerShiftRequest.type === "cancel" && (
                        <Pane className="flex flex-col gap-1">
                          <Paragraph className="py-2 !text-base">
                            Je hebt gevraagd of je deze dienst mag annuleren.
                          </Paragraph>
                          <Pane className="py-2">
                            <Heading size={500}>
                              Jouw aanvraag informatie:
                            </Heading>
                            <Paragraph className="py-1 !text-base">
                              {workerShiftRequest.notes}
                            </Paragraph>
                          </Pane>
                          <Pane className="py-2">
                            <Heading size={500}>Admin reactie:</Heading>
                            <Paragraph className="py-1 !text-base">
                              {workerShiftRequest.adminNotes ??
                                "Nog geen reactie..."}
                            </Paragraph>
                          </Pane>
                        </Pane>
                      )}
                  </Pane>
                  <Pane className="flex grow justify-end">
                    <Pane className="flex w-full flex-col justify-between">
                      {workerShiftRequest &&
                        workerShiftRequest.status === "declined" && (
                          <Button
                            marginLeft="auto"
                            iconBefore={<BanCircleIcon color="danger" />}
                            onClick={() => setCancelRequestIsShown(true)}
                            height={majorScale(5)}
                          >
                            Nogmaals annuleren
                          </Button>
                        )}

                      <Pane marginLeft="auto">
                        {workerShiftRequest &&
                          workerShiftRequest.type === "cancel" && (
                            <Pane className="flex items-center gap-1">
                              <Heading size={300}>Status:</Heading>
                              <Heading size={200}>
                                {t(
                                  "worker_shift_request." +
                                    workerShiftRequest.status,
                                )}
                              </Heading>
                              {IconMap[workerShiftRequest.status]}
                            </Pane>
                          )}
                      </Pane>
                    </Pane>
                  </Pane>
                </Pane>
              </Card>
            )}
          </Pane>

          {/*  <Card title={"Danger zone"} elevation={1} className={"mt-8 p-8"}>*/}
          {/*      <Pane className="flex items-center w-full">*/}
          {/*        <Heading size={700}>Annuleren</Heading>*/}
          {/*        <Button*/}
          {/*          disabled={workerShiftRequest ? workerShiftRequest.type === 'cancel': false}*/}
          {/*          onClick={() => setCancelRequestIsShown(true)}*/}
          {/*          intent="danger"*/}
          {/*          marginLeft='auto'*/}
          {/*          height={majorScale(5)}>Annuleren*/}
          {/*        </Button>*/}
          {/*      </Pane>*/}
          {/*    </Card>*/}
        </Pane>
      )}
    </Page>
  );
}
