import { toaster } from "evergreen-ui";
import { eachDayOfInterval, eachWeekOfInterval, parseISO } from "date-fns";
import {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useDoRequest, useLoadResource } from "src/lib/request-hooks";
import { groupBy, transform } from "lodash";
import { Period } from "../types/apiTypes";

export const FormContext = createContext({
  formState: {
    isDirty: false,
  },
  onChange: () => {},
});

export function useForm() {
  return useContext(FormContext);
}

function formReducer(state, action) {
  switch (action.type) {
    case "onChange":
      return {
        ...state,
        isDirty: true,
      };
    default:
      return state;
  }
}

type AvailabilityType = {
  id: number;
  timeslotUuid: string;
  date: string;
};

/**
 * Here we make a map of date -> <timeslot + shift date>
 * structure:
 * "<date_of_the_shift>": {
 *     "<timeslot_uid>": <timeslot>
 * }
 */
export function transformWorkerAvailability(
  workerAvailabilityList,
): AvailabilityType[] {
  const workerAvailabilityGroupedByDate = groupBy(
    workerAvailabilityList,
    "date",
  );

  return transform(
    workerAvailabilityGroupedByDate,
    (result, value, key) => {
      result[key] = {};

      value.forEach((v) => {
        result[key][v.timeslotUid] = v;
      });
    },
    [],
  );
}

export function transformToApiWorkerAvailability(availability) {
  const result = [];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [_, avData] of Object.entries(availability)) {
    // @ts-ignore
    result.push(...Object.values(avData));
  }

  return result;
}

/**
 * @param find
 * @param submit
 * @param options
 */
export default function usePeriodForm(
  find,
  submit,
  options = { ignoreIsLocked: false },
) {
  const { periodId } = useParams();

  const availability = useRef<AvailabilityType[]>([]);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [interval, setInterval] = useState<Date[] | null>(null);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [period, setPeriod] = useState<Period | null>(null);
  const [notes, setNotes] = useState<string>("");

  const isDirty = useRef<boolean>(false);
  const submitContext = useDoRequest();

  const setData = (period: Period) => {
    availability.current = transformWorkerAvailability(
      period.workerAvailability,
    );

    setFromDate(parseISO(period.startAt));
    setToDate(parseISO(period.endAt));
    setNotes(period.workerAvailabilityStatus?.notes || "");

    setInterval(
      eachDayOfInterval({
        start: parseISO(period.startAt),
        end: parseISO(period.endAt),
      }),
    );

    setPeriod(period);

    if (options?.ignoreIsLocked === false) {
      setIsLocked(period.isLocked);
    }
  };

  const weekInterval = useMemo(() => {
    if (fromDate && fromDate) {
      return eachWeekOfInterval(
        {
          start: fromDate!,
          end: toDate!,
        },
        {
          weekStartsOn: 1,
        },
      );
    }

    return [];
  }, [interval, fromDate, toDate]);

  const doSubmit = async () => {
    if (!submit) {
      return;
    }

    const data = {
      availability: transformToApiWorkerAvailability(availability.current),
      periodId: parseInt(periodId!),
      notes: notes,
    };

    await submitContext.handle(submit!(data));

    toaster.success("Saved!");
  };

  function refreshView() {
    setRefreshCount(refreshCount + 1);
  }

  const [formState, dispatch] = useReducer(formReducer, {
    isDirty: false,
  });

  const formContext = {
    formState,
    onChange: () => {
      dispatch({ type: "onChange" });
    },
  };

  return {
    ...useLoadResource(find, setData, true),
    fromDate,
    toDate,
    availability,
    doSubmit,
    interval,
    submitContext,
    refreshCount,
    refreshView,
    isDirty,
    formContext,
    isLocked,
    weekInterval,
    period,
    notes,
    setNotes,
    ignoreIsLocked: options.ignoreIsLocked,
  };
}
