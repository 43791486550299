import { TextInput } from "evergreen-ui";
import { acceptedFileFormats } from "../constants";
import { ImageEditor } from "../editors/ImageEditor";

export const ImageUploadComponent = ({
  image,
  handleImageSelection,
}: {
  image: { file?: File; description: string };
  handleImageSelection: (uploadedImage: {
    file?: File;
    description: string;
  }) => void;
}) => {
  return (
    <>
      <TextInput
        className="mb-4"
        value={image.description}
        name="Image description"
        placeholder="Description for the image"
        onChange={(e) =>
          handleImageSelection({ ...image, description: e.target.value })
        }
      />
      <ImageEditor
        acceptedFileFormats={acceptedFileFormats}
        description={`Upload media for the report. Max size 10 MB and accepted formats - ${acceptedFileFormats.join(", ")}`}
        handleFileUpload={(file) => handleImageSelection({ ...image, file })}
      />
    </>
  );
};
