import {
  AdditionalDataObservationTypes,
  Area,
  AreaGroup,
  AreaObservation,
  AreaVisit,
  AreaVisitTable,
  AreaWithObservationsComponent,
  AreaWithVisitsComponent,
  BibliographyComponent,
  ImageComponent,
  MapImageComponent,
  MultipleImagesComponent,
  PageBreakComponent,
  Report,
  ReportComponent,
  ReportData,
  TableComponent,
  TableComponentColumns,
  TableComponentData,
  TableFormatterType,
  TableOfContentsComponent,
  TableType,
  TextEditorComponent,
  User,
} from "@/types/apiTypes";
import {
  areaObservationsTableColumns,
  areaVisitsTableColumns,
  getSpeciesObservationTypeOptions,
} from "./constants";
import axios from "axios";
import { format } from "date-fns";
import { flattenDeep, uniq } from "lodash";
import { bbox, feature, featureCollection } from "@turf/turf";

export function getFormattedData(data: any, formatter: TableFormatterType) {
  if (data) {
    switch (formatter) {
      case "date":
        return getFormattedDate(data);

      case "dateTime":
        return getFormattedDateTime(data);

      // Not formatting as few numbers are in range and is a string.  Eg., "1-3".  So to provide backward compatibility, retaining this type
      case "number":
        return data;

      case "string":
        return `${replaceUnderScores(data)}`;
    }
  }
  return "N/A";
}

export function getFormattedDate(date: string) {
  return format(new Date(date), "dd-MM-yyyy");
}

function getFormattedDateTime(date: string) {
  return new Intl.DateTimeFormat("nl-NL", {
    dateStyle: "medium",
    timeStyle: "short",
  }).format(new Date(date));
}

function getTimeString({
  startedAt,
  endedAt,
}: {
  startedAt: string;
  endedAt: string;
}) {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: "Europe/Amsterdam",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const startTime = new Date(startedAt).toLocaleString("en-GB", options);
  const endTime = new Date(endedAt).toLocaleString("en-GB", options);

  return `${startTime} - ${endTime}`;
}

export function getFlattenedComponents(components: ReportComponent[]) {
  const flattenedComponents: ReportComponent[] = [];
  components.forEach((e) => {
    if (e.type === "areaWithVisits" || e.type === "areaWithObservations") {
      const { childComponents } = e;
      const flattenedChildComponents = getFlattenedComponents(childComponents);
      flattenedComponents.push(...flattenedChildComponents);
    } else {
      flattenedComponents.push(e);
    }
  });

  return flattenedComponents;
}

function getSpeciesNames(areas: Area[]): string[] {
  const speciesFromAllAreas: string[] = [];
  areas.forEach((area) =>
    speciesFromAllAreas.push(...Object.keys(area.species)),
  );
  return uniq(speciesFromAllAreas);
}

export function generateVisitsTableForAreaCheckbox(area: Area): {
  [activity: string]: TableComponentData[];
} {
  const speciesNames = getSpeciesNames([area]);
  return generateVisitsTableForArea(area, speciesNames);
}

export function generateVisitsTableForArea(
  area: Area,
  speciesNames: string[],
): { [specie: string]: TableComponentData[] } {
  const speciesVisitsMap: { [specie: string]: TableComponentData[] } = {};
  speciesNames.forEach((specieName) => {
    speciesVisitsMap[specieName] = generateVisitsTable(
      area.species[specieName].visits,
    );
  });

  return speciesVisitsMap;
}

function generateVisitsTable(visits: AreaVisit[]): AreaVisitTable[] {
  return visits.map((visit, index) => ({
    workerCount: visit.workerCount,
    date: new Date(visit.startedAt),
    parsedTime: getTimeString(visit),
    temperature: `${visit.temperature}°C`,
    wind: `${visit.wind} ${visit.windDirection}`,
    precipitation: visit.precipitation,
    activityNo: `${index + 1}`,
  }));
}

function generateAreaMapImageComponent(areas: Area[]): MapImageComponent {
  const features: Array<GeoJSON.Feature<GeoJSON.Geometry>> = areas.map(
    (area) => ({
      type: "Feature",
      properties: {
        id: area.id,
        label: area.name,
        type: "area",
      },
      geometry: {
        type: "Polygon",
        coordinates:
          (area.geometry.geometries[0].type === "Polygon" &&
            area.geometry.geometries[0].coordinates) ||
          [],
      },
    }),
  );

  const featureCollection: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: features,
  };

  return {
    type: "mapImage",
    description: "",
    mapMetadata: {
      mapType: "area",
      mapStyle: "standard-satellite",
      mapData: featureCollection,
    },
  };
}

export function generateAreaVisitsComponent(
  areaGroup: AreaGroup,
  onlyOneAreaPresent: boolean,
): AreaWithVisitsComponent {
  const groupedAreas = areaGroup.areas.map((e) => e.area);
  return {
    type: "areaWithVisits",
    name: areaGroup.groupName,
    areas: groupedAreas,
    childComponents: [
      {
        type: "textEditor",
        content: `${generateH2Tag(areaGroup.groupName)}`,
      },
      generateAreaMapImageComponent(groupedAreas),
      {
        type: "textEditor",
        content: `${onlyOneAreaPresent ? generatePTag("[Situation description of this specific area, with what work (i.e. insulating walls, roof renovation, etc.)  will be done in this area]") : ""}
        ${generateH3Tag("Veldbezoeken")}
        ${generatePTag("Er zijn gerichte veldbezoeken voor [activity in system] uitgevoerd op onderstaande datums met de respectievelijke weersomstandigheden:")}`,
      },
      ...getVisitComponents(groupedAreas),
    ],
  };
}

export function generateAreaObservationsComponent(
  areaGroup: AreaGroup,
  areaIndex: number,
  observationTypes: AdditionalDataObservationTypes,
): AreaWithObservationsComponent {
  const groupedAreas = areaGroup.areas.map((e) => e.area);
  return {
    type: "areaWithObservations",
    name: areaGroup.groupName,
    areas: groupedAreas,
    childComponents: [
      {
        type: "textEditor",
        content: `${generateH3Tag("Resultaten")}${generatePTag("Aan de hand van de veldbezoeken zijn de volgende observaties aangetroffen binnen het plangebied:")}`,
      },
      ...getObservationComponents(areaGroup, observationTypes),
    ],
  };
}

function getVisitComponents(areas: Area[]): ReportComponent[] {
  const visitsComponents: ReportComponent[] = [];

  areas.forEach((area) => {
    let visitsComponentsGroupedByArea: ReportComponent[] = [];
    const visitsGroupedBySpecieMap = generateVisitsTableForAreaCheckbox(area);
    const visitsComponentsGroupedBySpecies: ReportComponent[] = [];
    Object.keys(visitsGroupedBySpecieMap).forEach((specie) => {
      if (visitsGroupedBySpecieMap[specie].length) {
        visitsComponentsGroupedBySpecies.push(
          {
            type: "textEditor",
            content: `${generateH5Tag(`Veldbezoeken voor ${specie}`)}`,
          },
          {
            type: "table",
            tableIdentifier: {
              tableType: "visit",
            },
            columns: areaVisitsTableColumns,
            data: visitsGroupedBySpecieMap[specie],
          },
        );
      }
    });
    if (visitsComponentsGroupedBySpecies.length) {
      visitsComponentsGroupedByArea = visitsComponentsGroupedBySpecies;
      if (areas.length > 1) {
        visitsComponentsGroupedByArea.unshift({
          type: "textEditor",
          content: `${generateH4Tag(area.name)}`,
        });
      }
    }

    if (visitsComponentsGroupedByArea.length) {
      visitsComponents.push(...visitsComponentsGroupedByArea);
    }
  });

  return visitsComponents;
}

function getObservationComponents(
  areaGroup: AreaGroup,
  observationTypes: AdditionalDataObservationTypes,
) {
  const observationComponents: ReportComponent[] = [];
  const groupedAreas = areaGroup.areas.map((e) => e.area);

  const filteredObservationDataForAllAreas: TableComponentData[] =
    filterObservationsByObservationTypes(groupedAreas, observationTypes);

  const speciesGroupObservationsMap: {
    [specie: string]: TableComponentData[];
  } = groupObservationBySpeciesGroup(filteredObservationDataForAllAreas);

  Object.keys(speciesGroupObservationsMap).forEach((specieGroup, index) => {
    const observationsForAllAreas: TableComponentData[] =
      addMapImageComponentForObservation(
        areaGroup,
        speciesGroupObservationsMap[specieGroup],
      );

    const sortedObservations = sortObservationByObservationTypes(
      observationsForAllAreas,
      specieGroup,
    );

    // SpecieGroup title
    observationComponents.push({
      type: "textEditor",
      content: `${generateH5Tag(`Observaties van ${specieGroup}`)}`,
    });

    const areasWithNoObservations: string[] = [];
    const areaComponentsWithObservations: ReportComponent[] = [];

    groupedAreas.forEach((area) => {
      const filterObservationsForArea = sortedObservations.filter(
        (e) => e.properties.areaId === area.uuid,
      );
      if (filterObservationsForArea.length) {
        areaComponentsWithObservations.push(
          {
            type: "textEditor",
            content: `${generateH4Tag(area.name)}`,
          },
          ...generateComponentsForObservationGroupedBySpecies(
            filterObservationsForArea,
            [area],
          ),
        );
      } else {
        areasWithNoObservations.push(area.name);
      }
    });

    if (areasWithNoObservations.length) {
      observationComponents.push({
        type: "textEditor",
        content: `${generatePTag(`Geen observaties gemaakt van ${specieGroup} in ${areasWithNoObservations.join(", ")}`)}`,
      });
    }

    observationComponents.push(...areaComponentsWithObservations);

    if (index < observationsForAllAreas.length) {
      observationComponents.push(generatePageBreakComponent());
    }
  });

  return observationComponents;
}

function filterObservationsByObservationTypes(
  areas: Area[],
  observationTypes: AdditionalDataObservationTypes,
) {
  const filteredObservationDataForAllAreas: TableComponentData[] = [];
  const speciesNames = getSpeciesNames(areas);
  const observationsDataForAllAreas = getObservationsForAllAreas(
    areas,
    speciesNames,
  );
  observationsDataForAllAreas.forEach((e) => {
    const {
      properties: { type, finding },
    } = e;
    // If present and empty, include all observation type for specie
    if (observationTypes[type]) {
      if (
        observationTypes[type].length === 0 ||
        observationTypes[type].includes(finding)
      ) {
        filteredObservationDataForAllAreas.push(e);
      }
    }
  });

  return filteredObservationDataForAllAreas;
}

function groupObservationBySpeciesGroup(
  filteredObservationData: TableComponentData[],
) {
  const observationsGroupedBySpeciesGroup: {
    [specie: string]: TableComponentData[];
  } = {};

  filteredObservationData.forEach((e) => {
    const {
      properties: { type },
    } = e;
    if (observationsGroupedBySpeciesGroup[type]) {
      observationsGroupedBySpeciesGroup[type].push(e);
    } else {
      observationsGroupedBySpeciesGroup[type] = [e];
    }
  });

  return observationsGroupedBySpeciesGroup;
}

function sortObservationByObservationTypes(
  observations: TableComponentData[],
  specieGroup: string,
) {
  const [observationTypesForSpecieGroup] = getSpeciesObservationTypeOptions(
    [specieGroup],
    false,
  );
  const observationTypes = observationTypesForSpecieGroup.observationTypes.map(
    (e) => e.value,
  );
  const sortedObservations: TableComponentData[] = [];

  const observationsObservationTypesMap: {
    [observationType: string]: TableComponentData[];
  } = {};

  observations.forEach((observation) => {
    if (observationsObservationTypesMap[observation.properties.finding]) {
      observationsObservationTypesMap[observation.properties.finding].push(
        observation,
      );
    } else {
      observationsObservationTypesMap[observation.properties.finding] = [
        observation,
      ];
    }
  });

  observationTypes.forEach((observationType) => {
    if (observationsObservationTypesMap[observationType]) {
      sortedObservations.push(
        ...observationsObservationTypesMap[observationType],
      );
    }
  });
  return sortedObservations;
}

function generateComponentsForObservationGroupedBySpecies(
  observations: TableComponentData[],
  areas: Area[],
) {
  const observationsGroupedBySpecies: {
    [species: string]: TableComponentData[];
  } = {};
  observations.forEach((e) => {
    const {
      properties: { species },
    } = e;
    if (observationsGroupedBySpecies[species]) {
      observationsGroupedBySpecies[species].push(e);
    } else {
      observationsGroupedBySpecies[species] = [e];
    }
  });

  const specieGroupedComponents: ReportComponent[] = [];
  Object.keys(observationsGroupedBySpecies).forEach((e) => {
    const observationsWithProperties: TableComponentData[] =
      observationsGroupedBySpecies[e].map(
        (observation) => observation.properties,
      );
    const images: {
      fullPath: string;
      mimeType: string;
      originalFileName: string;
      modelId: string;
    }[] = flattenDeep(observationsWithProperties.map((e) => e.media)).filter(
      (e) => !!e && e.mimeType.includes("image"),
    );
    specieGroupedComponents.push(
      generatePageBreakComponent(),
      {
        type: "textEditor",
        content: `${generateH6Tag(`Observaties van ${e}`)}`,
      },
      getObservationMapImageComponent(observationsGroupedBySpecies[e], areas),
      {
        type: "table",
        tableIdentifier: {
          tableType: "observation",
        },
        columns: areaObservationsTableColumns,
        data: observationsWithProperties,
      },
      generateMultipleImagesComponent(images),
      generatePageBreakComponent(),
    );
  });

  return specieGroupedComponents;
}

export function getObservationsForAllAreas(
  areas: Area[],
  speciesNames: string[],
): TableComponentData[] {
  const data: TableComponentData[] = [];
  speciesNames.forEach((specieName) => {
    const observationsFromAllAreas: AreaObservation[] = [];
    areas.forEach((area) =>
      observationsFromAllAreas.push(...area.species[specieName].observations),
    );
    data.push(...observationsFromAllAreas);
  });

  return data;
}

function addMapImageComponentForObservation(
  areaGroup: AreaGroup,
  observations: TableComponentData[],
): TableComponentData[] {
  return observations.map((observation) => {
    return {
      ...observation,
      properties: {
        ...observation.properties,
        mapImage: getObservationMapImageComponent(
          [observation],
          areaGroup.areas.map((area) => area.area),
        ),
      },
    };
  });
}

function getObservationMapImageComponent(
  observations: TableComponentData[],
  areas: Area[],
): MapImageComponent {
  const observationFeatures = observations.map((observation) =>
    feature(observation.geometry),
  );

  const areaFeatures = areas.map((area) => feature(area.geometry));

  const combineBbox = bbox(
    featureCollection([...areaFeatures, ...observationFeatures]),
  );

  const features: Array<GeoJSON.Feature<GeoJSON.Geometry>> = observations.map(
    (observation) => ({
      type: "Feature",
      properties: {
        id: observation.properties.id,
        label: observation.properties.modelId,
        type: "observation",
        species: observation.properties.species,
        speciesGroup: observation.properties.type,
        observationType: observation.properties.finding,
        bbox: combineBbox,
      },
      geometry: {
        type: observation.geometry.type,
        coordinates: observation.geometry.coordinates,
      },
    }),
  );

  const areaMapFeatures = areas.map((area) =>
    feature(area.geometry, {
      id: area.id,
      label: area.name,
      type: "area",
    }),
  );

  return {
    type: "mapImage",
    description: "",
    mapMetadata: {
      mapType: "observation",
      mapStyle: "standard",
      mapData: featureCollection([...features, ...areaMapFeatures]),
    },
  };
}

export async function fetchImagesUrls(
  medias: { fullPath: string; mimeType: string }[],
) {
  const imageUrlsPromises = medias.map(async (media) => {
    const { fullPath, mimeType } = media;
    if (!mimeType || !fullPath || fullPath === "") {
      return null;
    }
    const [fileType] = mimeType.split("/");

    if (fileType === "image") {
      return await fetchUrl(fullPath);
    }
    return null;
  });
  const imageUrls: string[] = (await Promise.all(imageUrlsPromises)).filter(
    (e) => !!e,
  );
  return imageUrls;
}

export async function fetchUrl(fullPath: string) {
  const response = await axios.get(
    `https://api.app.gaia-ecologie.nl/cdn/url/${fullPath}`,
    {
      withCredentials: true,
    },
  );
  return response.data.url;
}

export function generateH1Tag(text: string) {
  return `<h1>${text}</h1>`;
}

export function generateH2Tag(text: string) {
  return `<h2>${text}</h2>`;
}

export function generateH3Tag(text: string) {
  return `<h3>${text}</h3>`;
}

export function generateH4Tag(text: string) {
  return `<h4>${text}</h4>`;
}

export function generateH5Tag(text: string) {
  return `<h5>${text}</h5>`;
}

export function generateH6Tag(text: string) {
  return `<h6>${text}</h6>`;
}

export function generatePTag(text: string) {
  return `<p>${text}</p>`;
}

export function generateTextEditorComponent(): TextEditorComponent {
  return {
    type: "textEditor",
    content: generatePTag("Voorbeeldrapport Sjabloon"),
  };
}

export function generatePageBreakComponent(): PageBreakComponent {
  return {
    type: "pageBreak",
  };
}

export function generateTableComponent(
  columns: TableComponentColumns[],
  data: TableComponentData[],
  tableType: TableType,
): TableComponent {
  return {
    type: "table",
    columns,
    data,
    tableIdentifier: {
      tableType,
    },
  };
}

export function generateMultipleImagesComponent(
  images: {
    fullPath: string;
    mimeType: string;
    originalFileName: string;
    modelId: string;
  }[],
): MultipleImagesComponent {
  return {
    type: "multipleImages",
    images,
  };
}

export function generateImageComponent(
  mediaItemId: number,
  description: string,
): ImageComponent {
  return {
    type: "image",
    mediaItemId: mediaItemId,
    description,
  };
}

export function generateTableOfContentsComponent(
  components: ReportComponent[],
): TableOfContentsComponent {
  return {
    type: "tableOfContents",
    content: getTableOfContentsComponentContent(components),
  };
}

function getTableOfContentsComponentContent(components: ReportComponent[]) {
  const textComponents = components.filter(
    (e) => e.type === "textEditor" || e.type === "bibliography",
  );

  const h1Tags = getTagsWithNumbering(textComponents, "h1");
  const h2Tags = getTagsWithNumbering(textComponents, "h2");
  const tags = [...h1Tags, ...h2Tags];
  return `${generateH1Tag("Inhoudsopgave")}${sortTagsInAscendingOrder(tags)}`;
}

function getTagsWithNumbering(
  textComponents: Array<TextEditorComponent | BibliographyComponent>,
  tag: string,
) {
  const elementsWithTags: string[] = [];
  const regexp = new RegExp(`(<${tag}>)\\d.+?(</${tag}>)`, "g");
  textComponents.forEach((e) => {
    try {
      const matches = e.content.match(regexp);
      if (matches) {
        elementsWithTags.push(...matches);
      }
    } catch (err) {
      throw new Error(`Chapter numbering semantics not followed in ${e}`);
    }
  });

  return elementsWithTags;
}

function sortTagsInAscendingOrder(tags: string[]) {
  const orderMap: { [orderNumber: string]: string } = {};
  tags.forEach((tag) => {
    try {
      const [match] = tag.match(/(\d+\.)+[ ]([a-zA-Z]+[ ]*)+/g)!;

      const [numbering, ...chapterName] = match.split(" ");
      if (orderMap[numbering]) {
      } else {
        orderMap[numbering] = chapterName.join(" ");
      }
    } catch (err) {
      throw new Error(`Chapter numbering pattern not followed in ${tag}`);
    }
  });

  let result = "";
  const sortedOrder = Object.keys(orderMap).sort((a, b) => {
    const firstVal = +a.substring(0, a.length - 1);
    const secondVal = +b.substring(0, b.length - 1);
    return firstVal - secondVal;
  });

  result += sortedOrder
    .map((order) =>
      order.split(".").length === 2
        ? generateH2Tag(`${order} ${orderMap[order]} ...`)
        : generateH3Tag(
            `&nbsp;&nbsp;&nbsp;&nbsp;${order} ${orderMap[order]} ...`,
          ),
    )
    .join("");

  return result;
}

export function getTemplateMappingValues(
  projectData: ReportData,
  report: Report,
  user: User,
): { [key: string]: string } {
  const { project } = projectData;
  return {
    projectTitle: project.name,
    clientName: project.organisationName || "",
    reportGeneratedBy: `${user.firstName} ${user.lastName}`,
    reportDate: getFormattedDate(`${report.date}`),
    species: Object.keys(projectData.species).join(", "),
  };
}

export function replaceUnderScores(content: string) {
  return content.replaceAll("_", " ");
}

export function sortRowsByDateAndReorder(
  rows: TableComponentData[],
): TableComponentData[] {
  const sortedRows = rows.sort(
    (d1, d2) => new Date(d1.date).getTime() - new Date(d2.date).getTime(),
  );

  return sortedRows.map((e, index) => ({ ...e, activityNo: `${index + 1}` }));
}
