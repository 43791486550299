import { Pane, Paragraph } from "evergreen-ui";
import { AddressMinimap, config } from "@mapbox/search-js-react";
import { useEffect, useMemo, useState } from "react";
import ClipboardWrapper from "../../../common/ClipboardWrapper";

export function DisplayReportAddress({ address, ...rest }) {
  const { minHeightMap = 250, showMap = true, ...restProps } = rest;

  const feature = useMemo(() => {
    if (!address) {
      return null;
    }

    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [address.lng, address.lat],
      },
    };
  }, [address]);

  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

    if (accessToken) {
      setToken(accessToken);
      config.accessToken = accessToken;
    }
  }, []);

  if (!address) {
    return <></>;
  }

  const fullAddress =
    address.formattedAddress +
    (address.postalCode ? ` ${address.postalCode}` : "");

  return (
    <Pane {...restProps}>
      <Pane>
        <ClipboardWrapper value={fullAddress}>
          <Paragraph size={400}>{address.formattedAddress}</Paragraph>

          {address.postalCode && (
            <Paragraph size={400}>{address.postalCode}</Paragraph>
          )}
        </ClipboardWrapper>
      </Pane>

      <Pane className="w-full" minHeight={feature && showMap && minHeightMap}>
        {feature && showMap && token && (
          <AddressMinimap
            canAdjustMarker={false}
            satelliteToggle={true}
            //@ts-ignore
            feature={feature}
            show={true}
            footer={false}
            accessToken={token}
          />
        )}
      </Pane>
    </Pane>
  );
}
