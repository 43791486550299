import { IntroductionComponent, MediaItems } from "@/types/apiTypes";
import { TextInput } from "evergreen-ui";
import { ReactNode, useEffect, useState } from "react";
import { fetchImagesUrls } from "../utility";
import { ImageEditor } from "./ImageEditor";
import { acceptedFileFormats } from "../constants";

export const IntroductionEditor = ({
  component,
  handleComponentUpdate,
  handleFileUploadChanges,
  mediaItems,
  children,
}: {
  component: IntroductionComponent;
  handleComponentUpdate: (component: IntroductionComponent) => void;
  handleFileUploadChanges: (files: { [key: string]: File }) => void;
  mediaItems: MediaItems | undefined;
  children?: ReactNode;
}) => {
  const { coverPhoto } = component;
  const [introductionComponent, setIntroductionComponent] =
    useState<IntroductionComponent>({
      ...component,
    });
  const [coverPhotoURL, setCoverPhotoURL] = useState<string>();
  useEffect(() => {
    async function fetchImagesFromMedia() {
      const mediaItemId = parseInt(coverPhoto);
      if (mediaItemId) {
        const { uploaded, fullPath } = mediaItems?.[mediaItemId] || {
          uploaded: false,
          fullPath: "",
        };

        if (!uploaded) {
          return;
        }
        const urls = await fetchImagesUrls([
          {
            fullPath: fullPath,
            mimeType: "image/*",
          },
        ]);
        setCoverPhotoURL(urls[0]);
      }
    }
    fetchImagesFromMedia();
  }, [mediaItems, coverPhoto]);

  function handleFileUpload(file: File) {
    handleFileUploadChanges({ introductionCoverPhoto: file });
  }

  function handleChanges(e) {
    const updatedComponent = {
      ...introductionComponent,
      [e.target.name]: e.target.value,
    };
    setIntroductionComponent(updatedComponent);
    handleComponentUpdate(updatedComponent);
  }

  return (
    <div>
      <div className="flex flex-row">
        <div className="flex flex-col">
          <TextInput
            placeholder="Primary title"
            value={introductionComponent.title}
            name="title"
            onChange={handleChanges}
          />

          <TextInput
            placeholder="Subtitle"
            className="my-4"
            value={introductionComponent.subtitle}
            name="subtitle"
            onChange={handleChanges}
          />
        </div>
        {children && <div className="ml-16">{children}</div>}
      </div>

      <ImageEditor
        availableImageUrl={coverPhotoURL}
        acceptedFileFormats={acceptedFileFormats}
        description={`Upload a photo for the report. Max size 10 MB and accepted formats - ${acceptedFileFormats.join(", ")}`}
        handleFileUpload={handleFileUpload}
      />
    </div>
  );
};
