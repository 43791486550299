import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  CalendarIcon,
  CrossIcon,
  DriveTimeIcon,
  EditIcon,
  EyeOpenIcon,
  Heading,
  IconButton,
  KnownVehicleIcon,
  Pane,
  Popover,
  Position,
  Text,
  TimeIcon,
} from "evergreen-ui";
import {
  __r,
  ADMIN_SUBMITTED_SHIFTS_DETAIL_PAGE,
  ADMIN_USERS_EDIT_PAGE,
  ADMIN_WORKER_AVAILABILITY_PAGE,
  PLANNER_PERIOD_PLANNING_WORKER_SHIFT_REQUEST_OVERLAY,
} from "src/RouteMap";
import WorkerItem, {
  WorkerItemProps,
} from "src/components/shared/worker/WorkerItem";
import { memo, useEffect, useState } from "react";
import { Shift, WorkerApiType } from "src/types/apiTypes";
import { DisplayAddressSimple } from "src/components/shared/filters/AddressFilter";
import DurationValue from "../../../components/common/DurationValue";
import TravelDistanceValue from "../../../components/common/TravelDistanceValue";
import { FaExternalLinkAlt } from "react-icons/fa";

interface WorkerItemWithPopoverProps extends WorkerItemProps {
  setPopupShownId?: (id: number | null) => void;
  popupShownId?: number | null;
  shift: Shift | null;
}

const WorkerItemWithPopover = function ({
  worker,
  setDriver = undefined,
  setIsDriving = undefined,
  onClick = undefined,
  onDelete = undefined,
  setPopupShownId = undefined,
  popupShownId = undefined,
  shift,
  ...rest
}: WorkerItemWithPopoverProps) {
  const navigate = useNavigate();
  const { periodId } = useParams();

  const [isShown, setIsShown] = useState<boolean>(false);
  const hasWorkerShiftRequest = !!worker.workerShiftRequest;

  function navToDialog(workerShiftRequest) {
    navigate({
      pathname: __r(PLANNER_PERIOD_PLANNING_WORKER_SHIFT_REQUEST_OVERLAY, {
        workerShiftRequestId: workerShiftRequest.id,
        periodId: periodId,
      }),
    });
  }

  function navToAvailability(worker: WorkerApiType) {
    navigate(
      __r(ADMIN_WORKER_AVAILABILITY_PAGE, { workerId: worker.id, periodId }),
      {
        state: {
          backPath: window.location.pathname + window.location.search,
        },
      },
    );
  }

  function navToSubmittedShifts(worker: WorkerApiType, shift: Shift) {
    navigate(
      __r(ADMIN_SUBMITTED_SHIFTS_DETAIL_PAGE, {
        workerId: worker.id,
        shiftId: shift.originalShiftEntryId,
      }),
      {
        state: {
          backPath: window.location.pathname + window.location.search,
        },
      },
    );
  }

  function onClickPopover(e) {
    e.stopPropagation();
    setIsShown(true);

    if (setPopupShownId) {
      setPopupShownId(worker.id);
    }
  }

  function onCloseComplete() {
    setIsShown(false);
  }

  useEffect(() => {
    if (popupShownId !== worker.id) {
      setIsShown(false);
    }
  }, [popupShownId]);

  function goToDetailPage() {
    navigate({
      pathname: __r(ADMIN_USERS_EDIT_PAGE, {
        userId: worker.user?.id,
      }),
    });
  }

  return (
    <Popover
      bringFocusInside
      key={worker.id}
      isShown={isShown}
      onClose={onCloseComplete}
      shouldCloseOnExternalClick={true}
      shouldCloseOnEscapePress={true}
      position={Position.TOP}
      content={
        <Pane
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="gap-2"
          height={300}
          width={280}
          paddingX={20}
          paddingY={20}
          display="flex"
          flexDirection="column"
        >
          <Pane className="flex">
            <Pane className="grow">
              <Text className="flex gap-1 overflow-ellipsis py-1 pr-1 !text-xs !font-bold">
                <DriveTimeIcon />
                <TravelDistanceValue meters={worker.distanceToArea} />
              </Text>

              <Text className="flex gap-1 overflow-ellipsis py-1 pr-1 !text-xs !font-bold">
                <TimeIcon color="muted" size={14} />
                <DurationValue seconds={worker.travelDuration} />
              </Text>
            </Pane>

            <Pane className="shrink">
              {shift && (
                <IconButton
                  marginLeft="auto"
                  icon={FaExternalLinkAlt}
                  height={24}
                  onClick={(e) => {
                    navToSubmittedShifts(worker, shift);
                  }}
                />
              )}
              <IconButton
                marginLeft="auto"
                icon={CrossIcon}
                appearance="minimal"
                height={24}
                onClick={(e) => {
                  e.stopPropagation();
                  onCloseComplete();
                }}
              />
            </Pane>
          </Pane>
          <Pane className="grow">
            <Pane className="flex gap-1">
              <Pane className="flex flex-col">
                <Text className="overflow-ellipsis pr-1 !text-xs !font-bold capitalize">
                  {`${worker.user?.firstName}`}
                </Text>
                <Text className="overflow-ellipsis !text-xs !font-bold capitalize">
                  {`${worker.user?.lastName}`}
                </Text>
                <Heading size={200} className="overflow-ellipsis pr-1 !text-xs">
                  {`${worker.user?.email}`}
                </Heading>
                <Text className="overflow-ellipsis pr-1 !text-xs capitalize">
                  {`${worker.phone ?? "-"}`}
                </Text>
              </Pane>

              <Avatar
                className="ml-auto hover:opacity-90"
                src={worker.user!.profilePictureThumbnail ?? ""}
                size={60}
              />
            </Pane>
          </Pane>
          <DisplayAddressSimple address={worker.address} />

          {worker.carpool?.driver && (
            <Pane>
              <Pane className="bg-gray-50 py-4 pl-2">
                <Pane className="flex items-center gap-1">
                  <KnownVehicleIcon color="green" />
                  <Heading size={100}>Carpool bestuurder:</Heading>
                </Pane>
                <Pane>
                  <span className="overflow-ellipsis pr-1 !text-xs !font-bold capitalize">
                    {`${worker.carpool.driver?.user?.firstName}`}
                  </span>
                  <span className="overflow-ellipsis !text-xs !font-bold capitalize">
                    {`${worker.carpool.driver?.user?.lastName}`}
                  </span>
                </Pane>
              </Pane>
            </Pane>
          )}
          <Button
            paddingX={40}
            height={40}
            iconBefore={CalendarIcon}
            intent="success"
            appearance="primary"
            onClick={(e) => {
              e.stopPropagation();
              navToAvailability(worker);
            }}
          >
            Beschikbaarheid
          </Button>

          {hasWorkerShiftRequest && (
            <Button
              paddingX={40}
              height={40}
              appearance="primary"
              intent="warning"
              onClick={(e) => {
                e.stopPropagation();
                navToDialog(worker.workerShiftRequest);
              }}
              iconBefore={EyeOpenIcon}
            >
              Bekijk verzoek...
            </Button>
          )}

          <Button
            paddingX={40}
            height={40}
            onClick={(e) => {
              e.stopPropagation();
              goToDetailPage();
            }}
            iconBefore={EditIcon}
          >
            Bewerken
          </Button>
        </Pane>
      }
    >
      <a className="relative">
        <WorkerItem
          {...rest}
          setIsDriving={setIsDriving}
          setDriver={setDriver}
          onDelete={onDelete}
          onClick={onClickPopover}
          key={"workeritem" + worker.id}
          worker={worker}
        />
      </a>
    </Popover>
  );
};

export default memo(WorkerItemWithPopover);
