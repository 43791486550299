import { createContext, useContext } from "react";
import { Period, Timeslot } from "../types/apiTypes";
import { TimeslotMapById } from "../hooks/useTimeslotResources";

interface PlannerContextType {
  timeslots: Timeslot[];
  timeslotMapById: TimeslotMapById;
  period: Period | null;
  popupShownId?: number | null;
  setPopupShownId?: (id: number | null) => void;
  refresh: () => void;
}

export const PlannerContext = createContext({
  timeslots: [],
  timeslotMapById: {},
  period: null,
  refresh: () => {},
} as PlannerContextType);

export function usePlannerContext() {
  return useContext(PlannerContext);
}
