import { MediaItems, ReportComponent } from "@/types/apiTypes";
import { TextEditorPreviewComponent } from "./TextEditorPreviewComponent";
import { PageBreakPreviewComponent } from "./PageBreakPreviewComponent";
import { TablePreviewComponent } from "./TablePreviewComponent";
import { IntroductionPreviewComponent } from "./IntroductionPreviewComponent";
import { ImagePreviewComponent } from "./ImagePreviewComponent";
import { MultipleImagesPreviewComponent } from "./MultipleImagesPreviewComponent";

export const ReportModulePreviewComponent = ({
  component,
  mediaItems,
}: {
  component: ReportComponent;
  mediaItems: MediaItems;
}) => {
  const { type } = component;
  switch (type) {
    case "textEditor":
    case "bibliography":
    case "tableOfContents":
      return <TextEditorPreviewComponent content={component.content} />;
    case "pageBreak":
      return <PageBreakPreviewComponent />;
    case "table":
      return <TablePreviewComponent component={component} />;
    case "introduction":
      return <IntroductionPreviewComponent component={component} />;
    case "image":
    case "mapImage":
      return (
        <ImagePreviewComponent component={component} mediaItems={mediaItems} />
      );
    case "multipleImages":
      return <MultipleImagesPreviewComponent images={component.images} />;
    default:
      return null;
  }
};
