import {
  Badge,
  Button,
  CrossIcon,
  majorScale,
  Pane,
  Paragraph,
  PlusIcon,
  SelectMenu,
} from "evergreen-ui";
import { LabeledOption } from "@/types/appTypes";

type MultiSelectInputFieldProps = {
  title?: string;
  emptySelectText: string;
  options: LabeledOption[];
  selected: LabeledOption[];
  setSelected: Function;
  renderItem?: Function | null;
};

const MultiSelectInputField = function ({
  title = "Select an item",
  emptySelectText,
  options,
  selected,
  setSelected,
  renderItem = null,
  ...rest
}: MultiSelectInputFieldProps) {
  const selectedAsValues = selected.map((v) => v.value);

  const onSelect = (item) => {
    const selectedItems = [...selected, item];
    setSelected(selectedItems);
  };

  const onDeselect = (item) => {
    const deselectedItemIndex = selectedAsValues.indexOf(item.value);
    const selectedItems = selected.filter(
      (_item, i) => i !== deselectedItemIndex,
    );
    setSelected(selectedItems);
  };

  return (
    <Pane>
      <SelectMenu
        isMultiSelect
        title={title}
        options={options}
        selected={selectedAsValues}
        onSelect={onSelect}
        onDeselect={onDeselect}
        {...rest}
      >
        <Button className="flex gap-2" height={majorScale(5)}>
          {selected.length === 0 ? (
            <>
              <PlusIcon color="#C1C4D6" />
              Add Activity
            </>
          ) : (
            <Pane className="flex flex-wrap gap-1">
              {selected.map((item, index) => (
                <Badge
                  isInteractive
                  key={item.value}
                  color="neutral"
                  paddingX={2}
                  paddingY={3}
                  margin={2}
                  display="flex"
                  alignItems="center"
                  background={"#ececec"}
                  className={"border-1 hover:cursor my-8 gap-2"}
                  justifyContent="center"
                  cursor="pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeselect(item);
                  }}
                >
                  <Paragraph size="small" className="!font-bold !text-gray-800">
                    {item.label}
                  </Paragraph>
                  <CrossIcon className="hover:bg-amber-50" size={12} />
                </Badge>
              ))}
            </Pane>
          )}
        </Button>
      </SelectMenu>

      {renderItem && (
        <Pane className={"flex flex-col gap-2 py-2 pt-2"}>
          {selected.map((item, index) =>
            //@ts-ignore
            renderItem({ item, index, onDeselect }),
          )}
        </Pane>
      )}
    </Pane>
  );
};

export default MultiSelectInputField;
