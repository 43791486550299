import { Card, Heading, Pane, Text } from "evergreen-ui";
import { AreaActivityType, Id, Period, Planning } from "src/types/apiTypes";

import {
  AreaActivityTypeWithShiftsMap,
  augmentAreaActivities,
  classNames,
  roundNumber,
} from "src/lib/functions";
import { usePlannerContext } from "src/context/PlannerContext";
import PlannerRoundsItem, { ShiftAugmented } from "./PlannerRoundsItem";
import { parseISO } from "date-fns";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { TbSum, TbUrgent } from "react-icons/tb";

export interface PlannerForPeriodTableProps {
  prevPeriod?: Period | null;
  nextPeriod?: Period | null;
  period: Period;
  planning: Planning[];
}

export default function PlannerForPeriodTable({
  planning,
  prevPeriod,
  nextPeriod,
  period,
  ...rest
}: PlannerForPeriodTableProps) {
  const periodStartDate = parseISO(period.startAt);

  return (
    <Pane className="flex flex-wrap gap-2 lg:flex-nowrap">
      <Pane className="pb-2">
        {planning && (
          <PlannerTable
            startDate={periodStartDate}
            period={period}
            planning={planning}
          />
        )}
      </Pane>
      {/*))}*/}
    </Pane>
  );
}

function PlannerTable({ startDate, period, planning, ...rest }) {
  return (
    <Pane {...rest} className="planner-rounds-table table w-full">
      <PlannerTableHeaderRow weekStartDate={startDate} />

      {planning.map((plan, index) => (
        <PlannerRoundsTableRow
          key={plan.id}
          period={period}
          startDate={startDate}
          plan={plan}
        />
      ))}
    </Pane>
  );
}

function PlannerTableHeaderRow({ ...rest }) {
  return (
    <Pane className="planner-header-row py-5">
      {/* the first cell with the topic */}
      <PlannerHeaderCell
        key={"header-topic"}
        className="planner-th planner-header-topic"
      >
        <Heading size={100}>Activiteit</Heading>
      </PlannerHeaderCell>

      <th className="">
        <Heading size={100}>Dienst</Heading>
      </th>
    </Pane>
  );
}

function PlannerHeaderCell(props) {
  const { children, className, ...rest } = props;

  const classes = classNames(className, "py-2 px-2 text-center");

  return (
    <Pane className={classes} {...rest}>
      {children}
    </Pane>
  );
}

/**
 * @param rounds
 * @param plan
 * @constructor
 */
function PlannerRoundsTableRow({ startDate, period, plan }) {
  const { timeslotMapById } = usePlannerContext();

  const areaActivityTypesAugmented = useMemo(() => {
    return augmentAreaActivities(plan.areaActivityTypes, timeslotMapById);
  }, [plan.areaActivityTypes, timeslotMapById]);

  const allShiftsInArea: ShiftAugmented[] = useMemo(() => {
    const { areaActivityTypes } = areaActivityTypesAugmented;

    return areaActivityTypes
      .flatMap((aat: AreaActivityType) => {
        if (isEmpty(aat.shifts)) {
          return null;
        }

        return {
          name: aat.activityType.name,
          // startAt: aat.activityType.startAt,
          // endAt: aat.activityType.endAt,
          ...aat.shifts.at(0),
        };
      })
      .filter((s) => s) as ShiftAugmented[];
  }, [plan.areaActivityTypes]);

  const allShiftsInAreaMap = useMemo(() => {
    return allShiftsInArea.reduce((map, shift: ShiftAugmented) => {
      if (!shift) {
        return map;
      }

      map[shift?.originalShiftEntryId] = shift;

      return map;
    }, {});
  }, [allShiftsInArea]);

  return (
    <>
      <Pane className="tr planner-table-topic-row bg-gray-50">
        {/* the first cell with the area name and projct */}
        <Pane className="planner-table-topic-row-cell planner-table-cell w-[5rem] border-b border-t border-l bg-white py-2 text-center">
          {plan?.distanceKm >= 0 && (
            <Heading size={200}>{roundNumber(plan.distanceKm)}KM</Heading>
          )}

          <Text className="!text-xs !font-bold">{plan.name}</Text>

          <Heading size={100}>{plan.project.name}</Heading>

          <Pane className="flex items-center justify-center gap-1">
            <TbSum />
            <Heading className="!text-xs !font-normal">
              {roundNumber(plan.maxUrgency, 2)}
            </Heading>
          </Pane>
        </Pane>
        <Pane className="table-cell border-t" />
      </Pane>

      {areaActivityTypesAugmented.areaActivityTypes.map((entry, index) => (
        <ShiftRow
          key={index}
          entry={entry}
          startDate={startDate}
          plan={plan}
          period={period}
          allShiftsInArea={allShiftsInArea}
          allShiftsInAreaMap={allShiftsInAreaMap}
          parentMap={areaActivityTypesAugmented.parentMap}
        />
      ))}
    </>
  );
}

function ShiftRow({
  entry,
  startDate,
  plan,
  period,
  allShiftsInArea,
  allShiftsInAreaMap,
  parentMap,
}: {
  entry: AreaActivityTypeWithShiftsMap;
  startDate: Date;
  plan: Planning;
  period: Period;
  allShiftsInArea: ShiftAugmented[];
  allShiftsInAreaMap: Record<string, ShiftAugmented>;
  parentMap: Map<Id, AreaActivityTypeWithShiftsMap>;
}) {
  const shiftsInPeriod = entry.shiftsOfPeriod;

  const childAreaActivityType = shiftsInPeriod
    .flatMap((shift) => {
      const childAreaActivityTypes = parentMap.get(shift.originalShiftEntryId);

      return !isEmpty(childAreaActivityTypes) ? childAreaActivityTypes : null;
    })
    .filter((s) => s);

  return (
    <>
      <ShiftRowCard
        isChild={false}
        entry={entry}
        startDate={startDate}
        plan={plan}
        period={period}
        allShiftsInArea={allShiftsInArea}
        allShiftsInAreaMap={allShiftsInAreaMap}
        parentMap={parentMap}
      />

      {childAreaActivityType.map((entry, index) => (
        <ShiftRowCard
          isChild={true}
          key={index}
          entry={entry!}
          startDate={startDate}
          plan={plan}
          period={period}
          allShiftsInArea={allShiftsInArea}
          allShiftsInAreaMap={allShiftsInAreaMap}
          parentMap={parentMap}
        />
      ))}
    </>
  );
}

function ShiftRowCard({
  entry,
  startDate,
  plan,
  period,
  allShiftsInArea,
  allShiftsInAreaMap,
  parentMap,
  isChild,
}: {
  entry: AreaActivityTypeWithShiftsMap;
  startDate: Date;
  plan: Planning;
  period: Period;
  allShiftsInArea: ShiftAugmented[];
  allShiftsInAreaMap: Record<string, ShiftAugmented>;
  parentMap: Map<Id, AreaActivityTypeWithShiftsMap>;
  isChild: boolean;
}) {
  return (
    <Card key={"row-" + entry.id} className="planner-table-row w-full px-5">
      <Pane className="planner-table-row-cell planner-table-cell table-cell items-center border-b border-l bg-white px-2 py-4 text-center">
        <Pane className="h-full">
          <Pane className="flex h-full min-h-full flex-col">
            <Pane className="grow">
              <Text>{entry.activityType.name}</Text>
            </Pane>

            <Pane className="flex shrink items-center justify-center gap-1">
              <TbUrgent />
              <Heading className="!text-xs !font-normal">
                {roundNumber(entry.activityType.urgency, 2)}
              </Heading>
            </Pane>
          </Pane>
        </Pane>
      </Pane>

      <Pane className="table-cell">
        <Pane className="min-w-lg flex flex-wrap lg:flex-nowrap">
          <ShiftItems
            allShiftsInAreaMap={allShiftsInAreaMap}
            allShiftsInArea={allShiftsInArea}
            entry={entry}
            startDate={startDate}
            plan={plan}
            period={period}
            isChild={isChild}
          />
        </Pane>
      </Pane>
    </Card>
  );
}

function ShiftItems({
  entry,
  startDate,
  plan,
  period,
  allShiftsInArea,
  allShiftsInAreaMap,
  isChild,
}: {
  entry: AreaActivityTypeWithShiftsMap;
  startDate: Date;
  plan: Planning;
  period: Period;
  allShiftsInArea: ShiftAugmented[];
  allShiftsInAreaMap: Record<string, ShiftAugmented>;
  isChild: boolean;
}) {
  return (
    <>
      {entry.shiftsOfPeriod.map((shift: ShiftAugmented, index: number) => (
        <ShiftItem
          key={index}
          entry={entry}
          shift={shift}
          index={index}
          startDate={startDate}
          plan={plan}
          period={period}
          allShiftsInArea={allShiftsInArea}
          allShiftsInAreaMap={allShiftsInAreaMap}
          isChild={isChild}
        />
      ))}

      {entry.shiftsOfPeriod.length === 0 && (
        <PlannerRoundsItem
          key={
            "item" + entry.activityType.id + entry.shifts.length + "empty-shift"
          }
          entry={entry}
          isChild={false}
          shifts={entry.allShifts ?? []}
          startDate={startDate}
          index={entry.allShifts.length}
          shift={null}
          areaActivityType={entry}
          plan={plan}
          period={period}
          allShiftsInArea={allShiftsInArea}
          allShiftsInAreaMap={allShiftsInAreaMap}
        />
      )}
    </>
  );
}

function ShiftItem({
  entry,
  shift,
  index,
  startDate,
  plan,
  period,
  allShiftsInArea,
  allShiftsInAreaMap,
  isChild,
}: {
  entry: AreaActivityTypeWithShiftsMap;
  shift: ShiftAugmented;
  index: number;
  startDate: Date;
  plan: Planning;
  period: Period;
  allShiftsInArea: ShiftAugmented[];
  allShiftsInAreaMap: Record<string, ShiftAugmented>;
  isChild: boolean;
}) {
  return (
    <>
      <PlannerRoundsItem
        key={"item" + entry.activityType.id + (shift?.id ?? index)}
        entry={entry}
        shifts={entry.allShifts ?? []}
        startDate={startDate}
        index={index}
        shift={shift ?? null}
        areaActivityType={entry}
        plan={plan}
        period={period}
        allShiftsInArea={allShiftsInArea}
        allShiftsInAreaMap={allShiftsInAreaMap}
        isChild={isChild}
      />
    </>
  );
}
