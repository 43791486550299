import { useState } from "react";
import { useLoadResource } from "src/lib/request-hooks";
import { isEmpty } from "lodash";
import { Carpool, Id } from "../types/apiTypes";
import { useApi } from "../context/AxiosContext";

interface UseCarpoolsProps {
  date: string | null;
  timeslotUid: string | null;
  periodId: Id;
  carpoolId: string | number;
  setCarpool: Function;
}

export default function useCarpools({
  date = null,
  timeslotUid = null,
  periodId,
  carpoolId,
  setCarpool,
}: UseCarpoolsProps) {
  const { apiInstance } = useApi();

  const [carpools, setCarpools] = useState<Carpool[]>([]);

  const searchParams = new URLSearchParams();

  searchParams.set("limit", "1000");

  if (date) {
    searchParams.set("date", date);
  }

  if (timeslotUid) {
    searchParams.set("timeslotUid", timeslotUid);
  }

  const find = () =>
    apiInstance!.adminCarpool.findManyForPeriod(periodId, searchParams);
  const findOne = () => apiInstance!.adminCarpool.findOne(carpoolId);

  function setSelectedOption(v) {
    setCarpool({
      value: v.id,
      label: v.worker.user.firstName + " " + v.worker.user.lastName,
    });
  }

  // find the selected worker.
  useLoadResource(findOne, setSelectedOption, !!carpoolId);

  return {
    ...useLoadResource(find, setCarpools, isEmpty(carpools)),
    carpools,
  };
}
