import {
  Alert,
  Button,
  Card,
  FormField,
  Heading,
  InfoSignIcon,
  majorScale,
  Pane,
  Paragraph,
  Spinner,
  TextInputField,
  toaster,
  TrashIcon,
} from "evergreen-ui";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useApi } from "../../../context/AxiosContext";
import { useDoRequest, useLoadResource } from "../../../lib/request-hooks";
import Block from "../../../components/common/Block";
import Form from "../../../components/common/Form";
import Actions from "../../../components/common/Actions";
import { parseISO } from "date-fns";
import SelectInputField from "../../../components/formfields/SelectInputField";
import DateRangeInput from "../../../components/formfields/DateRangeInput";
import Divider from "../../../components/common/Divider";
import { ActivityType, ActivityTypeOption } from "../../../types/apiTypes";
import { useApp } from "../../../context/AppContext";
import useActivityTypeOptions from "../../../hooks/useActivityTypeOptions";
import { classNames } from "@/lib/functions";

function useActivityTypeFormFields() {
  const { apiInstance } = useApi();
  const { activityTypeId, projectId } = useParams();

  const [name, setName] = useState<string>("");
  const [minDaysBetweenRounds, setMinDaysBetweenRounds] = useState<string>("");
  const [numberOfRounds, setNumberOfRounds] = useState<string>("");
  const [selectedType, setSelectedType] = useState<ActivityTypeOption | null>(
    null,
  );
  const [startAt, setStartAt] = useState<Date | null>(null);
  const [endAt, setEndAt] = useState<Date | null>(null);

  const {
    handle,
    errorMessage: submitErrorMessage,
    isLoading: isSubmitting,
    setValidationErrors,
    validationErrors,
  } = useDoRequest();

  const doSubmit = async () => {
    let request;

    const data = {
      name,
      type: selectedType ? selectedType!.value : null,
      numberOfRounds: parseInt(numberOfRounds),
      minDaysBetweenRounds: parseInt(minDaysBetweenRounds),
      startAt,
      endAt,
    };

    if (activityTypeId) {
      request = apiInstance!.adminActivityTypes.update(activityTypeId, data);
    } else {
      request = apiInstance!.adminActivityTypes.createForProject(
        projectId!,
        data,
      );
    }

    await handle(request);

    toaster.success("Saved!");
  };

  const find = () => apiInstance!.adminActivityTypes.findOne(activityTypeId!);
  const remove = () => apiInstance!.adminActivityTypes.remove(activityTypeId!);

  const setData = (activityType) => {
    setName(activityType.name);
    setMinDaysBetweenRounds(activityType.minDaysBetweenRounds);
    setNumberOfRounds(activityType.numberOfRounds);
    setSelectedType(activityType.type);
    setStartAt(activityType.startAt ? parseISO(activityType.startAt) : null);
    setEndAt(activityType.endAt ? parseISO(activityType.endAt) : null);
  };

  function doRemove() {
    if (window.confirm("Are you sure you want to delete this activity type?")) {
      return remove();
    }
  }

  function resetForm() {
    setName("");
    setEndAt(null);
    setMinDaysBetweenRounds("");
    setNumberOfRounds("");
    setStartAt(null);
    setSelectedType(null);
  }

  return {
    ...useLoadResource(find, setData, !!activityTypeId),
    resetForm,
    name,
    setName,
    selectedType,
    setSelectedType,
    startAt,
    setStartAt,
    endAt,
    setEndAt,
    numberOfRounds,
    setNumberOfRounds,
    minDaysBetweenRounds,
    setMinDaysBetweenRounds,
    isSubmitting,
    doRemove,
    submitContext: {
      doSubmit,
      isSubmitting,
      submitErrorMessage,
      validationErrors,
      setValidationErrors,
    },
  };
}

export default function ActivityTypesEditPage() {
  const { activityTypeId, projectId } = useParams();
  const navigate = useNavigate();
  const { contentRef } = useApp();

  const {
    name,
    setName,
    selectedType,
    setSelectedType,
    startAt,
    setStartAt,
    endAt,
    setEndAt,
    numberOfRounds,
    setNumberOfRounds,
    minDaysBetweenRounds,
    setMinDaysBetweenRounds,
    isLoading,
    submitContext,
    resetForm,
    refreshCount,
    setRefreshCount,
    doRemove,
  } = useActivityTypeFormFields();

  const { activityTypeOptions, isLoading: staticIsLoading } =
    useActivityTypeOptions();

  function onSelectActivityType(activityType: ActivityTypeOption) {
    setSelectedType(activityType);
    setMinDaysBetweenRounds(activityType.minDaysBetweenRounds.toString());
    setNumberOfRounds(activityType.numberOfRounds.toString());
    setStartAt(activityType.startAt ? parseISO(activityType?.startAt) : null);
    setEndAt(activityType.endAt ? parseISO(activityType?.endAt) : null);
    setRefreshCount(refreshCount + 1);
  }

  async function submit() {
    await submitContext.doSubmit();

    navigate(`/projects/${projectId}/activity-types`);
  }

  async function doRemoveCb() {
    await doRemove();

    navigate(`/projects/${projectId}/activity-types`);
  }

  async function submitAndNew() {
    try {
      await submitContext.doSubmit();

      resetForm();
      setRefreshCount(refreshCount + 1);

      // if an area id was present, we need to go the new url.
      if (activityTypeId) {
        navigate(`/projects/${projectId}/activity-types/add`);
      }
    } finally {
      if (contentRef.current) {
        contentRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  }

  return (
    <Block>
      <Block className="flex w-full gap-2">
        <Heading marginBottom={20}>
          {!activityTypeId ? "Maak een nieuwe " : "Edit "}
          Activiteit
        </Heading>

        {isLoading && (
          <Block className="flex justify-end">
            <Spinner size={20} />
          </Block>
        )}
      </Block>

      <Form staticIsLoading={staticIsLoading && isLoading === false}>
        <>
          <FormField
            hint="Selecteer de activiteit. Dit is verplicht. Dit gebruiken we om verdere details in te vullen."
            label="Type"
          >
            <SelectInputField
              closeOnSelect
              selected={selectedType}
              setSelected={onSelectActivityType}
              title="Selecteer type"
              emptySelectText="Selecteer het type activiteit..."
              options={Object.values(activityTypeOptions)}
              validationMessage={submitContext.validationErrors?.type?.join(
                ", ",
              )}
            />
          </FormField>
          {selectedType && (
            <InfoBox description={selectedType?.description}></InfoBox>
          )}
          <Divider />
          <FormField>
            <TextInputField
              required
              isInvalid={!!submitContext.validationErrors?.name}
              validationMessage={submitContext.validationErrors?.name?.join(
                ", ",
              )}
              name="name"
              label="Name"
              placeholder="e.g., vleermuizen... "
              value={name}
              onChange={(e) => setName(e.target.value)}
              marginBottom={0}
              inputHeight={40}
              maxWidth={500}
            />
          </FormField>

          <FormField>
            <TextInputField
              required
              marginBottom={0}
              inputHeight={40}
              maxWidth={300}
              name="Rounds"
              label="Rondes"
              type="text"
              inputMode="numeric"
              pattern="[1-9][0-9]+"
              min="1"
              placeholder="e.g., 5"
              value={numberOfRounds}
              onChange={(e) => setNumberOfRounds(e.target.value)}
              isInvalid={!!submitContext.validationErrors?.numberOfRounds}
              validationMessage={submitContext.validationErrors?.numberOfRounds?.join(
                ", ",
              )}
            />
          </FormField>

          <FormField>
            <TextInputField
              disabled
              marginBottom={0}
              hint={
                "Niet aanpasbaar, het type van de activiteit bepaald de waarde van dit veld."
              }
              inputHeight={40}
              maxWidth={300}
              name="daysBetweenRounds"
              label="Dagen tussen rondes"
              placeholder=".."
              type="number"
              value={minDaysBetweenRounds}
              onChange={(e) => setMinDaysBetweenRounds(e.target.value)}
            />
          </FormField>

          <FormField label="Start / einddatum">
            <DateRangeInput
              key={refreshCount}
              validationErrors={submitContext.validationErrors}
              setValidationErrors={submitContext.setValidationErrors}
              fromValue={startAt}
              setFromValue={setStartAt}
              toValue={endAt}
              setToValue={setEndAt}
            />
          </FormField>

          {submitContext.submitErrorMessage && (
            <Pane>
              <Alert
                marginTop={10}
                intent="danger"
                title={submitContext.submitErrorMessage}
              />
            </Pane>
          )}

          <Actions marginTop={10} marginBottom={10}>
            <Actions.Button
              onClick={() => navigate(`/projects/${projectId}/activity-types`)}
              disabled={!!submitContext.isSubmitting}
              height={majorScale(5)}
            >
              Terug
            </Actions.Button>

            <Actions.Button
              onClick={submit}
              isLoading={!!submitContext.isSubmitting}
              appearance="primary"
              intent="success"
              height={majorScale(5)}
            >
              Opslaan
            </Actions.Button>

            <Actions.Button
              onClick={submitAndNew}
              isLoading={!!submitContext.isSubmitting}
              appearance="primary"
              height={majorScale(5)}
            >
              Opslaan en nieuwe
            </Actions.Button>
          </Actions>

          {activityTypeId && (
            <Card title={"Danger zone"} elevation={1} className={"mt-8 p-8"}>
              <Pane className="flex w-full items-center">
                <Heading size={400}>Danger zone</Heading>
                <Button
                  onClick={doRemoveCb}
                  iconBefore={<TrashIcon />}
                  intent="danger"
                  appearance="primary"
                  marginLeft="auto"
                  height={majorScale(5)}
                >
                  Delete
                </Button>
              </Pane>
            </Card>
          )}
        </>
      </Form>
    </Block>
  );
}

export function InfoBox({
  className,
  description,
}: {
  className?: string;
  description: string;
}) {
  return (
    <Pane
      className={classNames(
        "flex items-center gap-2 rounded border border-gray-200 bg-gray-50 py-4 px-3",
        className,
      )}
    >
      <InfoSignIcon color="muted" size={20} />
      <Paragraph size="small">
        <span className="font-bold text-gray-700"></span>
        {description}
      </Paragraph>
    </Pane>
  );
}
