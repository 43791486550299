import { ImportObjectCsvRow } from "@/types/appTypes";
import React, { useEffect, useRef, useState } from "react";
import Block from "@/components/common/Block";
import { Button, Heading, Paragraph, Strong, TickIcon } from "evergreen-ui";
import { classNames, shortenString } from "@/lib/functions";
import { MdError } from "react-icons/md";

const ImportStatusIconMap = {
  success: <TickIcon color="success" size={20} />,
  error: <MdError color="red" size={20} />,
  warning: <MdError color="orange" size={20} />,
};

type ObjectCsvImportListProps = {
  doImportRow: (
    csvObject: ImportObjectCsvRow,
    index: number,
    forceImport?: boolean,
  ) => Promise<any>;
  rowsToImport: ImportObjectCsvRow[];
  setRowsToImport: (rows: any[]) => void;
  onClickImportRow: (geometry: any) => void;
};

export default function ObjectCsvImportList(props: ObjectCsvImportListProps) {
  const { rowsToImport, doImportRow, setRowsToImport, onClickImportRow } =
    props;

  const [procesedCount, setProcessedCount] = useState(0);
  const statusMap = useRef({});

  async function doImportAllRows() {
    let count = 0;

    for (const row of rowsToImport) {
      if (statusMap.current[count]?.geometry) {
        count += 1;

        continue;
      }

      const statusRow = await doImportRow(row, count);

      statusMap.current[statusRow.index] = statusRow;

      if (statusRow.status === "success") {
        onClickImportRow(statusRow.geometry);
      }

      setProcessedCount((count) => count + 1);
      count += 1;
    }
  }

  async function onClick(
    objectRow: ImportObjectCsvRow,
    index: number,
    forceImport = false,
  ) {
    if (!forceImport && statusMap.current[index].geometry) {
      onClickImportRow(statusMap.current[index].geometry);

      return;
    }

    const statusRow = await doImportRow(objectRow, index, forceImport);

    statusMap.current[statusRow.index] = statusRow;

    if (statusMap.current[index].geometry) {
      onClickImportRow(statusMap.current[index].geometry);
    }

    setProcessedCount((count) => count + 1);
  }

  useEffect(() => {
    if (rowsToImport.length === 0) {
      setProcessedCount(0);
      statusMap.current = {};

      return;
    }

    setProcessedCount(rowsToImport.length);

    statusMap.current = rowsToImport.reduce((acc, row, index) => {
      acc[index - 1] = {
        status: "pending",
        message: "Pending",
      };

      return acc;
    }, {});
  }, [rowsToImport]);

  function getImportStatus(index: number) {
    return statusMap.current[index]
      ? statusMap.current[index]
      : { status: "pending", message: "Pending" };
  }

  return (
    <Block className="pb-32">
      <Block className="my-2 flex justify-center gap-2 border py-3 shadow">
        <Button appearance="primary" onClick={() => doImportAllRows()}>
          Start import
        </Button>
        <Button
          className="justify-self-end"
          onClick={() => setRowsToImport([])}
        >
          Cancel
        </Button>
      </Block>
      <Block className="max-h-[calc(100vh-20rem)] overflow-auto border-t shadow">
        <Block className={classNames("cursor-pointer flex-col gap-3")}>
          {rowsToImport.map((object, index) => (
            <Block
              onClick={() => onClick(object, index)}
              className={classNames(
                "relative items-center gap-1 border bg-white shadow-sm hover:border-blue-200 hover:bg-blue-50",
              )}
              title={[
                object?.Adres,
                object?.Huisnummer,
                object?.Woonplaats,
                object?.Postcode,
              ].join(" ")}
              key={
                object.Adres +
                object.Huisnummer +
                object.Woonplaats +
                object.Postcode
              }
            >
              <Block className="flex justify-end gap-2 px-1">
                <Heading title={getImportStatus(index).message} size={200}>
                  {ImportStatusIconMap[getImportStatus(index).status]}
                </Heading>
              </Block>
              <Block
                className={classNames("flex items-center justify-between py-2")}
              >
                <Block className="flex gap-2">
                  <Block className="flex flex-col items-center justify-center border-r px-2">
                    {/*<CgPinAlt color={'#3366FF'} size={15} />*/}
                    <Paragraph
                      title={"Csv row index: " + index.toString() + 1}
                      size={300}
                      className="!text-gray-800"
                    >
                      {index + 1}
                    </Paragraph>
                  </Block>
                  <Block className="flex-nowrap px-1 hover:!text-white">
                    <Heading title={object.Straatnaam} size={200}>
                      {shortenString(object.Straatnaam, 30)}
                    </Heading>
                    <Heading title={object.Huisnummer} size={200}>
                      {shortenString(object.Huisnummer, 30)}
                    </Heading>
                    <Heading title={object.Woonplaats} size={200}>
                      {shortenString(object.Woonplaats, 30)}
                    </Heading>
                    <Heading title={object.Postcode} size={200}>
                      {shortenString(object.Postcode, 30)}
                    </Heading>
                    <Block className="flex items-center gap-2 border-t py-1">
                      <Strong size={300}>status:</Strong>
                      <Heading
                        title={getImportStatus(index).message}
                        size={100}
                      >
                        {getImportStatus(index).message}
                      </Heading>
                    </Block>
                  </Block>
                  <Block>
                    {getImportStatus(index).status === "warning" && (
                      <Button onClick={() => onClick(object, index, true)}>
                        Force import
                      </Button>
                    )}
                  </Block>
                </Block>
              </Block>
            </Block>
          ))}
          <Block className="h-64" />
        </Block>
      </Block>
    </Block>
  );
}
