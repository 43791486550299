import {
  AddReportPosition,
  AreaGroup,
  Report,
  ReportComponent,
  ReportComponentIds,
  ReportData,
  TableComponentColumns,
} from "@/types/apiTypes";
import { Dialog, RadioGroup, toaster } from "evergreen-ui";
import { useState } from "react";
import { DialogFooterComponent } from "./DialogFooterComponent";
import {
  generateAreaObservationsComponent,
  generateAreaVisitsComponent,
  generateImageComponent,
  generatePageBreakComponent,
  generateTableComponent,
  generateTableOfContentsComponent,
  generateTextEditorComponent,
} from "../utility";
import { moduleTypeOptions, positionOptions } from "../constants";
import { AreasListComponent } from "./AreasListComponent";
import { useApi } from "@/context/AxiosContext";
import { ImageUploadComponent } from "./ImageUploadComponent";
import {
  generateNewColumn,
  GenerateTableComponent,
} from "./GenerateTableComponent";

export function AddReportModuleDialogComponent({
  projectData,
  report,
  addReportModuleDialogVisibility,
  saveLoaderVisibility,
  handleOnClose,
  handleOnSave,
}: {
  projectData: ReportData;
  report: Report;
  addReportModuleDialogVisibility: boolean;
  saveLoaderVisibility: boolean;
  handleOnClose: () => void;
  handleOnSave: (
    position: AddReportPosition,
    components: ReportComponent[],
  ) => void;
}) {
  const {
    areas,
    project: { id },
  } = projectData;

  const [positionValue, setPositionValue] =
    useState<AddReportPosition>("before");
  const [moduleType, setModuleType] =
    useState<ReportComponentIds>("textEditor");
  const [image, setImage] = useState<{ file?: File; description: string }>({
    description: "",
  });
  const [columns, setColumns] = useState<TableComponentColumns[]>([
    generateNewColumn(),
  ]);

  const [isConfirmEnabled, setIsConfirmEnabled] = useState(true);
  const { apiInstance } = useApi();

  const [areaGroups, setAreaGroups] = useState<AreaGroup[]>([]);

  async function handleConfirmDialog() {
    let components: ReportComponent[] = [];
    switch (moduleType) {
      case "textEditor":
        components.push(generateTextEditorComponent());
        break;

      case "pageBreak":
        components.push(generatePageBreakComponent());
        break;

      case "areaWithVisits":
        areaGroups.forEach((e) => {
          components.push(
            generateAreaVisitsComponent(e, areaGroups.length === 1),
          );
        });
        break;

      case "areaWithObservations":
        areaGroups.forEach((e, index) => {
          components.push(
            generateAreaObservationsComponent(
              e,
              index,
              report.additionalData.observationTypes,
            ),
          );
        });
        break;

      case "image":
        const imageResponse = await handleFileUpload(image.file!);
        components.push(
          generateImageComponent(imageResponse!, image.description),
        );
        break;

      case "tableOfContents":
        try {
          components.push(
            generateTableOfContentsComponent(report.components),
            generatePageBreakComponent(),
          );
          break;
        } catch (err) {
          toaster.danger(`${err}`);
        }
        break;

      case "table":
        components.push(generateTableComponent(columns, [], "visit"));
        break;
    }

    if (components.length) {
      handleOnSave(positionValue, components);
    }
  }

  function handleModuleOnChange(event) {
    const option = event.target.value as ReportComponentIds;
    setModuleType(option);
    if (
      option === "image" ||
      option === "table" ||
      ((option === "areaWithVisits" || option === "areaWithObservations") &&
        areas.length > 1)
    ) {
      setIsConfirmEnabled(false);
    } else {
      setIsConfirmEnabled(true);
    }

    if (option === "table") {
      setColumns([generateNewColumn()]);
    }
  }

  function handleFileUpload(file: File) {
    try {
      return apiInstance!.adminReports.uploadMedia(id, file);
    } catch (e) {
      console.error("Filed to upload media", e);
    }
  }

  function handleImageSelection(uploadedImage: {
    file?: File;
    description: string;
  }) {
    setImage(uploadedImage);
    setIsConfirmEnabled(!!(uploadedImage.file && uploadedImage.description));
  }

  function handleAreaGroupsChange(areaGroups: AreaGroup[]) {
    setAreaGroups(areaGroups);
    setIsConfirmEnabled(!!areaGroups.length);
  }

  return (
    <Dialog
      title="Add Module"
      width="90%"
      shouldCloseOnOverlayClick={false}
      topOffset="2%"
      shouldCloseOnEscapePress={false}
      isShown={addReportModuleDialogVisibility}
      onCloseComplete={handleOnClose}
      footer={
        <DialogFooterComponent
          saveLoaderVisibility={saveLoaderVisibility}
          handleCloseDialog={handleOnClose}
          handleConfirmDialog={handleConfirmDialog}
          isConfirmEnabled={isConfirmEnabled}
        />
      }
    >
      <div className="flex">
        <RadioGroup
          label="Position"
          size={16}
          value={positionValue}
          options={positionOptions}
          onChange={(event) =>
            setPositionValue(event.target.value as AddReportPosition)
          }
        />
        <RadioGroup
          marginLeft={72}
          label="Module Type"
          size={16}
          value={moduleType}
          options={moduleTypeOptions}
          onChange={handleModuleOnChange}
        />
      </div>
      <div className="mt-4">
        {moduleType === "table" && (
          <GenerateTableComponent
            columns={columns}
            handleColumnsChange={(columns) => {
              setColumns(columns);
              setIsConfirmEnabled(true);
            }}
          />
        )}
        {moduleType === "image" && (
          <ImageUploadComponent
            image={image!}
            handleImageSelection={handleImageSelection}
          />
        )}
        {(moduleType === "areaWithVisits" ||
          moduleType === "areaWithObservations") && (
          <div className="rounded border border-zinc-200 p-4 drop-shadow-sm">
            <AreasListComponent
              areas={areas}
              handleAreaGroupsChange={handleAreaGroupsChange}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
}
