import { EmptyState, Pane, SearchIcon, Table } from "evergreen-ui";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "../../../context/AxiosContext";
import { useLoadResource } from "../../../lib/request-hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InviteDialog from "../InviteDialog";
import Actions from "src/components/common/Actions";
import StandardTable from "src/components/table/StandardTable";
import DateValue from "src/components/common/DateValue";
import Block from "src/components/common/Block";
import { Invite } from "src/types/apiTypes";
import TextClipBoard from "../../../components/common/TextClipBoard";
import Page from "../../../components/common/Page";

const useInvites = function () {
  const [searchParams] = useSearchParams();
  const { apiInstance } = useApi();

  const [invites, setInvites] = useState<Invite[]>([]);
  const request = () => apiInstance!.adminInvites.findMany(searchParams);

  const setData = (invites) => {
    setInvites(invites);
  };

  return {
    ...useLoadResource(request, setData, true),
    invites,
  };
};

export default function InvitesPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);

  const { invites, meta, isLoading, refresh } = useInvites();

  const navTo = (user) => {
    navigate(`/admin/invites/${user.id}`);
  };

  const onCloseDialog = () => {
    setIsInviteDialogOpen(false);
    refresh();
  };

  return (
    <Page>
      <InviteDialog isOpen={isInviteDialogOpen} close={onCloseDialog} />

      {/*<PageTitle>{t('invites_page.title')}</PageTitle>*/}

      <Pane className="flex w-full gap-2">
        {/*<SubSidebarLeft*/}
        {/*    closedContent={*/}
        {/*        <IconButton*/}
        {/*            appearance="minimal"*/}
        {/*            icon={SearchIcon}*/}
        {/*            type="button"*/}
        {/*        />*/}
        {/*    }*/}
        {/*    title={"Filters"}>*/}
        {/*    <UsersFilter />*/}
        {/*</SubSidebarLeft>*/}
        <Block fullWidth>
          <Actions marginBottom={10}>
            <Actions.Button
              onClick={() => setIsInviteDialogOpen(true)}
              appearance="primary"
            >
              Invite a new Worker
            </Actions.Button>
          </Actions>
          <StandardTable
            refresh={refresh}
            meta={meta}
            isLoading={isLoading}
            data={invites}
            emptyState={<EmptyUsersState />}
          >
            <Table.Head>
              <Table.TextHeaderCell minWidth={200}>Email</Table.TextHeaderCell>
              <Table.TextHeaderCell>Role</Table.TextHeaderCell>
              <Table.TextHeaderCell>Created / updated</Table.TextHeaderCell>
              <Table.TextHeaderCell>Expires</Table.TextHeaderCell>
              <Table.TextHeaderCell maxWidth={100}>Url</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body height="auto">
              {invites.map((invite) => (
                <Table.Row
                  key={invite.id}
                  isSelectable
                  onSelect={() => {
                    navTo(invite);
                  }}
                >
                  <Table.TextCell minWidth={200}>{invite.email}</Table.TextCell>
                  <Table.TextCell>{invite.role}</Table.TextCell>
                  <Table.TextCell>
                    <DateValue date={invite.updatedAt} />
                  </Table.TextCell>
                  <Table.TextCell>
                    <DateValue date={invite.expiresAt} />
                  </Table.TextCell>
                  <Table.TextCell maxWidth={100}>
                    <TextClipBoard value={invite.url} />
                  </Table.TextCell>
                </Table.Row>
              ))}
            </Table.Body>
          </StandardTable>
        </Block>
      </Pane>
    </Page>
  );
}

function EmptyUsersState() {
  return (
    <EmptyState
      background="light"
      title="No invites found"
      orientation="horizontal"
      icon={<SearchIcon color="#C1C4D6" />}
      iconBgColor="#EDEFF5"
      description="Invites appear when you created new invites"
    />
  );
}
