import BaseApi from "./_baseApi";

import { doRequest } from "./_helpers";
import { createSearchParams } from "react-router-dom";
import { SearchParams } from "../types/appTypes";
import { CsvDataUrl, Id, WorkerShift } from "../types/apiTypes";

export default class AdminWorkerShifts extends BaseApi {
  /**
   * @param workerId
   * @param searchParams
   */
  public findManyForWorker(
    workerId: Id,
    searchParams: SearchParams = {},
  ): Promise<WorkerType[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/workers/${workerId}/shifts${query}`,
    });
  }

  public findMany(searchParams: SearchParams = {}): Promise<WorkerType[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/worker-shifts${query}`,
    });
  }

  public async approve(workerId: Id, shiftId: Id, data): Promise<WorkerType> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/workers/${workerId}/shifts/${shiftId}/approve`,
      data,
    });
  }

  public async reject(workerId: Id, shiftId: Id, data): Promise<WorkerShift> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/workers/${workerId}/shifts/${shiftId}/reject`,
      data,
    });
  }

  public async fixStatusUpdates(data): Promise<any> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/shifts/status-updates`,
      data,
    })
  }

  public async cancel(workerId: Id, shiftId: Id): Promise<WorkerShift> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/workers/${workerId}/shifts/${shiftId}/cancel`,
    });
  }

  public findOneForWorker(workerId: Id, shiftId: Id): Promise<WorkerType> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/workers/${workerId}/shifts/${shiftId}`,
    });
  }

  public findOne(shiftId: Id): Promise<WorkerType> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/worker-shifts/${shiftId}`,
    });
  }

  /**
   * @param workerId
   * @param searchParams
   */
  public exportToCsv(
    workerId: Id,
    searchParams: SearchParams = {},
  ): Promise<CsvDataUrl> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";

    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/workers/${workerId}/shifts/csv${query}`,
    });
  }
}
