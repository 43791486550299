import { Heading, Pane } from "evergreen-ui";
import { Area, Form, ObservationFeatureCollection } from "@/types/apiTypes";
import ObservationItem from "@/pages/submitted-worker-shifts/components/ObservationItem";
import Divider from "@/components/common/Divider";
import useObservation from "@/pages/submitted-worker-shifts/hooks/useObservation";
import { BsBinocularsFill } from "react-icons/bs";

type ObservationListProps = {
  observations?: ObservationFeatureCollection;
  objects: Object[];
  observationApi: ReturnType<typeof useObservation>;
  area: Area;
  forms: Form[];
};

export default function ObservationList({
  observations,
  observationApi,
  area,
  forms,
}: ObservationListProps) {
  const features = observations?.features || [];

  return (
    <Pane className="my-8">
      <Heading>
        <span className="flex items-center gap-1">
          <BsBinocularsFill color="#5f5f5f" />
          {`Observations (${features.length})`}
        </span>
      </Heading>
      <Divider />
      {features.map((observation, index) => (
        <ObservationItem
          key={observation.id}
          count={index + 1}
          totalCount={features.length}
          area={area}
          observationApi={observationApi}
          observation={observation}
          form={forms.find((form) => form.id === observation.properties.formId)}
        />
      ))}
    </Pane>
  );
}
