import { classNames } from "@/lib/functions";
import React from "react";

const classMap = {
  fullWidth: "w-full",
  fullHeight: "h-full",
  center: "flex justify-center items-center",
};

interface BlockProps extends React.HTMLAttributes<HTMLDivElement> {
  style?: React.CSSProperties;
  fullWidth?: boolean;
  fullHeight?: boolean;
  center?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export default function Block(props: BlockProps) {
  const { style, fullWidth, fullHeight, center, className, children, ...rest } =
    props;

  const blockClassNames = classNames(
    "block",
    className,
    fullWidth ? classMap.fullWidth : "",
    fullHeight ? classMap.fullHeight : "",
    center ? classMap.center : "",
  );

  const { ...divProps } = rest;

  return (
    <div style={style} className={blockClassNames} {...divProps}>
      {children}
    </div>
  );
}
