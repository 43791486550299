import { BanCircleIcon, CrossIcon, TickCircleIcon } from "evergreen-ui";
import MoonIcon from "../common/illustrations/MoonIcon";
import SunriseIcon from "../common/illustrations/SunriseIcon";
import SunsetIcon from "../common/illustrations/SunsetIcon";
import { MdPendingActions } from "react-icons/md";
import {
  FaFileCircleQuestion,
  FaPersonWalkingDashedLineArrowRight,
} from "react-icons/fa6";
import { RiErrorWarningFill } from "react-icons/ri";
import { FcStart } from "react-icons/fc";
import { FaWalking } from "react-icons/fa";
import { IoSunny } from "react-icons/io5";

export const TimeslotIconMap = {
  moon: MoonIcon,
  sunrise: SunriseIcon,
  sunset: SunsetIcon,
};

export const TimeslotTypeToIconMap = {
  morning: IoSunny,
  evening: SunsetIcon,
  night: MoonIcon,
  midnight: FcStart,
};

export const ShiftStatusIconMap = {
  pending: MdPendingActions,
  published: TickCircleIcon,
  planned: FaPersonWalkingDashedLineArrowRight,
  expired: CrossIcon,
  cancelled: BanCircleIcon,
  rejected: BanCircleIcon,
  submitted: TickCircleIcon,
  started: FaWalking,
  stopped: BanCircleIcon,
  approved: TickCircleIcon,
  changesRequested: FaFileCircleQuestion,
  invalid: RiErrorWarningFill,
};

export const ShiftStatusColorMap = {
  planned: "#4d42e1",
  submitted: "#f6ad55",
  expired: "#f56565",
  started: "#b0b436",
  stopped: "#f56565",
  cancelled: "#ff0000",
  rejected: "#ef0b0b",
  approved: "#48bb78",
  changesRequested: "#2974ff",
  invalid: "#8f0000",
};
