import {
  AddReportPosition,
  ReportComponentIds,
  SpeciesObservationTypesCheckbox,
  TableComponentColumns,
} from "@/types/apiTypes";

export const positionOptions: Array<{
  label: string;
  value: AddReportPosition;
}> = [
  { label: "Before", value: "before" },
  { label: "After", value: "after" },
];

export const moduleTypeOptions: Array<{
  label: string;
  value: ReportComponentIds;
}> = [
  { label: "Text Editor", value: "textEditor" },
  { label: "Page Break", value: "pageBreak" },
  { label: "Image", value: "image" },
  { label: "Table", value: "table" },
  { label: "Area Visits", value: "areaWithVisits" },
  { label: "Area Observations", value: "areaWithObservations" },
  { label: "Inhoudsopgave", value: "tableOfContents" },
];

export function getSpeciesObservationTypeOptions(
  speciesInProject: string[],
  value: boolean,
): SpeciesObservationTypesCheckbox[] {
  const data = [
    {
      label: "Vleermuis",
      value: "bat",
      observationTypes: [
        { label: "Verblijfplaats", value: "verblijfplaats", isSelected: value }, //
        { label: "Zwermlocatie", value: "zwermlocatie", isSelected: value },
        {
          label: "Foerageergebied",
          value: "foerageergebied",
          isSelected: value,
        },
        { label: "Vliegroute", value: "vliegroute", isSelected: value },
        { label: "Overvliegend", value: "overvliegend", isSelected: value }, // Show on map but don't show in the table
        { label: "Overig", value: "overig", isSelected: value },
      ],
      isSelected: value,
    },
    {
      label: "Huismus",
      value: "sparrow",
      observationTypes: [
        { label: "Nestlocatie", value: "nestlocatie", isSelected: value },
        { label: "Kwetterstruik", value: "kwetterstruik", isSelected: value },
        { label: "Badder/drinkplaats", value: "zandbad", isSelected: value },
        { label: "Foerageerplek", value: "foerageerplek", isSelected: value },
        { label: "Stofbad", value: "stofbad", isSelected: value },
        { label: "Anders", value: "other", isSelected: value },
      ],
      isSelected: value,
    },
    {
      label: "Gierzwaluw",
      value: "swallow",
      observationTypes: [
        { label: "Nestlocatie", value: "nestlocatie", isSelected: value },
        { label: "Overig", value: "overig", isSelected: value },
      ],
      isSelected: value,
    },
    {
      label: "Roofvogels",
      value: "birds_of_prey",
      observationTypes: [], // Always show all observations. Finding is empty due to the way that form was setup.
      isSelected: value,
    },
  ];

  return data.filter((e) => speciesInProject.includes(e.value));
}

export const areaVisitsTableColumns: TableComponentColumns[] = [
  {
    key: "activityNo",
    label: "#",
    formatter: "string",
  },
  {
    key: "workerCount",
    label: "Aantal Veldwerkers",
    formatter: "number",
  },
  {
    key: "date",
    label: "Datum",
    formatter: "date",
  },
  {
    key: "parsedTime",
    label: "Tijd",
    formatter: "string",
  },
  {
    key: "temperature",
    label: "Temp",
    formatter: "string",
  },
  {
    key: "wind",
    label: "Wind (bft)",
    formatter: "string",
  },
  {
    key: "precipitation",
    label: "Neerslag",
    formatter: "string",
  },
];

export const areaObservationsTableColumns: TableComponentColumns[] = [
  {
    key: "finding",
    label: "Observatie type",
    formatter: "string",
  },
  {
    key: "count",
    label: "Aantal dieren",
    formatter: "number",
  },
  {
    key: "createdAt",
    label: "Datum observatie",
    formatter: "dateTime",
  },
  {
    key: "modelId",
    label: "Reference",
    formatter: "number",
  },
];

export const acceptedFileFormats = ["png", "jpg", "jpeg"];

export const weatherDutchTranslationMapping = {
  Dry: "Droog",
  Mist: "Mist",
  Drizzle: "Motregen",
  Rain: "Regen",
  "Heavy rain": "Zware regen",
  Hail: "Hagel",
  Snow: "Sneeuw",
};

export const specieGroupTranslation = {
  "birds_of_prey": "roofvogels en uilen",
  sparrow: "huismus",
  swallow: "gierzwaluw",
  bat: "vleermuis",
  "gewone_dwerg": "gewone dwergvleermuis",
  "ruige_dwerg": "ruige dwergvleermuis",
};
