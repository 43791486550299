import {
  Avatar,
  BookIcon,
  CalendarIcon,
  DocumentIcon,
  GitRepoIcon,
  Heading,
  InboxIcon,
  LogOutIcon,
  majorScale,
  Menu,
  Pane,
  PaperclipIcon,
  PersonIcon,
  Popover,
  Position,
  ShieldIcon,
  Text,
  UserIcon,
} from "evergreen-ui";
import { useAuth } from "@/context/AuthContext";
import { Link, useLocation, useParams } from "react-router-dom";
import { classNames } from "@/lib/functions";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { difference, isEmpty, last } from "lodash";
import {
  __r,
  ADMIN_BOOKKEEPING_EXPORT_LIST_PAGE,
  ADMIN_PROJECTS_PAGE,
  ADMIN_SUBMITTED_SHIFTS_LIST_PAGE,
  ADMIN_USERS_PAGE,
  WORKER_SHIFTS_GO_TO_APP_PAGE,
  WORKER_SHIFTS_OBSERVATIONS_PAGE,
  WORKER_SHIFTS_PLANNED_PAGE,
} from "@/RouteMap";
import { useApp } from "@/context/AppContext";
import Block from "@/components/common/Block";
import { FaPersonWalkingDashedLineArrowRight } from "react-icons/fa6";

type MenuItem = {
  label: string;
  pathname: string;
  icon: JSX.Element;
  role?: string;
};

const menuItems: MenuItem[] = [
  {
    label: "Planner",
    pathname: "/planner",
    icon: <CalendarIcon />,
    role: "admin",
  },
  {
    label: "Projecten",
    pathname: ADMIN_PROJECTS_PAGE,
    icon: <GitRepoIcon />,
    role: "admin",
  },
  {
    label: "Gelopen diensten",
    pathname: ADMIN_SUBMITTED_SHIFTS_LIST_PAGE,
    icon: <DocumentIcon />,
    role: "admin",
  },
  {
    label: "Boekhouding",
    pathname: ADMIN_BOOKKEEPING_EXPORT_LIST_PAGE,
    icon: <BookIcon />,
    role: "admin",
  },
  {
    label: "Gebruikers",
    pathname: ADMIN_USERS_PAGE,
    icon: <UserIcon />,
    role: "admin",
  },
];

const workerMenuItems: MenuItem[] = [
  {
    label: "navigation.your_shifts",
    pathname: WORKER_SHIFTS_GO_TO_APP_PAGE,
    icon: <GitRepoIcon />,
  },
  {
    label: "navigation.requests",
    pathname: "/periods",
    icon: <InboxIcon />,
  },
];

const MenuItem = function ({ location, item }) {
  const { setSidebarIsOpen } = useApp();

  function onClickLink() {
    if (window.innerWidth <= 640) {
      setSidebarIsOpen(false);
    }
  }

  const { t } = useTranslation();

  const isSelected = useCallback(
    (tabItem) => {
      const subLocsTab = tabItem.pathname.split("/");
      const subLocsHere = location.pathname.split("/");

      const diff = difference(subLocsHere, subLocsTab);

      return (
        last(subLocsTab) === subLocsHere[subLocsTab.length - 1] || isEmpty(diff)
      );
    },
    [location.pathname],
  );

  const className = classNames(isSelected(item) ? "!bg-gray-100" : "");

  return (
    <Link to={item.pathname} onClick={onClickLink}>
      <Menu.Item className={className} icon={item?.icon}>
        {t(item.label)}
      </Menu.Item>
    </Link>
  );
};

function SidebarMenu() {
  const location = useLocation();
  const { t } = useTranslation();
  const { getAdminUser, user } = useAuth();

  const adminUser = getAdminUser();
  const adminWorkerItems = [
    {
      label: t("worker_shifts_page.planned_shifts_admin"),
      pathname: __r(WORKER_SHIFTS_PLANNED_PAGE),
      icon: <FaPersonWalkingDashedLineArrowRight />,
    },
  ];

  return (
    <Menu>
      <Menu.Group>
        {user!.role === "admin" &&
          menuItems.map((item) => (
            <MenuItem key={item.pathname} location={location} item={item} />
          ))}

        {user!.worker &&
          workerMenuItems.map((item) => (
            <MenuItem key={item.pathname} location={location} item={item} />
          ))}
        {adminUser &&
          user!.role === "worker" &&
          adminWorkerItems.map((item) => (
            <MenuItem key={item.pathname} location={location} item={item} />
          ))}
      </Menu.Group>
      <Menu.Divider />
    </Menu>
  );
}

function SidebarContent() {
  return (
    <>
      <Pane className="max-w-[15rem] px-5 py-10 text-3xl">
        <img src={"/logo.png"} alt="Gaia ecologie logo" />
      </Pane>
      <Pane>
        <SidebarMenu />
      </Pane>
    </>
  );
}

function AccountSettingsMenu() {
  const { user, setUser, setJwt } = useAuth();

  const logout = () => {
    setJwt!(null);
    setUser!(null);
  };

  const isMobile = window.innerWidth <= 640;

  return (
    <Menu>
      <Menu.Group>
        <Popover
          bringFocusInside
          statelessProps={{ style: { zIndex: 99 } }}
          position={isMobile ? Position.TOP : Position.RIGHT}
          content={
            <Pane
              width={200}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Menu>
                <Menu.Group>
                  <Link to={"/account-settings"}>
                    <Menu.Item icon={PersonIcon}>Account Settings</Menu.Item>
                  </Link>
                </Menu.Group>
                <Menu.Divider />
                <Menu.Group>
                  <Menu.Item onClick={logout} icon={LogOutIcon} intent="danger">
                    Logout
                  </Menu.Item>
                </Menu.Group>
              </Menu>
            </Pane>
          }
        >
          <Menu.Item
            className="justify-self-end"
            height={80}
            paddingTop={20}
            paddingBottom={20}
          >
            <Heading>
              {user?.profilePictureThumbnail ? (
                <Pane className="flex items-center gap-3">
                  <Avatar
                    className="hover:opacity-90"
                    src={user!.profilePictureThumbnail ?? ""}
                    name={"You"}
                    size={50}
                  />

                  <Text className="!font-bold">
                    {user?.firstName} {user?.lastName}
                  </Text>
                </Pane>
              ) : (
                <Avatar
                  name={`${user?.firstName}} ${user?.lastName}`}
                  size={40}
                >
                  <Text className="!font-bold">
                    {user?.firstName} {user?.lastName}
                  </Text>
                  {user!.worker?.isVeteran && (
                    <Block className="flex items-center gap-1">
                      <ShieldIcon color="#f6ad55" size={majorScale(3)} />
                    </Block>
                  )}
                </Avatar>
              )}
            </Heading>
          </Menu.Item>
        </Popover>
      </Menu.Group>
    </Menu>
  );
}

export default function MainNavigationMenu() {
  const isMobile = window.innerWidth <= 640;

  return (
    <Pane className="flex grow flex-col">
      <Pane className="grow">
        <SidebarContent />
      </Pane>

      <Pane className={classNames(isMobile ? "pb-20" : "", "shrink")}>
        <Menu.Divider />
        <AccountSettingsMenu />
      </Pane>
    </Pane>
  );
}
