import {
  Avatar,
  Button,
  CrossIcon,
  EmptyState,
  Heading,
  IconButton,
  majorScale,
  Pane,
  SearchIcon,
  Table,
  Text,
  TickIcon,
} from "evergreen-ui";

import StandardTable from "src/components/table/StandardTable";
import { memo } from "react";
import { WorkerApiType } from "src/types/apiTypes";
import { isEmpty } from "lodash";
import { usePlannerDialog } from "../logic/PlannerDialogContext";

interface FilterWorkerTableProps {
  // availableWorkers: WorkerApiType[];
  // refresh: Function;
  // workersIsLoading: boolean | null;
  // meta: any;
  // doSortBy: Function;
}

// availableWorkers={availableWorkers}
// refresh={refresh}
// workersIsLoading={isLoading}
// meta={meta}
// doSortBy={doSortBy}

const FilterWorkerTable = memo(function FilterWorkerTable() {
  const { state, dispatch, helpers } = usePlannerDialog();

  const { attachedWorkers, filterLogic } = state;

  const {
    availableWorkers,
    isLoading: workersIsLoading,
    refresh,
    meta,
    doSortBy,
  } = filterLogic;

  function doFlipAssignedWorker(worker: WorkerApiType) {
    const isSelected = attachedWorkers.find((v) => v.id === worker.id);

    if (isSelected) {
      helpers!.doUnAssignWorker(worker);
    } else {
      helpers!.doAssignWorker(worker);
    }
  }

  if (isEmpty(filterLogic)) {
    return <Heading size={100}>...</Heading>;
  }

  return (
    <StandardTable
      minHeight={400}
      refresh={refresh}
      meta={meta}
      isLoading={workersIsLoading}
      data={availableWorkers}
      emptyState={<EmptyWorkersState />}
    >
      <Table.Head className="!pr-0">
        <StandardTable.HeaderCell doSortBy={doSortBy} sortBy="firstName">
          Name
        </StandardTable.HeaderCell>
        <StandardTable.HeaderCell
          doSortBy={doSortBy}
          sortBy="distanceKm"
          maxWidth={140}
        >
          Afstand tot Gebied
        </StandardTable.HeaderCell>
        <Table.HeaderCell maxWidth={140}>Rijbewijs</Table.HeaderCell>
        <Table.HeaderCell maxWidth={140}>Auto</Table.HeaderCell>
        <Table.HeaderCell maxWidth={140}>Kaart</Table.HeaderCell>
        <Table.HeaderCell maxWidth={140}>Toevoegen</Table.HeaderCell>
      </Table.Head>
      {availableWorkers.map((worker) => (
        <Table.Row
          key={worker.id}
          isSelectable
          onSelect={() => doFlipAssignedWorker(worker)}
        >
          <Table.Cell>
            <Pane className="flex items-center gap-2">
              <Avatar
                className="hover:opacity-90"
                src={worker.user!.profilePictureThumbnail ?? ""}
                name={""}
                size={20}
              />
              <Text>
                {worker.user.firstName} {worker.user.lastName}
              </Text>
            </Pane>
          </Table.Cell>
          <Table.Cell maxWidth={140}>
            <Text>
              {(worker.distanceKm &&
                Number(parseFloat(worker.distanceKm).toFixed(2))) ??
                "-"}{" "}
              KM
            </Text>
          </Table.Cell>
          <Table.Cell maxWidth={140}>
            {worker.hasDrivingLicense ? <TickIcon /> : <CrossIcon />}
          </Table.Cell>
          <Table.Cell maxWidth={140}>
            {worker.hasTransport ? <TickIcon /> : <CrossIcon />}
          </Table.Cell>
          <Table.Cell maxWidth={140}>
            {worker.address && (
              <Heading size={100}>{worker.address.formattedAddress}</Heading>
            )}
          </Table.Cell>
          <Table.Cell maxWidth={140}>
            <AssignWorkerButton
              // doAssignWorker={doAssignWorker}
              // doUnAssignWorker={doUnAssignWorker}
              workers={attachedWorkers ?? []}
              worker={worker}
            />
          </Table.Cell>
        </Table.Row>
      ))}
    </StandardTable>
  );
});

function EmptyWorkersState() {
  return (
    <EmptyState
      background="light"
      title="Geen beschikbare veldwerkers gevonden"
      orientation="horizontal"
      icon={<SearchIcon color="#C1C4D6" />}
      iconBgColor="#EDEFF5"
      description="Bekijk of de filters correct zijn, of probeer het later nog eens"
    />
  );
}

interface AssignWorkerButtonProps {
  worker: WorkerApiType;
  workers: WorkerApiType[];
}

function AssignWorkerButton({ worker, workers }: AssignWorkerButtonProps) {
  const { helpers } = usePlannerDialog();
  const isSelected = workers.find((v) => v.id === worker.id);

  function onSelectWorker(e) {
    e.stopPropagation();

    helpers!.doAssignWorker(worker, {});
  }

  function onUnSelectWorker(e) {
    e.stopPropagation();

    helpers!.doUnAssignWorker(worker);
  }

  return (
    <Pane className="flex items-center justify-center">
      {isSelected ? (
        <IconButton
          onClick={onUnSelectWorker}
          intent="success"
          appearance="primary"
          disabled={false}
          icon={TickIcon}
          type="button"
        />
      ) : (
        <Button
          onClick={onSelectWorker}
          className="flex gap-2"
          height={majorScale(4)}
        >
          Toevoegen
        </Button>
      )}
    </Pane>
  );
}

export default FilterWorkerTable;
