import { AddIcon, DeleteIcon, EditIcon } from "evergreen-ui";
import { EditReportModuleDialogComponent } from "../dialogs/EditReportModuleDialogComponent";
import { ReportModulePreviewComponent } from "../preview-components/ReportModulePreviewComponent";
import { useState } from "react";
import {
  AddReportPosition,
  Report,
  ReportComponent,
  ReportData,
} from "@/types/apiTypes";
import { AddReportModuleDialogComponent } from "../dialogs/AddReportModuleDialogComponent";
import { DeletetDialogComponent } from "../dialogs/DeletetDialogComponent";
import { AreaPreviewComponent } from "../preview-components/AreaPreviewComponent";

export const ReportModuleComponent = ({
  project,
  report,
  component,
  index,
  saveLoaderVisibility,
  isAddEnabled,
  isDeleteEnabled,
  isEditEnabled,
  handleComponentUpdate,
  handleComponentDelete,
  handleAddModule,
}: {
  project: ReportData;
  report: Report;
  component: ReportComponent;
  index: number;
  saveLoaderVisibility: boolean;
  isAddEnabled?: boolean;
  isDeleteEnabled?: boolean;
  isEditEnabled?: boolean;
  handleComponentUpdate: (component: ReportComponent, index: number) => void;
  handleComponentDelete?: (index) => void;
  handleAddModule?: (
    index: number,
    position: AddReportPosition,
    components: ReportComponent[],
  ) => void;
}) => {
  const [editorVisiblity, setEditorVisiblity] = useState(false);
  const [deleteDialogVisibility, setDeleteDialogVisibility] = useState(false);
  const [addReportModuleDialogVisiblity, setAddReportModuleDialogVisiblity] =
    useState(false);

  function handleEditDialogOpen() {
    setEditorVisiblity(true);
  }

  function handleEditDialogClose() {
    setEditorVisiblity(false);
  }

  function handleDeleteDialogOpen() {
    setDeleteDialogVisibility(true);
  }

  function handleDeleteDialogClose() {
    setDeleteDialogVisibility(false);
  }

  function handleAddReportModuleDialogOpen() {
    setAddReportModuleDialogVisiblity(true);
  }
  function handleAddReportModuleDialogClose() {
    setAddReportModuleDialogVisiblity(false);
  }

  async function handleEditDialogOnSave(updatedComponent: ReportComponent) {
    await handleComponentUpdate(updatedComponent, index);
    handleEditDialogClose();
  }

  async function handleAreaComponentChanges(
    childComponents: ReportComponent[],
  ) {
    const updatedComponent = { ...component, childComponents };
    await handleEditDialogOnSave(updatedComponent);
  }

  return (
    <div className="card-container mt-4 border border-zinc-200 bg-white px-6 pt-4 pb-6 drop-shadow-sm">
      <div className="flex justify-between pb-3">
        <div className="flex justify-start">
          {isAddEnabled && (
            <AddIcon
              color="#3366ff"
              cursor="pointer"
              onClick={handleAddReportModuleDialogOpen}
            />
          )}
        </div>
        <div className="flex justify-end">
          {isEditEnabled && (
            <EditIcon onClick={handleEditDialogOpen} cursor="pointer" />
          )}
          {isDeleteEnabled && (
            <DeleteIcon
              marginLeft={16}
              onClick={handleDeleteDialogOpen}
              color="#ff6565"
              cursor="pointer"
            />
          )}
        </div>
      </div>

      {component.type === "areaWithVisits" ||
      component.type === "areaWithObservations" ? (
        <AreaPreviewComponent
          project={project}
          report={report}
          component={component}
          handleComponentChanges={handleAreaComponentChanges}
          saveLoaderVisibility={saveLoaderVisibility}
        />
      ) : (
        <div
          className={`max-h-36 ${component.type === "multipleImages" ? "overflow-scroll	" : "overflow-hidden"}`}
        >
          <ReportModulePreviewComponent
            component={component}
            mediaItems={report.mediaItems}
          />
        </div>
      )}

      {addReportModuleDialogVisiblity && handleAddModule && (
        <AddReportModuleDialogComponent
          report={report}
          projectData={project}
          addReportModuleDialogVisibility={addReportModuleDialogVisiblity}
          saveLoaderVisibility={saveLoaderVisibility}
          handleOnClose={handleAddReportModuleDialogClose}
          handleOnSave={async (position, components) => {
            await handleAddModule(index, position, components);
            handleAddReportModuleDialogClose();
          }}
        />
      )}

      {editorVisiblity && (
        <EditReportModuleDialogComponent
          component={component}
          editorVisiblity={editorVisiblity}
          saveLoaderVisibility={saveLoaderVisibility}
          mediaItems={report.mediaItems}
          onClose={handleEditDialogClose}
          onSave={handleEditDialogOnSave}
        />
      )}
      {deleteDialogVisibility && handleComponentDelete && (
        <DeletetDialogComponent
          title="Delete module"
          bodyContent="Are you sure you want to delete the module?"
          deleteDialogVisibility={deleteDialogVisibility}
          saveLoaderVisibility={saveLoaderVisibility}
          handleCloseDeleteDialog={handleDeleteDialogClose}
          handleConfirmDeleteDialog={async () => {
            await handleComponentDelete(index);
            handleDeleteDialogClose();
          }}
        />
      )}
    </div>
  );
};
