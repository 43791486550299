import {
  BriefcaseIcon,
  ChevronLeftIcon,
  Heading,
  IconButton,
  MapMarkerIcon,
  NewObjectIcon,
  Pane,
  SettingsIcon,
  ExportIcon,
  useTheme,
  PrintIcon,
} from "evergreen-ui";
import { MdOutlineWorkOutline } from "react-icons/md";
import { Link, Outlet, useParams } from "react-router-dom";
import { useState } from "react";

import TopSubNav from "@/components/common/TopSubNav";
import { Project } from "@/types/apiTypes";
import { useApi } from "@/context/AxiosContext";
import { useLoadResource } from "@/lib/request-hooks";
import Page from "@/components/common/Page";

import {
  __r,
  ADMIN_ACTIVITY_TYPE_ADD_PAGE,
  ADMIN_ACTIVITY_TYPES_PAGE,
  ADMIN_AREA_ADD_PAGE,
  ADMIN_CLIENT_ACTIVITY_PAGE,
  ADMIN_PROJECT_REPORTS,
  ADMIN_OBJECT_ADD_PAGE,
  ADMIN_PROJECT_EXPORT_PAGE,
  ADMIN_PROJECT_SETTINGS_PAGE,
} from "@/RouteMap";

const useProject = function () {
  const { projectId } = useParams();
  const [project, setProject] = useState<Project>();
  const { apiInstance } = useApi();

  const find = () => apiInstance!.adminProjects.findOne(projectId!);

  const setData = (project: Project) => {
    setProject(project);

    return Promise.resolve();
  };

  return {
    ...useLoadResource(find, setData, !!projectId),
    project,
  };
};

export default function ProjectLayout() {
  const { project } = useProject();
  const { projectId } = useParams();

  const theme = useTheme();

  const menuItems = [
    {
      label: "Klant Activiteiten",
      pathname: __r(ADMIN_CLIENT_ACTIVITY_PAGE, { projectId }),
      icon: <MdOutlineWorkOutline />,
    },
    {
      label: "Objecten",
      pathname: __r(ADMIN_OBJECT_ADD_PAGE, { projectId }),
      icon: <NewObjectIcon />,
    },
    {
      label: "Gebieden",
      pathname: __r(ADMIN_AREA_ADD_PAGE, { projectId }),
      icon: <MapMarkerIcon />,
    },
    {
      label: "Activiteiten",
      pathname: __r(ADMIN_ACTIVITY_TYPES_PAGE, { projectId }),
      icon: <BriefcaseIcon />,
    },
    {
      label: "Export",
      pathname: __r(ADMIN_PROJECT_EXPORT_PAGE, { projectId }),
      icon: <ExportIcon />,
    },
    {
      label: "Reports",
      pathname: __r(ADMIN_PROJECT_REPORTS, { projectId }),
      icon: <PrintIcon />,
    },
    {
      label: "Project Settings",
      pathname: __r(ADMIN_PROJECT_SETTINGS_PAGE, { projectId }),
      icon: <SettingsIcon />,
    },
  ];

  return (
    <Pane>
      <Pane className="flex gap-1 px-5">
        <Link to={"/projects"}>
          <Pane className="flex">
            <IconButton
              appearance="minimal"
              icon={ChevronLeftIcon}
              type="button"
            />
            <Heading
              size={800}
              color={theme.colors.gray900}
              className="cursor-pointer hover:underline"
            >
              Projects
            </Heading>
          </Pane>
        </Link>
        <Heading size={800}>/</Heading>
        <Heading className="!text-2xl" size={100}>
          {project?.name}
        </Heading>
      </Pane>

      <TopSubNav marginY={20} menuItems={menuItems} />

      <Page paddingBottom={0}>
        <Outlet />
      </Page>
    </Pane>
  );
}
