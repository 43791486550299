import { useMemo, useRef } from "react";

import {
  Checkbox,
  CrossIcon,
  Dialog,
  EmptyState,
  FormField,
  Heading,
  IconButton,
  MapIcon,
  Pane,
  SearchIcon,
  Select,
  SettingsIcon,
} from "evergreen-ui";

import Collapse from "src/components/common/Collapse";
import DatePicker from "src/components/formfields/DatePicker";
import FilterWorkerTable from "./filters/FilterWorkerTable";
import Form from "src/components/common/Form";
import PendingChangesDialog from "src/components/common/PendingChangesDialog";
import WorkerFilters, {
  RefWorkerFilter,
} from "./filters/WorkerFiltersForPlanner";
import WorkerItemWithPopover from "./WorkerItemWithPopover";
import usePlannerDialogLogic from "./logic/usePlannerDialogLogic";
import useSafeLeave from "src/hooks/useSafeLeave";

import { TableLoadingSpinner } from "src/components/table/StandardTable";
import { useTranslation } from "react-i18next";

import { AreaActivityType, Shift, WorkerApiType } from "src/types/apiTypes";
import {
  PlannerDialogContext,
  usePlannerDialog,
} from "./logic/PlannerDialogContext";
import { InfoBox } from "../../projects/activity-types/ActivityTypeEditPage";
import Block from "@/components/common/Block";
import ObjectList from "@/components/objects/ObjectList.component";
import { Link } from "react-router-dom";
import { __r, ADMIN_AREA_EDIT_PAGE } from "@/RouteMap";

export default function PlannerDialog() {
  const { t } = useTranslation();
  const WorkerFilterRef = useRef<RefWorkerFilter>(null);

  const {
    areaActivityType,
    date,
    period,
    doSubmit,
    handleTimeslotChange,
    notAfterDate,
    notBeforeDate,
    onCloseDialog,
    selectedTimeslot,
    shiftIsLoading,
    timeslotUid,
    timeslotsAvailable,
    setDate,
    datePickerHint,
    submitContext,
    state,
    dispatch,
    helpers,
    ignoreTravelTime,
    setIgnoreTravelTime,
    shift,
  } = usePlannerDialogLogic();

  const { closeSafeLeaveDialog, doLeave, onLeave, safeLeaveDialogShown } =
    useSafeLeave({ onConfirm: onCloseDialog, isDirty: state.isDirty });

  function setDateCb(date: Date) {
    if (WorkerFilterRef.current) {
      WorkerFilterRef.current.setAvailableOnDate(date);
    }

    setDate(date);
  }

  function setTimeslotCb(value: string) {
    if (WorkerFilterRef.current) {
      WorkerFilterRef.current.setTimeslotUid(value);
    }

    handleTimeslotChange(value);
  }

  const dateInQuery = useMemo(() => {
    return new URLSearchParams(window.location.search).get("date");
  }, [date]);

  return (
    <Dialog
      width={1500}
      contentContainerProps={{
        paddingRight: 0,
        position: "relative",
        height: "100%",
        overflowY: "unset",
        minHeight: "50vh",
      }}
      isShown={true}
      isConfirmLoading={!!submitContext.isLoading}
      shouldCloseOnOverlayClick={false}
      header={
        <DialogHeader
          close={onLeave}
          ignoreTravelTime={ignoreTravelTime}
          setIgnoreTravelTime={setIgnoreTravelTime}
          areaActivityType={areaActivityType}
        />
      }
      title={areaActivityType?.activityType.name}
      onCloseComplete={onCloseDialog}
      onCancel={onLeave}
      onConfirm={doSubmit}
    >
      <PlannerDialogContext.Provider value={{ state, dispatch, helpers }}>
        <Pane className="relative flex gap-2">
          <PendingChangesDialog
            doAction={doLeave}
            isShown={safeLeaveDialogShown}
            onCloseComplete={closeSafeLeaveDialog}
            isConfirmLoading={!!submitContext.isLoading}
          />

          <Pane className="h-full w-1/5 min-w-[15rem] border-r border-gray-200 pr-2">
            <Pane className="flex gap-1 pb-4">
              <SettingsIcon size={16} color="#C1C4D6" marginRight={8} />
              <Heading size={100}>Filters</Heading>
            </Pane>

            {!shiftIsLoading && date && areaActivityType && selectedTimeslot ? (
              <WorkerFilters
                key={date?.toString() || "no-date"}
                ref={WorkerFilterRef}
                shiftDate={date}
                timeslot={selectedTimeslot}
                address={areaActivityType?.area.addresses?.[0]}
                area={areaActivityType.area}
              />
            ) : (
              <Pane className="py-2">
                <InfoBox description="Selecteer een datum en tijdslot om de beschikbare filters te zien." />
              </Pane>
            )}
          </Pane>

          <Pane className="flex w-4/5 flex-col">
            <Form className="flex gap-2 pr-5" marginY={50}>
              <>
                <FormField
                  className="self-start"
                  label="Plannen op datum:"
                  hint={datePickerHint}
                >
                  {notBeforeDate && notAfterDate && period && (
                    <DatePicker
                      key={notAfterDate?.toDateString()}
                      datePickerOptions={{
                        disabled: {
                          before: notBeforeDate,
                          after: notAfterDate,
                        },
                      }}
                      disabled={false}
                      dateValue={date}
                      defaultMonth={notBeforeDate}
                      setDateValue={setDateCb}
                    />
                  )}
                </FormField>

                <FormField className="self-start" label="Tijdslot">
                  <Select
                    value={timeslotUid}
                    onChange={(e) => setTimeslotCb(e.target.value)}
                    width={240}
                    height={40}
                  >
                    <option key="-">-</option>
                    {timeslotsAvailable.map((option) => (
                      <option key={option.value} value={option.value}>
                        {t(option.label)}
                      </option>
                    ))}
                  </Select>
                </FormField>
              </>
            </Form>

            <Pane className="py-4">
              <AttachedWorkers
                shift={shift}
                areaActivityType={areaActivityType}
              />
              <Block>
                <Heading size={100}>Objecten</Heading>
                <ObjectList objects={areaActivityType?.area?.objects || []} />
              </Block>
            </Pane>

            <Pane>
              <FilterWorkerTable />
            </Pane>

            {shiftIsLoading === null && (
              <TableLoadingSpinner title="Loading workers" />
            )}

            {shiftIsLoading === false && date === null && (
              <Pane>
                <EmptyWorkersState />
              </Pane>
            )}
          </Pane>
        </Pane>
      </PlannerDialogContext.Provider>
    </Dialog>
  );
}

function AttachedWorkers({
  areaActivityType,
  shift,
}: {
  areaActivityType: AreaActivityType | null;
  shift: Shift | null;
}) {
  const { state, helpers } = usePlannerDialog();

  const { attachedWorkers, setPopupShownId, popupShownId } = state;

  function setDriver(worker: WorkerApiType, driverId: number | null) {
    helpers!.updateWorker(worker, { driverId });
  }

  function setIsDriving(worker: WorkerApiType) {
    const isDriving = !worker.isDriving;

    helpers!.updateWorker(worker, {
      isDriving: !worker.isDriving,
      driverId: isDriving ? worker.id : null,
    });
  }

  function onDelete(worker: WorkerApiType) {
    helpers!.doUnAssignWorker(worker);
  }

  return (
    <Pane>
      <Heading size={100}>
        {attachedWorkers.length} / {areaActivityType?.nWorkers} veldwerker(s)
        toegewezen
      </Heading>
      <Collapse defaultIsOpen={true} title={"Veldwerkers"}>
        <Pane className="flex flex-wrap gap-2">
          {attachedWorkers.map((worker) => (
            <WorkerItemWithPopover
              worker={worker}
              shift={shift}
              onDelete={onDelete}
              setIsDriving={setIsDriving}
              setDriver={setDriver}
              setPopupShownId={setPopupShownId}
              popupShownId={popupShownId}
              key={"workeritem" + worker.id}
            />
          ))}
        </Pane>
      </Collapse>
    </Pane>
  );
}

function EmptyWorkersState() {
  return (
    <EmptyState
      background="light"
      title="De minimale dag waarop deze shift gepland kan worden valt buiten deze periode."
      orientation="horizontal"
      icon={<SearchIcon color="#C1C4D6" />}
      iconBgColor="#EDEFF5"
      description="No workers available"
    />
  );
}

type DialogHeaderProps = {
  areaActivityType: AreaActivityType | null;
  close: () => void;
  setIgnoreTravelTime: (value: boolean) => void;
  ignoreTravelTime: boolean;
};

function DialogHeader({
  areaActivityType,
  ignoreTravelTime,
  setIgnoreTravelTime,
  close,
}: DialogHeaderProps) {
  return (
    <Pane className="flex w-full">
      <Pane className="flex grow items-center gap-3">
        <Link
          target="_blank"
          to={__r(ADMIN_AREA_EDIT_PAGE, {
            projectId: areaActivityType?.area.projectId,
            areaId: areaActivityType?.area.id,
          })}
        >
          <IconButton title="Open in map" icon={MapIcon} type="button" />
        </Link>
        <Heading size={800}>{areaActivityType?.activityType.name}</Heading>
        <Heading className="pt-1" size={100}>
          in {areaActivityType?.area.name}
        </Heading>
      </Pane>

      <Pane className="flex shrink items-center gap-2">
        <Pane className="flex items-center gap-2">
          <Heading size={400}>Tel geen reistijd</Heading>
          <Checkbox
            onChange={(e) => setIgnoreTravelTime(e.target.checked)}
            checked={ignoreTravelTime}
          />
        </Pane>
        <IconButton
          onClick={close}
          appearance="minimal"
          icon={CrossIcon}
          type="button"
        />
      </Pane>
    </Pane>
  );
}
