import { useApi } from "src/context/AxiosContext";
import { useState } from "react";
import { useDoRequest } from "src/lib/request-hooks";
import { WorkerShift } from "@/types/apiTypes";
import { WORKER_SHIFT_REJECT_FORM_ID } from "@/components/config/forms";
import { Dialog, Pane, Text, TextareaField, toaster } from "evergreen-ui";
import { useTranslation } from "react-i18next";

function useRejectWorkerShiftForm({ workerShift, status }) {
  const { apiInstance } = useApi();
  const [notes, setNotes] = useState<string>(workerShift.notes);
  const { t } = useTranslation();

  const {
    handle,
    setIsLoading: setIsSubmitting,
    isLoading: isSubmitting,
    validationErrors,
    errorMessage: submitErrorMessage,
  } = useDoRequest();

  const doSubmit = async () => {
    const data = {
      formId: WORKER_SHIFT_REJECT_FORM_ID,
      answers: {
        shift_status_rejected__notes: notes,
      },
      status,
    };

    let request: Promise<WorkerShift>;

    request = apiInstance!.adminWorkerShifts.reject(
      workerShift.workerId,
      workerShift.id,
      data,
    );

    await handle(request);

    toaster.success(t("shift.status." + status));
  };

  return {
    submitContext: {
      doSubmit,
      setIsSubmitting,
      isSubmitting,
      submitErrorMessage,
      validationErrors,
    },
    notes,
    setNotes,
    doSubmit,
  };
}

export default function RejectWorkerShiftDialog({
  workerShift,
  isShown,
  close,
  afterSubmit,
  status,
}: {
  workerShift: WorkerShift;
  isShown: boolean;
  close: () => void;
  afterSubmit: () => void;
  status: string;
}) {
  const { t } = useTranslation();
  const { submitContext, notes, setNotes, doSubmit } = useRejectWorkerShiftForm(
    {
      workerShift: workerShift,
      status,
    },
  );

  async function onConfirm() {
    await doSubmit();

    setNotes("");
    afterSubmit();
  }

  return (
    <Dialog
      confirmLabel={t("shifts.setStatus." + status)!}
      intent="danger"
      isShown={isShown}
      onCloseComplete={close}
      onConfirm={onConfirm}
      shouldCloseOnOverlayClick={false}
      title={t("shifts.setStatus." + status)}
    >
      <Pane>
        <Text className="my-4">Geef een reden op van afkeuring.</Text>
        <Pane className="mt-4">
          <TextareaField
            required
            name="notes"
            label="Notities"
            placeholder={t("shifts.setStatus." + status)!}
            isInvalid={!!submitContext.validationErrors?.notes}
            validationMessage={submitContext.validationErrors?.notes?.join(
              ", ",
            )}
            value={notes || ""}
            onChange={(e) => setNotes(e.target.value)}
            marginBottom={0}
            inputHeight={40}
            className="!text-base"
          />
        </Pane>
      </Pane>
    </Dialog>
  );
}
