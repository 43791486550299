import { Text } from "@react-pdf/renderer";
import { pdfPageNumberStyles } from "./styles/pdfReportStyles";

export const PdfPreviewPageNumberComponent = ({
  updateTotalPages,
}: {
  updateTotalPages: (totalPages: string) => void;
}) => {
  return (
    <Text
      style={pdfPageNumberStyles.pageNumber}
      render={({ pageNumber, totalPages }) => {
        updateTotalPages(`${totalPages}`);
        return `${pageNumber} / ${totalPages}`;
      }}
      fixed
    />
  );
};
