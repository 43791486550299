import { useWindowSearchParams } from "@/hooks/useWindowSearchParams";
import { useApi } from "@/context/AxiosContext";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { ClientActivity } from "@/types/apiTypes";
import { useLoadResource } from "@/lib/request-hooks";

export default function useClientActivities() {
  const searchParams = useWindowSearchParams();
  const { apiInstance } = useApi();

  const { projectId } = useParams();

  const [clientActivities, setClientActivities] = useState<ClientActivity[]>(
    [],
  );
  const request = () =>
    apiInstance!.adminClientActivity.findMany(projectId!, searchParams);

  const setData = (clientActivities: ClientActivity[]) => {
    setClientActivities(clientActivities);
  };

  return {
    ...useLoadResource(request, setData, true),
    clientActivities,
  };
}
