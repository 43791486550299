import { Shift } from "../../../types/apiTypes";
import { useLocation, useParams } from "react-router-dom";
import { __r, PLANNER_PERIOD_PLANNING_PAGE } from "../../../RouteMap";
import { isEmpty } from "lodash";
import { useApi } from "../../../context/AxiosContext";
import { useMemo, useState } from "react";
import { useLoadResource } from "../../../lib/request-hooks";

export default function usePendingShiftsForPeriod() {
  const location = useLocation();

  const { periodId } = useParams();
  const { apiInstance } = useApi();

  const shouldFetch = useMemo(() => {
    return (
      !isEmpty(periodId) &&
      location.pathname === __r(PLANNER_PERIOD_PLANNING_PAGE, { periodId })
    );
  }, [periodId, location]);

  const [pendingShifts, setPendingShifts] = useState<Shift[]>([]);

  const searchParams = new URLSearchParams();
  searchParams.set("isPublished", "false");

  const find = () =>
    apiInstance!.adminPlanning.findShiftsForPeriod(periodId!, searchParams);

  return {
    ...useLoadResource(find, setPendingShifts, shouldFetch),
    pendingShifts,
  };
}
