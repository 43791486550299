import { useState } from "react";

export default function useDelayedAction(delay: number) {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  return (action) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      action();
    }, delay);

    setTimeoutId(newTimeoutId);
  };
}
