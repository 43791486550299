import {
  Alert,
  Dialog,
  FormField,
  Pane,
  Paragraph,
  TextInputField,
  toaster,
} from "evergreen-ui";
import Form from "../../components/common/Form";

import { useApi } from "../../context/AxiosContext";
import { useEffect, useState } from "react";
import { useDoRequest } from "../../lib/request-hooks";

export default function InviteDialog({ isOpen, close }) {
  const { apiInstance } = useApi();
  const [email, setEmail] = useState<string>("");
  const { handle, errorMessage, validationErrors, isLoading } = useDoRequest();

  const sendInvite = async () => {
    const request = apiInstance!.adminInvites.inviteWorker({ email });

    await handle(request);

    toaster.success("Token Created!");

    closeDialog();
  };

  function closeDialog() {
    setEmail("");
    close();
  }

  return (
    <Dialog
      isShown={isOpen}
      title="Invite a new Worker"
      onConfirm={() => sendInvite()}
      isConfirmLoading={isLoading === true}
      onCloseComplete={closeDialog}
      onCancel={() => {
        closeDialog();
      }}
      confirmLabel="Create Invite"
    >
      {({ close }) => (
        <Pane>
          <Paragraph size={50}>Enter his or her email address</Paragraph>

          <Pane className="py-5">
            <Form onSubmit={sendInvite}>
              <FormField label="Email">
                <TextInputField
                  marginBottom={0}
                  inputHeight={40}
                  size={50}
                  name="email"
                  placeholder=""
                  isInvalid={!!validationErrors?.email}
                  validationMessage={validationErrors?.email?.join(", ")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormField>
            </Form>

            {errorMessage && (
              <Pane>
                <Alert marginTop={10} intent="danger" title={errorMessage} />
              </Pane>
            )}
          </Pane>
        </Pane>
      )}
    </Dialog>
  );
}
