import { useEffect, useState } from "react";
import { Image, Styles, Text, View } from "@react-pdf/renderer";
import { fetchUrl } from "../utility";
import {
  ImageComponent,
  MapImageComponent,
  MediaItems,
} from "@/types/apiTypes";
import { StyleSheet } from "@react-pdf/renderer";

type PdfPreviewImageProps = {
  imageComponent: ImageComponent | MapImageComponent;
  styles: Styles;
  mediaItems: MediaItems;
  showDescription?: boolean;
};

export const PdfPreviewImage = ({
  imageComponent,
  styles,
  mediaItems,
  showDescription = false,
}: PdfPreviewImageProps) => {
  const mediaItemId = imageComponent?.mediaItemId;
  const mediaItem = mediaItemId ? mediaItems?.[mediaItemId] : undefined;
  const uploaded = mediaItem ? mediaItem.uploaded : false;
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    async function fetchImagesFromMedia() {
      if (!mediaItem || !uploaded) {
        return;
      }
      const url = await fetchUrl(mediaItem?.fullPath);
      setImageUrl(url);
    }
    fetchImagesFromMedia();
  }, [imageComponent, mediaItem, uploaded]);

  //CoverPhoto doesn't have description and imagePosition
  const isCoverPhoto = !imageComponent?.imagePosition && !showDescription;

  const imagePosition = imageComponent?.imagePosition ?? "center";
  const componentStyles = isCoverPhoto
    ? styles
    : StyleSheet.create({
        ...styles.container,
        marginLeft: imagePosition === "left" ? 0 : "auto",
        marginRight: imagePosition === "right" ? 0 : "auto",
        width: imageComponent?.imageWidth ?? styles.width ?? 500,
      } as unknown as Styles);

  return imageUrl ? (
    <View style={componentStyles}>
      <Image src={imageUrl} />
      {showDescription && (
        <Text style={styles.description}>{imageComponent.description}</Text>
      )}
    </View>
  ) : (
    <></>
  );
};
