import PageTitle from "../../components/common/PageTitle";
import { useParams } from "react-router-dom";
import { useApi } from "../../context/AxiosContext";
import { useTranslation } from "react-i18next";
import Page from "../../components/common/Page";
import AvailabilityTable from "./_components/AvailabilityTable";
import usePeriodForm, { FormContext, useForm } from "../../hooks/usePeriodForm";
import { WORKER_PERIODS } from "../../RouteMap";

function useWorkerAvailability() {
  const { apiInstance } = useApi();
  const { periodId } = useParams();

  const find = () => apiInstance!.workerAvailabilityRequests.findOne(periodId!);
  const submit = (data) =>
    apiInstance!.workerAvailability.syncAvailability(data);

  return usePeriodForm(find, submit);
}

export default function WorkerAvailabilityPage() {
  const { t } = useTranslation();
  const workerAvailability = useWorkerAvailability();

  const availabilityProps = {
    ...workerAvailability,
    useForm: useForm,
    backPath: WORKER_PERIODS,
  };

  return (
    <Page>
      <FormContext.Provider value={workerAvailability.formContext}>
        <PageTitle backPath={WORKER_PERIODS}>
          {t("worker_availability_page.title")}
        </PageTitle>

        <AvailabilityTable {...availabilityProps} />
      </FormContext.Provider>
    </Page>
  );
}
