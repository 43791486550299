import {
  ImageComponent,
  ImagePosition,
  MapImageComponent,
  MediaItems,
  ReportComponent,
} from "@/types/apiTypes";
import { useEffect, useState } from "react";
import { fetchUrl } from "../utility";
import { TextInput, Text, Popover, Button, Menu, toaster } from "evergreen-ui";
import Block from "@/components/common/Block";

export const ImagePreviewComponent = ({
  component,
  mediaItems,
  handleComponentUpdate,
}: {
  component: ImageComponent | MapImageComponent;
  mediaItems: MediaItems;
  handleComponentUpdate?: (component: ReportComponent) => void;
}) => {
  const mediaItemId = component?.mediaItemId;
  const mediaItem = mediaItemId ? mediaItems?.[mediaItemId] : undefined;
  const uploaded = mediaItem ? mediaItem.uploaded : false;
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageConfiguration, setImageConfiguration] = useState({
    description: component.description ?? "",
    imageWidth: component.imageWidth ?? 500,
    imagePosition: component.imagePosition ?? "center",
  });

  useEffect(() => {
    async function fetchImageFromMedia() {
      if (!mediaItem || !uploaded) {
        return;
      }
      const url = await fetchUrl(mediaItem.fullPath);
      setImageUrl(url);
    }
    fetchImageFromMedia();
  }, [component, mediaItem, uploaded]);

  function validateImageSize(e) {
    if (e.target.name !== "imageWidth") {
      return true;
    }
    if (e.target.value > 700) {
      toaster.danger("Image width can not be greater than 700px");
      return false;
    }

    if (isNaN(e.target.value)) {
      toaster.danger("Image width should be a number");
      return false;
    }

    return true;
  }

  function handleChanges(e) {
    if (!validateImageSize(e)) {
      return;
    }
    const updatedComponent = {
      ...component,
      [e.target.name]: e.target.value,
    };
    setImageConfiguration({
      ...imageConfiguration,
      [e.target.name]: e.target.value,
    });

    handleComponentUpdate && handleComponentUpdate(updatedComponent);
  }
  const imagePositions: ImagePosition[] = ["left", "right", "center"];
  const ImagePositionSelection = imageConfiguration.imagePosition && (
    <Popover
      content={
        <Menu>
          <Menu.Group>
            {imagePositions.map((position) => (
              <Menu.Item
                key={position}
                onSelect={() => {
                  handleChanges({
                    target: { name: "imagePosition", value: position },
                  });
                }}
              >
                {position}
              </Menu.Item>
            ))}
          </Menu.Group>
          <Menu.Divider />
        </Menu>
      }
    >
      <Button marginRight={16}>{imageConfiguration.imagePosition}</Button>
    </Popover>
  );

  const getPreviewImagePosition = () => {
    switch (imageConfiguration.imagePosition) {
      case "left":
        return "ml-0 w-1/2";
      case "right":
        return "ml-auto mr-0 w-1/2";
      default:
        return "mx-auto w-1/2";
    }
  };

  return (
    <>
      <h3 className="mb-4 text-center">
        <strong>{component.description}</strong>
      </h3>
      {uploaded ? (
        <Block className="mx-auto">
          <img
            className={getPreviewImagePosition()}
            src={imageUrl}
            alt={component.description}
          />
          <Block>
            <Text>Image Description</Text>
            <TextInput
              placeholder="Image description"
              value={imageConfiguration.description}
              name="description"
              onChange={handleChanges}
              className="w-1/2 self-center"
              width="100%"
              height={30}
              marginTop={10}
            />
          </Block>
          <Block>
            <Text>Image Width in Pixels</Text>
            <TextInput
              placeholder="Image width in Pixels"
              value={imageConfiguration.imageWidth}
              name="imageWidth"
              onChange={handleChanges}
              className="w-1/2 self-center"
              width="100%"
              height={30}
              marginTop={10}
            />
          </Block>
          <Block className="mt-4">
            <Text>Image Position : </Text>
            {ImagePositionSelection}
          </Block>
        </Block>
      ) : (
        <h3 className="mb-4 text-center">
          <strong>Image is Not Available</strong>
        </h3>
      )}
    </>
  );
};
